/**
 * 登录接口
 */
import axios from "../http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块，把请求字段直接映射过来

const login = {
  // 手机获取验证码
  phoneCode(params) {
    return axios.post("/api/phoneCode", qs.stringify(params));
  },
  //手机验证码登录
  phoneCodeLogin(params) {
    return axios.post("/api/phoneCodeLogin", qs.stringify(params));
  },
  wxlogin(params) {
    return axios.post("/api/wxlogin", qs.stringify(params));
  },
  wxBindPhoneLogin(params) {
    return axios.post("/api/wxBindPhoneLogin", qs.stringify(params));
  },


};

// 导出接口
export default login;
