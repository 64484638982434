<template>
  <div class="EditUserINfo invitation clearfix">
    <!-- :class="{back:back}" -->
    <van-nav-bar
      :z-index="99"
      :fixed="false"
      :border="false"
      title="邀请有礼"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y-usertop">
      <div class="y-setuptop f-l y-por-rectop">
        <h2>邀好友赚返利</h2>
        <i>{{memberExtendList.wenan}}</i>
        <!-- <i>邀请好友完成首单</i> -->
        <!-- <i>可获得首单金额5%的奖励金</i> -->
        <van-button
          type="primary"
          round
          color="#FCB326"
          class="y-setuptop-buttom"
          @click="showPopup"
        >立即邀请</van-button>
        <!-- <van-popup :close-on-click-overlay="false" v-model="show" closeable>
          <div class="y-mymodel">
            <p class="y-mymodel1">
              <van-image round :src="memberExtendList.info.infothumb" />
            </p>
            <div class="y-mymodel2">
              <p class="y-mymodel3 f-l">
                <img :src="memberExtendList.info.mathumb" />
              </p>
              <span>扫一扫二维码，做推广达人</span>
            </div>
          </div>
        </van-popup> -->
      </div>
      <div class="y-invit-gz" @click="show_gzPopup">
        <p>活动规则</p>
      </div>
      <van-popup :close-on-click-overlay="false" v-model="show_gz" class="y-gz-all">
        <div class="y-gz-top">
          <p>使用规则</p>
        </div>
        <div class="y-gz-wz">
          <p>1.分享链接给好友，好友注册后您即可获得500积分奖励；</p>
          <p>2.好友完成首单消费后您可获得此单消费总金额2%的返利金；</p>
          <p>3.返利金将直接兑换成积分，每1元=100积分，积分将加到您的积分账户内；</p>
          <p>4.积分可在您后续消费时直接抵现，也可换取积分商城内商品或代金券；</p>
          <p>5.如出现作弊行为（包括但不限于批量注册，恶意购买，虚拟交易等）捷捷将自动取消您获利积分，并拥有取消您后续参加捷捷任意活动的权力，必要时追究法律责任。</p>
        </div>
        <div class="y-gz-buttom">
          <van-button plain round closed type="primary" @click=" show_gzKnow">明白了</van-button>
        </div>
      </van-popup>
      <!-- 分享弹框 -->
       <van-popup v-model="show_fx" class="fenxiang" @click="show_fx=false">
         <div class="con">
           <img src="/statics/images/index/jiantou.png" alt="">
           <div>
             <span>请点击右上角“...”,</span>
             <span>选择“发送好友”</span>
           </div>
         </div>
       </van-popup>
      <div class="y-myxia">
        <van-row class="y-yg">
          <van-col span="12" class="y-presets">
            <p>{{memberExtendList.total}}</p>
            <span>已邀请好友</span>
          </van-col>
          <van-col span="12" class="y-presets">
            <p>{{memberExtendList.today}}</p>
            <span>今日新增(人)</span>
          </van-col>
        </van-row>
      </div>
      <div class="y-invitation f-l">
        <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
          <div class="y_storage_list f-l">
            <!-- <div class="y-record-list" v-for="(item,index) in list" :key="index">
            <h2>
              <p>{{item.nickname}}</p>
            </h2>
            <h3>
              <p>{{item.phone}}</p>
              <span>{{new Date(item.time).toLocaleString('chinese',{hour12:false})}}</span>
            </h3>
            </div>-->
            <div class="y-record-list" v-for="(item,index) in memberExtendList.list" :key="index">
              <h2>
                <p>{{item.name}}</p>
              </h2>
              <h3>
                <p>{{item.phone}}</p>
                <!-- <span>{{new Date(item.time).toLocaleString('chinese',{hour12:false})}}</span> -->
                <span>{{item.created_at}}</span>
              </h3>
            </div>
          </div>
        </van-pull-refresh>
      </div>
    </div>
    <!-- 加载框 -->
    <!-- <van-popup class="jiazai" v-model="showloading">
      <van-loading type="spinner" />
    </van-popup>-->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      shareConfig: {},
      showloading: false,
      isLoading: false,
      show: false,
      show_gz: false,
      show_fx: false,
      back: false,
      curTab: "-1",
      memberExtendList: {
        info: {
          infothumb: "",
          mathumb: ""
        }
      },
      list: [
        {
          id: 0,
          state: 1,
          time: 12345678911123,
          nickname: "可画",
          phone: "13811006973"
        },
        {
          id: 0,
          state: 0,
          time: 12345678911123,
          nickname: "hotwind热风",
          phone: "13811006973"
        },
        {
          id: 1,
          state: 1,
          time: 12345678911123,
          nickname: "嘉宝",
          phone: "13811006973"
        },
        {
          id: 0,
          state: 1,
          time: 12345678911123,
          nickname: "可画",
          phone: "13811006973"
        },
        {
          id: 0,
          state: 0,
          time: 12345678911123,
          nickname: "hotwind热风",
          phone: "13811006973"
        },
        {
          id: 1,
          state: 1,
          time: 12345678911123,
          nickname: "嘉宝",
          phone: "13811006973"
        }
      ]
    };
  },
  created: function() {
    this.grtMemberExtendList();
    this.shareFun();
  },
  methods: {
    //下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        this.grtLaMemberExtendList();
      }, 1000);
    },
    grtMemberExtendList() {
      this.showloading = true;
      var param = {
        token: this.$store.getters.token
      };
      this.$api.member.memberExtendList(param).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.memberExtendList = res.result;
          if (this.memberExtendList.info.thumb.indexOf("http") == -1) {
            this.memberExtendList.info.infothumb =
              this.$publicData.imgUrl + this.memberExtendList.info.thumb;
          } else {
            this.memberExtendList.info.infothumb = this.memberExtendList.info.thumb;
          }
          if (this.memberExtendList.info.extend_thumb.indexOf("http") == -1) {
            this.memberExtendList.info.mathumb =
              this.$publicData.imgUrl + this.memberExtendList.info.extend_thumb;
          } else {
            this.memberExtendList.info.mathumb = this.memberExtendList.info.extend_thumb;
          }
        }
      });
    },
    grtLaMemberExtendList() {
      var param = {
        token: this.$store.getters.token
      };
      this.$api.member.memberExtendList(param).then(res => {
        if (res.code == 200) {
          this.memberExtendList = res.result;
          if (this.memberExtendList.info.thumb.indexOf("http") == -1) {
            this.memberExtendList.info.infothumb =
              this.$publicData.imgUrl + this.memberExtendList.info.thumb;
          } else {
            this.memberExtendList.info.infothumb = this.memberExtendList.info.thumb;
          }
          if (this.memberExtendList.info.extend_thumb.indexOf("http") == -1) {
            this.memberExtendList.info.mathumb =
              this.$publicData.imgUrl + this.memberExtendList.info.extend_thumb;
          } else {
            this.memberExtendList.info.mathumb = this.memberExtendList.info.extend_thumb;
          }
        }
      });
    },
    showPopup() {
      // this.show = true;
      this.show_fx = true;
    },
    show_gzPopup() {
      this.show_gz = true;
    },
    show_gzKnow() {
      this.show_gz = false;
    },
    shareFun() {
        var that = this;
        var tempurl = "https://member.jiejieglass.com/#/home";
        if(this.$store.getters.user_id != undefined && this.$store.getters.user_id!=null){
            if(location.href.indexOf("?") == -1){// 不存在？ 则没有参数
                tempurl = location.href + '?type=member&sharememberid='+this.$store.getters.user_id;
            }else{
                if(location.href.indexOf("sharememberid") == -1){// 不存在则直接加
                    tempurl = location.href + '&type=member&sharememberid='+this.$store.getters.user_id;
                }else{ // 参数替换
                    var heheurl = location.href;
                    var heheArr = heheurl.split('&');
                    tempurl = "";
                    for(let i in heheArr){
                        if(i == 0 ){
                           tempurl = heheArr[0]; 
                           continue;
                        }

                        if(heheArr[i].indexOf('sharememberid=') != -1){
                            tempurl +=  "&sharememberid=" + this.$store.getters.user_id;
                        }else{
                            tempurl +=  "&" + heheArr[i];
                        }
                    }
                }
            }
        }
        const url = tempurl;
        console.log("home-share----url=" + url);
        const shareUrl = location.href.split("#")[0];
        that.$api.common.commonShare({url: shareUrl}).then(res => {
            if (res.code == 200) {
                that.shareConfig = res.result;
                wx.config({
                    debug: that.shareConfig.debug,
                    beta: that.shareConfig.beta,
                    jsApiList: that.shareConfig.jsApiList,
                    appId: that.shareConfig.appId,
                    nonceStr: that.shareConfig.nonceStr, // 随机串
                    timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
                    signature: that.shareConfig.signature // 签名
                });
                // 分享
                wx.ready(function () {
                    var shareDataA = {
                        title: that.$publicData.shareTitle, // 分享标题
                        desc: that.$publicData.shareDesc, // 分享描述
                        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.$publicData.imgUrl + that.$publicData.img, // 分享图标
                        success: function () {
                        },
                        cancel: function (e) {
                        }
                    };

                    var shareDataB = {
                        title: that.$publicData.shareTitle, // 分享标题
                        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.$publicData.imgUrl + that.$publicData.img, // 分享图标
                        success: function () {
                        },
                        cancel: function (e) {
                        }
                    };
                    wx.updateAppMessageShareData(shareDataA); // 分享给朋友
                    wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
                });
            }
        });
    },
  },
    // TabBar() {
    //   var userOld = document.getElementsByClassName("y-myxia")[0];
    //   var that = this;
    //   if (userOld.scrollHeight - scrollY <= 100) {
    //     that.back = true;
    //   } else {
    //     that.back = false;
    //   }
    // }
  mounted() {
    // window.addEventListener("scroll", this.TabBar);
  }
};
</script>
