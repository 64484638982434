<template>
  <div class="y-all loginphone">
    <!-- <div class="y-dd"> -->
    <!-- <h2>Hi,早上好！</h2>
    <p>欢迎使用捷捷配镜，轻松预约上门定制，制作完成送货上门，不用担心路程远没时间的问题！</p>-->
    <!-- </div>  -->
    <div class="y-login">
      <p class="y-log-p">绑定您的手机号</p>
      <van-cell-group class="filegroup">
        <p class="labelname">手机号码</p>
        <van-field v-model="tel" type="tel" placeholder="请输入手机号码">
          <template #button>
            <!-- 发送验证码 -->
            <van-button class="yanzheng" size="small" type="primary" @click="sendyzma">发送验证码</van-button>
            <!-- 倒计时  -->
            <van-count-down
              class="daoshu"
              ref="countDown"
              millisecond
              :time="60000"
              :auto-start="false"
              format="ss秒后重新发送"
              @finish="finish"
            />
          </template>
        </van-field>
        <p class="labelname">手机验证码</p>
        <van-field v-model="sms" placeholder="请输入手机验证码" />
      </van-cell-group>
    </div>
    <van-button
      @click="toHome"
      round
      type="primary"
      block
      color="linear-gradient(to right, #35B6D8, #52D3C5)"
    >确认绑定</van-button>
  </div>
</template>

<script>
import { LOGOUT, LOGIN } from "../../vuex/mutation_types";
export default {
  name: "login",
  data() {
    return {
      tel: "",
      sms: "",
      wxInfo:{},
    };
  },
  created: function() {
    this.wxInfo = JSON.parse(window.localStorage.getItem("bindPhoneInfo"));
  },
  methods: {
    //点击发送验证码
    sendyzma() {
      // console.log(this.$api);
      if (this.tel) {
        var t = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        if (t.test(this.tel)) {
          //手机号格式正确
          var param = {
            phone: this.tel
          };
          this.$api.login.phoneCode(param).then(res => {
            if (res.code == 200) {
              // console.log(res);
              document.getElementsByClassName("yanzheng")[0].style.display =
                "none";
              document.getElementsByClassName("daoshu")[0].style.display =
                "block";
              this.$refs.countDown.start(); //开始
            }
          });
        } else {
          this.$toast("手机号输入错误");
        }
      } else {
        this.$toast("请输入手机号");
      }
    },
    //点击手机验证密码登录
    toHome() {
      var type = window.localStorage.getItem("extendType");
      var id = window.localStorage.getItem("extendId");
      // console.log('binphone'+ type);
      // console.log('binphone'+ id);
      var t = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (this.tel && t.test(this.tel)) {
        var param = {
          phone: this.tel,
          phonecode: this.sms,
          wxInfo:this.wxInfo,
        };
        if(type != undefined && type != '' && type != null){
          param.type = type;
        }
        if(id != undefined && id != '' && id != null){
          param.id = id;
        }
        this.$api.login.wxBindPhoneLogin(param).then(res => {
          if (res.code == 200) {
            this.$store.commit(LOGIN, {
              token: res.result.token,
              result: JSON.stringify(res.result),
              id:res.result.id,
              name:res.result.name,
            });
            var inPath = window.localStorage.getItem("inPath");
            // console.log(inPath);
            if(inPath !=undefined && inPath != null && inPath != ''){
              window.localStorage.removeItem("inPath");
              this.$router.push({
                path: inPath
              });
              return ;
            }
            this.$router.push({
              path: "/home"
            });
          }
        });
      }
    },
    // 倒计时结束
    finish() {
      // this.$toast("倒计时结束");
      document.getElementsByClassName("daoshu")[0].style.display = "none";
      document.getElementsByClassName("yanzheng")[0].style.display = "block";
      this.$refs.countDown.reset(); //重置
    }
  }
};
</script>
<style scoped>
/* .loginphone .y-login-wx {
  margin-top: 35px;
  width: 80%;
  margin-left: 10%;
  text-align: center;
}
.loginphone .van-button--primary {
  border: none;
  background-color: #ffffff;
  color: #35b6d8;
}
.loginphone{
  width: 75%;
  height: 80vh;
  margin: auto;
  position: relative;
}
.loginphone .y-dd {
  margin-top: 110px;
  padding: 0px;
}
.loginphone .y-dd h2 {
  text-align: left;
  font-size: 30px;
  line-height: 30px;
  font-weight: normal;
}
.loginphone .y-dd p {
  font-size: 0.35rem;
  color: #666;
  line-height: 24px;
  margin-top: 10px;
  text-align: left;
}
.loginphone .y-login {
  margin-bottom: 40px;
}
.loginphone .y-log-p {
  font-size: 0.53rem;
  text-align: left;
  font-weight: bold;
  margin-bottom: 1.25rem;
  margin-top: 1.24rem;
}
.loginphone .y-login .labelname {
  padding-top: 10px;
  font-size: 0.35rem;
  color: #5c5c5c;
}
.loginphone .y-login .van-cell {
  padding: 13px 17px 13px 0px !important;
}
.loginphone .y-login .filetext {
  border-bottom: 0.0133rem solid #e5e5e5;
}

.loginphone .y-login .filetext {
  padding: 0.133rem 0;
  font-size: 0.35rem;
  color: #e0e0e0;
}
.loginphone .van-count-down {
  font-size: 0.35rem;
  color: rgba(0, 0, 0, 0.2);
  border: 0.01rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.36rem;
  padding: 0.1rem 0.2rem;
}
.loginphone .daoshu {
  display: none;
}
.loginphone .van-button {
  position: absolute;
  bottom: 0;
  right: 0;
}
.loginphone .van-cell::after {
  right: 0;
  left: 0;
} */
</style>
