<template>
  <div class="receiving_information">
    <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y_add_storage y-rec-info">
      <van-address-edit
        :area-list="areaList"
        :show-postal="false"
        :show-delete="false"
        show-set-default
        show-search-result
        :search-result="searchResult"
        :area-columns-placeholder="['请选择', '请选择', '请选择']"
        @save="onSave"
        @delete="onDelete"
        @change-detail="onChangeDetail"
      />
      <!-- <div class="y-edit-button">
        <van-button
          to="home"
          round
          type="primary"
          block
          color="linear-gradient(to right, #35B6D8, #52D3C5)"
        >保存</van-button>
      </div> -->
    </div>
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";

export default {
  data() {
    return {
      title: "新建收货地址",
      areaList,
      searchResult: []
    };
  },
  methods: {
    onSave() {
      this.$toast("save");
    },
    onDelete() {
      this.$toast("delete");
    },
    onChangeDetail(val) {
      if (val) {
        this.searchResult = [
          {
            name: "黄龙万科中心",
            address: "杭州市西湖区"
          }
        ];
      } else {
        this.searchResult = [];
      }
    }
  }
};
// export default {
//   data() {
//     return {
//       title: "新建收件地址",
//       nickname: this.$route.params.id,
//       username: "",
//       password: ""
//     };
//   },
//   methods: {
//     onSubmit(values) {
//       console.log("submit", values);
//     }
//   }
// };
</script>
<style scoped>
/* .receiving_information ::v-deep .y-rec-info .van-button--danger{
background-image: linear-gradient(to right, #35B6D8 ,  #52D3C5);
border: none;
}
.receiving_information .y_add_storage{
  min-height: 94vh;
}
.receiving_information ::v-deep .van-address-edit__buttons{
      width: 94%;
    float: left;
    margin-left: 2%;
    bottom: 0;
    z-index: 1000;
    position: fixed;
    left: 0;
    bottom: 0;
    margin-bottom: 25px;
}
.receiving_information .y-rec-info .van-address-edit {
  width: 100%;
  padding: 0px;
}
.receiving_information .y-rec-info .van-address-edit__default{
  border-radius: 0px !important;
}
.receiving_information ::v-deep .van-switch--on {
  background-color: #35b6d8 !important;
}
.receiving_information .van-nav-bar .van-icon {
  color: #000;
}
.receiving_information .y-edit-button {
  width: 94%;
  float: left;
  margin-left: 3%;
  bottom: 0;
  z-index: 1000;
  position: fixed;
  left: 0;
  bottom: 0;
  margin-bottom: 25px;
} */
</style>