<template>
  <div class="comment_list">
    <van-nav-bar
      :fixed="true"
      :placeholder="true"
      title="评论中心"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y_add_storage">
      <van-tabs background="#F3F4F9" v-model="curTab">
        <van-tab title="全部" name="-1">
          <div class="y_storage_list">
            <div class="y_storage_list_item" v-for="item in list" :key="item.id">
              <router-link to="service_details">
                <div class="y_storage_no">
                  <span>{{new Date(item.time).toLocaleString('chinese',{hour12:false})}}</span>
                  <span v-if="item.state==1" class="y_storage_no_state_finish">已评价</span>
                  <span v-else-if="item.state==0" class="y_storage_no_state_collect">待评价</span>
                </div>
              </router-link>
              <div class="y-user-oldxia f-l y-op_orderxia y-xzcptop-img y-order-list">
                <van-card
                  num="1"
                  price="1599.99"
                  title="男女复古超轻圆框眼镜框 树脂材质耐磨抗压"
                  thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
                >
                  <template #tags>
                    <van-tag color="#F3F4F9" text-color="#999">防蓝光1.61非球面镜片0-800 | 度数留言</van-tag>
                  </template>
                </van-card>
              </div>
              <!-- <div class="y-user-oldxia f-l y-op_orderxia y-xzcptop-img y-order-list">
                <van-card
                  num="1"
                  price="1599.99"
                  title="男女复古超轻圆框眼镜框 树脂材质耐磨抗压"
                  thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
                >
                  <template #tags>
                    <van-tag color="#F3F4F9" text-color="#999">防蓝光1.61非球面镜片0-800 | 度数留言</van-tag>
                  </template>
                </van-card>
              </div>-->
              <div class="y-order-money">
                <p>总价¥4499.00，优惠¥90.00</p>
                <span>合计：¥4409.00</span>
              </div>
              <div class="y-appo-qx">
                  <van-button
                  round
                  size="small"
                  color="#35B6D8"
                  plain
                  style="float: right; padding:5px 14px;"
                >追加评价</van-button>
                 <van-button
                  round
                  size="small"
                  color="#999"
                  plain
                  style="float: right; padding:5px 14px;"
                >查看详情</van-button>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="待评价" name="0"></van-tab>
        <van-tab title="已评价" name="1"></van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      curTab: "-1",
      list: [
        {
          id: 0,
          state: 0,
          time: 1234567891012,
          source: 0
        },
        {
          id: 2,
          state: 1,
          time: 1234567891012,
          source: 1
        },
        {
          id: 3,
          state: 1,
          time: 1234567891012,
          source: 0
        }
      ]
    };
  }
};
</script>

<style scoped>
/* .comment_list .y-appo-qx {
  width: 90%;
  float: right;
  padding: 12px 5%;
  border-top: 1px #eeeeee solid;
}
.comment_list .y-appo-qx .van-button {
  width: 82px;
  margin: 0px 5px;
}
.comment_list .y-order-money span {
  font-size: 0.34rem;
}
.comment_list .y-order-money p {
  color: #999;
  float: left;
  padding: 0px 8px;
}
.comment_list .van-card {
  background-color: transparent !important;
}
.comment_list .van-card__title {
  font-size: 0.34rem;
  line-height: 18px;
  float: left;
  max-height: 42px;
}
.comment_list .van-tag {
  margin-top: 8px;
}
.comment_list .y-order-money {
  float: left;
  font-size: 0.34rem;
  padding: 10px 15px;
  float: right;
}

.comment_list .van-button {
  height: auto;
}

.comment_list ::v-deep .van-tabs__line {
  background-color: transparent;
}
.comment_list ::v-deep .van-tab--active {
  font-size: 0.4rem;
  font-weight: bold;
}
.comment_list .van-tabs__line {
  background-color: transparent !important;
}
.comment_list .van-tab--active {
  font-size: 0.34rem;
}
.comment_list .y_add_storage {
  background-color: #f3f4f9;
  min-height: 95vh;
  float: left;
  width: 100%;
}
.comment_list .y_storage_list {
  padding: 0 15px;
}
.comment_list .y_storage_list .y_storage_list_item {
  width: 100%;
  float: left;
  margin: 8px 0;
  border-radius: 7.5px;
  background-color: #fff;
  box-shadow: 0px 2px 5px #eeeeee;
}
.comment_list .y_storage_no {
  height: 45px;
  line-height: 45px;
  padding: 0 12px;
  font-size: 0.34rem;
  color: #000;
  padding-right: 0;
  border-bottom: 1px #eeeeee solid;
  position: relative;
}
.comment_list .y_storage_no div {
  float: left;
}

.comment_list .y_storage_no_tag_zb {
  background-color: #5cc6da;
}
.comment_list .y_storage_no_tag_db {
  background-color: #7fc89e;
}
.comment_list .y_storage_no_num {
  margin-left: 8px;
  font-size: 0.34rem;
  line-height: 21px;
}
.comment_list .y_storage_no_state {
  padding: 5px 14px;
  font-size: 0.34rem;
  margin-top: 10px;
  float: right;
  line-height: 13px;
  border: 1px #eee solid;
  border-radius: 25px 0 0 25px;
}
.comment_list .y_storage_no_state_collect {
  border: 1px #f54749 solid;
  color: #f54749;
  border-right: none;
  padding: 5px 14px;
  font-size: 0.34rem;
  margin-top: 10px;
  float: right;
  line-height: 13px;
  border-radius: 25px 0 0 25px;
}
.comment_list .y_storage_no_state_finish {
  border: 1px #35b6d8 solid;
  color: #35b6d8;
  border-right: none;
  padding: 5px 14px;
  font-size: 0.34rem;
  margin-top: 10px;
  float: right;
  line-height: 13px;
  border-radius: 25px 0 0 25px;
  border-right: none;
}
.comment_list .y_stirage_info {
  padding-left: 12px;
  padding: 15px 12px;
}
.comment_list .y_stirage_info_name {
  font-size: 0.34rem;
  line-height: 18px;
}
.comment_list .y_stirage_info_time {
  font-size: 0.34rem;
  color: #999;
  padding: 3px 0px;
} */
</style>