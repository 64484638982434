<template>
    <div class="y-all loginwx">
        <div class="y-dd"></div>
        <div class="y-login_wx_img">
            <img src="../../assets/images/logo.png"/>
        </div>
        <van-button
                :icon="require('/src/assets/images/login-wx.png')"
                round
                type="primary"
                block
                color="linear-gradient(to right, #35B6D8, #52D3C5)"
                @click="wxlogin"
        >微信登录
        </van-button>
        <div class="y-login-wx" v-if="!iswei">
            <van-divider>or</van-divider>
            <router-link to="/login">
                <h2>手机登录</h2>
            </router-link>
        </div>
    </div>
</template>
<script>
    import {LOGOUT, LOGIN} from "../.././vuex/mutation_types";
    import wx from "weixin-js-sdk";
    export default {
        name: "login",
        components: {},
        props: {},
        watch: {},
        data() {
            return {
                shareConfig: {},
                iswei: true,
                tel: "",
                sms: "",
                getCodeUrl: "http://admin.jiejieglass.com/api/wxcode?url=/login_wx", // 正式
                // getCodeUrl: "http://jiejie.shanmuzhi.com/api/wxcode?url=/login_wx", // 测试
                memberUrl: "http://member.jiejieglass.com/#",
                // memberUrl: "http://jiejie.shanmuzhi.com/#",
            };
        },
        created: function () {
            this.shareFun();
            var codeArr = this.getUrlCode();
            // 如果是推广码进来的，记录推广信息
            if (codeArr["type"] != undefined) {
                window.localStorage.setItem("extendType", codeArr["type"]);
                window.localStorage.setItem("extendId", codeArr["id"].split('#')[0]);
            }
            // 判断有没有token 有的话直接跳个人中心。
            var token = this.$store.getters.token;
            if (token && token != undefined) {
                this.$router.push({
                    path: "/home"
                });
                return;
            }
            if (codeArr["code"] != undefined) {
                var code = codeArr["code"];
                var params = {
                    code: code
                };
                // 绑定
                this.$api.login.wxlogin(params).then(res => {
                    if (res.code == 200) {
                        this.$store.commit(LOGIN, {
                            token: res.result.token,
                            result: JSON.stringify(res.result),
                            id:res.result.id,
                            name:res.result.name,
                        });
                        var inPath = window.localStorage.getItem("inPath");
                        if (inPath != undefined && inPath != null && inPath != '') {
                            window.localStorage.removeItem("inPath");
                            window.location.href = this.memberUrl + inPath;
                            return;
                        }
                        window.location.href = this.memberUrl + "/home";
                    } else if (res.code == 409) {
                        window.localStorage.setItem(
                            "bindPhoneInfo",
                            JSON.stringify(res.result)
                        );
                        window.location.href = this.memberUrl + "/login_phone";
                    }
                });
            }
            this.iswei = this.isWeiXin();
        },
        methods: {
            isWeiXin: function () {
                var isOnline = location.href.indexOf('member.jiejieglass.com') > 0
                if (!isOnline) {
                    return false; // 测试服打开此处 允许 手机号登录。?
                }
                var ua = window.navigator.userAgent.toLowerCase()
                if (ua.indexOf('micromessenger') !== -1) {
                    return true
                } else {
                    return false
                }
            },

            // 截取url中的code方法
            getUrlCode() {
                var allUrl = location.href;
                var url = allUrl.substring(allUrl.indexOf("?"), allUrl.length);
                this.winUrl = url;
                var theRequest = new Object();
                if (url.indexOf("?") != -1) {
                    var str = url.substr(1);
                    var strs = str.split("&");
                    for (var i = 0; i < strs.length; i++) {
                        theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
                    }
                }
                return theRequest;
            },
            // 微信登录
            wxlogin: function () {
                var that = this;
                setTimeout(function () {
                    window.location.href = that.getCodeUrl;
                    that.showChatPop = false;
                }, 1);
            },
            shareFun() {
                var that = this;
                var tempurl = location.href;
                if(this.$store.getters.user_id != undefined && this.$store.getters.user_id!=null){
                    if(location.href.indexOf("?") == -1){// 不存在？ 则没有参数
                        tempurl = location.href + '?type=member&sharememberid='+this.$store.getters.user_id;
                    }else{
                        if(location.href.indexOf("sharememberid") == -1){// 不存在则直接加
                            tempurl = location.href + '&type=member&sharememberid='+this.$store.getters.user_id;
                        }else{ // 参数替换
                            var heheurl = location.href;
                            var heheArr = heheurl.split('&');
                            tempurl = "";
                            for(let i in heheArr){
                                if(i == 0 ){
                                   tempurl = heheArr[0]; 
                                   continue;
                                }

                                if(heheArr[i].indexOf('sharememberid=') != -1){
                                    tempurl +=  "&sharememberid=" + this.$store.getters.user_id;
                                }else{
                                    tempurl +=  "&" + heheArr[i];
                                }
                            }
                        }
                        // tempurl = location.href + '&type=member&sharememberid='+this.$store.getters.user_id;
                    }
                }
                const url = tempurl;
                console.log("wx_login_share----url=" + url);
                const shareUrl = location.href.split("#")[0];
                that.$api.common.commonShare({url: shareUrl}).then(res => {
                    if (res.code == 200) {
                        that.shareConfig = res.result;
                        wx.config({
                            debug: that.shareConfig.debug,
                            beta: that.shareConfig.beta,
                            jsApiList: that.shareConfig.jsApiList,
                            appId: that.shareConfig.appId,
                            nonceStr: that.shareConfig.nonceStr, // 随机串
                            timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
                            signature: that.shareConfig.signature // 签名
                        });
                        // console.log('wx_login_share微信分享设置---开始');
                        // 分享
                        wx.ready(function () {
                            var shareDataA = {
                                title: that.$publicData.shareTitle, // 分享标题
                                desc: that.$publicData.shareDesc, // 分享描述
                                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                imgUrl: that.$publicData.imgUrl +that.$publicData.img, // 分享图标
                                success: function () {
                                },
                                cancel: function (e) {
                                }
                            };

                            var shareDataB = {
                                title: that.$publicData.shareTitle, // 分享标题
                                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                imgUrl: that.$publicData.imgUrl + that.$publicData.img, // 分享图标
                                success: function () {
                                },
                                cancel: function (e) {
                                }
                            };
                            wx.updateAppMessageShareData(shareDataA); // 分享给朋友
                            wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
                            // console.log('wx_login_share微信分享设置---结束');
                        });
                    }
                });
            },
        },
        mounted() {
        }
    };
</script>
<style scoped>
</style>
