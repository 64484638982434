<template>
  <div class="EditUserINfo myorderdetails">
    <!-- :class="{back:back}" -->
    <van-nav-bar
      :z-index="99"
      :border="false"
      title="订单详情"
      :fixed="false"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y-usertop">
      <div class="y-setuptop f-l y-order-det">
        <img v-show="bigStatus == -1" src="../../assets/images/order_wait.png" />
        <p v-show="bigStatus == -1">已取消</p>
        <img v-show="bigStatus == 1" src="../../assets/images/order_wait.png" />
        <p v-show="bigStatus == 1">待收货</p>
        <img v-show="bigStatus == 2" src="../../assets/images/order-finish.png" />
        <p v-show="bigStatus == 2">已完成</p>
      </div>
      <div class="y-order-1">
        <div class="y-order1-left f-l">
          <van-icon name="location-o" color="#000" size="18" />
        </div>
        <div class="y-order1-con f-l">
          <p>{{OrderDetail.name}} {{OrderDetail.phone}}</p>
          <span>{{OrderDetail.address}}</span>
        </div>
      </div>
      <div class="y-user-old1 f-l">
        <div class="y-order-top">
          <p>产品信息</p>
        </div>
        <div v-for="(item,index) in OrderDetail.member_order_goods" :key="index">
          <div class="y-user-oldxia f-l y-op_orderxia y-xzcptop-img y-order-list">
            <van-card
              v-if="OrderDetail.order_type == 1"
              :num="item.num"
              :price="item.goods_spec.member_discount"
              :origin-price="item.goods_spec.member_price"
              :title="item.goods.title"
              :thumb="item.goods.goodsthumb"
            >
              <template #tags>
                <van-tag color="#F3F4F9" text-color="#999">
                  <span class="slh">{{item.goods_spec.goods_spec_name}}</span>
                </van-tag>
              </template>
            </van-card>
            <van-card
              v-if="OrderDetail.order_type == 2"
              :num="item.num"
              :title="item.goods.title"
              :thumb="item.goods.goodsthumb"
            >
              <template #tags>
                <van-tag color="#F3F4F9" text-color="#999">
                  <span class="slh">{{item.goods_spec.goods_spec_name}}</span>
                </van-tag>
              </template>
              <template #price>
                <span>{{item.score_price}}积分</span>
              </template>
            </van-card>
          </div>
        </div>
      </div>
      <div class="y-order-xx clearfix">
        <p class="clearfix">
          <i>订单编号</i>
          <span>{{OrderDetail.order_sn}}</span>
        </p>
        <p class="clearfix">
          <i>下单时间</i>
          <span>{{OrderDetail.created_at}}</span>
        </p>
        <p class="clearfix">
          <i>支付方式</i>
          <span>{{OrderDetail.pay_way}}</span>
        </p>
        <p class="clearfix">
          <i>交易时间</i>
          <span>{{OrderDetail.pay_at}}</span>
        </p>
        <p class="clearfix">
          <i>备注</i>
          <span>{{OrderDetail.remark}}</span>
        </p>
      </div>
      <div class="y-user-old1 f-l">
        <div class="y-user-olddate f-l y-op_orderpay">
          <p v-if="OrderDetail.order_type == 1">
            商品总额
            <!-- <span>¥{{OrderDetail.origin_total_price}}</span> -->
            <span>¥{{payprice.toFixed(2)}}</span>
          </p>
          <p v-if="OrderDetail.order_type == 1">
            运费
            <span>-¥{{OrderDetail.peisong_price}}</span>
          </p>
          <p v-if="OrderDetail.order_type == 1">
            积分抵用
            <span>-¥{{OrderDetail.score_price}}</span>
          </p>
          <p v-if="OrderDetail.order_type == 1">
            优惠券
            <span>-¥{{OrderDetail.preferential}}</span>
          </p>
          <p v-if="OrderDetail.order_type == 1">
            会员等级折扣 ({{OrderDetail.member.member_level.name}})
            <span>(-￥{{zkyoumoney.toFixed(2)}}) {{parseFloat(OrderDetail.member.member_level.discount)}}折</span>
          </p>
          <p class="y-paymoney" v-if="OrderDetail.order_type == 1">
            实付金额：
            <!-- <span>¥{{OrderDetail.final_pay_price}}</span> -->
            <span>¥{{payPriceall.toFixed(2)}}</span>
          </p>
          <p v-if="OrderDetail.order_type == 2">
            运费
            <span>{{(OrderDetail.peisong_price)*(OrderDetail.score_rate)}}积分</span>
          </p>
          <p v-if="OrderDetail.order_type == 2">
            商品总积分:
            <span>{{OrderDetail.use_score_number}}积分</span>
          </p>
        </div>
      </div>
      <div class="y-foot-bottom" v-if="bigStatus == 1">
        <van-tabbar v-model="finactive" class="y-order-foot">
          <!-- <router-link> -->
          <van-button
            @click="receipt"
            round
            size="small"
            color="#35B6D8"
            plain
            style="float: right;"
          >确认收货</van-button>
        </van-tabbar>
      </div>

      <div class="y-foot-bottom" v-if="bigStatus == 2">
        <van-tabbar v-model="finactive" class="y-order-foot">
          <!-- <router-link> -->
          <van-button
            @click="toOrder_evaluate"
            v-if="OrderDetail.is_comment == 1"
            round
            size="small"
            color="#35B6D8"
            plain
            style="float: right;"
          >查看评价</van-button>
          <van-button
            v-if="OrderDetail.is_comment == 0"
            round
            size="small"
            color="#35B6D8"
            plain
            style="float: right;"
            @click="toOrder_comment"
          >评价</van-button>
        </van-tabbar>
      </div>
    </div>
    <!-- 加载框 -->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      zkyoumoney:0,
      payprice:0,
      payPriceall: 0,
      priceall: 0,
      showloading: false,
      OrderDetail: {},
      bigStatus: "",
      goodsthumb: "",

      finactive: "",
      show: false,
      back: false,
      pjOrderDetail: {
        yg_order: {
          member: {
            default_address: {
              name: ""
            }
          }
        }
      }
    };
  },
  created: function() {
    this.getMyOrderDetail();
  },
  methods: {
    //配镜订单详情接口
    getMyOrderDetail() {
      this.showloading = true;
      var param = {
        token: this.$store.getters.token,
        order_id: this.$route.query.id
      };
      this.$api.goodsOrder.detail(param).then(res => {
        this.showloading = false;
        this.OrderDetail = res.result;
        for (var m in this.OrderDetail.member_order_goods) {
          var price =
            this.OrderDetail.member_order_goods[m].goods_spec.member_price *
            this.OrderDetail.member_order_goods[m].num;
          this.priceall += price; 
          this.payprice =
            this.OrderDetail.member_order_goods[m].goods_spec.member_discount *
            this.OrderDetail.member_order_goods[m].num;
          this.payPriceall += this.payprice; //商品总额
        }

        var pay = this.payPriceall + this.OrderDetail.peisong_price*1 -this.OrderDetail.score_price*1 - this.OrderDetail.preferential*1;//实付金额
        this.zkyoumoney = pay*(1-this.OrderDetail.member.member_level.discount/10);
        this.payPriceall = pay-this.zkyoumoney;
        this.bigStatus = this.$route.query.bigStatus;
        for (var i in this.OrderDetail.member_order_goods) {
          var pic = this.OrderDetail.member_order_goods[i].goods.thumb;
          if (pic.indexOf("http") == -1) {
            this.OrderDetail.member_order_goods[i].goods.goodsthumb =
              this.$publicData.imgUrl + pic;
          } else {
            this.OrderDetail.member_order_goods[i].goods.goodsthumb = pic;
          }
        }
      });
    },
    // 点击确认收货
    receipt() {
      var param = {
        token: this.$store.getters.token,
        order_id: this.$route.query.id
      };
      this.$api.goodsOrder.recive(param).then(res => {
        if (res.code == 200) {
          this.$toast("已确认收货");
          this.getMyOrderDetail();
          this.$router.push({
            path: "order_list"
          });
        }
      });
    },
    //跳转到评价
    toOrder_comment() {
      var id = this.$route.query.id;
      var bigStatus = this.$route.query.bigStatus;
      // console.log("id", id);
      this.$router.push({
        path: "/order_comment",
        query: {
          id: id,
          bigStatus: bigStatus,
          type: 1
        }
      });
    },
    //跳转到查看评价
    toOrder_evaluate() {
      //   this.$router.push({
      //     path: "/order_evaluate",
      //     query: {
      //       order_sn: this.pjOrderDetail.order_sn
      //     }
      //   });
    },
    showPopup() {
      this.show = true;
    }
  }
};
</script>
<style scoped>
/* .myorderdetails .van-nav-bar {
  position: absolute;
  width: 100%;
}
.myorderdetails .van-card__title {
  margin-bottom: 0.15rem;
}
.myorderdetails .back {
  background: #fff !important;
}
.myorderdetails .back .van-icon {
  color: #000 !important;
}
.myorderdetails ::v-deep.back .van-nav-bar__title {
  color: #000 !important;
}
.myorderdetails .y-foot-bottom {
  width: 100%;
  float: left;
  height: 60px;
}
.myorderdetails .y-order-foot {
  width: 94%;
  padding-left: 3%;
  float: left;
  border-top: 1px #eeeeee solid;
}
.myorderdetails .y-order-foot .van-button {
  width: 82px;
  float: right;
  height: 25px;
  margin: 11px 5px;
}

.myorderdetails .y-paymoney {
  text-align: right;
}
.myorderdetails .y-paymoney span {
  width: auto !important;
  font-size: 0.35rem !important;
  font-weight: bold;
  color: #ee1f0f !important;
}
.myorderdetails .y-user-olddate p {
  width: 92%;
  margin-left: 4%;
  font-size: 0.35rem;
  padding: 7px 0px;
}
.myorderdetails .y-user-olddate p span {
  width: 110px;
  font-size: 0.35rem;
  color: #000;
  float: right;
  text-align: center;
}
.myorderdetails .y-user-olddate {
  padding: 6px 0px;
  font-size: 0.35rem;
  width: 100%;
}
.myorderdetails .y-user-old1 {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 15px 0px;
  color: #999;
  margin-top: 10px;
  box-shadow: 0px 2px 5px #eeeeee;
}
.myorderdetails ::v-deep .van-nav-bar__title {
  color: #ffffff !important;
}
.myorderdetails .van-popup--bottom {
  width: 90%;
  padding: 10px 5% 0px 5%;
}
.myorderdetails .van-cell {
  border: 1px #eeeeee solid;
  padding: 8px 15px !important;
  margin: 40px 0px 15px 0px;
}
.myorderdetails ::v-deep .van-popup__close-icon {
  color: #999 !important;
  margin-top: -10px;
}
.myorderdetails .y-order-s,
.myorderdetails .y-order-m {
  padding: 8px 18px;
  border: 1px #35b6d8 solid;
  float: left;
}
.myorderdetails .y-order-m {
  border-radius: 0px 20px 20px 0px;
}
.myorderdetails .y-order-m img {
  width: 19px;
  float: left;
}
.myorderdetails .y-order-s {
  border-radius: 20px 0px 0px 20px;
  color: #35b6d8;
  font-size: 0.35rem;
  margin-right: 10px;
}

.myorderdetails .y-bottom20 {
  margin-bottom: 20px;
}

.myorderdetails .y-order-xx p i {
  width: 70px;
  float: left;
  font-size: 0.35rem;
  color: #999;
}
.myorderdetails .y-order-xx p span {
  font-size: 0.35rem;
  color: #000;
}
.myorderdetails .y-order-xx p {
  width: 92%;
  margin-left: 4%;
  padding: 7px 0px;
}
.myorderdetails .y-order-yg {
  width: 100%;
  padding-left: 4%;
  padding-right: 4%;
  float: left;
  font-size: 0.35rem;
  padding-bottom: 0.2rem;
  border-bottom: 1px #eeeeee solid;
  box-sizing: border-box;
}
.myorderdetails .y-order-yg p {
  float: left;
  color: #000;
}
.myorderdetails .y-order-yg span {
  margin-left: 40px;
  color: #999;
  display: block;
  margin-bottom: 0.2rem;
}
.myorderdetails .y-order-list {
  margin-top: 10px;
  padding-bottom: 15px;
}
.myorderdetails .y-order-top p {
  font-size: 0.35rem;
  padding: 8px 20px;
  color: #000;
}
.myorderdetails .y-user-old1,
.myorderdetails .y-order-xx {
  width: 94%;
  margin-left: 3%;
  background: #ffffff;
  border-radius: 8px;
  float: left;
  padding: 15px 0px;
  color: #999;
  margin-top: 10px;
  box-shadow: 0px 2px 5px #eeeeee;
}
.myorderdetails .van-card {
  background-color: #ffffff;
}
.myorderdetails .y-xzcptop-img .van-image {
  width: 90px !important;
  height: 90px !important;
  border: 1px #eeeeee solid;
  border-radius: 8px;
}
.myorderdetails .van-tag--default {
  margin-right: 10px;
  margin-bottom: 10px;
}
.myorderdetails .van-card__thumb {
  margin-right: 18px;
}
.myorderdetails .y-order1-con span {
  color: #999;
  padding-top: 10px;
  float: left;
}
.myorderdetails .y-order1-con p {
  color: #000;
}
.myorderdetails .y-order1-con {
  padding: 0px 10px;
  width: 83%;
  text-align: left;
  font-size: 0.35rem;
}
.myorderdetails .y-order1-right .van-icon {
  color: #999 !important;
}
.myorderdetails .y-order1-left .van-icon {
  color: #000 !important;
}
.myorderdetails .y-order-1 {
  width: 90%;
  min-height: 1.6rem !important;
  float: left;
  margin-left: 3%;
  padding: 15px 2%;
  background: #ffffff;
  border-radius: 8px;
  height: auto;
  box-shadow: 0px 2px 5px #eeeeee;
  text-align: center;
  margin-top: 10px;
}
.myorderdetails .y-order-det {
  width: 94%;
  margin-left: 3%;
  margin-top: 70px;
  color: #ffffff;
  font-size: 0.35rem;
  line-height: 20px;
}
.myorderdetails .y-order-det img {
  width: 0.5rem;
  float: left;
  margin: 0px 8px;
}
.myorderdetails .y-order-det p {
  color: #fff;
}
.myorderdetails .van-nav-bar {
  background-color: transparent;
}
.myorderdetails .van-icon {
  color: #ffffff !important;
}
.myorderdetails .y-usertop {
  float: left;
  background-image: url(../../assets/images/pre-admin-bg.png);
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #f3f4f9;
  padding-bottom: 0.6rem;
}
.myorderdetails .y-por-rectop {
  color: #ffffff;
  margin-top: 30px;
  width: 94%;
  margin-left: 3%;
}
.myorderdetails .y-setuptop {
  float: left;
  margin-bottom: 0.2rem;
  padding: 12px 0px;
  font-size: 0.45rem;
} */
</style>