<template>
  <div class="EditUserINfo community">
    <van-nav-bar
      :z-index="99"
      title="种草社区"
      :fixed="false"
      :border="false"
      :placeholder="true"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y_add_storage">
      <div class="y-community-all">
        <div class="y-community" v-for="(item,index) in communityList" :key="index">
          <!-- 大图 -->
          <img :src="item.showAlum" @click="toInfo(item.id,item.is_zan)" />
          <p @click="toInfo(item.id)">{{item.name}}</p>
          <div class="y-community-admin">
            <!-- 头像 -->
            <img :src="item.member.memberthumb" />
            <span>{{item.member.name}}</span>
            <h2 @click="community_zan(item.id,index)">
              <van-icon class="xin" name="like-o" size="17px" v-show="item.is_zan == 0" />
              <van-icon
                class="redxin"
                name="like"
                size="17px"
                color="red"
                v-show="item.is_zan == 1"
              />
              <i class="zannum" v-show="item.is_zan == 0">{{item.zan}}</i>
              <i class="redzannum" v-show="item.is_zan == 1">{{item.zan}}</i>
            </h2>
          </div>
        </div>
      </div>
      <!-- 新增入口 -->
      <!-- <div class="community_add" @click="tosubmit">
        <img :src="plus" alt />
      </div>-->
    </div>
    <!-- 加载框 -->
    <!-- <van-popup class="jiazai" v-model="showloading">
        <van-loading type="spinner" />
    </van-popup>-->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showloading: false,
      plus: require("../../assets/images/plus.png"),
      communityList: [],
      active: false,
      memberthumb: ""
    };
  },
  created: function() {
    this.getCommunityList();
  },
  methods: {
    //种草社区-列表
    getCommunityList() {
      this.showloading = true;
      var params = {
        token: this.$store.getters.token
      };
      this.$api.enjoylife.communityList(params).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.communityList = res.result.list;
          for (var i in this.communityList) {
            var showAlum = this.communityList[i].alumArr[0];
            var showmember = this.communityList[i].member.thumb;
            if (showAlum.indexOf("http") != -1) {
              this.communityList[i].showAlum = showAlum;
            } else {
              this.communityList[i].showAlum =
                this.$publicData.imgUrl + showAlum;
            }
            if (showmember.indexOf("http") != -1) {
              this.communityList[i].member.memberthumb = showmember;
            } else {
              this.communityList[i].member.memberthumb =
                this.$publicData.imgUrl + showmember;
            }
          }
        }
      });
    },
    //跳转到种草社区详情
    toInfo(id) {
      this.$router.push({
        path: "/community_info",
        query: {
          id: id
          // is_zan:is_zan
        }
      });
    },
    //跳转到新增
    tosubmit() {
      this.$router.push({
        path: "/community_add"
      });
    },
    //点赞
    community_zan(id, i) {
      var params = {
        token: this.$store.getters.token,
        id: id
      };
      this.$api.enjoylife.communityZan(params).then(res => {
        if (res.code == 200) {
          this.getCommunityList();
          // var iszan = this.communityList[i].is_zan;
          //  console.log("iszan",iszan);
          // if (iszan == "0") {
          // document.getElementsByClassName("xin")[i].style.display = "none";
          // document.getElementsByClassName("redxin")[i].style.display =
          //   "block";
          // document.getElementsByClassName("zannum")[i].style.color = "red";
          // } else {
          // document.getElementsByClassName("redxin")[i].style.display = "none";
          // document.getElementsByClassName("xin")[i].style.display = "block";
          // document.getElementsByClassName("zannum")[i].style.color = "#ccc";
          // }
        }
      });
    }
  },
  activated:function(){
   this.getCommunityList();
  }
};
</script>
<style  scoped>
/* .community {
  background: #f3f4f9;
}
.community .van-nav-bar {
  position: absolute;
  width: 100%;
  background: transparent;
}
.community .van-nav-bar__placeholder {
  height: 0 !important;
}
.community .community_add {
  position: fixed;
  right: 0.5rem;
  bottom: 1rem;
}
.community .community_add img {
  width: 1.81rem;
  height: 1.81rem;
}
.community .y-community-admin {
  width: 90%;
  margin-left: 5%;
  float: left;
  margin-bottom: 12px;
}
.community .y-community-admin h2 {
  float: right;
}
.community .y-community-admin h2 .van-icon {
  margin: 4px 2px 0px 0px;
}
.community .y-community-admin h2 i {
  font-size: 0.34rem;
  color: #cccccc;
  float: left;
  margin-top: 5px;
}
.community .y-community-admin h2 .redzannum {
  color: red;
}
.community .y-community-admin span {
  font-size: 0.34rem;
  color: #999;
  float: left;
  line-height: 22px;
  margin-left: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 2.6rem;
  overflow: hidden;
}
.community .y-community-admin img {
  width: 22px !important;
  height: 22px;
  float: left;
  border-radius: 22px !important;
}
.community .y-community p {
  font-size: 0.34rem;
  line-height: 20px;
  width: 90%;
  margin: 10px 5%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.community .y-community {
  width: 100%;
  margin: 5px 0px;
  float: left;
  background-color: #ffffff;
  border-radius: 8px;
  -webkit-column-break-inside: avoid;
}
.community .y-community-all {
  width: 94%;
  column-count: 2;
  float: left;
  margin-left: 3%;
  margin-top: 1.3rem;
}
.community .y-community img {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
} */
</style>