<template>
<!-- 支付成功 -->
  <div class="paySuccess">
      <!-- <van-nav-bar
      :fixed="false"
      title="支付结果"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar> -->
    <div class="con">
      <van-icon name="success" />
      <span class="success">支付成功</span>
      <!-- <span class="money">￥{{price}}</span> -->
      <van-button round type="info" to="/home">返回首页</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      price:""
    };
  },
  created:function(){
    this.price = this.$route.query.price
    if(this.price<0){
      this.price =0;
    }
  },
  methods: {
    // 返回键
    // fanhui() {
    //   var that = this;
    //   that.$router.go(-1);
    // }
  }
};
</script>

<style>
</style>