<template>
  <div class="order_list">
    <!-- :class="{back:back}" -->
    <van-nav-bar :fixed="false" :border="false" title="全部订单" left-arrow @click-left="tohome">
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y_add_storage clearfix">
      <div class="y-order-nav">
        <!-- <van-row type="flex" justify="space-around">
          <van-col span="5">配镜订单</van-col>
          <van-col span="5">我的订单</van-col>
        </van-row>-->
        <van-tabs v-model="activeorder">
          <!-- 配镜订单 -->
          <van-tab name="a">
            <template #title><span>配镜订单</span><div class="gang"></div></template>
            <!-- <div style="width:100%;height:150px"></div> -->
            <div class="tabcon">
              <van-tabs background="#F3F4F9" v-model="curTab" @click="changeTab" class="tabgoods">
                <van-tab title="全部" name="-1"></van-tab>
                <!-- <van-tab title="待付款" name="0"></van-tab> -->
                <van-tab title="制作中" name="1"></van-tab>
                <van-tab title="待配送" name="2"></van-tab>
                <van-tab title="已完成" name="3"></van-tab>
              </van-tabs>
              <van-pull-refresh v-model="isLoading1" success-text="刷新成功" @refresh="onRefresh1">
                <div class="y_storage_list clearfix">
                  <div v-for="(pj,index) in pjOrderList" :key="index">
                    <!-- 老订单 -->
                    <div class="y_storage_list_item" v-if="pj.is_old_order == 1">
                      <div class="y_storage_no" @click="toOrderdetails(pj.id,pj.bigStatus)">
                        <span>{{pj.zj_order.created_at}}</span>
                        <div class="y_storage_no_s">
                          <span v-if="pj.bigStatus==3" class="y_storage_no_state_finish">已完成</span>
                          <span v-else-if="pj.bigStatus==2" class="y_storage_no_state_collect">待配送</span>
                          <span v-else-if="pj.bigStatus==1" class="y_storage_no_state_wait">制作中</span>
                          <!-- <span v-else-if="pj.bigStatus==0" class="y_storage_no_state_fu">待付款</span> -->
                        </div>
                      </div>
                      <div class="oldInfo" @click="toOrderdetails(pj.id,pj.bigStatus)">
                        <div class="hang">
                          姓名：
                          <span>{{pj.member.name}}</span>
                        </div>
                        <div class="hang">
                          联系电话：
                          <span>{{pj.member.phone}}</span>
                        </div>
                        <div class="hang">
                          收货地址：
                          <span>{{pj.peisong_remark}}</span>
                        </div>
                        <div class="hang">
                          消费金额：
                          <span>{{pj.final_pay_price}}</span>
                        </div>
                      </div>
                    </div>
                    <!-- 新订单 -->
                    <div
                      class="y_storage_list_item"
                      v-if="pj.pj_order_goods.length != 0 && pj.is_old_order == 0"
                    >
                      <div class="y_storage_no" @click="toOrderdetails(pj.id,pj.bigStatus)">
                        <span>{{pj.zj_order.created_at}}</span>
                        <div class="y_storage_no_s">
                          <span v-if="pj.bigStatus==3" class="y_storage_no_state_finish">已完成</span>
                          <span v-else-if="pj.bigStatus==2" class="y_storage_no_state_collect">待配送</span>
                          <span v-else-if="pj.bigStatus==1" class="y_storage_no_state_wait">制作中</span>
                          <!-- <span v-else-if="pj.bigStatus==0" class="y_storage_no_state_fu">待付款</span> -->
                        </div>
                      </div>

                      <div
                        v-for="(pjgoods,int) in pj.pj_order_goods"
                        :key="int"
                        @click="toOrderdetails(pj.id,pj.bigStatus)"
                      >
                        <div class="y-user-oldxia f-l y-op_orderxia y-xzcptop-img y-order-list">
                          <van-card
                            :num="pjgoods.num"
                            :price="pjgoods.goods_spec.member_discount"
                            :origin-price="pjgoods.goods_spec.member_price"
                            :title="pjgoods.goods.title"
                            :thumb="pjgoods.goods.goodsthumb"
                          >
                            <template #tags>
                              <van-tag
                                color="#F3F4F9"
                                text-color="#999"
                              >{{pjgoods.goods_spec.goods_spec_name}}</van-tag>
                            </template>
                          </van-card>
                        </div>
                      </div>
                      <div class="y-order-money">
                        <p>总价¥{{pj.origin_total_price}}，会员优惠¥{{pj.origin_total_price-pj.final_pay_price}}</p>
                        <span>合计：¥{{pj.final_pay_price}}</span>
                      </div>
                      <div class="y-appo-qx" v-if="pj.bigStatus ==3 && pj.zj_order.is_refund == 0">
                        <van-button
                          v-if="pj.bigStatus==3 && pj.is_comment == 0"
                          @click="toOrder_comment(pj.id,pj.bigStatus)"
                          round
                          size="small"
                          color="#35B6D8"
                          plain
                          style="float: right; padding:5px 14px;"
                        >评价</van-button>
                        <van-button
                          v-if="pj.bigStatus==3 && pj.is_comment == 1"
                          @click="toOrder_evaluate(pj.order_sn)"
                          round
                          size="small"
                          color="#35B6D8"
                          plain
                          style="float: right; padding:5px 14px;"
                        >查看评价</van-button>
                        <van-button
                          v-if="pj.bigStatus==3 && pj.is_after_sale ==0"
                          @click="toAftersales(pj.id)"
                          round
                          size="small"
                          color="#999"
                          plain
                          style="float: right; padding:5px 14px;"
                        >申请售后</van-button>
                        <van-button
                          v-if="pj.bigStatus==3 && pj.is_after_sale == 1"
                          to="service_list"
                          round
                          size="small"
                          color="#999"
                          plain
                          style="float: right; padding:5px 14px;"
                        >查看售后</van-button>
                      </div>
                    </div>
                  </div>
                </div>
              </van-pull-refresh>
            </div>
          </van-tab>
          <!-- 商城订单 -->
          <van-tab name="b">
            <template #title><span>商城订单</span><div class="gang"></div></template>
            <div class="tabcon">
              <van-tabs
                background="#F3F4F9"
                v-model="curMyTab"
                @click="changeMyTab"
                class="tabgoods"
              >
                <van-tab title="全部" name="-2"></van-tab>
                <van-tab title="已取消" name="-1"></van-tab>
                <van-tab title="待支付" name="0"></van-tab>
                <van-tab title="待收货" name="1"></van-tab>
                <van-tab title="已完成" name="2"></van-tab>
              </van-tabs>

              <van-pull-refresh v-model="isLoading2" success-text="刷新成功" @refresh="onRefresh2">
                <div class="y_storage_list clearfix">
                  <div v-for="(myo,i) in goodsOrderList" :key="i">
                    <div class="y_storage_list_item" v-if="myo.member_order_goods.length !=0">
                      <!-- @click="toOrderdetails(pj.id,pj.bigStatus)" -->
                      <div class="y_storage_no" @click="todetails(myo.id,myo.bigStatus)">
                        <span>单号:{{myo.order_sn}}</span>
                        <div class="y_storage_no_s">
                          <span v-if="myo.bigStatus==2" class="y_storage_no_state_finish">已完成</span>
                          <span v-else-if="myo.bigStatus==1" class="y_storage_no_state_collect">待收货</span>
                          <span v-else-if="myo.bigStatus==0" class="y_storage_no_state_wait">待支付</span>
                          <span v-else-if="myo.bigStatus==-1" class="y_storage_no_state_fu">已取消</span>
                        </div>
                      </div>

                      <!-- @click="toOrderdetails(pj.id,pj.bigStatus)" -->
                      <div
                        v-for="(mygoods,int) in myo.member_order_goods"
                        :key="int"
                        @click="todetails(myo.id,myo.bigStatus)"
                      >
                        <div class="y-user-oldxia f-l y-op_orderxia y-xzcptop-img y-order-list">
                          <van-card
                            v-if="myo.order_type == 1"
                            :num="mygoods.num"
                            :price="mygoods.goods_spec.member_discount"
                            :origin-price="mygoods.goods_spec.member_price"
                            :title="mygoods.goods.title"
                            :thumb="mygoods.goods.mygoodsthumb"
                          >
                            <template #tags>
                              <van-tag
                                color="#F3F4F9"
                                text-color="#999"
                              >{{mygoods.goods_spec.goods_spec_name}}</van-tag>
                            </template>
                          </van-card>
                          <van-card
                            v-if="myo.order_type == 2"
                            :num="mygoods.num"
                            :title="mygoods.goods.title"
                            :thumb="mygoods.goods.mygoodsthumb"
                          >
                            <template #tags>
                              <van-tag
                                color="#F3F4F9"
                                text-color="#999"
                              >{{mygoods.goods_spec.goods_spec_name}}</van-tag>
                            </template>
                            <template #price>
                              <span class="jifen" style="{font-size:0.35rem}">{{mygoods.score_price}} 积分</span>
                            </template>
                          </van-card>
                        </div>
                      </div>
                      <div class="y-order-money">
                        <p v-if="myo.order_type == 1">总价¥{{myo.origin_total_price}}，优惠¥{{myo.origin_total_price-myo.final_pay_price}}</p>
                        <span  v-if="myo.order_type == 1">合计：¥{{myo.final_pay_price}}</span>
                        <span  v-if="myo.order_type == 2">合计：{{myo.use_score_number}}积分</span>
                      </div>
                      <div class="y-appo-qx" v-if="myo.bigStatus !=-1">
                        <!-- @click="toOrder_comment(pj.id,pj.bigStatus)" -->
                        <van-button
                          v-if="myo.bigStatus==0"
                          round
                          size="small"
                          color="#35B6D8"
                          plain
                          style="float: right; padding:5px 14px;"
                          @click="todetails(myo.id,myo.bigStatus)"
                        >去支付</van-button>
                        <van-button
                          v-if="myo.bigStatus==0"
                          @click="cancelOrder(myo.id)"
                          round
                          size="small"
                          color="#999"
                          plain
                          style="float: right; padding:5px 14px;"
                        >取消订单</van-button>
                        <van-button
                          @click="receipt(myo.id)"
                          v-if="myo.bigStatus==1"
                          round
                          size="small"
                          color="#35B6D8"
                          plain
                          style="float: right; padding:5px 14px;"
                        >确认收货</van-button>
                        <!-- @click="toAftersales(pj.id)" -->
                        <van-button
                          v-if="myo.bigStatus==2&& myo.is_comment == 0"
                          @click="toMyOrder_comment(myo.id)"
                          round
                          size="small"
                          color="#35B6D8"
                          plain
                          style="float: right; padding:5px 14px;"
                        >评价</van-button>
                        <van-button
                          v-if="myo.bigStatus==2 && myo.is_comment == 1"
                          @click="toMyOrder_evaluate(myo.order_sn)"
                          round
                          size="small"
                          color="#35B6D8"
                          plain
                          style="float: right; padding:5px 14px;"
                        >查看评价</van-button>
                      </div>
                    </div>
                  </div>
                </div>
              </van-pull-refresh>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <!-- 加载框 -->
    <!-- <van-popup class="jiazai" v-model="showloading">
      <van-loading type="spinner" />
    </van-popup>-->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      shareConfig: {},
      isLoading1: false,
      isLoading2: false,
      showloading: false,
      pjOrderList: [],
      member_price: "",
      curTab: "-1",
      curMyTab: "-2",
      activeorder: "",
      back: false,
      goodsOrderList: [],
      pages: {
        page: 1,
        size: 999
      },
      list: [
        {
          id: 0,
          state: 0,
          time: 1234567891012,
          source: 0
        },
        {
          id: 2,
          state: 1,
          time: 1234567891012,
          source: 1
        },
        {
          id: 3,
          state: 2,
          time: 1234567891012,
          source: 0
        }
      ]
    };
  },
  created: function() {
    this.getPjorder();
    this.getMyorder();
  },
  methods: {
    //下拉刷新
    onRefresh1() {
      setTimeout(() => {
        this.isLoading1 = false;
        this.getLaPjorder();
      }, 1000);
    },
    //下拉刷新
    onRefresh2() {
      setTimeout(() => {
        this.isLoading2 = false;
        this.getLaMyorder();
      }, 1000);
    },
    //点击取消订单
    cancelOrder(id) {
      var param = {
        token: this.$store.getters.token,
        order_id: id
      };
      this.$api.goodsOrder.cancel(param).then(res => {
        if (res.code == 200) {
          this.$toast("已取消订单");
          this.getMyorder();
        }
      });
    },
    //点击确认收货
    receipt(id) {
      var param = {
        token: this.$store.getters.token,
        order_id: id
      };
      this.$api.goodsOrder.recive(param).then(res => {
        if (res.code == 200) {
          this.$toast("已确认收货");
          this.getMyorder();
        }
      });
    },
    //跳转到首页
    tohome() {
      this.$router.push({
        path: "/home"
      });
    },
    //跳转到订单详情
    todetails(id, bigStatus) {
      if (bigStatus == 0) {
        this.$router.push({
          path: "/goods_order",
          query: {
            id: id,
            bigStatus: bigStatus
          }
        });
      } else {
        this.$router.push({
          path: "/myorder_details",
          query: {
            id: id,
            bigStatus: bigStatus
          }
        });
      }
    },
    //跳转到申请售后
    toAftersales(id) {
      this.$router.push({
        path: "/after_sales",
        query: {
          id: id
        }
      });
    },
    // 配镜订单列表接口
    getPjorder() {
      this.showloading = true;
      var param = {
        token: this.$store.getters.token
      };
      if (this.curTab != -1) {
        param.bigStatus = this.curTab;
      }
      this.$api.pjorder.pjOrderList(param).then(res => {
        this.showloading = false;
        this.pjOrderList = res.result;
        for (var i in this.pjOrderList) {
          var pjgoods = this.pjOrderList[i].pj_order_goods;
          for (var j in pjgoods) {
            var pjthumb = pjgoods[j].goods.thumb;
            if (pjthumb.indexOf("http") == -1) {
              this.pjOrderList[i].pj_order_goods[j].goods.goodsthumb =
                this.$publicData.imgUrl + pjthumb;
            } else {
              this.pjOrderList[i].pj_order_goods[j].goods.goodsthumb = pjthumb;
            }
          }
        }
      });
    },
    getLaPjorder() {
      var param = {
        token: this.$store.getters.token
      };
      if (this.curTab != -1) {
        param.bigStatus = this.curTab;
      }
      this.$api.pjorder.pjOrderList(param).then(res => {
        this.pjOrderList = res.result;
        for (var i in this.pjOrderList) {
          var pjgoods = this.pjOrderList[i].pj_order_goods;
          for (var j in pjgoods) {
            var pjthumb = pjgoods[j].goods.thumb;
            if (pjthumb.indexOf("http") == -1) {
              this.pjOrderList[i].pj_order_goods[j].goods.goodsthumb =
                this.$publicData.imgUrl + pjthumb;
            } else {
              this.pjOrderList[i].pj_order_goods[j].goods.goodsthumb = pjthumb;
            }
          }
        }
      });
    },
    //商城订单接口
    getMyorder() {
      this.showloading = true;
      var param = {
        token: this.$store.getters.token,
        page: this.pages.page,
        size: this.pages.size
      };
      if (this.curMyTab != -2) {
        param.status = this.curMyTab;
      }
      this.$api.goodsOrder.list(param).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.goodsOrderList = res.result;

          for (var i in this.goodsOrderList) {
            var mygoods = this.goodsOrderList[i].member_order_goods;
            for (var j in mygoods) {
              var mythumb = mygoods[j].goods.thumb;
              if (mythumb.indexOf("http") == -1) {
                this.goodsOrderList[i].member_order_goods[
                  j
                ].goods.mygoodsthumb = this.$publicData.imgUrl + mythumb;
              } else {
                this.goodsOrderList[i].member_order_goods[
                  j
                ].goods.mygoodsthumb = mythumb;
              }
            }
          }
        }
      });
    },
    getLaMyorder() {
      this.showloading = true;
      var param = {
        token: this.$store.getters.token,
        page: this.pages.page,
        size: this.pages.size
      };
      if (this.curMyTab != -2) {
        param.status = this.curMyTab;
      }
      this.$api.goodsOrder.list(param).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.goodsOrderList = res.result;

          for (var i in this.goodsOrderList) {
            var mygoods = this.goodsOrderList[i].member_order_goods;
            for (var j in mygoods) {
              var mythumb = mygoods[j].goods.thumb;
              if (mythumb.indexOf("http") == -1) {
                this.goodsOrderList[i].member_order_goods[
                  j
                ].goods.mygoodsthumb = this.$publicData.imgUrl + mythumb;
              } else {
                this.goodsOrderList[i].member_order_goods[
                  j
                ].goods.mygoodsthumb = mythumb;
              }
            }
          }
        }
      });
    },
    //跳转详情页
    toOrderdetails(id, bigStatus) {
      this.$router.push({
        path: "order_details",
        query: {
          id: id,
          bigStatus: bigStatus
        }
      });
    },
    //跳转评价
    toOrder_comment(id) {
      this.$router.push({
        path: "order_comment",
        query: {
          id: id,
          type: 2
        }
      });
    },
    //我的订单跳转评价
    toMyOrder_comment(id) {
      this.$router.push({
        path: "order_comment",
        query: {
          id: id,
          type: 1
        }
      });
    },
    //跳转到查看评价
    toOrder_evaluate(order_sn) {
      this.$router.push({
        path: "/order_evaluate",
        query: {
          order_sn: order_sn
        }
      });
    },
    //我的订单跳转到查看评价
    toMyOrder_evaluate(order_sn) {
      this.$router.push({
        path: "/order_evaluate",
        query: {
          order_sn: order_sn
        }
      });
    },
    changeTab() {
      var that = this;
      that.pjOrderList = [];
      that.getPjorder();
    },
    changeMyTab() {
      this.goodsOrderList = [];
      this.pages.page = 1;
      this.getMyorder();
    },
    shareFun() {
        var that = this;
        var tempurl = location.href;
        if(this.$store.getters.user_id != undefined && this.$store.getters.user_id!=null){
            if(location.href.indexOf("?") == -1){// 不存在？ 则没有参数
                tempurl = location.href + '?type=member&sharememberid='+this.$store.getters.user_id;
            }else{
                if(location.href.indexOf("sharememberid") == -1){// 不存在则直接加
                    tempurl = location.href + '&type=member&sharememberid='+this.$store.getters.user_id;
                }else{ // 参数替换
                    var heheurl = location.href;
                    var heheArr = heheurl.split('&');
                    tempurl = "";
                    for(let i in heheArr){
                        if(i == 0 ){
                           tempurl = heheArr[0]; 
                           continue;
                        }

                        if(heheArr[i].indexOf('sharememberid=') != -1){
                            tempurl +=  "&sharememberid=" + this.$store.getters.user_id;
                        }else{
                            tempurl +=  "&" + heheArr[i];
                        }
                    }
                }
            }
        }
        const url = tempurl;
        console.log("home-share----url=" + url);
        const shareUrl = location.href.split("#")[0];
        that.$api.common.commonShare({url: shareUrl}).then(res => {
            if (res.code == 200) {
                that.shareConfig = res.result;
                wx.config({
                    debug: that.shareConfig.debug,
                    beta: that.shareConfig.beta,
                    jsApiList: that.shareConfig.jsApiList,
                    appId: that.shareConfig.appId,
                    nonceStr: that.shareConfig.nonceStr, // 随机串
                    timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
                    signature: that.shareConfig.signature // 签名
                });
                // 分享
                wx.ready(function () {
                    var shareDataA = {
                        title: that.$publicData.shareTitle, // 分享标题
                        desc: that.$publicData.shareDesc, // 分享描述
                        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.$publicData.imgUrl + that.$publicData.img, // 分享图标
                        success: function () {
                        },
                        cancel: function (e) {
                        }
                    };

                    var shareDataB = {
                        title: that.$publicData.shareTitle, // 分享标题
                        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.$publicData.imgUrl + that.$publicData.img, // 分享图标
                        success: function () {
                        },
                        cancel: function (e) {
                        }
                    };
                    wx.updateAppMessageShareData(shareDataA); // 分享给朋友
                    wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
                });
            }
        });
    },
    // TabBar() {
    //   var userOld = document.getElementsByClassName("y_storage_list_item")[0];
    //   var that = this;
    //   // console.log("lll", userOld.scrollHeight);
    //   if (userOld.scrollHeight - scrollY <= 300) {
    //     that.back = true;
    //   } else {
    //     that.back = false;
    //   }
    // }
  },
  mounted() {
    // window.addEventListener("scroll", this.TabBar);
  },
  activated: function() {
    // console.log("activated");
    this.getPjorder();
    this.getMyorder();
  }
};
</script>

<style scoped>
/* .order_list ::v-deep .van-tab {
  color: #fff;
  font-size: 0.4rem;
  opacity: 0.3;
}
.order_list ::v-deep .van-tab--active {
  color: #fff;
  opacity: 1;
}
.order_list ::v-deep .van-tabs__line {
  width: 0.4rem;
  height: 0.03rem;
  background-color: #fff;
}
.order_list ::v-deep .van-tab__pane {
  margin-top: 1rem;
}
.order_list {
  background: #f3f4f9;
}
.order_list .van-nav-bar {
  position: absolute;
  width: 100%;
}
.order_list .back {
  background: #fff !important;
}
.order_list .back .van-icon {
  color: #000 !important;
}
.order_list ::v-deep .back .van-nav-bar__title {
  color: #000 !important;
}
.order_list .y-order-money span {
  font-size: 0.35rem;
}
.order_list .y-order-money p {
  color: #999;
  float: left;
  padding: 0px 8px;
}
.order_list .van-card {
  background-color: transparent !important;
}
.order_list .van-card__title {
  font-size: 0.35rem;
  line-height: 18px;
  float: left;
  max-height: 42px;
}
.order_list .van-tag {
  margin-top: 8px;
  max-width: 5.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.order_list .y-order-money {
  float: left;
  font-size: 0.35rem;
  padding: 10px 15px;
  float: right;
}
.order_list .y-order-nav .van-row {
  width: 100%;
  float: left;
  margin-top: 1.75rem;
  text-align: center;
  font-size: 0.43rem;
  color: #ffffff;
}
.order_list .van-button {
  height: auto;
}
.order_list .y-appo-qx {
  width: 90%;
  float: right;
  padding: 12px 5%;
  border-top: 1px #eeeeee solid;
}
.order_list .y-appo-qx .van-button {
  width: 82px;
  margin: 0px 5px;
}

.order_list ::v-deep .tabcon .van-tabs__line {
  background-color: transparent;
}
.order_list ::v-deep .tabcon .van-tab {
  color: #000;
  opacity: 1;
}
.order_list ::v-deep .tabcon .van-tab--active {
  font-size: 0.45rem;
  font-weight: bold;
  color: #000;
}
.order_list .van-tabs__line {
  background-color: transparent !important;
}
.order_list .y_add_storage {
  background-color: #f3f4f9;
  min-height: 100vh;
  float: left;
  width: 100%;
}
.order_list .y_storage_list {
  padding: 0 15px;
  background-color: #f3f4f9;
}
.order_list .y_storage_list .y_storage_list_item {
  width: 100%;
  float: left;
  margin: 8px 0;
  border-radius: 7.5px;
  background-color: #fff;
  box-shadow: 0px 2px 5px #eeeeee;
}
.order_list .y_storage_no {
  height: 45px;
  line-height: 45px;
  padding: 0 12px;
  font-size: 0.35rem;
  color: #000;
  padding-right: 0;
  border-bottom: 1px #eeeeee solid;
  position: relative;
}
.order_list .y_storage_no div {
  float: left;
}

.order_list .y_storage_no_tag_zb {
  background-color: #5cc6da;
}
.order_list .y_storage_no_tag_db {
  background-color: #7fc89e;
}
.order_list .y_storage_no_num {
  margin-left: 8px;
  font-size: 0.35rem;
  line-height: 21px;
}
.order_list .y_storage_no_state {
  padding: 5px 14px;
  font-size: 0.35rem;
  margin-top: 10px;
  float: right;
  line-height: 13px;
  border: 1px #eee solid;
  border-radius: 25px 0 0 25px;
}
.order_list .y_storage_no_s {
  float: right !important;
}
.order_list .y_storage_no_state_collect {
  border: 1px #f54749 solid;
  color: #f54749;
  border-right: none;
  padding: 5px 14px;
  font-size: 0.35rem;
  margin-top: 10px;
  float: right;
  line-height: 13px;
  border-radius: 25px 0 0 25px;
}
.order_list .y_storage_no_state_wait {
  border: 1px #f77413 solid;
  color: #f77413;
  border-right: none;
  border-right: none;
  padding: 5px 14px;
  font-size: 0.35rem;
  margin-top: 10px;
  float: right;
  line-height: 13px;
  border-radius: 25px 0 0 25px;
}
.order_list .y_storage_no_state_fu {
  border: 1px #f00000 solid;
  color: #f00000;
  border-right: none;
  border-right: none;
  padding: 5px 14px;
  font-size: 0.35rem;
  margin-top: 10px;
  float: right;
  line-height: 13px;
  border-radius: 25px 0 0 25px;
}
.order_list .y_storage_no_state_finish {
  border: 1px #35b6d8 solid;
  color: #35b6d8;
  border-right: none;
  padding: 5px 14px;
  font-size: 0.35rem;
  margin-top: 10px;
  float: right;
  line-height: 13px;
  border-radius: 25px 0 0 25px;
  border-right: none;
}
.order_list .y_stirage_info {
  padding-left: 12px;
  padding: 15px 12px;
}
.order_list .y_stirage_info_name {
  font-size: 0.35rem;
  line-height: 18px;
}
.order_list .y_stirage_info_time {
  font-size: 0.35rem;
  color: #999;
  padding: 3px 0px;
}
.order_list .y-order-nav {
  width: 100%;
  background: linear-gradient(to right, #35b6d8, #52d3c5);
  border: none;
  height: 116px;
  float: left;
}
.order_list .van-nav-bar {
  background-color: transparent;
}
.order_list .van-icon {
  color: #ffffff !important;
}
.order_list ::v-deep .van-nav-bar__title {
  color: #ffffff !important;
}
.order_list ::v-deep .van-tabs--line .van-tabs__wrap {
  margin-top: 1.5rem !important;
}
.order_list ::v-deep .van-tabs--line .van-tabs__wrap .van-tabs__nav {
  background-color: transparent;
}
.order_list ::v-deep .tabgoods .van-tabs__wrap {
  margin-top: 0.8rem !important;
} */
</style>