/**
 * 视力档案接口
 */
import axios from "../http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块，把请求字段直接映射过来

const ygrecord = {
  //自建验光记录
  createYgRecord(params) {
    return axios.post("/api/member/createYgRecord", qs.stringify(params));
  },
  //查询详情
  ygRecordDetail(params) {
    return axios.post("/api/member/ygRecordDetail", qs.stringify(params));
  },
  //查询验光记录列表
  ygRecordList(params) {
    return axios.post("/api/member/ygRecordList", qs.stringify(params));
  },
     //修改验光记录
     editYgRecord(params) {
       return axios.post("/api/member/editYgRecord", qs.stringify(params));
     },
};
// 导出接口
export default ygrecord;
