<template>
  <div class="EditUserINfo salesdatails">
      <!-- :class="{back:back}" -->
    <van-nav-bar
      :z-index="99"
      title="售后详情"
      :fixed="false"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y-usertop y_add_storage">
      <div class="y-setuptop f-l y-order-det y-service">
        <img src="../../assets/images/order_wait.png" />
        <p>已取消</p>
       <span>退款金额<i>￥1599.00</i>
       </span>
      </div>
    
      <div class="y-user-old1 f-l">
        <div class="y-order-top">
          <p>售后商品</p>
        </div>
        <div class="y-user-oldxia f-l y-op_orderxia y-xzcptop-img y-order-list">
          <van-card
            num="1"
            price="1599.99"
            title="男女复古超轻圆框眼镜框 树脂材质耐磨抗压"
            thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
          >
            <template #tags>
              <van-tag color="#F3F4F9" text-color="#999">FRANZLISZT</van-tag>
              <van-tag color="#F3F4F9" text-color="#999">超轻系列</van-tag>
            </template>
          </van-card>
        </div>
        <div class="y-user-oldxia f-l y-op_orderxia y-xzcptop-img y-order-list">
          <van-card
            num="1"
            price="1599.99"
            title="男女复古超轻圆框眼镜框 树脂材质耐磨抗压"
            thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
          >
            <template #tags>
              <van-tag color="#F3F4F9" text-color="#999">FRANZLISZT</van-tag>
              <van-tag color="#F3F4F9" text-color="#999">超轻系列</van-tag>
            </template>
          </van-card>
        </div>
      </div>
      <div class="y-order-xx">
        <div class="y-order-top">
          <p>售后信息</p>
        </div>
        <p>
          <i>售后凭证：</i>
          <span>
            <i>眼镜腿处有裂痕</i>
            <van-image class="y-hf-img" radius="8" src="https://img01.yzcdn.cn/vant/cat.jpeg" />
          </span>
        </p>
        <p>
          <i>申请时间：</i>
          <span>2021.04.10 13:20:15</span>
        </p>
        <p>
          <i>订单编号：</i>
          <span>24512354313215484</span>
        </p>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      back: false,
      fileList: [],
      message: "",
      show: false
    };
  },

  methods: {
    showPopup() {
      this.show = true;
    },
    // TabBar() {
    //   var userOld = document.getElementsByClassName("y-user-old1")[0];
    //   var that = this;
    //   if (userOld.scrollHeight - scrollY <= 300) {
    //     that.back = true;
    //   } else {
    //     that.back = false;
    //   }
    // }
  },
  mounted() {
    // window.addEventListener("scroll", this.TabBar);
  }
};
</script>
<style scoped>
/* .salesdatails .van-nav-bar{
  position: absolute;
  width: 100%;
}
.salesdatails .y-service span{
  font-size: 0.35rem;
  color: #E2F4F6;
  margin: 8px 0px 0px 33px;
  float: left;
}

.salesdatails .back {
  background: #fff !important;
}
.salesdatails .back .van-icon {
  color: #000 !important;
}
.salesdatails ::v-deep.back .van-nav-bar__title {
  color: #000 !important;
}
.salesdatails .y-nav-yb ::v-deep.y-hf-2 .van-uploader__preview-image {
  width: 65px;
  height: 65px;
}
.salesdatails ::v-deep.y-hf-2 .van-image__img {
  width: 65px;
  height: 65px;
}
.salesdatails .y-s-bg {
  width: 65px;
  height: 65px;
}
.salesdatails .y-service1-xia {
  width: 94%;
  margin-left: 3%;
  float: left;
  border-top: 1px #eee solid;
  margin-top: 10px;
}
.salesdatails .y-service1-xia p {
  margin-left: 0px !important;
  margin-top: 10px;
  font-size: 0.35rem;
  padding: 0px !important;
}
.salesdatails .y-service1-admin h2 {
  float: left;
  font-weight: normal;
  margin-left: 8px;
}
.salesdatails .y-service1-admin h2 p {
  font-size: 0.35rem;
  color: #000;
  margin-left: 0px;
  padding: 7px 0px 3px 0px;
}
.salesdatails .y-hf-img2 {
  width: 35px;
  height: 35px;
  float: left;
  margin-left: 4%;
  margin-top: 10px;
}
.salesdatails .y-service-complete .y-service-silder1 p {
  border: 1px #ffffff solid;
  background-color: #ffffff;
}
.salesdatails .y-service-complete .y-service-silder2 {
  color: #ffffff;
}
.salesdatails .y-service-complete .y-c-complete {
  font-size: 0.35rem;
}

.salesdatails .y-service-reply .y-service-silder1 .y-c-replyp,
.salesdatails .y-service-reply .y-service-silder1 p:first-child {
  border: 1px #ffffff solid;
  background-color: #ffffff;
}
.salesdatails .y-service-reply .y-c-reply,
.salesdatails .y-service-reply .y-c-wait {
  color: #ffffff;
}
.salesdatails .y-service-reply .y-c-reply {
  font-size: 0.35rem;
}

.salesdatails .y-service-wait .y-service-silder1 p:first-child {
  border: 1px #ffffff solid;
  background-color: #ffffff;
}
.salesdatails .y-service-wait .y-c-wait {
  color: #ffffff;
  font-size: 0.35rem;
}

.salesdatails .y-service-silder1 .van-divider {
  width: 65px;
  float: left;
  margin: 2.5px 8px;
}
.salesdatails .y-service-silder1 p {
  width: 4px;
  height: 4px;
  float: left;
  border: 1px #a0e1e8 solid;
  border-radius: 5px;
  background-color: #a0e1e8;
}
.salesdatails .y-service-silder2 {
  text-align: center;
  width: 100%;
  float: left;
  font-size: 0.35rem;
  color: #a0e1e8;
  margin: 10px 0px 10px 0px;
}
.salesdatails .y-service-silder1 {
  width: 80%;
  float: left;
  margin-left: 10%;
  margin-top: 12px;
}
.salesdatails .y-service {
  margin-top: 70px !important;
}
.salesdatails .y-service p{
  color: #fff;
}
.salesdatails .y-hf-1 .van-cell {
  border: none;
  padding: 0px !important;
  margin: 10px 0px;
}
.salesdatails .y-hf-1,
.salesdatails .y-hf-2,
.salesdatails .y-service-silder {
  width: 92%;
  float: left;
  margin-left: 4%;
}
.salesdatails .y-order-xx .y-order-top p {
  margin-left: 0px;
  padding: 8px 15px;
  font-weight: bold;
}

.salesdatails .van-popup--bottom {
  width: 90%;
  padding: 10px 5% 0px 5%;
}
.salesdatails .van-cell {
  border: 1px #eeeeee solid;
  padding: 8px 15px !important;
  margin: 40px 0px 15px 0px;
}
.salesdatails ::v-deep.van-popup__close-icon {
  color: #999 !important;
  margin-top: -10px;
}
.salesdatails .y-edit-button {
  width: 94%;
  float: left;
  margin: 20px 3%;
}
.salesdatails .y-bottom20 {
  margin-bottom: 20px;
}
.salesdatails .y-order-xx p i {
  width: 80px;
  float: left;
  font-size: 0.35rem;
  color: #999;
}
.salesdatails .y-order-xx p span {
  font-size: 0.35rem;
  color: #666;
  float: left;
  width: 240px;
}
.salesdatails .y-order-xx p span i {
  float: left;
  color: #666;
  width: 100%;
  margin-bottom: 5px;
}
.salesdatails .y-hf-img {
  width: 65px;
  height: 65px;
}
.salesdatails .y-order-xx p {
  width: 92%;
  float: left;
  margin-left: 4%;
  padding: 5px 0px;
}
.salesdatails .y-order-list {
  margin-top: 10px;
  padding-bottom: 15px;
}
.salesdatails .y-order-top {
  width: 100%;
  float: left;
}
.salesdatails .y-order-top p {
  font-size: 0.35rem;
  padding: 8px 20px;
  color: #000;
  font-weight: bold;
}
.salesdatails .y-user-old1,
.salesdatails .y-order-xx {
  width: 94%;
  margin-left: 3%;
  background: #ffffff;
  border-radius: 8px;
  float: left;
  padding: 15px 0px;
  color: #999;
  margin-top: 10px;
  box-shadow: 0px 2px 5px #eeeeee;
}
.salesdatails .van-card {
  background-color: #ffffff;
}
.salesdatails .y-xzcptop-img .van-image {
  width: 90px !important;
  height: 90px !important;
  border: 1px #eeeeee solid;
  border-radius: 8px;
}
.salesdatails .van-tag--default {
  margin-right: 10px;
  margin-bottom: 10px;
}
.salesdatails .van-card__thumb {
  margin-right: 18px;
}
.salesdatails .y-order-det {
  width: 94%;
  margin-left: 3%;
  margin-top: 100px;
  color: #ffffff;
  font-size: 0.45rem;
  line-height: 20px;
}
.salesdatails .y-order-det img {
  width: 18px;
  float: left;
  margin: 0px 8px;
}
.salesdatails .van-nav-bar {
  background-color: transparent;
}
.salesdatails .van-icon {
  color: #ffffff !important;
}
.salesdatails .y-usertop {
  float: left;
  background-image: url(../../assets/images/promotion.png);
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.salesdatails .y-por-rectop {
  color: #ffffff;
  margin-top: 30px;
  width: 94%;
  margin-left: 3%;
}
.salesdatails .y-setuptop {
  float: left;
  padding: 12px 0px;
}

.salesdatails .y_add_storage {
  background-color: #f3f4f9;
  min-height: 100vh;
  float: left;
  width: 100%;
}
.salesdatails .van-nav-bar .van-icon {
  color: #ffffff;
}
.salesdatails .van-nav-bar {
  background-color: transparent;
}
.salesdatails ::v-deep .van-nav-bar__title {
  color: #ffffff;
} */
</style>