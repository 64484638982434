<template>
  <div class="integral">
    <!-- :class="{back:back}" -->
    <van-nav-bar
      :z-index="99"
      :fixed="false"
      title="积分中心"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y-integral-all clearfix">
      <div class="y-integral-banner f-l">
        <h2>
          <span>积分兑豪礼</span>
          <p>网红好物 任你兑换</p>
          <router-link to="integral_exchange">
            <van-button type="primary" size="mini">立即兑换</van-button>
          </router-link>
        </h2>
      </div>
      <div class="y-integral-1">
        <img src="../../assets/images/integral1.png" />
        <router-link to="integral_exchange">
          <h2>
            <p>
              <span>{{scoreList.total_score}}</span>
              <i>积分</i>
              <van-icon name="arrow" color="#EE904F" size="16" />
            </p>
            <span>购物赚积分 积分当钱花</span>
          </h2>
        </router-link>
      </div>
      <div class="y-fg"></div>

      <div class="y-integral-2">
        <router-link to="integral_task">
          <div class="y-my-integral-top f-l">
            <p>做任务得积分</p>
            <span>
              <van-icon size="15" name="arrow" />
            </span>
          </div>
        </router-link>
        <div class="y-integral-2-list">
          <div class="y-integral-2-info" v-for="(item,index) in scoreList.score_task" :key="index">
            <img :src="item.score_task.scorethumb" />
            <div class="y-integral-2-info-c">
              <p>
                {{item.score_task.name}}
                <i>+{{item.score_task.score_num}}积分</i>
              </p>
              <van-slider
                v-if="(item.score_task_id ==2 ||item.score_task_id == 3||item.score_task_id == 4 )&& item.total_value != 0"
                v-model="item.curr_value"
                active-color="#EE904F"
                disabled
                :max="item.total_value"
                class="y-in-f-jt"
              >
                <template></template>
              </van-slider>
              <span>{{item.score_task.desc}}</span>
            </div>
            <div class="y-integral-2-info-r">
              <!-- :to="item.score_task.btn_path" -->
              <van-button
                v-if="item.score_task_id ==1 &&item.is_done ==0"
                round
                type="info"
                size="small"
                color="#EE904F"
                @click="toPj(item.score_task.btn_path,item.score_task_id,item.id)"
              >{{item.score_task.btn_name}}</van-button>
              <van-button
                v-if="item.score_task_id !=1"
                round
                type="info"
                size="small"
                color="#EE904F"
                @click="toPj(item.score_task.btn_path,item.score_task_id,item.id)"
              >{{item.score_task.btn_name}}</van-button>
              <van-button
                v-if="item.score_task_id ==1 && item.is_done ==1&&item.is_get_score == 0"
                round
                type="info"
                size="small"
                color="red"
                @click="toLing(item.id,index)"
              >待领取</van-button>
              <!-- @click="show_rwPopup(index)" -->
              <van-button
                v-if="item.score_task_id ==1 && item.is_done ==1&&item.is_get_score == 1"
                round
                type="info"
                size="small"
                color="#999"
              >已完成</van-button>
            </div>
          </div>

          <van-popup :close-on-click-overlay="false" v-model="show_rw" class="lqwork">
            <h2>任务完成</h2>
            <div class="y-task-top">
              <p>奖励</p>
            </div>
            <div class="y-task-zj">
              <p>+{{scorenum}}</p>
              <img src="../../assets/images/jb.png" />
            </div>
            <div class="y-task-buttom">
              <van-button
                round
                closed
                color="linear-gradient(to right, #F28E26, #FD644F)"
                type="primary"
                @click="show_rw =false"
              >完成</van-button>
            </div>
          </van-popup>

          <!-- <div class="y-integral-2-info">
            <img src="../../assets/images/integral2.png" />
            <div class="y-integral-2-info-c">
              <p>
                完善资料
                <i>+10积分</i>
              </p>
              <span>完善个人资料可获得10积分</span>
            </div>
            <div class="y-integral-2-info-r">
              <van-button round type="info" size="small" color="#EE904F">完善信息</van-button>
            </div>
          </div>
          y-integral-2-friends
          <div class="y-integral-2-info">
            <img src="../../assets/images/integral3.png" />
            <div class="y-integral-2-info-c">
              <p>
                邀请5个好友
                <i>+100积分</i>
              </p>
              <van-slider
                style="display:block;"
                v-model="value"
                active-color="#EE904F"
                disabled
                :max="5"
                class="y-in-f-jt"
              >
                <template></template>
              </van-slider>
              <span>已邀请2人，还差3人</span>
            </div>
            <div class="y-integral-2-info-r">
              <van-button round type="info" size="small" color="#EE904F">邀请好友</van-button>
            </div>
          </div>
          <div class="y-integral-2-info">
            <img src="../../assets/images/integral4.png" />
            <div class="y-integral-2-info-c">
              <p>
                评价商品
                <i>+5积分</i>
              </p>
              <span>评价商品可获得5积分</span>
            </div>
            <div class="y-integral-2-info-r">
              <van-button round type="info" size="small" color="#EE904F">去评价</van-button>
            </div>
          </div>-->
        </div>
        <div class="y-fg"></div>
        <div class="y-integral-3">
          <div class="y-my-integral-top f-l">
            <p>积分明细</p>
          </div>
          <div class="y-integral-3-all f-l">
            <!-- <van-tabs v-model="curTab">
              <van-tab title="全部" name="-1">
                <div class="y_storage_list f-l">
                  <div class="y-record-list" v-for="(item,index) in list" :key="index">
                    <h2>
                      <p>{{item.nickname}}</p>
                      <p v-if="item.carry == 0">配镜</p>
                      <p v-if="item.carry == 1">推广用户配镜</p>
                      <p v-if="item.carry == 2">完善资料</p>
                      <span>{{item.money}}</span>
                    </h2>
                    <h3>
                      <p>{{new Date(item.time).toLocaleString('chinese',{hour12:false})}}</p>
                      <span v-if="item.state == 0">消费</span>
                      <span v-if="item.state == 1">推广</span>
                      <span v-if="item.state == 2">任务</span>
                    </h3>
                  </div>
                </div>
              </van-tab>
              <van-tab title="消费积分" name="0"></van-tab>
              <van-tab title="推广积分" name="1"></van-tab>
              <van-tab title="积分任务" name="2"></van-tab>
            </van-tabs>-->
            <van-tabs v-model="curTab" @change="getScore">
              <van-tab title="全部" name="-1"></van-tab>
              <van-tab title="收入" name="1"></van-tab>
              <van-tab title="支出" name="0"></van-tab>
              <!-- <van-pull-refresh class="clearfix" v-model="laLoading" success-text="刷新成功" @refresh="onRefresh"> -->
              <div class="y_storage_list f-l">
                <div class="y-record-list" v-for="(item,index) in scoreList.list" :key="index">
                  <h2>
                    <p>{{item.source}}</p>
                    <span>{{item.curr_score}}</span>
                  </h2>
                  <h3>
                    <p>{{item.updated_at}}</p>
                    <span v-if="item.status == 1">收入</span>
                    <span v-if="item.status == 0">支出</span>
                  </h3>
                </div>
              </div>
              <!-- </van-pull-refresh> -->
            </van-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- 加载框 -->
    <!-- <van-popup class="jiazai" v-model="showloading">
      <van-loading type="spinner" />
    </van-popup>-->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
 <script>
import { Toast } from "vant";
export default {
  data() {
    return {
      // laLoading: false,
      showloading: false,
      scoreList: {},
      scorethumb: "",
      back: false,
      // slidervalue: 0,
      curTab: "",
      scorenum: "",
      show_rw: false,
      list: [
        {
          id: 0,
          state: 1,
          carry: 1,
          time: 12345678911123,
          money: "1,299.00"
        },
        {
          id: 0,
          state: 0,
          carry: 0,
          time: 12345678911123,
          money: "1,080.00"
        },
        {
          id: 1,
          state: 1,
          carry: 0,
          time: 12345678911123,
          money: "19.00"
        },
        {
          id: 1,
          state: 2,
          carry: 2,
          time: 12345678911123,
          money: "19.00"
        }
      ]
    };
  },
  created: function() {
    this.getScore();
  },
  methods: {
    // onRefresh() {
    //   setTimeout(() => {
    //     this.laLoading = false;
    //   }, 1000);
    // },
    // 积分列表接口
    getScore() {
      this.showloading = true;
      var param = {
        token: this.$store.getters.token,
        status: this.curTab == -1 ? "" : this.curTab
      };
      this.$api.score.scoreList(param).then(res => {
        this.showloading = false;
        this.scoreList = res.result;
        for (var i in this.scoreList.score_task) {
          var showscore = this.scoreList.score_task[i].score_task.thumb;
          if (showscore.indexOf("http") == -1) {
            this.scoreList.score_task[i].score_task.scorethumb =
              this.$publicData.imgUrl + showscore;
          } else {
            this.scoreList.score_task[i].score_task.scorethumb = showscore;
          }
          if (this.scoreList.score_task[i].total_value) {
            // this.slidervalue = this.scoreList.score_task[i].curr_value;
            // console.log("slidervalue",this.slidervalue);
          }
        }
      });
    },
    //点击待领取
    toLing(id, index) {
      this.scorenum = this.scoreList.score_task[index].score_task.score_num;
      var params = {
        token: this.$store.getters.token,
        id: id
      };
      this.$api.score.takeTaskScore(params).then(res => {
        if (res.code == 200) {
          this.show_rw = true;
          this.getScore();
        }
      });
    },
    //点击去评价
    toPj(url, score_task_id, id) {
      // if (score_task_id == 0) {
      //   this.$toast("无可评价信息");
      // } else {
      if (score_task_id == 1) {
        this.$router.push({
          path: url
        });
      }
      if (score_task_id == 2) {
        if (this.scoreList.notCommentYgOrderId == 0) {
          this.$toast("无可评价验光服务");
          return;
        }
        this.$router.push({
          path: "/optometry_evaluate",
          query: {
            id: id,
            creatid: this.scoreList.notCommentYgOrderId
          }
        });
      }
      if (score_task_id == 3) {
        if (this.scoreList.notCommentZjOrderId == 0) {
          this.$toast("无可评价信息");
          return;
        }
        this.$router.push({
          path: "/order_comment",
          query: {
            id: this.scoreList.notCommentZjOrderId,
            type: 2
          }
        });
      }
      // if (score_task_id == 4) {
      //   if (this.scoreList.notCommentAfterSaleId == 0) {
      //     this.$toast("无可评价信息");
      //     return;
      //   }
      //   this.$router.push({
      //     path: "/service_details",
      //     query: {
      //       id: this.scoreList.notCommentAfterSaleId
      //     }
      //   });
      // }
      // }
    },
    show_rwPopup(index) {
      // this.indexbtn = id;
      // console.log(index);
      this.scorenum = this.scoreList.score_task[index].score_task.score_num;
      this.show_rw = true;
    }
    // showPopup() {
    //   this.show = true;
    // }
    // onChange(value) {
    //   Toast("当前值：" + value);
    // },
    // TabBar() {
    //   var userOld = document.getElementsByClassName("y-integral-1")[0];
    //   var that = this;
    //   if (userOld.scrollHeight - scrollY <= 50) {
    //     that.back = true;
    //   } else {
    //     that.back = false;
    //   }
    // }
  },
  mounted() {
    // window.addEventListener("scroll", this.TabBar);
  }
};
</script>
<style scoped>
/* .integral ::v-deep .van-tab--active{
  font-size: 0.5rem !important;
  font-weight: 600;
}
.integral .y-task-all h2 {
  width: 100%;
  float: left;
  font-size: 0.35rem;
  color: #c36200;
  font-weight: bold;
  margin-top: 35px;
}
.integral .y-task-zj {
  width: 150px;
  float: left;
  margin: 0px 70px 25px 70px;
}
.integral .y-task-zj p {
  font-size: 42px;
  color: #c36200;
  font-weight: bold;
  float: left;
  margin-right: 5px;
}
.integral .y-task-top p {
  color: #e19c39;
}
.integral ::v-deep .y-task-zj img {
  width: 33px !important;
  height: auto !important;
  float: left;
  margin-top: 8px;
}
.integral .y-task-top {
  background-image: url(../../assets/images/coupons-bg4.png);
  background-size: 100%;
  background-repeat: no-repeat;
  width: 162px;
  height: 40px;
  float: left;
  margin: 40px 40px 5px 40px;
  text-align: center;
  font-size: 0.35rem;
}
.integral .y-task-buttom .van-button {
  width: 240px;
}
.integral .y-task-buttom {
  width: 240px;
  margin: 0 auto;
}
.integral .y-task-all {
  padding: 30px 23px 20px 23px;
  width: 249px;
  height: 305px;
  border-radius: 8px;
  text-align: center;
  background-image: url(../../assets/images/coupons-bg3.png);

  background-size: 100%;
  background-repeat: no-repeat;
}
.integral .van-nav-bar {
  position: absolute;
  width: 100%;
}
.integral ::v-deep .y-in-f-jt .van-slider__button {
  display: none;
}
.integral .y-integral-2-friends .y-integral-2-info-c span {
  font-size: 0.35rem !important;
}
.integral .van-slider--disabled {
  opacity: 1;
}
.integral .y-in-f-jt {
  width: 120px;
  float: left;
  height: 3px;
  margin-top: 6px;
}
.integral .y-integral-3-all {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}
.integral .y_storage_list {
  margin-top: 10px;
  width: 100%;
}
.integral .y-record-list {
  width: 100%;
  float: left;
  border-bottom: 1px #eeeeee solid;
  padding: 13px 0px 3px 0px;
}
.integral .y-record-list h2,
.integral .y-record-list h3 {
  font-weight: normal;
  width: 94%;
  margin-left: 3%;
  float: left;
}
.integral .y-record-list h2 {
  font-size: 0.35rem;
}
.integral .y-record-list h3 {
  font-size: 0.35rem;
  color: #999;
  padding: 7px 0px;
}
.integral .y-record-list h2 p,
.integral .y-record-list h3 p {
  float: left;
}
.integral .y-record-list h2 span,
.integral .y-record-list h3 span {
  float: right;
}

.integral .y-integral-2-info-r .van-button {
  height: 28px;
  padding: 4px 12px;
  margin-top: 13px;
}
.integral .y-integral-2-info-c span {
  font-size: 0.35rem;
  color: #999;
  margin-top: 6px;
}
.integral .y-integral-2-info-c p i {
  font-size: 0.35rem;
  font-weight: normal;
  color: #ee904f;
}
.integral .y-integral-2-info-c p {
  font-weight: bold;
  font-size: 0.35rem;
  color: #000;
  margin-top: 8px;
}
.integral .y-integral-2-info-c p,
.integral .y-integral-2-info-c span {
  width: 100%;
  float: left;
}
.integral .y-integral-2-info-c {
  width: 55%;
  float: left;
}
.integral .y-integral-2-info-r {
  float: right;
}
.integral .y-integral-2-info img {
  width: 55px;
  height: 55px;
  float: left;
  margin-right: 10px;
}
.integral .y-integral-2-info {
  width: 93%;
  padding: 3%;
  float: left;
  border: 1px #eeeeee solid;
  margin: 5px 0px;
  border-radius: 5px;
}
.integral .y-integral-2-list {
  width: 94%;
  float: left;
  margin: 0px 3% 10px 3%;
}
.integral .y-integral-2 {
  width: 100%;
  float: left;
  margin: 5px 0px;
}
.integral .y-my-integral-top {
  width: 94%;
  margin: 10px 3%;
}
.integral .y-my-integral-top p {
  font-size: 0.35rem;
  float: left;
}
.integral .y-my-integral-top span {
  float: right;
  margin-top: 5px;
}
.integral .y-my-integral-top img {
  width: 72px;
}
.integral .y-my-integral-top .van-icon {
  float: right;
  color: #999 !important;
}
.integral .y-fg {
  width: 100%;
  float: left;
  height: 10px;
  background-color: #f3f4f9;
}
.integral .y-integral-1 span {
  font-size: 0.35rem;
  color: #999;
  float: left;
}
.integral .y-integral-1 {
  width: 94%;
  margin: 10px 3% 15px 3%;
  float: left;
}
.integral .y-integral-1 h2 {
  float: left;
  width: 60%;
}
.integral .y-integral-1 h2 p {
  width: 100%;
  float: left;
  color: #ee904f;
  margin-bottom: 3px;
}
.integral .y-integral-1 h2 p span {
  margin-top: 0.25rem;
  font-size: 0.35rem;
  float: left;
  margin-right: 5px;
  color: #ee904f;
}
.integral .y-integral-1 h2 p i {
  font-size: 0.35rem;
  float: left;
  margin: 7px 0px 0px 0px;
}
.integral .y-integral-1 h2 p .van-icon {
  color: #ee904f !important;
  float: left;
  margin-top: 11px;
}
.integral .y-integral-1 img {
  width: 50px;
  height: 50px;
  float: left;
  margin: 0px 10px;
}
.integral .y-integral-all {
  width: 100%;
  background-image: url(../../assets/images/integral-bg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  float: left;
}
.integral .y-integral-banner {
  width: 94%;
  margin-left: 3%;
  margin-top: 50px;
  background-image: url(../../assets/images/integral-banner.png);
  background-size: 100%;
  height: 160px;
  background-repeat: no-repeat;
}
.integral .y-integral-banner h2 {
  padding: 50px 20px 0px 20px;
  color: #ffffff;
}
.integral .y-integral-banner h2 span {
  font-size: 0.35rem;
  letter-spacing: 2px;
}
.integral .y-integral-banner h2 p {
  font-size: 0.35rem;
  color: #fcd4c5;
  margin-top: 5px;
}
.integral .y-integral-banner .van-button {
  background-color: #ffffff;
  border: none;
  color: #ff7351;
  margin-top: 12px;
  padding: 3px 8px;
  border-radius: 10px 1px 10px 1px;
}
.integral .y-integral-banner .van-button span {
  font-size: 0.35rem;
  letter-spacing: 0px;
}

.integral ::v-deep .van-tabs__line {
  background-color: transparent !important;
}

.integral ::v-deep .back {
  background: #fff !important;
}
.integral .back .van-icon {
  color: #000 !important;
}
.integral ::v-deep .back .van-nav-bar__title {
  color: #000 !important;
}
.integral .van-nav-bar {
  background-color: transparent;
}
.integral .van-icon {
  color: #ffffff !important;
}
.integral ::v-deep .van-nav-bar__title {
  color: #ffffff !important;
} */
</style>