<template>
  <div class="EditUserINfo setup">
    <div class="y_optometry">
      <van-nav-bar :title="title" left-arrow @click-left="gohome">
        <template #left>
          <van-icon name="arrow-left" color="#000" size="20" />
        </template>
      </van-nav-bar>
      <div class="y-setuptop f-l">
        <h2>基本信息</h2>
        <p>查看并修改您的基本信息</p>
      </div>
      <van-cell-group style class="f-l y-setuplist">
        <van-cell title="头像" is-link @click="onAvatarClick">
          <van-uploader :after-read="onRead">
            <van-image class="avatar" round :src="bigThumb" />
          </van-uploader>
        </van-cell>
        <!-- <input type="file" hidden ref="file" @change="onFileChange" /> -->
        <!-- <van-uploader
            :after-read="onRead"
            accept
            multiple
            class="icon-add"
        ></van-uploader>-->
        <van-cell title="昵称" is-link>
          <div @click="editSomeThing(1,user.name)">{{user.name}}</div>
        </van-cell>
        <!-- @click="editSomeThing(2,user.sexShow)" -->
        <van-cell title="性别" is-link @click="sex=true">
          <div>{{user.sex == 1?"男":user.sex==0?"女":""}}</div>
        </van-cell>
        <!-- @click="editSomeThing(3,user.brithday)" -->
        <van-cell title="生日" is-link @click="brithday=true">
          <div>{{user.brithday}}</div>
        </van-cell>
        <!-- @click="editSomeThing(4,user.dj_year)" -->
        <van-cell title="戴镜年数" is-link @click="yearsnum=true">
          <div>{{user.dj_year}}</div>
        </van-cell>
        <van-cell title="联系电话">
          <!-- @click="editSomeThing(5,user.phone)" -->
          <div>{{user.phone}}</div>
        </van-cell>
        <van-cell title="邮箱" is-link @click="editSomeThing(6,user.email)">
          <div>{{user.email}}</div>
        </van-cell>
      </van-cell-group>
      <van-cell-group style="margin-top:15px; padding-top:0px" class="f-l y-setuplist">
        <van-cell title="收件地址" is-link @click="toAddress">
          <div>{{user.address}}</div>
        </van-cell>
      </van-cell-group>
      <span class="banben">版本号:1.0.1</span>
      <van-cell-group style="margin-top:20px; padding-top:0px" class="f-l y-setuplist">
        <van-button plain type="primary" block @click="logout">退出登录</van-button>
      </van-cell-group>
      <!-- 性别弹出框 -->
      <van-action-sheet
        v-model="sex"
        :close-on-click-overlay="false"
        :actions="actionssexShow"
        cancel-text="取消"
        close-on-click-action
        @cancel="onCancelsexShow"
        @select="onSelectsexShow"
      />
      <!-- 戴镜年数弹出框 -->
      <van-action-sheet
        v-model="yearsnum"
        :close-on-click-overlay="false"
        :actions="actionsyearsnum"
        cancel-text="取消"
        close-on-click-action
        :style="{ height: '40%' }"
        @cancel="onCancelyearsnum"
        @select="onSelectyearsnum"
      />
      <!-- 生日弹出层 -->
      <van-popup
        v-model="brithday"
        round
        :close-on-click-overlay="false"
        position="bottom"
        :style="{ height: '40%' }"
      >
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="confirm"
          @cancel="cancel"
        />
      </van-popup>
      <!-- 加载框 -->
      <!-- <van-popup class="jiazai" v-model="showloading">
        <van-loading type="spinner" />
      </van-popup>-->
      <van-overlay class="jiazai" :show="showloading">
        <div class="wrapper">
          <van-loading type="spinner" />
        </div>
      </van-overlay>
      <!-- 积分弹出框 -->
      <van-popup :close-on-click-overlay="false" v-model="show_rw" class="lqwork">
        <h2>任务完成</h2>
        <div class="y-task-top">
          <p>奖励</p>
        </div>
        <div class="y-task-zj">
          <p>+{{popScoreNum}}</p>
          <img src="../../assets/images/jb.png" />
        </div>
        <div class="y-task-buttom">
          <van-button
            round
            closed
            color="linear-gradient(to right, #F28E26, #FD644F)"
            type="primary"
            @click="show_rw =false"
          >完成</van-button>
        </div>
      </van-popup>
    </div>
  </div>
</template>
 
<script>
import { LOGOUT, LOGIN } from "../.././vuex/mutation_types";
import { timestampToTime } from "../../utils/util";
export default {
  name: "EditUserINfo",
  components: {},
  props: {},
  data() {
    return {
      setType: null,
      show_rw: false,
      popScoreNum: 0,
      showloading: false,
      bigThumb: "",
      brithday: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      sex: false,
      yearsnum: false,
      actionssexShow: [{ name: "男" }, { name: "女" }],
      actionsyearsnum: [
        { name: "1" },
        { name: "2" },
        { name: "3" },
        { name: "4" },
        { name: "5" },
        { name: "6" },
        { name: "7" },
        { name: "8" },
        { name: "9" },
        { name: "10" },
        { name: "10+" }
      ],
      title: "",
      // user: {},
      user: {
        thumb: "",
        name: "",
        sex: null,
        brithday: "",
        dj_year: "",
        phone: "",
        email: "",
        address: ""
      }, //用户信息
      isPreviewShow: false,
      images: [] //预览图片列表
    };
  },
  watch: {},
  computed: {
    file() {
      return this.$refs["file"];
    }
  },
  created: function() {
    this.setType = this.$route.query.type ? this.$route.query.type : null;
    console.log("setType", this.setType);
    if (this.setType && this.setType == 1) {
      var params1 = {
        token: this.$store.getters.token,
        name: this.$route.query.name
      };
      this.$api.member.editUserInfo(params1).then(res => {
        if (res.code == 200) {
          var editUserInfo1 = res.result;
          if (editUserInfo1.isPopGetScore == 1) {
            this.popScoreNum = editUserInfo1.popScoreNum;
            this.show_rw = true;
          }
        }
      });
    } else if (this.setType && this.setType == 6) {
      var params2 = {
        token: this.$store.getters.token,
        email: this.$route.query.name
      };
      this.$api.member.editUserInfo(params2).then(res => {
        if (res.code == 200) {
          var editUserInfo2 = res.result;
          if (editUserInfo2.isPopGetScore == 1) {
            this.popScoreNum = editUserInfo2.popScoreNum;
            this.show_rw = true;
          }
        }
      });
    }
    this.getUserInfo();
  },
  methods: {
    //点击跳转到收件地址
    toAddress() {
      this.$router.push({
        path: "/address",
        query: {
          status: 0
        }
      });
    },
    //点击生日取消
    cancel() {
      this.brithday = false;
    },
    //点击生日确定
    confirm(value) {
      var time = new Date(value).getTime();
      // console.log("time", timestampToTime(time));
      var params = {
        token: this.$store.getters.token,
        brithday: timestampToTime(time)
      };
      this.$api.member.editUserInfo(params).then(res => {
        if (res.code == 200) {
          // this.$toast(res.msg);
          var editUserInfo = res.result;
          if (editUserInfo.isPopGetScore == 1) {
            this.popScoreNum = editUserInfo.popScoreNum;
            this.show_rw = true;
          }
          this.user.brithday = timestampToTime(time);
          this.brithday = false;
        }
      });
    },
    //戴镜年数
    onSelectyearsnum(item) {
      var params = {
        token: this.$store.getters.token,
        dj_year: item.name
      };
      this.$api.member.editUserInfo(params).then(res => {
        if (res.code == 200) {
          var editUserInfo = res.result;
          if (editUserInfo.isPopGetScore == 1) {
            this.popScoreNum = editUserInfo.popScoreNum;
            this.show_rw = true;
          }
          // this.$toast(res.msg);
          this.yearsnum = false;
          this.user.dj_year = item.name;
        }
      });
    },
    onCancelyearsnum() {
      // this.$toast("取消");
    },
    //选中性别
    onSelectsexShow(item) {
      var sexa = item.name == "男" ? 1 : 0;
      var params = {
        token: this.$store.getters.token,
        sex: sexa
      };
      this.$api.member.editUserInfo(params).then(res => {
        if (res.code == 200) {
          // this.$toast(res.msg);
          var editUserInfo = res.result;
          if (editUserInfo.isPopGetScore == 1) {
            this.popScoreNum = editUserInfo.popScoreNum;
            this.show_rw = true;
          }
          this.user.sex = sexa;
          this.sex = false;
        }
      });
    },
    //点击性别取消
    onCancelsexShow() {
      // this.$toast("取消");
    },
    editSomeThing(type, name) {
      this.$router.push({
        path: "/setup_edit",
        query: {
          type: type,
          name: name
        }
      });
    },
    gohome() {
      this.$router.push("/home");
    },
    // 上传图片
    onRead: function(file) {
      var that = this;
      var token = this.$store.getters.token;
      this.$api.member.uploadTu({ imgFile: file.content }).then(res => {
        if (res.code == 200) {
          this.$api.member
            .editUserInfo({ token: token, thumb: res.result.url })
            .then(res => {
              if (res.code == 200) {
                // this.$toast(res.msg);
                var editUserInfo = res.result;
                if (editUserInfo.isPopGetScore == 1) {
                  this.popScoreNum = editUserInfo.popScoreNum;
                  this.show_rw = true;
                }
                // this.user = res.result;
                this.getUserInfo();
              }
            });
        }
      });
    },
    logout: function() {
      this.$store.commit(LOGOUT);
      this.$router.push("/login_wx");
    },
    //更新头像昵称
    getUserInfo() {
      this.showloading = true;
      var param = {
        token: this.$store.getters.token
      };
      this.$api.member.userInfo(param).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.user = res.result.memberInfo;
          var tempThumb = this.user.thumb;
          if (tempThumb.indexOf("http") == -1) {
            this.bigThumb = this.$publicData.imgUrl + tempThumb;
          } else {
            this.bigThumb = tempThumb;
          }
        }
      });
    },
    onAvatarClick() {}
  },
  mounted() {}
};
</script>
<style scoped>
</style>