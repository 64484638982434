<template>
    <div class="layout">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive">
                <!-- 这里是会被缓存的视图组件，比如 Home！ -->
            </router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive">

        </router-view>
    </div>
</template>

<script>
    import wx from "weixin-js-sdk";
    export default {
        name: 'App',
        data() {
            return {
                shareConfig: {},
            };
        },
        components: {},
        created: function () {
            console.log(this.$route.query);
            if(this.$route.query.type!=null && this.$route.query.type!=undefined && this.$route.query.type!= ''){
                window.localStorage.setItem("extendType", this.$route.query.type);
                window.localStorage.setItem("extendId",  this.$route.query.sharememberid);
            }
        },
        watch: {
            $route(to, from) {
                // 根据路由判断, meta.share_config 为true, 统一设置分享
                if (this.$route.meta.share_config) {
                    this.shareFun();
                }
            }
        },
        methods: {
            shareFun() {
                var that = this;
                var tempurl = location.href;
                if(this.$store.getters.user_id != undefined && this.$store.getters.user_id!=null){
                    if(location.href.indexOf("?") == -1){// 不存在？ 则没有参数
                        tempurl = location.href + '?type=member&sharememberid='+this.$store.getters.user_id;
                    }else{
                        if(location.href.indexOf("sharememberid") == -1){// 不存在则直接加
                            tempurl = location.href + '&type=member&sharememberid='+this.$store.getters.user_id;
                        }else{ // 参数替换
                            var heheurl = location.href;
                            var heheArr = heheurl.split('&');
                            tempurl = "";
                            for(let i in heheArr){
                                if(i == 0 ){
                                   tempurl = heheArr[0]; 
                                   continue;
                                }

                                if(heheArr[i].indexOf('sharememberid=') != -1){
                                    tempurl +=  "&sharememberid=" + this.$store.getters.user_id;
                                }else{
                                    tempurl +=  "&" + heheArr[i];
                                }
                            }
                        }
                        // tempurl = location.href + '&type=member&sharememberid='+this.$store.getters.user_id;
                    }
                }
                const url = tempurl;
                console.log("Iframe ---share----url=" + url);
                const shareUrl = location.href.split("#")[0];
                that.$api.common.commonShare({url: shareUrl}).then(res => {
                    if (res.code == 200) {
                        that.shareConfig = res.result;
                        wx.config({
                            debug: that.shareConfig.debug,
                            beta: that.shareConfig.beta,
                            jsApiList: that.shareConfig.jsApiList,
                            appId: that.shareConfig.appId,
                            nonceStr: that.shareConfig.nonceStr, // 随机串
                            timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
                            signature: that.shareConfig.signature // 签名
                        });
                        // console.log('Iframe ---微信分享设置---开始');
                        // 分享
                        wx.ready(function () {
                            var shareDataA = {
                                title: that.$publicData.shareTitle, // 分享标题
                                desc: that.$publicData.shareDesc, // 分享描述
                                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                imgUrl: that.$publicData.imgUrl + that.$publicData.img, // 分享图标
                                success: function () {
                                },
                                cancel: function (e) {
                                }
                            };

                            var shareDataB = {
                                title: that.$publicData.shareTitle, // 分享标题
                                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                imgUrl: that.$publicData.imgUrl + that.$publicData.img, // 分享图标
                                success: function () {
                                },
                                cancel: function (e) {
                                }
                            };
                            wx.updateAppMessageShareData(shareDataA); // 分享给朋友
                            wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
                            // console.log('Iframe ---微信分享设置---结束');
                        });
                    }
                });
            },
        }
    }
</script>
