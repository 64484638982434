/**
 * 配镜订单接口接口
 */
 import axios from "../http"; // 导入http中创建的axios实例
 import qs from "qs"; // 根据需求是否导入qs模块，把请求字段直接映射过来
 
 const pjorder = {
   //配镜-订单列表
   pjOrderList(params) {
     return axios.post("/api/member/pjOrderList", qs.stringify(params));
   },
   //配镜-订单详情
   pjOrderDetail(params) {
     return axios.post("/api/member/pjOrderDetail", qs.stringify(params));
   },
   //配镜-评价标签
   pjOrderCommentTag(params) {
     return axios.post("/api/member/pjOrderCommentTag", qs.stringify(params));
   },
   //实力档案-评价标签
   ygRecordCommentTag(params) {
     return axios.post("/api/member/ygRecordCommentTag", qs.stringify(params));
   },
   //配镜-订单提交评价
   commentCreate(params) {
     return axios.post("/api/member/commentCreate", qs.stringify(params));
   },
   //配镜-查看评价
   commentDetail(params) {
     return axios.post("/api/member/commentDetail", qs.stringify(params));
   },
  
 };
 // 导出接口
 export default pjorder;
 