import Vue from 'vue'
import App from './App.vue'
import api from './api/api'//导入api接口
import 'lib-flexible/flexible'
import router from "./router"
import vant from 'vant'
import 'vant/lib/index.css'

import VueResource from 'vue-resource'
import { Lazyload } from 'vant'
import { AddressEdit } from 'vant'
import { Slider } from 'vant'
import store from "./vuex/store"
import VConsole from "vconsole";
// new VConsole()

Vue.use(Lazyload)
Vue.use(VueResource) 
Vue.use(AddressEdit)
Vue.use(Slider)

const publicData = {
  imgUrl: "http://jiejieglass.oss-cn-beijing.aliyuncs.com", // 正式
  img:"/console/img/share_car.png",
  shareTitle : "省时省事，专业验光，实惠舒心的配镜新模式来啦~",
  shareDesc:"超专业的上门验光配镜服务，疫情期间最安全便捷的配眼镜方式。",
};

Vue.config.productionTip = false
Vue.prototype.$api = api//将api挂载到vue原型上
Vue.prototype.$publicData = publicData;
Vue.use(vant)
new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
