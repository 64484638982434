<template>
  <div id="app">
     <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      shareConfig:{},
    };
  },
  components: {
  },
  created: function(){
  },
  methods: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #333333;
  /* margin-top: 60px; */
  max-width: 750px;
  width: 100%;
}
.f-l{
  float: left;
}
p,h2,h3{
  margin: 0px;
}

</style>
