<template>
  <div class="sendCoupons">
    <div class="sendtop">
      <img src="../../assets/images/zhuan_top.png" />
    </div>
    <div class="sendcon">
      <img :src="zhuanZengDetail.memberBigThumb" alt="pic"/>
      <p>“送你一张优惠券，快快收下吧！”</p>
      <div class="zhuan_ka">
        <div class="ka_left">
          <span v-if="zhuanZengDetail.coupon_info.type == 0">现金券</span>
          <span v-if="zhuanZengDetail.coupon_info.type == 1">折扣券</span>
          <span class="time">有效日期至{{zhuanZengDetail.coupon_info.valide_end}}</span>
        </div>
        <div class="ka_right" v-if="zhuanZengDetail.coupon_info.type == 0">
          {{zhuanZengDetail.coupon_info.money *1}}
          <span>元</span>
        </div>
        <div class="ka_right" v-if="zhuanZengDetail.coupon_info.type == 1">
          {{zhuanZengDetail.coupon_info.money *10}}
          <span>%</span>
        </div>
      </div>
      <van-button round type="info" @click="shouxia" class="shou">收下</van-button>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { Dialog } from "vant";
export default {
  data() {
    return {
      type: null,
      lingurl: "",
      shareConfig: {},
      coupon: {},
      member_id: null,
      coupon_sn: null,
      zhuanZengDetail: {
        coupon_info:{
          type:""
        }
      },
      code:"",
      memberBigThumb:"",
      order_sn:"",
    };
  },
  created: function() {
    var arr = location.href.split("order_sn=");
    var order_sn = arr[1];
    if (order_sn != null && order_sn != undefined) {
      this.order_sn = order_sn;
      // 分享进来的
      // 这里需要访问接口查询券详情。
      this.$api.coupon.zhuanZengDetail({ order_sn: order_sn }).then(res => {
        this.code = res.code;
        if (res.code == 200) {
          this.zhuanZengDetail = res.result;
          this.coupon_sn = res.result.coupon_sn;
          if (this.zhuanZengDetail.member_thumb.indexOf("http") == -1) {
                this.zhuanZengDetail.memberBigThumb = this.$publicData.imgUrl + this.zhuanZengDetail.member_thumb;
            } else {
              this.zhuanZengDetail.memberBigThumb = this.zhuanZengDetail.member_thumb;
            }
        }
        // 这里有券已被领取情况。
        else if (res.code == 409) {
          document.getElementsByClassName("shou")[0].style.background = "#999";
          document.getElementsByClassName("shou")[0].style.borderColor = "#999";
          this.$toast(res.msg);
        }
      });
    } else {
      // console.log("Meiyou");
    }
  },
  methods: {
    shareFunction(){
      var that =this;
      const shareUrl = location.href.split("#")[0];
      that.$api.common.commonShare({ url: shareUrl }).then(res => {
        if (res.code == 200) {
          that.shareConfig = res.result;
          wx.config({
            debug: that.shareConfig.debug,
            beta: that.shareConfig.beta,
            jsApiList: that.shareConfig.jsApiList,
            appId: that.shareConfig.appId,
            nonceStr: that.shareConfig.nonceStr, // 随机串
            timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
            signature: that.shareConfig.signature // 签名
          });
          // 分享
          wx.ready(function() {
              var shareDataA = {
                  title: '送你一张优惠券', // 分享标题
                  desc: '分享自捷捷公众号', // 分享描述
                  link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: that.$publicData.imgUrl + '/console/img/quan.png', // 分享图标
                  success: function() {
                  },
                  cancel: function(e) {
                  }
              };
              var shareDataB = {
                  title: '送你一张优惠券', // 分享标题
                  link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: that.$publicData.imgUrl + '/console/img/quan.png', // 分享图标
                  success: function() {
                  },
                  cancel: function(e) {
                  }
              };
            wx.updateAppMessageShareData(shareDataA); // 分享给朋友
            wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
          });
        }
      });
    },
    shouxia() {
      if (this.code == 200) {
        var token = this.$store.getters.token;
        if (token == undefined || token == null || token == "") {
          window.localStorage.setItem("inPath", "/sendCoupons?order_sn="+this.order_sn);
          Dialog.confirm({
            message: "当前尚未登录,是否登录"
          })
            .then(() => {
              window.localStorage.setItem("curCouponSn", this.coupon_sn);
              this.$router.push("/login_wx");
            })
            .catch(() => {
              // on cancel
            });
          return;
        } else {
          if (this.coupon_sn == null || this.coupon_sn == undefined) {
            this.coupon_sn = window.localStorage.getItem("curCouponSn");
          }
          this.$api.coupon
            .couponZhuanLing({ coupon_sn: this.coupon_sn, token: token })
            .then(res => {
              if (res.code == 200) {
                // 领取成功，可以跳转至 我的优惠券列表。 这里我的优惠券列表上边左侧导航需要跳转至首页。不要跳回领券页面。
                this.$toast("领取成功");
                setTimeout(() => {
                  this.$router.push("/myCoupon");
                }, 1000);
              }
            });
        }
      }
    }
  }
};
</script>

<style scoped>
/* .sendCoupons ::v-deep .van-nav-bar {
  padding-top: 0.1rem;
}
.sendCoupons ::v-deep .van-nav-bar .van-icon {
  color: #fff !important;
  font-size: 0.4rem;
}
.sendCoupons ::v-deep .van-nav-bar__title {
  color: #fff !important;
  font-size: 0.45rem;
}
.sendCoupons .zhuan_ka {
  background: url(../../assets/images/zhuan_quan.png) no-repeat;
  background-size: 6.91rem;
} */
</style>