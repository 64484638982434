<template>
  <!-- 个人中心 top -->
  <div class="appointment">
    <!-- :class="{back:back}" -->
    <van-nav-bar
      :fixed="false"
      title="预约记录"
      left-arrow
      :border="false"
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" />
      </template>
    </van-nav-bar>
    <div class="y-pre clearfix">
      <div class="y-predet-title">
        <div class="y-predet-title1">
          <img src="../../assets/images/order_wait.png" />
          <!-- <p>待分配</p> -->
          <p>{{result.showStatus}}</p>
        </div>
        <!-- 已分配状态开始 -->
        <!-- <div class="y-predet-title1">
        <img src="../../assets/images/order-finish.png" />
        <p>已分配</p>
        </div>-->
        <!-- 已分配状态结束 -->
        <div class="y-pre-deta-car" v-if="result.store!=null">
          <div class="y-pre-deta-car-top f-l">
            <div class="y-pre-deta-car-car">
              <img src="../../assets/images/pre-det1.png" />
            </div>
            <div class="y-pre-deta-car-carinfo">
              <p>车牌号</p>
              <span>{{result.store.name}}</span>
            </div>
          </div>
          <div class="y-pre-deta-car-xia f-l">
            <div class="y-pre-deta-car-car">
              <img src="../../assets/images/pre-det2.png" />
            </div>
            <div class="y-con-admin">
              <van-row>
                <van-col span="24" v-for="juese in result.store.staff" :key="juese.id">
                  <!-- <div v-if="juese.role_id == 1">
                    <van-image round class="y-con-admin_img" :src="juese.staffthumb" />
                    <p>
                      <span>{{juese.name}}</span>
                      <i>市场主管</i>
                    </p>
                  </div> -->
                  <div v-if="juese.role_id == 2">
                    <van-image round class="y-con-admin_img" :src="juese.staffthumb" />
                    <p>
                      <span>{{juese.name}}</span>
                      <i>验光师</i>
                    </p>
                  </div>
                </van-col>
                <!-- <van-col span="12">
                  <div>
                    <van-image
                      round
                      class="y-con-admin_img"
                      src="https://img01.yzcdn.cn/vant/cat.jpeg"
                    />
                    <p>
                      <span>欧阳淼淼</span>
                      <i>验光师</i>
                    </p>
                  </div>
                </van-col>-->
              </van-row>
            </div>
          </div>
        </div>
        <div class="y-pre-infor">
          <van-field v-model="result.name" label="联系人" placeholder="请输入联系人姓名" />
          <van-field v-model="result.pre_phone" type="tel" label="手机号码" placeholder="请输入手机号码" />
          <van-field v-model="result.pre_time" label="预约时间" placeholder="请输入预约时间" />
          <van-field v-model="result.pre_address" label="预约地址" placeholder="请输入预约地址" />
        </div>
      </div>

      <div class="y-pre-objective">
        <h2>预约目的</h2>
        <div class="y-pre-objective1">
          <van-button
            v-for="item in result.showRemarkArr"
            :key="item.index"
            :value="item"
            color="#35B6D8"
            round
            plain
          >{{item}}</van-button>
        </div>
      </div>
      <div class="y-pre-button" v-if="result.status != 0">
        <van-button
        v-if="result.showStatus !='已完成'"
          type="primary"
          block
          round
          color="linear-gradient(to right, #35B6D8, #52D3C5)"
          @click="cancelPreOrder(result.id)"
        >取消预约</van-button>
      </div>
    </div>
    <!-- 加载框 -->
    <!-- <van-popup class="jiazai" v-model="showloading">
        <van-loading type="spinner" />
    </van-popup>-->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script >
import Vue from "vue";
import { Dialog } from "vant";
export default {
  data() {
    return {
      showloading: false,
      staffthumb: "",
      result: {},
      // back: false,
      tel: "13811006973",
      name: "马楠",
      tame: "2021.04.03 14:30",
      add: "北京市石景山区万达广场A区西侧停车场",
      remarks: "验光，配眼镜"
    };
  },
  created: function() {
    this.showloading = true;
    this.$api.member
      .preOrderDetail({
        token: this.$store.getters.token,
        id: this.$route.query.id
      })
      .then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.result = res.result;
          if (this.result.store != null) {
            for (var i in this.result.store.staff) {
              var staffpic = this.result.store.staff[i].thumb;
              if (staffpic.indexOf("http") == -1) {
                this.result.store.staff[i].staffthumb =
                  this.$publicData.imgUrl + staffpic;
              } else {
                this.result.store.staff[i].staffthumb = staffpic;
              }
            }
          }
        }
      });
  },
  methods: {
    // 取消预约
    cancelPreOrder(orderid) {
      this.$api.member
        .cancelPreOrder({ token: this.$store.getters.token, id: orderid })
        .then(res => {
          if (res.code == 200) {
            this.$toast("已取消");
            this.$router.push("/appointment_record");
          }
        });
    }
    // TabBar() {
    //   var userOld = document.getElementsByClassName("y-pre-infor")[0];
    //   var that = this;
    //   if (userOld.scrollHeight - scrollY <= 90) {
    //     that.back = true;
    //   } else {
    //     that.back = false;
    //   }
    // }
  },
  mounted() {
    // window.addEventListener("scroll", this.TabBar);
  }
};
</script>
<style  scoped>
/* .appointment .back {
  background: #fff !important;
}
.appointment ::v-deep.back .van-icon {
  color: #000 !important;
}
.appointment ::v-deep.back .van-nav-bar__title {
  color: #000 !important;
}
.appointment .van-nav-bar .van-icon {
  color: #ffffff;
}
.appointment .van-nav-bar {
  background-color: transparent;
  position: absolute;
  top: 0;
  width: 100%;
}
.appointment ::v-deep .van-nav-bar__title {
  color: #ffffff;
}
.appointment .y-pre-deta-car-carinfo p {
  font-size: 0.35rem;
  color: #999;
}
.appointment .y-pre-deta-car-carinfo span {
  font-size: 0.35rem;
  color: #000;
  line-height: 26px;
}
.appointment .y-pre-deta-car-car {
  background-color: #5cc6da;
  width: 30px;
  height: 30px;
  float: left;
  border-radius: 30px;
  text-align: center;
  margin-right: 13px;
  margin-top: 5px;
}
.appointment .y-pre-deta-car-car img {
  width: 15px;
  margin-top: 8px;
}
.appointment .y-pre-deta-car-top {
  padding: 20px 14px 6px 14px;
}
.appointment .y-pre-deta-car-xia {
  padding: 6px 14px 20px 14px;
}
.appointment .y-pre-deta-car-top,
.appointment .y-pre-deta-car-xia {
  width: 92%;
}
.appointment .y-pre-deta-car {
  margin: 0px 3% 15px 3%;
  width: 94%;
  float: left;
  border-radius: 10px;
  background-color: #ffffff;
}
.appointment .y-con-admin {
  width: 86%;
  float: right;
  background-color: transparent;
  padding: 4px 0px 0px 0px;
  border-radius: 0px;
  margin: 0px;
}
.appointment .y-con-admin_img {
  width: 35px;
  height: 35px;
  float: left;
  margin-right: 8px;
}
.appointment .y-con-admin p {
  float: left;
  padding-left: 0px;
}
.appointment .y-con-admin p span {
  width: 100%;
  float: left;
  font-size: 0.35rem;
  color: #000;
}
.appointment .y-con-admin p i {
  width: 100%;
  float: left;
  font-size: 0.35rem;
  color: #999;
}

.appointment .y-predet-title1 p {
  font-size: 0.43rem;
  color: #ffffff;
  padding: 0px 9px;
  float: left;
}
.appointment .y-predet-title1 img {
  width: 18px;
  float: left;
  margin-top: 2px;
}
.appointment .y-predet-title1 {
  padding: 80px 0px 25px 27px;
}
.appointment .van-cell {
  background-color: transparent;
}

.appointment ::v-deep .van-field__label {
  width: 60px !important;
}
.appointment .y-pre-objective h2 {
  font-size: 0.35rem;
  padding: 5px 0px 2px 15px;
}
.appointment .y-pre-button {
  margin: 180px 0px 10px 0px;
}
.appointment .y-foot .van-tabbar-item--active {
  color: #35b6d8;
}
.appointment .y-foot .van-tabbar-item__icon img {
  width: 20px;
  margin-bottom: 2px;
}
.appointment .y-pre-objective1 .van-button {
  padding: 5px 14px;
  height: auto;
  line-height: none;
  font-size: 0.35rem;
  margin: 5px;
}
.appointment .y-pre-infor .van-cell {
  border-bottom: 1px #e8e8e9 solid;
}
.appointment .y-pre-objective,
.appointment .y-pre-button {
  margin-left: 3%;
  width: 94%;
  float: left;
}
.appointment .y-pre-objective1 {
  margin: 10px 0px;
  padding: 10px;
  width: 91%;
  float: left;
  border-radius: 10px;
  background-color: #ffffff;
}
.appointment .y-pre-infor {
  margin: 0px 3% 15px 3%;
  width: 94%;
  float: left;
  border-radius: 10px;
  background-color: #ffffff;
}
.appointment .y-predet-title {
  float: left;
  background-image: url("../../assets/images/pre-bg2.png");
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.appointment .y-pre-title h2 {
  padding: 80px 30px;
  color: #ffffff;
}
.appointment .y-pre-title h2 p {
  font-size: 0.35rem;
}
.appointment .y-pre-title h2 span {
  color: #d8f1f3;
  font-size: 0.35rem;
  line-height: 22px;
}
.appointment .y-pre {
  background-color: #f3f4f9;
  width: 100%;
  min-height: 100vh;
  float: left;
} */
</style>
