<template>
  <!-- 验光师详情 -->

  <div class="yanDetails">
    <!-- 返回键 -->
    <van-nav-bar
      :fixed="true"
      :border="false"
      :placeholder="true"
      title="详细信息"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#fff" />
      </template>
    </van-nav-bar>
    <van-image class="backimg" :src="img_back" fit="cover" />
    <!-- 验光师 -->
    <div class="con">
      <div class="box">
        <!-- <van-image :src="img_yan" fit="cover" /> -->
        <van-image :src="yanguang.touxiang" fit="cover" />
        <div class="right">
          <div class="top">
            <span class="name">{{yanguang.name}}</span>
            <span class="ren" v-if="yanguang.role_id==1">市场主管</span>
            <van-icon :name="img_v" v-if="yanguang.role_id==2"/>
            <span class="ren" v-if="yanguang.role_id==2">认证验光师</span>
          </div>
          <div class="down">
            <van-icon :name="img_tel" />
            <span class="tel">{{yanguang.phone}}</span>
            <!-- <span class="good">擅长散光矫正治疗</span> -->
            <span class="bai">
              好评率：
              <span>100%</span>
            </span>
          </div>
        </div>
      </div>
      <div class="introduce">
        <h3>验光师介绍</h3>
        <!-- <p>毕业于安徽医科大学临床医学专业，学士学位，验光师，安徽省近视预防与治疗研究会成员。2004年在中国科学技术大学医院参加工作，对青少年近视发展控制、圆锥角膜和大散光矫正治疗有深入研究。</p> -->
        <p>{{yanguang.desc}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img_back: require("../../assets/images/back.png"),
      img_yan: require("../../assets/images/yan.png"),
      img_v: require("../../assets/images/v.png"),
      img_tel: require("../../assets/images/tel.png"),
      yanguang:{}
    };
  },
  created: function() {
      this.getStaffList();
  },
  methods: {
    //   员工信息接口
    getStaffList() {
      var that = this;
      var params = {
        staff_id: that.$route.query.id
      };
      that.$api.member.staffInfo(params).then(res => {
          if(res.code == 200){
              that.yanguang = res.result;
              if(that.yanguang.thumb.indexOf("http")==-1){
                  that.yanguang.touxiang = this.$publicData.imgUrl + that.yanguang.thumb
              }else{
                   that.yanguang.touxiang = that.yanguang.thumb
              }
          }
      });
    }
  }
};
</script>

<style scoped>
/* .yanDetails ::v-deep .van-nav-bar {
  background: #35b6d8 !important;
}
.yanDetails ::v-deep .van-nav-bar__title {
  color: #fff;
}
.yanDetails .backimg {
  top: -1.23rem;
}
.yanDetails .con {
  position: relative;
  top: -4.9rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
.yanDetails .con .box {
  display: flex;
  background: #fff;
  border-radius: 0.2rem;
  box-shadow: 0rem 0.13rem 0.28rem 0rem rgba(205, 205, 205, 0.2);
  width: 9.2rem;
  padding-left: 0.3rem;
  padding-top: 0.3rem;
  margin-bottom: 0.89rem;
}
.yanDetails .con .box .van-image {
  width: 4.05rem;
  height: 5.57rem;
  margin-right: 0.6rem;
  margin-bottom: 0.45rem;
}
.yanDetails .con .box .right .top {
  margin-bottom: 1.3rem;
}
.yanDetails .con .box .right .top .name {
  display: block;
  font-size: 0.45rem;
  line-height: 0.48rem;
  font-weight: 600;
  margin-bottom: 0.36rem;
  margin-top: 0.36rem;
}
.yanDetails .con .box .right .top .ren {
  font-size: 0.35rem;
  color: #35b6d8;
  display: inline-block;
  margin-left: 0.1rem;
  vertical-align: text-bottom;
}
.yanDetails .con .box .right .down .good {
  display: block;
}
.yanDetails .con .box .right .down .van-icon {
  margin-right: 0.1rem;
}
.yanDetails .con .box .right .down .tel {
  font-size: 0.37rem;
}
.yanDetails .con .box .right .down .good {
  font-size: 0.4rem;
  opacity: 0.8;
  margin-top: 0.2rem;
  margin-bottom: 0.1rem;
}
.yanDetails .con .box .right .down .bai {
  margin-top: 0.2rem;
  display: block;
  font-size: 0.35rem;
  opacity: 0.6;
}
.yanDetails .con .box .right .down .bai span {
  color: #ffa200;
  font-size: 0.35rem;
  font-style: italic;
  font-weight: 500;
}
.yanDetails .con .introduce {
  padding-left: 0.4rem;
  padding-right: 0.7rem;
}
.yanDetails .con .introduce h3 {
  font-size: 0.4rem;
  font-weight: 600;
  margin-bottom: 0.35rem;
}
.yanDetails .con .introduce p {
  font-size: 0.35rem;
  opacity: 0.6;
  line-height: 0.61rem;
} */
</style>