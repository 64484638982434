<template>
  <div class="edittel">
    <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y-edit y-tel">
      <van-field v-model="tel" center clearable placeholder="输入新的手机号码">
        <template #button>
          <van-button size="small" type="primary">获取验证码</van-button>
        </template>
      </van-field>
      <van-cell-group>
        <van-field v-model="sms" clearable placeholder="输入手机验证码" />
      </van-cell-group>
    </div>
    <div class="y-edit-button">
      <van-button
        to="home"
        round
        type="primary"
        block
        color="linear-gradient(to right, #35B6D8, #52D3C5)"
      >保存</van-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
         tel: '',
      sms: '',
      title: "修改手机号",
      nickname: this.$route.params.id
    };
  }
};
</script>
<style scoped>
/* .edittel .y-tel .van-button--primary{
  background-color: #ffffff;
  color: #5CC6DA;
  border: 1px #5CC6DA solid;
  border-radius: 15px;
  height: 25px;
  font-size: 0.35rem;
}
.edittel .y-tel .van-cell{
border-bottom: 1px #eeeeee solid;
}
.edittel .van-nav-bar .van-icon {
  color: #000;
}
.edittel .y-edit-button {
  width: 94%;
  float: left;
  margin-left: 3%;
  bottom: 0;
  z-index: 1000;
  position: fixed;
  left: 0;
  bottom: 0;
  margin-bottom: 25px;
} */
</style>