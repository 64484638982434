<template>
  <!-- 修改收件地址 -->

  <div class="newAddress">
    <van-nav-bar
      :z-index="99"
      :border="false"
      title="新建收件地址"
      :fixed="false"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <!-- 地址编辑 -->
    <div class="edit">
      <van-address-edit
        :area-list="areaList"
        show-set-default
        show-search-result
        :search-result="searchResult"
        :area-columns-placeholder="['请选择', '请选择', '请选择']"
        area-placeholder="请选择地区"
        save-button-text="保存并使用"
        @save="onSave"
        @change-detail="onChangeDetail"
      />
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { areaList } from "@vant/area-data";
export default {
  data() {
    return {
      areaList:{},
      //   areaList: {
      //     province_list: {
      //       110000: "北京市",
      //       120000: "天津市"
      //     },
      //     city_list: {
      //       110100: "北京市",
      //       120100: "天津市"
      //     },
      //     county_list: {
      //       110101: "东城区",
      //       110102: "西城区",
      //       120101: "宁河区"
      //     }
      //   },
      searchResult: [],
    //   areasDetail:[]
      //   AddressInfo:{
      //       name:111
      //   }
    };
  },
  created: function() {
      this.getAreasDetail();
  },
  methods: {
    //地址接口
    getAreasDetail() {
         var params = {}
           this.$api.address.areasDetail(params).then(res=>{
               if(res.code == 200){
                   this.areaList = res.result;
               }
           })
    },
    // 点击保存按钮时触发
    onSave() {
      var that = this;
      that.$router.push({
        path: "/order"
      });
    },
    // 修改详细地址时触发
    onChangeDetail(val) {
      //   if (val) {
      //     this.searchResult = [
      //       {
      //         name: "黄龙万科中心",
      //         address: "杭州市西湖区"
      //       }
      //     ];
      //   } else {
      //     this.searchResult = [];
      //   }
    }
  }
};
</script>

<style>
</style>