<template>
  <div class="optometry_revise">
    <van-nav-bar
      :z-index="99"
      :fixed="false"
      :placeholder="true"
      :title="title"
      left-arrow
      :v-else-if="false"
      :border="false"
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <!-- 表单 -->
    <van-form @submit="onSubmit">
      <van-field v-model="username" name="姓名" label="姓名" placeholder="请填写姓名" />
      <!-- <van-field v-model="tel" type="tel" name="手机号码" label="手机号码" placeholder="请填写手机号码" />
      <van-field
        readonly
        clickable
        name="area"
        :value="value"
        label="地区选择"
        placeholder="点击选择省市区"
        @click="showArea = true"
      />
      <van-popup v-model="showArea" position="bottom">
        <van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
      </van-popup>
      <van-field v-model="address" name="详细地址" label="详细地址" placeholder="市县、乡镇、街道、楼牌号等" /> -->
    </van-form>
    <van-button
      class="optomtry_save"
      color="linear-gradient(97deg, #35B6D8, #52D3C5)"
      round
      type="info"
      @click="save_use"
    >保存并使用</van-button>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
export default {
  data() {
    return {
      username: "",
      tel: "",
      value: "",
      showArea: false,
      areaList: areaList,
      address: "",
      title:""
    };
  },
  created:function(){
      var type = this.$route.query.type;
      if(type == "1"){
          this.title = "添加视力档案"
      }else{
           this.title = "修改视力档案"
      }
      if(this.$route.query.name){
          var name = this.$route.query.name;
          this.username = name
      }

  },
  methods: {
    onSubmit() {},
    onConfirm(values) {
      this.value = values
        .filter(item => !!item)
        .map(item => item.name)
        .join("/");
      this.showArea = false;
    },
    // 点击保存并使用
    save_use() {
        var type = this.$route.query.type;
        if(type == 1){
            this.$router.push({
                path:"/optometry_add",
                query:{
                    name:this.username
                }
            })
        }else{
             this.$router.push({
                path:"/optometry_infor",
                query:{
                    name:this.username,
                    id:this.$route.query.id
                }
            })
        }
    }
  }
};
</script>

<style>
/* .optometry_revise {
  min-height: 100vh;
  background: #f3f4f9;
  position: relative;
}
.optometry_revise .optomtry_save {
  width: 9.2rem;
  position: absolute !important;
  bottom: 1rem;
  left: 0.4rem;
} */
</style>