<template>
    <div class="EditUserINfo service_process">
    <van-nav-bar
      :z-index="99"
      title="服务流程"
      :fixed="false"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y-srrvice-pro-bg f-l">
        <img src="../../assets/images/home-my-bgs1.png">
    </div>
    <div class="y-srrvice-pro-info">
        <h2>全套服务流程</h2>
        <img src="../../assets/images/home-my-bg2.png">
    </div>
    </div>
</template>
<script>
export default {
     
}
</script>
<style  scoped>
/* .service_process .van-nav-bar{
  position: absolute;
  width: 100%;
}
.service_process .y-srrvice-pro-info img{
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
    margin-bottom: 30px;
}
.service_process .y-srrvice-pro-info{
    width: 100%;
    float: left;
    margin-top:20px;
}
.service_process .y-srrvice-pro-info h2{
    font-size: 0.35rem;
    font-weight: bold;
    text-align: center;
}
.service_process .y-srrvice-pro-bg{
    width: 100%;
}
.service_process .y-srrvice-pro-bg img{
    width: 100%;
}

.service_process .van-nav-bar .van-icon {
  color: #ffffff !important;
}
.service_process .van-nav-bar {
  background-color: transparent;
}
.service_process ::v-deep .van-nav-bar__title {
  color: #ffffff ;
} */

</style>