<template>
  <div class="EditUserINfo deitvip clearfix">
    <van-nav-bar
      :z-index="99"
      :border="false"
      title="会员权益"
      :fixed="false"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y-vip-all clearfix">
      <div class="y-vip-banner">
        <!-- 轮播 -->
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item,index) in levelDetailvip.levelList" :key="index">
              <div class="y-vip-banner-c f-l">
                <img class="y-vip-img" :src="item.memberthumb" alt="pic" />
                <div class="y-vip-card-1 clearfix">
                  <div class="y-vip-card-top" v-if="levelDetailvip.levelInfo.id == item.id">当前等级</div>
                  <div class="y-vip-card-xia">
                    <div class="y-vip-card-tx">
                      <img :src="levelDetailvip.memberInfo.infothumb" />
                    </div>
                    <div class="y-vip-caed-wz">
                      <h2>
                        <i>
                          <img :src="item.levelicon" alt="pic" />
                        </i>
                        <p>{{item.name}}</p>
                      </h2>
                      <span>/ 尊享{{item.member_levelright.length}}项权益 /</span>
                    </div>
                  </div>
                </div>
                <div class="y-vip-card-2">
                  <h2>经验值</h2>
                  <!-- <div class="box_slider"> -->
                  <van-slider
                    v-model="jingyan"
                    active-color="#B68239"
                    disabled
                    :max="Number(item.money_end)"
                    class="y-vip-jt"
                  >
                    <template #button>
                      <div>
                        <div
                          class="custom-button xiao"
                          v-if="valuejingyan <= item.money_end &&valuejingyan<=999"
                        >{{valuejingyan}}</div>
                        <div
                          class="custom-button da"
                          v-if="valuejingyan <= item.money_end &&valuejingyan>999"
                        >{{valuejingyan}}</div>
                      </div>
                    </template>
                  </van-slider>
                  <!-- </div> -->

                  <i>{{Number(item.money_end)}}</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="y-vip-nav">
        <div class="y-vip-nav-top">
          <p>尊享权益</p>
          <span>
            （ {{nowlength}}
            <span>/ {{alllength}}</span> ）
          </span>
        </div>
        <div class="y-vip-nav-xia f-l">
          <van-row style="text-align: center; margin-top: 18px;">
            <van-col
              span="6"
              v-for="(item,index) in alltag"
              :key="index"
              class="quany"
              @click="toDesc(item)"
            >
              <div class="yin" :class="item.suoname"></div>
              <img :src="item.thumbicon" />
              <!-- v-for="(itemsuo,isuo) in levelDetailvip.levelInfo.member_levelright"
              :key="isuo"-->
              <span>
                <!-- :class="itemsuo.member_level_right.id == item.id ?'y-vip-n':'y-vip-s'" -->
                <img :class="item.suoname" src="../../assets/images/vip-nav5.png" />
              </span>
              <p>{{item.name}}</p>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
    <van-popup v-model="desc" closeable class="jfpop" :close-on-click-overlay="false">
      <div class="jfdesc">
        <h1>积分奖励</h1>
        <div class="con">
          <h3>权益说明</h3>
          <span>{{jidesc}}</span>
        </div>
      </div>
    </van-popup>
    <!-- 加载框 -->
    <!-- <van-popup class="jiazai" v-model="showloading">
        <van-loading type="spinner" />
    </van-popup>-->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/swiper.min.css";
import { Toast } from "vant";
export default {
  data() {
    return {
      swipernum: "",
      jidesc: "",
      desc: false,
      suoname: "",
      nowindex: 0,
      showloading: false,
      jingyan: 0,
      levelDetailvip: {
        levelInfo: {
          member_levelright: []
        },
        memberInfo: {
          infothumb: ""
        }
      },
      memberthumb: "",
      levelicon: "",
      thumbicon: "",
      alllength: "",
      nowlength: "",
      allRight: [],
      alltag: []
      // infothumb:"",
    };
  },
  created: function() {
    this.getLevelDetail();
    // var a =
    //   "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJKvNSEf7Np0ATrOR0YV0BZMb9yd6PGhLD0o9HuurCJ3d2EzYj9de295Ftm0zAnQrO7RoKYk4gEiag/132";
    // console.log(a.indexOf("http") == -1);
  },
  methods: {
    toDesc(item) {
      this.desc = true;
      this.jidesc = item.desc;
    },
    //会员权益接口
    getLevelDetail() {
      var that = this;
      this.showloading = true;
      var param = {
        token: this.$store.getters.token
      };
      this.$api.enjoylife.levelDetail(param).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.levelDetailvip = res.result;
          this.allRight = res.result.allRight;
          this.alltag = this.allRight;
          this.jingyan = Number(this.levelDetailvip.memberInfo.cost_money);
          this.valuejingyan = Number(this.levelDetailvip.memberInfo.cost_money);
          var showall = this.alltag;
          for (var i in showall) {
            var showpic = showall[i].thumb;
            if (showpic.indexOf("http") == -1) {
              this.alltag[i].thumbicon = this.$publicData.imgUrl + showpic;
            } else {
              this.alltag[i].thumbicon = showpic;
            }
          }
          this.alllength = this.alltag.length;
          this.nowlength = this.levelDetailvip.levelInfo.member_levelright.length;

          for (var j in this.levelDetailvip.levelList) {
            // 当前等级
            if (
              this.levelDetailvip.levelInfo.id ==
              this.levelDetailvip.levelList[j].id
            ) {
              this.nowindex = j;
            }
            new Swiper(".swiper-container", {
              loop: false, // 循环模式选项
              slidesPerView: 1.15, //两侧显示的图
              spaceBetween: 0, //图片间空隙
              centeredSlides: true, //居中
              observer: true,
              observeParents: true,
              initialSlide: this.nowindex, //默认第几个
              on: {
                slideChangeTransitionStart: function() {
                  // alert(this.activeIndex);
                  // console.log(this.activeIndex);
                  that.swipernum = this.activeIndex;
                  // console.log("nei",that.swipernum);
                }
              }
            });
            // console.log("wei",that.swipernum);
            var levelLisepic = this.levelDetailvip.levelList[j].thumb;
            var levelLiseicon = this.levelDetailvip.levelList[j].icon;
            if (levelLisepic.indexOf("http") == -1) {
              this.levelDetailvip.levelList[j].memberthumb =
                this.$publicData.imgUrl + levelLisepic;
            } else {
              this.levelDetailvip.levelList[j].memberthumb = levelLisepic;
            }
            if (levelLiseicon.indexOf("http") == -1) {
              this.levelDetailvip.levelList[j].levelicon =
                this.$publicData.imgUrl + levelLiseicon;
            } else {
              this.levelDetailvip.levelList[j].levelicon = levelLiseicon;
            }
          }

          var showmemberpic = this.levelDetailvip.memberInfo.thumb;
          if (showmemberpic.indexOf("http") == -1) {
            // var showtu = this.levelDetailvip.memberInfo.thumb;
            this.levelDetailvip.memberInfo.infothumb =
              this.$publicData.imgUrl + showmemberpic;
          } else {
            this.levelDetailvip.memberInfo.infothumb = showmemberpic;
          }
          // console.log(this.levelDetailvip.memberInfo.infothumb);
          for (var r in this.alltag) {
            var allrigId = this.alltag[r].id;
            for (var m in this.levelDetailvip.levelInfo.member_levelright) {
              var memberleId = this.levelDetailvip.levelInfo.member_levelright[
                m
              ].member_level_right.id;
              if (memberleId == allrigId) {
                this.alltag[r].suoname = "y-vip-n";
                break;
              } else {
                this.alltag[r].suoname = "y-vip-s";
              }
            }
          }
          // console.log("ll",this.allRight);
        }
      });
    }
    // onChange(value) {
    //   Toast("当前值：" + value);
    // }
  },
  watch: {
    swipernum: function(newVal, oldVal) {
      var that = this;
      // console.log("newVal:", newVal);
      var param = {
        token: that.$store.getters.token
      };
      that.$api.enjoylife.levelDetail(param).then(res => {
        if (res.code == 200) {
          that.allRight = res.result.allRight;

          for (var i in  that.allRight) {
            var showpic =  that.allRight[i].thumb;
            if (showpic.indexOf("http") == -1) {
               that.allRight[i].thumbicon = this.$publicData.imgUrl + showpic;
            } else {
               that.allRight[i].thumbicon = showpic;
            }
            var djshow = that.allRight[i].is_bind_show;
            if(djshow == 1){
              that.allRight.splice(i,1)
            }
          }
          // console.log("aaa",that.allRight);
        }
      });

      that.alltag = that.allRight;
      for (var i in that.levelDetailvip.levelList[newVal].member_levelright) {
        that.levelDetailvip.levelList[newVal].member_levelright[
          i
        ].flage = false;
        var iid =
          that.levelDetailvip.levelList[newVal].member_levelright[i]
            .member_level_right.id;
        for (var j in that.alltag) {
          var jid = that.alltag[j].id;
          if (iid == jid) {
            that.levelDetailvip.levelList[newVal].member_levelright[
              i
            ].flage = true;
            // console.log("watchif");
          }
        }
      }
      // console.log("member_levelright",that.levelDetailvip.levelList[newVal].member_levelright);
      for (var l in that.levelDetailvip.levelList[newVal].member_levelright) {
        if (
          that.levelDetailvip.levelList[newVal].member_levelright[l].flage ==
          false
        ) {
          var pic =
            that.levelDetailvip.levelList[newVal].member_levelright[l]
              .member_level_right.thumb;
          if (pic.indexOf("http") == -1) {
            that.levelDetailvip.levelList[newVal].member_levelright[
              l
            ].member_level_right.thumbicon = that.$publicData.imgUrl + pic;
          } else {
            that.levelDetailvip.levelList[newVal].member_levelright[
              l
            ].member_level_right = pic;
          }

          that.alltag.push(
            that.levelDetailvip.levelList[newVal].member_levelright[l]
              .member_level_right
          );
        }
      }
      // console.log("that.levelDetailvip", that.levelDetailvip);
      // console.log("that.allRight", that.allRight);

      for (var r in that.alltag) {
        var allrigId = that.alltag[r].id;
        for (var m in that.levelDetailvip.levelList[newVal].member_levelright) {
          var memberleId =
            that.levelDetailvip.levelList[newVal].member_levelright[m]
              .member_level_right.id;
          if (memberleId == allrigId) {
            that.alltag[r].suoname = "y-vip-n";
            break;
          } else {
            that.alltag[r].suoname = "y-vip-s";
          }
        }
      }
      that.nowlength = that.levelDetailvip.levelList[newVal].member_levelright.length;
      that.alllength = that.alltag.length;
      // console.log("levelDetailvip",this.levelDetailvip);
      that.$forceUpdate();
    }
  },
  mounted: function() {
    // console.log("mmm", this.nowindex);
    // new Swiper(".swiper-container", {
    //   loop: false, // 循环模式选项
    //   slidesPerView: 1.15, //两侧显示的图
    //   spaceBetween: 0, //图片间空隙
    //   centeredSlides: true,//居中
    //   observer: true,
    //   observeParents: true,
    //   initialSlide :this.nowindex,//默认第几个
    // });
  }
};
</script>
<style  scoped>
</style>