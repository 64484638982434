<template>
  <!-- 填写订单 -->

  <div class="goods_order">
    <van-nav-bar
      :fixed="false"
      title="填写订单"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <!-- 收货地址 -->
    <div v-if="goodsdetails.address==null">
      <div class="receiv">
        <van-icon name="location-o" color="#36B7D7" class="rec" />
        <van-cell title="请填写收货地址" is-link @click="toAddress" />
      </div>
      <div class="line"></div>
    </div>

    <div v-if="goodsdetails.address !=null">
      <div class="address">
        <div class="add">
          <van-icon name="location-o" color="#36B7D7" class="rec" />
          <van-cell :title="goodsdetails.address" is-link @click="toAddress" />
        </div>
        <div class="user">{{goodsdetails.name}} {{goodsdetails.phone}}</div>
      </div>
      <div class="line"></div>
    </div>
    <!-- 商品 -->
    <div class="goods">
      <!-- 验光 -->
      <!-- <van-cell class="yanrecord" title="验光记录" is-link @click="yan = true" /> -->

      <van-swipe-cell class="clearfix" v-for="(item,index) in orderGoods" :key="index">
        <van-card
          v-if="goodsdetails.order_type ==1"
          :num="item.num"
          :price="item.goods_spec.member_discount"
          :origin-price="item.goods_spec.member_price"
          :desc="item.goods_spec.goods_spec_name"
          :title="item.goods.title"
          class="goods-card f-l"
          :thumb="item.goods.goodsthumb"
        />
        <van-card
          v-if="goodsdetails.order_type ==2"
          :num="item.num"
          :desc="item.goods_spec.goods_spec_name"
          :title="item.goods.title"
          class="goods-card f-l"
          :thumb="item.goods.goodsthumb"
        >
          <template #price>
            <span>{{item.score_price}}积分</span>
          </template>
        </van-card>
      </van-swipe-cell>

      <!-- 验光记录 -->
      <van-action-sheet v-model="yan" :close-on-click-overlay="false" title="验光记录" class="yan">
        <van-radio-group v-model="yanradio">
          <van-cell-group>
            <div class="record" v-for="(item,index) in record" :key="index">
              <van-cell clickable @click="yanradio = item.name">
                <div class="left">
                  <span class="tea">{{item.tea}}</span>
                  <span class="time">{{item.time}}</span>
                </div>
                <template #right-icon>
                  <van-radio :name="item.name" />
                </template>
              </van-cell>
              <div class="content">
                <van-image :src="item.img" round fit="cover" />
                <div>
                  <span class="user">{{item.user}}</span>
                  <span class="tel">{{item.tel}}</span>
                </div>
              </div>
            </div>
          </van-cell-group>
        </van-radio-group>
      </van-action-sheet>
    </div>
    <!-- 方式 -->
    <div class="mode">
      <!-- 支付 -->
      <!--is-link to @click="pay=true" -->
      <van-cell title="支付方式">
        <van-icon :name="img_wei" size="26" class="wei" />
        <span class="payway">微信支付</span>
      </van-cell>
      <!-- 支付弹出框 -->
      <van-action-sheet v-model="pay" :close-on-click-overlay="false" title="选择支付方式" class="pay">
        <van-radio-group v-model="payradio" class="wei">
          <van-cell-group>
            <van-cell clickable @click="payradio = '1'">
              <!-- <div class="icon"> -->
              <van-icon :name="img_wei" size="30" />
              <!-- </div> -->
              <span>微信支付</span>
              <template #right-icon>
                <van-radio name="1" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-radio-group v-model="payradio" class="zhi">
          <van-cell-group>
            <van-cell clickable @click="payradio = '2'">
              <!-- <div class="icon"> -->
              <van-icon :name="img_ji" size="30" />
              <!-- </div> -->
              <span>积分支付</span>
              <template #right-icon>
                <van-radio name="2" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-radio-group v-model="payradio" class="all">
          <van-cell-group>
            <van-cell clickable @click="payradio = '3'">
              <!-- <div class="icon"> -->
              <van-icon :name="img_weiji" size="30" />
              <!-- </div> -->
              <span>微信+积分</span>
              <template #right-icon>
                <van-radio name="3" />
              </template>
            </van-cell>
          </van-cell-group>
          <van-button round type="info" @click="pay=false">确定</van-button>
        </van-radio-group>
      </van-action-sheet>
      <!-- 配送 -->
      <!-- <van-cell title="配送方式" value="普通配送" /> -->
      <!-- 备注 -->
      <van-cell title="备注" class="remarks">
        <form action>
          <textarea placeholder="备注前建议先与商家沟通" id cols="35" rows="4"></textarea>
        </form>
      </van-cell>
    </div>

    <!-- 优惠 -->
    <div class="discount">
      <van-cell title="运费" v-if="goodsdetails.order_type==2">
        <template #default>
          <span>{{goodsdetails.yunfeiScore}}积分</span>
        </template>
      </van-cell>
      <van-cell title="运费" v-if="goodsdetails.order_type==1">
        <template #default>
          <span>￥{{(goodsdetails.yunfei).toFixed(2)}}</span>
        </template>
      </van-cell>
      <van-cell title="商品金额" v-if="goodsdetails.order_type==1">
        <template #default>
          <!-- <span>￥{{goodsdetails.goods_total_price}}</span> -->
          <span>￥{{pjPriceall.toFixed(2)}}</span>
        </template>
      </van-cell>
      <van-cell title="商品总积分" v-if="goodsdetails.order_type==2">
        <template #default>
          <span>{{goodsdetails.use_score_number}}积分</span>
        </template>
      </van-cell>
      <!-- <van-cell title="运费">
        <template #default>
          <span>￥{{goodsdetails.peisong_price}}</span>
        </template>
      </van-cell>-->
      <van-cell v-if="goodsdetails.order_type==1" class="ji" title="积分抵用" to>
        <span>(-￥{{jimoney.toFixed(2)}})</span>
        <select @change="changeoption">
          <option v-for="(item,index) in scoreArr" :key="index" :value="item">{{item}}</option>
        </select>
        <!-- <van-dropdown-menu>
            <van-dropdown-item v-model="creditValue" :options="creditOption" />
        </van-dropdown-menu>-->
      </van-cell>
      <van-cell
        v-if="goodsdetails.order_type==1"
        class="you"
        title="优惠(优惠券)"
        is-link
        to
        @click="toyou"
      >
        <template #default>
          <span>{{youname}}</span>
        </template>
      </van-cell>
      <van-cell v-if="goodsdetails.order_type==1" class="you">
        <template #title>
          <span>会员等级折扣({{goodsdetails.member.member_level.name}})</span>
        </template>
        <template #default>
          <span>(-￥{{zkyoumoney.toFixed(2)}}) {{parseFloat(zkmoney*10)}}折</span>
        </template>
      </van-cell>
      <!-- <van-cell class="hui" title="会员等级优惠" value="-￥0.00" to /> -->
    </div>
    <!-- 选择优惠券 -->
    <van-action-sheet
      v-model="you"
      :close-on-click-overlay="false"
      :style="{height:'60%'}"
      title="选择优惠券"
      class="quan"
    >
      <van-radio-group>
        <van-checkbox v-model="yhquan" checked-color="#36b7d7" @change="changeyou">不选择优惠券</van-checkbox>
      </van-radio-group>
      <div
        v-for="(item,index) in memberCoupon"
        :key="index"
        :class="'clearfix '+(item.coupon.type == 0?'xianquan':'zhequan')"
      >
        <div class="left f-l" v-if="item.coupon.type == 0">
          <span class="xian">现金券</span>
          <span class="rmb">
            <span>RMB</span>
            {{item.coupon.money*1}}
          </span>
          <span class="all">满{{item.coupon.threshold_money*1}}元可用</span>
        </div>
        <div class="left f-l" v-if="item.coupon.type == 1">
          <span class="xian">折扣券</span>
          <span class="bai">{{item.coupon.money*10}}%</span>
          <span class="all">满{{item.coupon.threshold_money*1}}元可用</span>
        </div>
        <div class="right">
          <span class="jie">{{item.coupon.name}}</span>
          <span class="time">{{item.coupon.bagintime}} - {{item.coupon.endtime}}</span>
          <span class="all">全场通用</span>
          <van-button
            round
            type="info"
            class="f-r"
            @click="touse(item.coupon.name,item.id,item.coupon.money,item.coupon.type)"
          >点击使用</van-button>
        </div>
      </div>
    </van-action-sheet>
    <!-- footer  -->
    <div class="footer" v-if="goodsdetails.order_type==1">
      <van-submit-bar :price="payprice<0?0:payprice*100" button-text="提交订单" @submit="onSubmit">
        <span class="sum">
          共优惠:￥
          <!-- <span>{{youprice.toFixed(2)}}</span> -->
          <span>{{(pjPriceall+(goodsdetails.yunfei)-payprice).toFixed(2)}}</span>
        </span>
      </van-submit-bar>
    </div>
    <div class="footer footer2" v-if="goodsdetails.order_type==2">
      <van-submit-bar button-text="立即兑换" @submit="onDui"></van-submit-bar>
    </div>
    <!-- 加载框 -->
    <!-- <van-popup class="jiazai" v-model="showloading">
        <van-loading type="spinner" />
    </van-popup>-->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      zkyoumoney: 0,
      zkmoney: 0,
      pjPriceall: 0,
      priceall: 0,
      iswei: true,
      couponArr: [],
      yhquan: false,
      memberCoupon: [], //优惠券
      bagintime: "",
      endtime: "",
      youmoney: 0, //优惠券抵用金额
      jimoney: 0, //积分抵用金额
      scoreArr: [], //积分下拉
      youname: "无可用优惠券",
      showloading: false,
      goodsdetails: { member: { member_level: {} } },
      orderGoods: [],
      goodsthumb: "",
      payprice: 0,
      youprice: 0,
      img_wei: require("../../assets/images/wei.png"),
      img_ji: require("../../assets/images/ji.png"),
      img_weiji: require("../../assets/images/weiji.png"),
      ordergoods: [
        {
          img: require("../../assets/images/1.jpg"),
          title: "米家防蓝光眼镜钛轻盈 防辐射眼镜平面无度数眼镜蓝光阻隔率>80%",
          price: "223.2",
          del: "270",
          desc: "防蓝光1.61非球面镜片0-800 | 度数留言"
        },
        {
          img: require("../../assets/images/1.jpg"),
          title: "米家防蓝光眼镜钛轻盈 防辐射眼镜平面无度数眼镜蓝光阻隔率>80%",
          price: "223.2",
          del: "270",
          desc: "防蓝光1.61非球面镜片0-800 | 度数留言"
        }
      ],
      yan: false,
      yanradio: "1",
      payradio: "1",
      pay: false,
      you: false,
      record: [
        {
          name: "1",
          tea: "验光师",
          time: "2021.04.10 13:10:05",
          img: require("../../assets/images/yan.png"),
          user: "欧阳娜娜",
          tel: "13811006973"
        },
        {
          name: "2",
          tea: "验光师",
          time: "2021.04.10 13:10:05",
          img: require("../../assets/images/yan.png"),
          user: "欧阳娜娜",
          tel: "13811006973"
        },
        {
          name: "3",
          tea: "验光师",
          time: "2021.04.10 13:10:05",
          img: require("../../assets/images/yan.png"),
          user: "欧阳娜娜",
          tel: "13811006973"
        }
      ]
    };
  },
  created: function() {
    this.getDetail();
    this.getAddress();
    // 优惠券
    this.getMemberCoupon();
    this.iswei = this.isWeiXin();
  },
  methods: {
    toyou() {
      if (this.youname != "无可用优惠券") {
        this.you = true;
      }
    },
    // 选择不使用优惠券
    changeyou() {
      // console.log(this.yhquan,this.youmoney);
      if (this.yhquan == true) {
        this.youmoney = 0;
        this.youname = "不使用优惠券";
        this.couponArr = [];
        this.payprice = this.priceall + this.goodsdetails.yunfei * 1; //总金额
        this.payprice = this.payprice - this.jimoney; //减去积分
        this.zkyoumoney = this.payprice - this.payprice * this.zkmoney; //折扣优惠金额
        this.payprice = this.payprice * this.zkmoney; //减去等级折扣
        // console.log("不使用优惠券", this.jimoney,this.payprice);
        this.you = false;
        // this.youprice =
        //   Number(this.goodsdetails.origin_total_price) -
        //   Number(this.goodsdetails.final_pay_price);
        // this.youprice = this.youprice + this.youmoney + this.jimoney;
        // this.payprice = Number(this.goodsdetails.final_pay_price) * 100;
        // this.payprice = this.payprice - this.youmoney * 100 - this.jimoney * 100;
      }
    },
    //获取优惠券
    getMemberCoupon() {
      var param = {
        token: this.$store.getters.token
      };
      this.$api.goodsOrder.memberCoupon(param).then(res => {
        if (res.code == 200) {
          this.memberCoupon = res.result.list;
          // 判断是否有优惠券
          if (this.memberCoupon.length != 0) {
            this.youname = "选择优惠券";
          }
          // 时间
          for (var i in this.memberCoupon) {
            this.memberCoupon[i].coupon.bagintime = this.memberCoupon[
              i
            ].coupon.valide_begin.replace(/-/g, ".");
            this.memberCoupon[i].coupon.endtime = this.memberCoupon[
              i
            ].coupon.valide_end.replace(/-/g, ".");
          }
        }
      });
    },
    //点击优惠券立即使用
    touse(name, id, money, type) {
      // console.log(name, id, money.type);
      this.youtype = type;
      this.payprice = this.priceall + this.goodsdetails.yunfei * 1; //总金额
      // console.log("点击优惠券", this.payprice);
      this.payprice = this.payprice - this.jimoney; //减去积分
      if (this.youtype == 0) {
        //现金券
        this.youmoney = money * 1;
        this.payprice = this.payprice - this.youmoney; //减去优惠券
      }
      if (this.youtype == 1) {
        //折扣券
        this.youmoney = money / 10;
        this.payprice = this.payprice * this.youmoney; //减去优惠券
      }
      this.zkyoumoney = this.payprice - this.payprice * this.zkmoney; //折扣优惠金额
      this.payprice = this.payprice * this.zkmoney; //减去等级折扣

      this.couponArr = [];
      this.couponArr.push(id);
      this.youname = name;
      this.you = false;
      // this.youmoney = money * 1;
      // this.youprice =
      //   Number(this.goodsdetails.origin_total_price) -
      //   Number(this.goodsdetails.final_pay_price);
      // this.youprice = this.youprice + this.youmoney + this.jimoney;
      // this.payprice = Number(this.goodsdetails.final_pay_price) * 100;
      // this.payprice = this.payprice - this.youmoney * 100 - this.jimoney * 100;
    },
    // 改变积分抵用
    changeoption() {
      var jifen = document.getElementsByTagName("select")[0].value;
      this.jimoney = jifen / 100;
      this.payprice = this.priceall + this.goodsdetails.yunfei * 1; //总金额
      // console.log("积分抵用", this.payprice);
      this.payprice = this.payprice - this.jimoney; //减去积分
      if (this.youtype == 0) {
        //现金券
        this.youmoney = this.youmoney == "" ? 0 : this.youmoney;
        this.payprice = this.payprice - this.youmoney; //减去优惠券
      }
      if (this.youtype == 1) {
        //折扣券
        this.youmoney = this.youmoney == "" ? 1 : this.youmoney;
        this.payprice = this.payprice * this.youmoney; //减去优惠券
      }
      this.zkyoumoney = this.payprice - this.payprice * this.zkmoney; //折扣优惠金额
      this.payprice = this.payprice * this.zkmoney; //减去等级折扣
      // this.youprice =
      //   Number(this.goodsdetails.origin_total_price) -
      //   Number(this.goodsdetails.final_pay_price);
      // this.youprice = this.youprice + this.youmoney + this.jimoney;
      // this.payprice = Number(this.goodsdetails.final_pay_price) * 100;
      // this.payprice = this.payprice - this.youmoney * 100 - this.jimoney * 100;
    },
    //修改地址接口
    getAddress() {
      if (this.$route.query.address_id) {
        var param = {
          token: this.$store.getters.token,
          order_id: this.$route.query.id,
          address_id: this.$route.query.address_id
        };
        this.$api.goodsOrder.setAddress(param).then(res => {
          if (res.code == 200) {
            this.getDetail();
          }
        });
      }
    },
    //订单详情接口
    getDetail() {
      this.showloading = true;
      var param = {
        token: this.$store.getters.token,
        order_id: this.$route.query.id
      };
      this.$api.goodsOrder.detail(param).then(res => {
        this.showloading = false;
        this.goodsdetails = res.result;
        if (
          this.goodsdetails.member.member_level.discount &&
          this.goodsdetails.member.member_level.discount != 0
        ) {
          this.zkmoney = this.goodsdetails.member.member_level.discount / 10; //会员等级折扣
        } else {
          this.zkmoney = 1;
        }
        this.orderGoods = this.goodsdetails.member_order_goods;
        //商品图片
        for (var i in this.orderGoods) {
          var pic = this.orderGoods[i].goods.thumb;
          this.orderGoods[i].goods.goodsthumb = "";
          if (pic.indexOf("http") == -1) {
            this.orderGoods[i].goods.goodsthumb = this.$publicData.imgUrl + pic;
          } else {
            this.orderGoods[i].goods.goodsthumb = pic;
          }
        }
        // 商品金额
        for (var p in this.orderGoods) {
          var pjPrice =
            this.orderGoods[p].goods_spec.member_price * this.orderGoods[p].num;
          this.pjPriceall += pjPrice;
          var price =
            this.orderGoods[p].goods_spec.member_discount *
            this.orderGoods[p].num;
          this.priceall += price;
        }
        this.payprice = this.priceall + this.goodsdetails.yunfei * 1; //总金额
        this.zkyoumoney = this.payprice - this.payprice * this.zkmoney; //折扣优惠金额
        this.payprice = this.payprice * this.zkmoney; //减去等级折扣
        // console.log("详情接口",this.payprice);
        // console.log(this.orderGoods);
        // this.payprice = Number(this.goodsdetails.final_pay_price) * 100;
        // this.youprice =
        //   Number(this.goodsdetails.origin_total_price) -
        //   Number(this.goodsdetails.final_pay_price);

        // 积分抵用
        var maxscore = this.goodsdetails.max_use_score;
        var memberscore = this.goodsdetails.member.score;
        var score = memberscore >= maxscore ? maxscore : memberscore;
        this.scoreArr = [];
        for (var j = 0; j <= score; j++) {
          if (j % 1000 == 0) {
            this.scoreArr.push(j);
          }
        }
      });
    },
    //跳转到收货地址列表
    toAddress() {
      var that = this;
      var bigStatus = that.$route.query.bigStatus;
      if (bigStatus) {
        if (bigStatus == 0) {
          that.$router.push({
            path: "/address",
            query: {
              id: that.$route.query.id,
              status: 1
            }
          });
        }
      } else {
        that.$router.push({
          path: "/address",
          query: {
            id: that.$route.query.id,
            status: 1
          }
        });
      }
    },
    // 判断是否微信登陆
    isWeiXin: function() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.indexOf("micromessenger") !== -1) {
        return true;
      } else {
        return false;
      }
    },
    // 点击立即兑换
    onDui() {
      var params = {
        token: this.$store.getters.token,
        order_id: this.$route.query.id
      };
      this.$api.score.payScoreMemberOrder(params).then(res => {
        if (res.code == 200) {
          this.$router.push({
            path: "/integral_paysuccess"
          });
        }
      });
    },
    //点击提交订单
    onSubmit() {
      var that = this;
      that.showloading = true;

      if (that.iswei) {
        var param = {
          token: that.$store.getters.token,
          order_id: that.$route.query.id,
          couponArr: that.couponArr,
          score: that.jimoney * 100
        };
        that.$api.goodsOrder.pay(param).then(res => {
          that.showloading = false;
          if (res.code == 200) {
            if (res.result.is_wx_pay == 0) {
              that.$router.push({
                path: "/paySuccess",
                query: {
                  price: this.payprice
                }
              });
              return;
            }
            var payInfo = res.result.payInfo;
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: payInfo[0], // 必填，公众号的唯一标识
              timestamp: payInfo[1], // 必填，生成签名的时间戳
              nonceStr: payInfo[2], // 必填，生成签名的随机串
              signature: payInfo[5], // 必填，签名，见附录1
              jsApiList: ["chooseWXPay"] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            });
            wx.ready(function() {
              // 调起微信支付
              wx.chooseWXPay({
                appId: payInfo[0],
                timestamp: payInfo[1], // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                nonceStr: payInfo[2], // 支付签名随机串，不长于 32 位
                package: payInfo[3],
                signType: payInfo[4], // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                paySign: payInfo[5],
                success: function(res) {
                  that.$router.push({
                    path: "/paySuccess",
                    query: {
                      price: this.payprice
                    }
                  });
                  that.showloading = false;
                },
                error: function(res) {
                  that.showloading = false;
                  that.$toast("支付失败");
                },
                cancel: function(res) {
                  that.showloading = false;
                  that.$toast("您取消了支付");
                }
              });
            });
          }
        });
      } else {
        //没有微信登录时
        that.showloading = false;
        that.$toast("请用微信登录后再操作");
      }
    }
  }
};
</script>