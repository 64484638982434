<template>
  <!-- 我的优惠券 -->

  <div class="myCoupon">
    <van-nav-bar
      :z-index="99"
      :border="false"
      title="我的优惠券"
      :fixed="false"
      left-arrow
      @click-left="onClickLeft"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <!-- 内容 -->
    <div class="con">
      <!-- <van-search v-model="exchangeCode" placeholder="输入神奇的兑换码" left-icon show-action>
        <template #action>
          <div>兑换</div>
        </template>
      </van-search> -->

      <van-tabs v-model="activeName" type="card" @click="getCouponList">
        <van-tab name="-1">
          <template #title>全部({{coupon.total}})</template>
        </van-tab>
        <van-tab name="1">
          <template #title>可用券({{coupon.can_use_count}})</template>
        </van-tab>
        <van-tab name="2">
          <template #title>已过期({{coupon.not_use_count}})</template>
        </van-tab>
      </van-tabs>
      <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
        <div class="quan">
          <!-- 券 -->
          <div v-for="(item,index) in couponList" :key="index">
            <div :class="item.classname">
              <div
                class="left f-l"
                v-if="item.classname == 'xianquan' || item.classname == 'guoxianquan'"
              >
                <span class="xian">现金券</span>
                <span class="rmb">
                  {{Number(item.coupon.money)}}
                  <span>元</span>
                </span>
              </div>
              <div
                class="left f-l"
                v-if="item.classname == 'zhequan' || item.classname == 'guozhequan'"
              >
                <span class="xian">折扣券</span>
                <span class="bai">{{item.coupon.money*10}}%</span>
              </div>

              <div class="right clearfix">
                <span class="jie">{{item.coupon.name}}</span>
                <span class="time">{{item.coupon.valide_begin}}-{{item.coupon.valide_end}}</span>
                <span class="all" @click="watchrule(item)">查看使用规则</span>

                <van-button
                  v-if="(item.classname == 'xianquan' || item.classname == 'zhequan') && item.coupon.is_zhuan == 0"
                  round
                  type="info"
                  class="useone"
                  to="/shopping_mall"
                >立即使用</van-button>
                <van-button
                  v-if="(item.classname == 'xianquan' || item.classname == 'zhequan')&& item.coupon.is_zhuan == 1 && item.is_zhuaning == 0"
                  round
                  type="info"
                  class="usenow"
                  to="/shopping_mall"
                >立即使用</van-button>
                <van-button
                  v-if="(item.classname == 'xianquan' || item.classname == 'zhequan') && item.coupon.is_zhuan == 1 && item.is_zhuaning == 0"
                  round
                  type="info"
                  class="f-r"
                  @click="transfer(item.id,index)"
                >
                  <img src="../../assets/images/coupons/zhuan.png" alt />
                  <span>转赠</span>
                </van-button>
                <van-button
                  v-if="(item.classname == 'xianquan' || item.classname == 'zhequan') && item.coupon.is_zhuan == 1 && item.is_zhuaning == 1"
                  round
                  type="info"
                  class="f-r zhuaning"
                  @click="transfer(item.id,index)"
                >
                  <img src="../../assets/images/coupons/zhuan.png" alt />
                  <span>转赠中</span>
                </van-button>
              </div>
            </div>
          </div>
          <!-- 使用规则 -->
          <van-popup v-model="showrule" class="userule">
            <h3>使用规则</h3>
            <span
              class="rulecon"
              v-if="currentItem.coupon"
            >{{currentItem.coupon.rule ? currentItem.coupon.rule : '暂无使用规则'}}</span>
            <van-button round plain type="info" @click="iknow">明白了</van-button>
          </van-popup>
          <!-- 转赠 -->
          <van-action-sheet v-model="showzhuan" :close-on-click-overlay="false" title="赠券给好友">
            <div class="content">
              <div class="zhuan_ka">
                <div class="ka_left">
                  <span>{{zeng_quan}}</span>
                  <span class="time">有效日期至{{zeng_time}}</span>
                </div>
                <div class="ka_right" v-if="typequan == 0">
                  {{zeng_xian}}
                  <span>元</span>
                </div>
                <div class="ka_right" v-if="typequan == 1">
                  {{zeng_zhe}}
                  <span>%</span>
                </div>
              </div>
              <div class="zhuan_remarks">
                <span>1、好友领取的期限为12小时,逾期将退回至您的账户。</span>
                <span>2、赠送期间您将不能使用此卡券,如未被领取,您将在结束领取期限后使用。</span>
              </div>
              <van-button round type="info" @click="tosend">
                <van-icon :name="weixin" />发送给好友
              </van-button>
            </div>
          </van-action-sheet>
        </div>
      </van-pull-refresh>
    </div>
    <!-- 遮罩 -->
    <van-overlay class="zhezhao" :show="showzhe" @click="showzhe = false">
      <div class="wrapper">
        <img src="../../assets/images/send.png" alt />
      </div>
    </van-overlay>
    <!-- 加载框 -->
    <!-- <van-popup class="jiazai" v-model="showloading">
      <van-loading type="spinner" />
    </van-popup>-->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      showloading: false,
      activeName: "-1",
      exchangeCode: "",
      coupon: {},
      couponList: [],
      classname: "",
      currentItem: {},
      showrule: false,
      showzhuan: false,
      weixin: require("../../assets/images/zhuan_wei.png"),
      zeng_quan: "",
      zeng_time: "",
      zeng_xian: "",
      zeng_zhe: "",
      typequan: 0,
      showzhe: false,
      zeng_model: {},
      lingurl: {},
      isLoading: false
    };
  },
  created: function() {
    this.getCouponList();
  },
  methods: {
    //下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        this.getLaCouponList();
      }, 1000);
    },
    getCouponList() {
      this.showloading = true;
      var param = {
        token: this.$store.getters.token,
        status: this.activeName == -1 ? "" : this.activeName
      };
      this.$api.coupon.couponList(param).then(res => {
        this.showloading = false;
        this.coupon = res.result;
        this.couponList = res.result.list;
        for (var i in this.couponList) {
          var coupontype = this.couponList[i].coupon.type;
          var couponguotype = this.couponList[i].is_valid;
          if (coupontype == 1) {
            if (couponguotype == 0) {
              this.couponList[i].classname = "zhequan";
            } else {
              this.couponList[i].classname = "guozhequan";
            }
          } else {
            if (couponguotype == 0) {
              this.couponList[i].classname = "xianquan";
            } else {
              this.couponList[i].classname = "guoxianquan";
            }
          }
        }
      });
    },
    getLaCouponList() {
      var param = {
        token: this.$store.getters.token,
        status: this.activeName == -1 ? "" : this.activeName
      };
      this.$api.coupon.couponList(param).then(res => {
        this.coupon = res.result;
        this.couponList = res.result.list;
        for (var i in this.couponList) {
          var coupontype = this.couponList[i].coupon.type;
          var couponguotype = this.couponList[i].is_valid;
          if (coupontype == 1) {
            if (couponguotype == 0) {
              this.couponList[i].classname = "zhequan";
            } else {
              this.couponList[i].classname = "guozhequan";
            }
          } else {
            if (couponguotype == 0) {
              this.couponList[i].classname = "xianquan";
            } else {
              this.couponList[i].classname = "guoxianquan";
            }
          }
        }
      });
    },
    // 点击转赠
    transfer(id, index) {
      this.zeng_model = this.couponList[index];
      // 弹出框相关
      this.showzhuan = true;
      this.typequan = this.couponList[index].coupon.type;
      if (this.typequan == 0) {
        this.zeng_quan = "现金券";
        this.zeng_xian = Number(this.couponList[index].coupon.money);
      } else {
        this.zeng_quan = "折扣券";
        this.zeng_zhe = this.couponList[index].coupon.money * 10;
      }
      this.zeng_time = this.couponList[index].coupon.valide_end;
    },
    //点击查看使用规则
    watchrule(item) {
      this.showrule = true;
      this.currentItem = item;
      // console.log("item", item);
    },
    //点击我明白了
    iknow() {
      this.showrule = false;
    },
    //点击发送给好友
    tosend() {
      this.showzhe = true;
      this.zeng_model;
      var that = this;
      this.$api.coupon
        .couponZhuan({
          token: this.$store.getters.token,
          id: this.zeng_model.id
        })
        .then(res => {
          if (res.code == 200) {
            //去分享
            this.lingurl = res.result;
            this.toShareZhezhao();
          }
        });
    },
    //点击导航栏返回键
    onClickLeft() {
      this.$router.push({
        path: "/home"
      });
    },
    toShareZhezhao() {
      var that = this;
      const url = that.lingurl;
      // console.log("url=" + url);
      const shareUrl = location.href.split("#")[0];
      that.$api.common.commonShare({ url: shareUrl }).then(res => {
        if (res.code == 200) {
          that.shareConfig = res.result;
          wx.config({
            debug: that.shareConfig.debug,
            beta: that.shareConfig.beta,
            jsApiList: that.shareConfig.jsApiList,
            appId: that.shareConfig.appId,
            nonceStr: that.shareConfig.nonceStr, // 随机串
            timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
            signature: that.shareConfig.signature // 签名
          });
          // 分享
          wx.ready(function() {
            var shareDataA = {
              title: "送你一张优惠券", // 分享标题
              desc: "分享自捷捷公众号", // 分享描述
              link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.$publicData.imgUrl + "/console/img/quan.png", // 分享图标
              success: function() {
                // 用户确认分享后执行的回调函数
                // alert('成功')
                // console.log("成功");
                // that.showzhuan = false;
                // that.showzhe = false;
                that.getCouponList();
              },
              cancel: function(e) {
                // alert(e)
                // 用户取消分享后执行的回调函数
                // console.log("成功");
              }
            };
            var shareDataB = {
              title: "送你一张优惠券", // 分享标题
              link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.$publicData.imgUrl + "/console/img/quan.png", // 分享图标
              success: function() {
                // 用户确认分享后执行的回调函数
                // alert('成功')
                // console.log("成功");
                // that.showzhuan = false;
                // that.showzhe = false;
                // that.getCouponList();
              },
              cancel: function(e) {
                // alert(e)
                // 用户取消分享后执行的回调函数
                // console.log("成功");
              }
            };

            wx.updateAppMessageShareData(shareDataA); // 分享给朋友
            wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
          });
        }
      });
    }
  }
};
</script>

<style scoped>
/* .zhuan_ka {
  background: url(../../assets/images/zhuan_quan.png) no-repeat;
  background-size: 6.91rem;
}
.zhuan_ka {
  width: 6.91rem;
  margin-left: 1.4rem;
  margin-top: 0.5rem;
  height: 2.85rem;
  background: url(../../assets/images/zhuan_quan.png) no-repeat;
  background-size: 6.91rem;
  display: flex;
  position: relative;
}
.zhuan_ka .ka_left {
  padding-top: 0.6rem;
  padding-left: 0.45rem;
}
.zhuan_ka .ka_left span {
  display: block;
  font-size: 0.45rem;
  font-weight: 500;
  color: #fff;
  opacity: 0.9;
}
.zhuan_ka .ka_left .time {
  font-size: 0.34rem;
  margin-top: 0.2rem;
}
.zhuan_ka .ka_right {
  position: absolute;
  right: 0.45rem;
  font-size: 0.85rem;
  line-height: 2.5rem;
  color: #9f6932;
  font-weight: bold;
}
.zhuan_ka .ka_right span {
  line-height: 2.5rem;
  font-size: 0.39rem;
  font-weight: 400;
} */
/* .zhuan_remarks {
  padding-left: 1.2rem;
  padding-right: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
}
.zhuan_remarks span {
  font-size: 0.35rem;
  color: #000;
  opacity: 0.6;
  display: block;
}
.van-action-sheet__content .van-button {
  width: 6.5rem;
  height: 1rem;
  background: #2bc360;
  border-color: #2bc360;
  margin-left: 1.5rem;
  margin-bottom: 0.85rem;
  font-size: 0.45rem;
  line-height: 1rem;
}
.van-action-sheet__content .van-button .van-icon {
  width: 0.57rem;
  font-size: 0.55rem;
  vertical-align: middle;
  margin-right: 0.15rem;
}
.quan {
  padding-left: 0.4rem;
}
:v-deep .van-pull-refresh__track {
  min-height: 100vh !important;
}
.quan .xianquan .left {
  background: url(../../assets/images/coupons/xianzuo.png);
}
.quan .xianquan .right {
  background: url(../../assets/images/coupons/xianyou.png) no-repeat;
  background-size: 9.2rem;
}
.quan .left .xian {
  background: url(../../assets/images/coupons/xian.png) no-repeat;
}
.quan .zhequan .left {
  background: url(../../assets/images/coupons/zhezuo.png) no-repeat;
  background-size: 2.75rem;
}
.quan .zhequan .right {
  background: url(../../assets/images/coupons/zheyou.png) no-repeat;
  background-size: 9.2rem;
}
.myCoupon .con .quan .guoxianquan .left,
.myCoupon .con .quan .guozhequan .left,
.myCoupon .con .quan .yongquan .left {
  background: url(../../assets/images/coupons/yizuo.png) no-repeat;
}
.myCoupon .con .quan .guoxianquan .right,
.myCoupon .con .quan .guozhequan .right {
  background: url(../../assets/images/coupons/guoyou.png) no-repeat;
  background-size: 9.2rem;
}
.myCoupon .con .quan .guoxianquan .right .jie,
.myCoupon .con .quan .guoxianquan .right .time,
.myCoupon .con .quan .guozhequan .right .jie,
.myCoupon .con .quan .guozhequan .right .time {
  color: #333;
  opacity: 0.5;
}
.myCoupon .con .quan .yongquan .right {
  background: url(../../assets/images/coupons/yongyou.png) no-repeat;
  background-size: 9.2rem;
}
.quan .right {
  width: auto;
}
.userule {
  width: 7.87rem;
}
.userule h3 {
  text-align: center;
  background: url(../../assets/images/rule.png) no-repeat 1.75rem;
  background-size: 4.33rem;
  font-size: 0.4rem;
  margin-top: 0.6rem;
  margin-bottom: 0.77rem;
}
.userule .rulecon {
  display: block;
  font-size: 0.35rem;
  color: #000;
  opacity: 0.6;
  margin: 0 0.65rem;
  line-height: 0.55rem;
}
.userule .van-button {
  width: 2.94rem;
  height: 0.93rem;
  color: #ff7351 !important;
  border-color: #ff7351;
  background: transparent;
  padding: 0;
  margin: 1.53rem 0 0.55rem 2.59rem;
} */
</style>