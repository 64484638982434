<template>
  <div class="service_list">
    <van-nav-bar
      :fixed="false"
      :border="false"
      :placeholder="true"
      title="售后服务"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y_add_storage clearfix">
      <van-tabs background="#F3F4F9" v-model="curTab" @click="changeTab">
        <van-tab title="全部" name="-1"></van-tab>
        <van-tab title="待回复" name="0"></van-tab>
        <van-tab title="已回复" name="1"></van-tab>
        <van-tab title="已完成" name="2"></van-tab>
      </van-tabs>

      <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
        <div class="y_storage_list">
          <div
            class="y_storage_list_item clearfix"
            v-for="item in afterSaleList"
            :key="item.id"
            @click="toDetails(item.id)"
          >
            <div class="y_storage_no">
              <!-- <span>{{new Date(item.time).toLocaleString('chinese',{hour12:false})}}</span> -->
              <span>{{item.updated_at}}</span>
              <span v-if="item.status==2" class="y_storage_no_state_finish">已完成</span>
              <span v-else-if="item.status==1" class="y_storage_no_state_wait">已回复</span>
              <span v-else-if="item.status==0" class="y_storage_no_state_collect">待回复</span>
            </div>
            <div
              class="y-user-oldxia y-op_orderxia y-xzcptop-img y-order-list"
              v-for="(carditem,i) in item.showInfo.goodsInfo"
              :key="i"
            >
              <van-card
                :num="carditem.num"
                :price="carditem.price"
                :title="carditem.title"
                :thumb="carditem.goodsthumb"
              >
                <template #tags>
                  <van-tag color="#F3F4F9" text-color="#999">{{carditem.spec}}</van-tag>
                </template>
              </van-card>
            </div>
            <div class="y-order-money">
              <p>总价¥{{item.showInfo.show_total_price}}，优惠¥{{item.showInfo.show_total_price-item.showInfo.final_pay_price}}</p>
              <span>合计：¥<i>{{item.showInfo.final_pay_price}}</i></span>
            </div>
            <!-- <div> -->
              <van-button
                v-if="item.status==2 && item.is_comment == 0"
                round
                size="small"
                color="#35B6D8"
                plain
                style="float: right; padding:5px 14px;margin: 0 0.4rem 0.4rem 0;"
              >评价</van-button>
            <!-- </div> -->
          </div>
        </div>
      </van-pull-refresh>
    </div>
    <!-- 加载框 -->
    <!-- <van-popup class="jiazai" v-model="showloading">
      <van-loading type="spinner" />
    </van-popup>-->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      showloading: false,
      curTab: "-1",
      afterSaleList: [],
      goodsthumb: ""
    };
  },
  created: function() {
    this.getAfterSaleList();
  },
  methods: {
    //下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        this.getLaAfterSaleList();
      }, 1000);
    },
    toDetails(id) {
      this.$router.push({
        path: "/service_details",
        query: {
          id: id
        }
      });
    },
    getAfterSaleList() {
      this.showloading = true;
      var param = {
        token: this.$store.getters.token
      };
      if (this.curTab != -1) {
        param.status = this.curTab;
      }
      this.$api.aftersale.afterSaleList(param).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.afterSaleList = res.result.list;
          for (var i in this.afterSaleList) {
            var goodsinfo = this.afterSaleList[i].showInfo.goodsInfo;
            for (var j in goodsinfo) {
              var goodspic = goodsinfo[j].thumb;
              if (goodspic.indexOf("http") == -1) {
                this.afterSaleList[i].showInfo.goodsInfo[j].goodsthumb =
                  this.$publicData.imgUrl + goodspic;
              } else {
                this.afterSaleList[i].showInfo.goodsInfo[
                  j
                ].goodsthumb = goodspic;
              }
            }
          }
        }
      });
    },
    getLaAfterSaleList() {
      var param = {
        token: this.$store.getters.token
      };
      if (this.curTab != -1) {
        param.status = this.curTab;
      }
      this.$api.aftersale.afterSaleList(param).then(res => {
        if (res.code == 200) {
          this.afterSaleList = res.result.list;
          for (var i in this.afterSaleList) {
            var goodsinfo = this.afterSaleList[i].showInfo.goodsInfo;
            for (var j in goodsinfo) {
              var goodspic = goodsinfo[j].thumb;
              if (goodspic.indexOf("http") == -1) {
                this.afterSaleList[i].showInfo.goodsInfo[j].goodsthumb =
                  this.$publicData.imgUrl + goodspic;
              } else {
                this.afterSaleList[i].showInfo.goodsInfo[
                  j
                ].goodsthumb = goodspic;
              }
            }
          }
        }
      });
    },
    changeTab() {
      var that = this;
      that.afterSaleList = [];
      that.getAfterSaleList();
    }
  }
};
</script>

<style scoped>
/* .service_list {
  background: #f3f4f9;
}
.service_list .van-nav-bar {
  background: transparent;
}
.service_list .y-order-money span {
  font-size: 0.35rem;
}
.service_list .y-order-money p {
  color: #999;
  float: left;
  padding: 0px 8px;
}
.service_list .van-card {
  background-color: transparent !important;
}
.service_list .van-card__title {
  font-size: 0.35rem;
  line-height: 18px;
  float: left;
  max-height: 42px;
}
.service_list .van-tag {
  margin-top: 8px;
}
.service_list .y-order-money {
  float: left;
  font-size: 0.35rem;
  padding: 10px 15px;
  float: right;
}

.service_list .van-button {
  height: auto;
}

.service_list ::v-deep .van-tabs__line {
  background-color: transparent;
}
.service_list ::v-deep .van-tab--active {
  font-size: 0.5rem;
  font-weight: 600;
}
.service_list .van-tabs__line {
  background-color: transparent !important;
}
.service_list .van-tab--active {
  font-size: 0.35rem;
}
.service_list .y_add_storage {
  background-color: #f3f4f9;
  min-height: 95vh;
  float: left;
  width: 100%;
}
.service_list .y_storage_list {
  padding: 0 15px;
}
.service_list .y_storage_list .y_storage_list_item {
  width: 100%;
  float: left;
  margin: 8px 0;
  border-radius: 7.5px;
  background-color: #fff;
  box-shadow: 0px 2px 5px #eeeeee;
}
.service_list .y_storage_no {
  height: 45px;
  line-height: 45px;
  padding: 0 12px;
  font-size: 0.35rem;
  color: #000;
  padding-right: 0;
  border-bottom: 1px #eeeeee solid;
  position: relative;
}
.service_list .y_storage_no div {
  float: left;
}

.service_list .y_storage_no_tag_zb {
  background-color: #5cc6da;
}
.service_list .y_storage_no_tag_db {
  background-color: #7fc89e;
}
.service_list .y_storage_no_num {
  margin-left: 8px;
  font-size: 0.35rem;
  line-height: 21px;
}
.service_list .y_storage_no_state {
  padding: 5px 14px;
  font-size: 0.35rem;
  margin-top: 10px;
  float: right;
  line-height: 13px;
  border: 1px #eee solid;
  border-radius: 25px 0 0 25px;
}
.service_list .y_storage_no_state_collect {
  border: 1px #f54749 solid;
  color: #f54749;
  border-right: none;
  padding: 5px 14px;
  font-size: 0.35rem;
  margin-top: 10px;
  float: right;
  line-height: 13px;
  border-radius: 25px 0 0 25px;
}
.service_list .y_storage_no_state_wait {
  border: 1px #f77413 solid;
  color: #f77413;
  border-right: none;
  border-right: none;
  padding: 5px 14px;
  font-size: 0.35rem;
  margin-top: 10px;
  float: right;
  line-height: 13px;
  border-radius: 25px 0 0 25px;
}
.service_list .y_storage_no_state_finish {
  border: 1px #35b6d8 solid;
  color: #35b6d8;
  border-right: none;
  padding: 5px 14px;
  font-size: 0.35rem;
  margin-top: 10px;
  float: right;
  line-height: 13px;
  border-radius: 25px 0 0 25px;
  border-right: none;
}
.service_list .y_stirage_info {
  padding-left: 12px;
  padding: 15px 12px;
}
.service_list .y_stirage_info_name {
  font-size: 0.35rem;
  line-height: 18px;
}
.service_list .y_stirage_info_time {
  font-size: 0.35rem;
  color: #999;
  padding: 3px 0px;
} */
</style>