<template>
  <!-- 商品评价 -->
  <div class="goods_pingjia">
    <van-nav-bar
      :fixed="false"
      title="商品详情"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <!-- 排序 -->
    <div class="goods_sort">
      <span>按时间排序</span>|
      <span class="active">默认排序</span>
    </div>
    <!-- 评论 -->
    <div class="goods_evaluate">
      <div
        class="evaluate"
        v-for="(item,index) in goodsCommentList"
        :key="'goodsCommentList'+index"
      >
        <div class="evaluate_top clearfix">
          <div class="top_img f-l">
            <!-- <van-image round src="https://img01.yzcdn.cn/vant/cat.jpeg" /> -->
            <van-image round :src="item.member.memberthumb" />
          </div>
          <div class="top_user f-l">
            <span class="user_name">{{item.member.name}}</span>
            <span class="time">{{item.chatime}}前</span>
            <span>{{item.specName}}</span>
          </div>
          <!-- <div class="top_time">
            <span>{{item.chatime}}前</span>
          </div>-->
        </div>
        <div class="evaluate_tag">
          <van-button v-for="(tag,i) in item.tagArr" :key="'tag'+i" type="info" round>{{tag}}</van-button>
        </div>
        <p class="evaluate_desc">{{item.content}}</p>
        <div class="evaluate_pic">
          <div class="goods_pic" :key="index">
            <van-image
              v-for="(pic,p) in item.alumList"
              :key="'pic'+p"
              fit="cover"
              :src="pic"
              @click="clickImage(p)"
            />
            <!-- <van-image
              v-if="pingjia.length == 1"
              width="7rem"
              fit="cover"
              src="https://img01.yzcdn.cn/vant/cat.jpeg"
            />
            <van-image
              v-if="pingjia.length == 2"
              width="4.5rem"
              fit="cover"
              src="https://img01.yzcdn.cn/vant/cat.jpeg"
            />
            <van-image
              v-if="pingjia.length >= 3"
              width="3rem"
              fit="cover"
              src="https://img01.yzcdn.cn/vant/cat.jpeg"
            />-->
          </div>
        </div>
        <!-- 查看大图 -->
        <van-image-preview
          v-model="showBigImg"
          :images="item.alumList"
          :startPosition="bigImgindex"
        ></van-image-preview>
      </div>
    </div>
    <!-- footer  -->
    <!-- <div class="footercar clearfix">
      <div class="footer_shopcar f-l" @click="toShopcar">
        <van-icon :name="img_shopcar" :badge="goodsDetail.cartsNum" />
        <span>购物车</span>
      </div>
      <div class="footer_btn">
        <van-button class="btn buynow" type="info" round @click="showshopcar = true">加入购物车</van-button>
      </div>
    </div>-->

    <!-- 加入购物车 -->
    <!-- <van-sku
      v-model="show"
      :close-on-click-overlay="false"
      :reset-selected-sku-on-hide="true"
      :reset-stepper-on-hide="true"
      :sku="sku"
      :goods="goods"
      :goods-id="goodsId"
      :quota="quota"
      :hide-stock="sku.hide_stock"
      :show-add-cart-btn="false"
      buy-text="确定"
      stepper-title="数量"
      @buy-clicked="onBuyClicked"
    />-->

    <van-popup
      v-model="showshopcar"
      :close-on-click-overlay="false"
      round
      closeable
      position="bottom"
      :style="{ height: '70%' }"
    >
      <div class="pophp_top">
        <img :src="goodsDetail.detailthumb" alt />
        <div class="right">
          <span class="mon">
            ￥
            <span>{{specsMon}}</span>
          </span>
          <span class="beginmon">
            原价:
            <span>￥{{specsyuanMon}}</span>
          </span>
          <span class="ku">库存:{{specsNum}}件</span>
          <span v-if="specsName !=''" class="alreadyspe slh">已选规格:{{specsName}}</span>
        </div>
      </div>
      <div class="pophp_down">
        <div class="spe">
          <h3>规格</h3>
          <div class="spe_btn">
            <van-button
              round
              type="info"
              v-for="(item,index) in goodsDetail.goods_spec"
              :key="'goodsDetail'+index"
              :class="item.classname"
              @click="changeSpecs(index)"
            >{{item.goods_spec_name}}</van-button>
          </div>
          <div class="num clearfix">
            <span class="f-l">数量</span>
            <van-stepper v-model="valuecar" class="f-r" />
          </div>
        </div>
      </div>
      <div class="okbtn">
        <van-button round type="info" class="determine" @click="determine">加入购物车</van-button>
        <van-button round type="info" class="determinetrue" @click="determinetrue">立即购买</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBigImg: false,
      bigImgindex: 0,
      showshopcar: false,
      specsMon: "",
      specsyuanMon: "",
      specsName: "",
      specsNum: "",
      specsid: "",
      valuecar: 1,
      goodsDetail: {},
      chatime: "",
      goodsCommentList: [],
      // pingjia: [{}, {}, {}, {}, {}, {}, {}],
      img_shopcar: require("../../assets/images/shopcar.png")
      // show: false, //是否显示商品规格弹窗
      // goodsId: "", //商品 id
      // quota: 0, //限购数，0表示不限购
      // sku: {
      //   tree: [
      //     {
      //       k: "规格",
      //       k_s: "s1",
      //       v: [
      //         {
      //           id: "1",
      //           name: "防蓝光1.56非球面镜片0-400 | 度数留言"
      //         },
      //         {
      //           id: "2",
      //           name: "防蓝光1.61非球面镜片0-800 | 度数留言"
      //         },
      //         {
      //           id: "3",
      //           name: "至薄防辐射1.74非球面镜片0-1200 | 度数留言"
      //         },
      //         {
      //           id: "4",
      //           name: "变色镜片1.56变色0-600 | 度数留言"
      //         },
      //         {
      //           id: "5",
      //           name: "配镜依视路镜片1.56钻晶A0-400度 | 度数留言"
      //         },
      //         {
      //           id: "6",
      //           name: "1.61轻薄非球面镜片0-800 | 度数留言"
      //         }
      //       ]
      //     }
      //   ],
      //   list: [
      //     {
      //       id: 1,
      //       s1: "1",
      //       price: 10000, // 默认价格（单位分）
      //       stock_num: 110 // 当前 sku 组合对应的库存
      //     },
      //     {
      //       id: 2,
      //       s1: "2",
      //       price: 10000,
      //       stock_num: 110
      //     },
      //     {
      //       id: 3,
      //       s1: "3",
      //       price: 10000,
      //       stock_num: 110
      //     },
      //     {
      //       id: 4,
      //       s1: "4",
      //       price: 10000,
      //       stock_num: 110
      //     },
      //     {
      //       id: 5,
      //       s1: "5",
      //       price: 10000,
      //       stock_num: 110
      //     },
      //     {
      //       id: 6,
      //       s1: "6",
      //       price: 10000,
      //       stock_num: 110
      //     }
      //   ],
      //   price: "1599.99", // 默认价格（单位元）
      //   stock_num: 227, // 商品总库存
      //   none_sku: false, // 是否无规格商品
      //   hide_stock: false, // 是否隐藏剩余库存
      //   showaddcartbtn: false //是否显示购物车按钮
      // },
      // goods: {
      //   // 商品信息
      //   picture: require("../../assets/images/banner_1.png")
      // }
    };
  },
  created: function() {
    this.getGoodsCommentList();
    this.getGoodsDetail();
  },
  methods: {
    // 点击图片
    clickImage(index) {
      this.showBigImg = true;
      this.bigImgindex = index;
    },
    //商品详情接口
    getGoodsDetail() {
      var param = {
        token: this.$store.getters.token,
        goods_id: this.$route.query.id
      };
      this.$api.mall.goodsDetail(param).then(res => {
        if (res.code == 200) {
          this.goodsDetail = res.result;
          // 图片格式
          var pic = this.goodsDetail.thumb;
          if (pic.indexOf("http") == -1) {
            this.goodsDetail.detailthumb = this.$publicData.imgUrl + pic;
          } else {
            this.goodsDetail.detailthumb = pic;
          }
          //规格按钮效果
          for (var j in this.goodsDetail.goods_spec) {
            this.goodsDetail.goods_spec[j].classname = "btn";
            if (
              this.goodsDetail.goods_spec[j].goods_spec_name ==
              this.goodsDetail.showSpecInfo.goods_spec_name
            ) {
              this.goodsDetail.goods_spec[j].classname = "btnactive";
              this.specsName = this.goodsDetail.goods_spec[j].goods_spec_name; //规格
              this.specsNum = this.goodsDetail.goods_spec[j].show_num; //库存
              this.specsMon = this.goodsDetail.goods_spec[j].member_discount; //价钱
              this.specsyuanMon = this.goodsDetail.goods_spec[j].member_price; //原价
              this.specsid = this.goodsDetail.goods_spec[j].id; //商品id
            }
          }
        }
      });
    },
    //点击加入购物车
    determine() {},
    //点击立即购买
    determinetrue() {},
    //点击规格按钮
    changeSpecs(index) {
      for (var i in this.goodsDetail.goods_spec) {
        this.goodsDetail.goods_spec[i].classname = "btn";
      }
      this.goodsDetail.showSpecInfo.goods_spec_name = this.goodsDetail.goods_spec[
        index
      ].goods_spec_name;
      this.goodsDetail.showSpecInfo.member_discount = this.goodsDetail.goods_spec[
        index
      ].member_discount;
      this.goodsDetail.showSpecInfo.member_price = this.goodsDetail.goods_spec[
        index
      ].member_price;
      this.specsName = this.goodsDetail.goods_spec[index].goods_spec_name; //规格
      this.specsNum = this.goodsDetail.goods_spec[index].show_num; //库存
      this.specsMon = this.goodsDetail.goods_spec[index].member_discount; //价钱
      this.specsyuanMon = this.goodsDetail.goods_spec[index].member_price; //原价
      this.specsid = this.goodsDetail.goods_spec[index].id; //商品id
      this.goodsDetail.goods_spec[index].classname =
        this.goodsDetail.goods_spec[index].classname == "btn"
          ? "btnactive"
          : "btn";
      // console.log(this.goodsDetail.goods_spec);
      this.$forceUpdate();
    },
    //商品评价接口
    getGoodsCommentList() {
      var param = {
        token: this.$store.getters.token,
        goods_id: this.$route.query.id
      };
      this.$api.mall.goodsCommentList(param).then(res => {
        if (res.code == 200) {
          this.goodsCommentList = res.result;
          for (var i in this.goodsCommentList) {
            // this.goodsCommentList[i].chatime = this.intervalTime(
            //   new Date(this.goodsCommentList[i].updated_at).valueOf(),
            //   new Date().valueOf()
            // );
            this.goodsCommentList[i].chatime = this.intervalTime(
              new Date(
                this.goodsCommentList[i].updated_at.replace(/-/g, "/")
              ).valueOf(),
              new Date().valueOf()
            );

            var memberpic = this.goodsCommentList[i].member.thumb;
            if (memberpic.indexOf("http") == -1) {
              this.goodsCommentList[i].member.memberthumb =
                this.$publicData.imgUrl + memberpic;
            } else {
              this.goodsCommentList[i].member.memberthumb = memberpic;
            }
            for (var a in this.goodsCommentList[i].alumArr) {
              var alumitem = this.goodsCommentList[i].alumArr[a];
              if (alumitem.indexOf("http") == -1) {
                this.goodsCommentList[i].alumArr[a] =
                  this.$publicData.imgUrl + alumitem;
              }
              this.goodsCommentList[i].alumList = this.goodsCommentList[
                i
              ].alumArr;
            }
            // console.log("11", this.goodsCommentList[i].alumList);
          }
        }
      });
    },
    toShopcar() {
      var that = this;
      that.$router.push({
        path: "/shopcar"
      });
    },
    // onBuyClicked() {},
    intervalTime(currentTimsmap, endTime) {
      // console.log(currentTimsmap, endTime);
      var subTime = endTime - currentTimsmap;
      // console.log(subTime);
      if (subTime > 0) {
        //  相差的天数
        var titmeTotal = subTime; //时间差的毫秒数
        var days = Math.floor(titmeTotal / (24 * 3600 * 1000));
        // 相差的小时数
        var leave1 = titmeTotal % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        var hours = Math.floor(leave1 / (3600 * 1000));
        //  计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000));
        //  计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟后剩余的毫秒数
        var seconds = Math.round(leave3 / 1000);

        // days + "天" + hours + "小时" + minutes + "分"
        return days + "天" + hours + "小时" + minutes + "分";
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
/* .goods_pingjia .footercar .van-info {
  font-size: 0.22rem;
}
.goods_pingjia ::v-deep .van-popup__close-icon--top-right {
  top: 0.3rem !important;
  right: 0.3rem !important;
} */
</style>