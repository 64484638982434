/**
 * 积分接口
 */
import axios from "../http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块，把请求字段直接映射过来

const score = {
  //积分列表
  scoreList(params) {
    return axios.post("/api/member/scoreList", qs.stringify(params));
  },
  //积分任务列表
  memberScoreTaskList(params) {
    return axios.post("/api/member/memberScoreTaskList", qs.stringify(params));
  },
  //推广-列表
  memberExtendList(params) {
    return axios.post("/api/member/memberExtendList", qs.stringify(params));
  },
  //领取积分
  takeTaskScore(params) {
    return axios.post("/api/member/takeTaskScore", qs.stringify(params));
  },
  //积分商城
  scoreShopCenter(params) {
    return axios.post("/api/member/scoreShopCenter", qs.stringify(params));
  },
  //积分商品详情
  scoreGoodsDetail(params) {
    return axios.post("/api/member/scoreGoodsDetail", qs.stringify(params));
  },
  //积分订单下单
  directCreateScoreOrder(params) {
    return axios.post(
      "/api/member/directCreateScoreOrder",
      qs.stringify(params)
    );
  },
  //支付积分订单
  payScoreMemberOrder(params) {
    return axios.post("/api/member/payScoreMemberOrder", qs.stringify(params));
  },
};
// 导出接口
export default score;
