/**
 * api接口统一管理
 */
import login from "./member/login.js";
import common from "./member/common.js";
import member from "./member/member";
import enjoylife from "./member/enjoylife";
import coupon from "./member/coupon";
import ygrecord from "./member/ygrecord";
import score from "./member/score";
import aftersale from "./member/aftersale";
import pjorder from "./member/pjorder";
import address from "./member/address";
import mall from "./member/mall";
import goodsOrder from "./member/goodsOrder";

const api = {
  login,
  member,
  enjoylife,
  coupon,
  ygrecord,
  score,
  aftersale,
  pjorder,
  address,
  mall,
  goodsOrder,
  common,
};
export default api;
