<template>
  <div class="EditUserINfo integralinfo">
    <van-nav-bar
      :z-index="99"
      title="商品详情"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y-community_info">
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(image, index) in showpic" :key="index">
          <!-- <img v-lazy="image" /> -->
          <van-image fit="fill" :src="image" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="y-integral_info-title">
      <p>{{goodsDetail.title}} {{goodsDetail.showSpecInfo.goods_spec_name}}</p>
      <span>
        {{goodsDetail.showSpecInfo.score}}
        <i>积分</i>
      </span>
    </div>
    <div class="y-fg"></div>
    <div class="y-integral_info-gg">
      <van-cell-group>
        <van-field
          @click="toguige(goodsDetail.showSpecInfo.goods_spec_name)"
          v-model="guige"
          label="规格"
          readonly
          right-icon="arrow"
          :placeholder="goodsDetail.showSpecInfo.goods_spec_name"
        />
        <van-field v-model="fuwu" label="服务" readonly placeholder="售后无忧" />
      </van-cell-group>
    </div>
    <div class="y-fg"></div>
    <div class="y-integral_info-all">
      <h2>商品详情</h2>
      <p class="htmlp" v-html="goodsDetail.content"></p>
      <!-- <img src="../../assets/images/4.png" /> -->
    </div>

    <!-- <van-tabbar v-model="active"> -->
    <van-button
      type="primary"
      @click="toIntadd"
      block
      round
      color="linear-gradient(to right, #F28E26, #FD644F)"
      class="y-integral_info-buttom"
    >立即兑换</van-button>
    <!-- </van-tabbar> -->
    <!-- 规格弹框 -->

    <van-popup
      class="guipop"
      v-model="showshopcar"
      :close-on-click-overlay="false"
      round
      closeable
      position="bottom"
      :style="{ height: '70%' }"
    >
      <div class="pophp_top">
        <img :src="ToPic(goodsDetail.thumb)" alt />
        <div class="right">
          <span class="mon">
            <span>{{specsMon}}</span>积分
          </span>
          <!-- <span class="beginmon">
            原价:
            <span>￥{{specsyuanMon}}</span>
          </span> -->
          <span class="ku">库存:{{specsNum}}件</span>
          <span v-if="specsName !=''" class="alreadyspe slh">已选规格:{{specsName}}</span>
        </div>
      </div>
      <div class="pophp_down">
        <div class="spe">
          <h3>规格</h3>
          <div class="spe_btn">
            <van-button
              round
              type="info"
              v-for="(item,index) in goodsDetail.goods_spec"
              :key="'goodsDetail'+index"
              :class="item.classname"
              @click="changeSpecs(index)"
            >{{item.goods_spec_name}}</van-button>
          </div>
          <div class="num clearfix">
            <span class="f-l">数量</span>
            <van-stepper v-model="valuecar" class="f-r" />
          </div>
        </div>
      </div>
      <div class="okbtn">
        <van-button round type="info" class="determinetrue" @click="determinetrue">确定</van-button>
      </div>
    </van-popup>
     <!-- 加载框 -->
     <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showloading:false,
      showshopcar: false,
      valuecar: 1,
      specsNum: "",
      specsMon: "",
      specsyuanMon: "",
      specsName: "",
      specsid: "",
      goodsDetail: { showSpecInfo: {} },
      showpic: [],
      // active: 0,
      guige: "",
      fuwu: "",
      images: [
        "https://img01.yzcdn.cn/vant/apple-2.jpg",
        "https://img01.yzcdn.cn/vant/apple-2.jpg"
      ]
    };
  },
  created: function() {
    this.getDetail();
  },
  methods: {
    ToPic(pic) {
      if (pic && pic.indexOf("http") == -1) {
        return this.$publicData.imgUrl + pic;
      } else {
        return pic;
      }
    },
    // 点击弹出规格弹框
    toguige(name) {
      this.showshopcar = true;
      for (var j in this.goodsDetail.goods_spec) {
        this.goodsDetail.goods_spec[j].classname = "btn";
        if (this.goodsDetail.goods_spec[j].goods_spec_name == name) {
          this.goodsDetail.goods_spec[j].classname = "btnactive";
          this.specsName = this.goodsDetail.goods_spec[j].goods_spec_name; //规格
          this.specsNum = this.goodsDetail.goods_spec[j].show_num; //库存
          this.specsMon = this.goodsDetail.goods_spec[j].score; //积分
          this.specsyuanMon = this.goodsDetail.goods_spec[j].member_price; //原价
          this.specsid = this.goodsDetail.goods_spec[j].id; //商品id
        }
      }
      // console.log(this.goodsDetail.goods_spec);
    },
    // 点击规格按钮
    changeSpecs(index) {
      for (var i in this.goodsDetail.goods_spec) {
        this.goodsDetail.goods_spec[i].classname = "btn";
      }
      this.goodsDetail.showSpecInfo.goods_spec_name = this.goodsDetail.goods_spec[
        index
      ].goods_spec_name;
      this.goodsDetail.showSpecInfo.member_discount = this.goodsDetail.goods_spec[
        index
      ].member_discount;
      this.goodsDetail.showSpecInfo.member_price = this.goodsDetail.goods_spec[
        index
      ].member_price;
      this.specsName = this.goodsDetail.goods_spec[index].goods_spec_name; //规格
      this.specsNum = this.goodsDetail.goods_spec[index].show_num; //库存
      this.specsMon = this.goodsDetail.goods_spec[index].score; //积分
      this.specsyuanMon = this.goodsDetail.goods_spec[index].member_price; //原价
      this.specsid = this.goodsDetail.goods_spec[index].id; //商品id
      this.goodsDetail.goods_spec[index].classname =
        this.goodsDetail.goods_spec[index].classname == "btn"
          ? "btnactive"
          : "btn";
    },
    // 点击规格弹出框确定
    determinetrue() {
      this.showshopcar = false;
    },
    getDetail() {
      this.showloading = true;
      var params = {
        token: this.$store.getters.token,
        goods_id: this.$route.query.id
      };
      this.$api.score.scoreGoodsDetail(params).then(res => {
        this.showloading = false;
        if (res.code == 200) {
          this.goodsDetail = res.result;
          this.specsid = this.goodsDetail.showSpecInfo.id;
          this.showpic = [];
          for (var i in this.goodsDetail.showAlbumArr) {
            var album = this.goodsDetail.showAlbumArr[i];
            if (album.indexOf("http") == -1) {
              var img = this.$publicData.imgUrl + album;
              this.showpic.push(img);
            } else {
              this.showpic.push(album);
            }
          }
          // console.log(this.showpic);
        }
      });
    },
    // 点击立即兑换
    toIntadd() {
      var params = {
        token: this.$store.getters.token,
        goods_id: this.$route.query.id,
        goods_spec_id: this.specsid,
        num: this.valuecar
      };
      this.$api.score.directCreateScoreOrder(params).then(res => {
        if (res.code == 200) {
          this.$router.push({
            path: "/integral_add",
            query:{
              id:res.result.id
            }
          });
        }
      });
    }
  }
};
</script>
<style  scoped>
/* .integralinfo .van-tabbar {
  border-top: 1px #eeeeee solid;
}
.integralinfo .y-integral_info-buttom {
  width: 94%;
  margin: 7px 3%;
  height: 35px;
}
.integralinfo .y-integral_info-all img {
  width: 100%;
}
.integralinfo .y-integral_info-all h2 {
  font-size: 0.35rem;
  text-align: left;
  padding: 10px 4px;
}
.integralinfo .y-integral_info-all {
  width: 94%;
  margin: 0px 3%;
  padding-bottom: 60px;
}
.integralinfo ::v-deep .y-integral_info-gg input::-webkit-input-placeholder {
  color: #000;
}
.integralinfo ::v-deep .y-integral_info-gg .van-field__label {
  width: 50px;
  color: #999;
}
.integralinfo .y-integral_info-gg {
  width: 100%;
}
.integralinfo .y-integral_info-title span i {
  font-size: 0.35rem;
}
.integralinfo .y-integral_info-title span {
  font-size: 0.35rem;
  color: #ee904f;
  width: 100%;
}
.integralinfo .y-integral_info-title p {
  font-size: 0.35rem;
  line-height: 23px;
  float: left;
  padding: 10px 0px 5px 0px;
}
.integralinfo .y-integral_info-title {
  width: 94%;
  margin: 0px 3%;
  padding-bottom: 10px;
}
.integralinfo .van-swipe img {
  width: 100%;
  border-radius: 10px;
}
.integralinfo .y-community_info {
  width: 94%;
  margin-left: 3%;
  float: left;
  height: 300px;
  margin-top: 10px;
} */
</style>