/**
 * 乐享生活接口
 */
import axios from "../http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块，把请求字段直接映射过来

const enjoylife = {
  //   getPreOrderPosition(params) {
  //     return axios.get("/api/member/getPreOrderPosition", { params: params });
  //   },

  //服务-列表(上门配镜)
  modeServiceList(params) {
    return axios.post("/api/member/modeServiceList", qs.stringify(params));
  },
  //轮播图-查询(上门配镜)
  bannerList(params) {
    return axios.post("/api/member/bannerList", qs.stringify(params));
  },
  //文章-列表(爱眼护眼)
  articlesList(params) {
    return axios.post("/api/member/articlesList", qs.stringify(params));
  },
  //文章-详情(爱眼护眼)
  articlesDetail(params) {
    return axios.post("/api/member/articlesDetail", qs.stringify(params));
  },
  //   种草社区-列表
  communityList(params) {
    return axios.post("/api/member/communityList", qs.stringify(params));
  },

  //   种草社区—详情
  communityDetail(params) {
    return axios.post("/api/member/communityDetail", qs.stringify(params));
  },
  //种草社区—提交
  communityCreate(params) {
    return axios.post("/api/member/communityCreate", qs.stringify(params));
  },
  //种草社区赞/取消赞
  communityZan(params){
    return axios.post("/api/member/communityZan", qs.stringify(params));
  },
  //种草社区—查询会员提交的猎豹
  communityMemberList(params) {
    return axios.post("/api/member/communityMemberList", qs.stringify(params));
  },
  //会员权益—查询会员权益信息
  levelDetail(params) {
    return axios.post("/api/member/levelDetail", qs.stringify(params));
  },
};

// 导出接口
export default enjoylife;
