<template>
  <div class="EditUserINfo couponsinfo">
    <!-- :class="{back:back}" -->
    <van-nav-bar
      :z-index="99"
      :fixed="false"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y-coupons-top">
      <img src="../../assets/images/coupons-bg.png" />
    </div>
    <div v-for="(item,index) in couponCenterList" :key="index" :class="item.classitem">
      <div class="y-coupons-list-l">
        <span>{{item.type ==0?"现金券":"折扣券"}}</span>
        <div class="mon">
          <i class="num" v-if="item.type==1">{{Number(item.money)*10}}</i>
          <i v-if="item.type==1">%</i>
          <i class="num" v-if="item.type==0">{{Number(item.money)}}</i>
          <i v-if="item.type==0">元</i>
        </div>
      </div>
      <div class="y-coupons-list-c">
        <p>{{item.name}}</p>
        <i>{{item.valide_begin.replace(/-/g, '.')}}-{{item.valide_end.replace(/-/g, '.')}}</i>
        <!-- watchrule(index) -->
        <van-button type="default" round @click="watchrule(item)">查看使用规则</van-button>
      </div>
      <div class="y-coupons-list-r">
        <van-button round type="info" @click="receive(item.id)">点击领取</van-button>
        <!-- <van-button round type="info" @click="receive(item.id)">{{btntext}}</van-button> -->
      </div>
    </div>
    <!-- 使用规则 -->
    <van-popup v-model="showrule" class="userule">
      <h3>使用规则</h3>
      <span class="rulecon">{{currentItem.rule == null? "暂无使用规则" : currentItem.rule}}</span>
      <van-button round plain type="info" @click="iknow">明白了</van-button>
    </van-popup>
    <!-- 加载框 -->
    <!-- <van-popup class="jiazai" v-model="showloading">
        <van-loading type="spinner" />
    </van-popup>-->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showloading: false,
      back: false,
      classitem: "",
      couponCenterList: [],
      showrule: false,
      indexrule: 0,
      // btntext: "点击领取",
      currentItem: {}
    };
  },
  created: function() {
    this.getCouponCenter();
  },
  methods: {
    // 领券中心接口
    getCouponCenter() {
      this.showloading = true;
      var params = {
        token: this.$store.getters.token
      };
      this.$api.coupon.couponCenter(params).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.couponCenterList = res.result.list;
          for (var i in this.couponCenterList) {
            var coupontype = this.couponCenterList[i].type;
            if (coupontype == 0) {
              this.couponCenterList[i].classitem = "y-coupons-list";
            } else {
              this.couponCenterList[i].classitem = "y-coupons-listz";
            }
            var status = this.couponCenterList[i].status;
            // if (status == 1) {
            //   this.btntext = "已领取";
            // } else {
            //   this.btntext = "点击领取";
            // }
          }
        }
      });
    },
    //点击查看使用规则
    watchrule(item) {
      // this.indexrule = i;
      // console.log("item",item);
      this.showrule = true;
      this.currentItem = item;
    },
    //点击我明白了
    iknow() {
      this.showrule = false;
    },
    //点击领取
    receive(id) {
      // console.log(this.btntext);
      // if (this.btntext == "点击领取") {
      var params = {
        token: this.$store.getters.token,
        id: id
      };
      this.$api.coupon.couponLingQuan(params).then(res => {
        if (res.code == 200) {
          this.getCouponCenter();
          this.$toast.success("领取成功");
          // this.btntext = "已领取";
        }
      });
      // } else {
      //   this.$toast.fail("每人限领一张,已经领取过");
      // }
    }
    // TabBar() {
    //   var userOld = document.getElementsByClassName("y-coupons-list")[0];
    //   var that = this;
    //   if (userOld.scrollHeight - scrollY <= 100) {
    //     that.back = true;
    //   } else {
    //     that.back = false;
    //   }
    // }
  },
  mounted() {
    // window.addEventListener("scroll", this.TabBar);
  }
};
</script>
<style scoped>
/* .couponsinfo .van-nav-bar {
  position: absolute;
}
.couponsinfo .y-coupons-listz {
  width: 94%;
  margin-left: 3%;
  float: left;
  margin-bottom: 10px;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 116px;
  background-image: url(../../assets/images/coupons-list-bg2.png);
}
.couponsinfo .y-coupons-listz .y-coupons-list-l {
  background-color: #fa436a;
}
.couponsinfo .y-coupons-listz .y-coupons-list-l span {
  background-image: linear-gradient(to right, #fda1b5, #fa436a);
}
.couponsinfo .y-coupons-listz .y-coupons-list-c p {
  color: #fa436a;
}
.couponsinfo .y-coupons-listz .y-coupons-list-c i {
  color: #fa436a;
}
.couponsinfo .y-coupons-listz .y-coupons-list-r .van-button {
  background-color: #fa436a;
}

.couponsinfo .y-coupons-list-r .van-button {
  padding: 5px 12px;
  float: left;
  height: 25px;
  line-height: 22px !important;
  color: #dac8b4;
  background-color: #fa7043;
  border: none;
  color: #fff;
  font-size: 0.34rem;
  margin: 22px 12px;
}
.couponsinfo .y-coupons-list-r {
  float: right;
  height: 70px;
  border-left: 1px #fecbaf dashed;
  margin: 23px 0px;
}
.couponsinfo .y-coupons-list-c .van-button span {
  font-size: 11px;
}
.couponsinfo .y-coupons-list-c .van-button {
  padding: 3px 12px;
  float: left;
  height: 22px;
  line-height: 22px !important;
  color: #dac8b4;
  background-color: transparent;
  border: 1px #dac8b4 solid;
  margin-top: 0.56rem;
}
.couponsinfo .y-coupons-list-c .van-button .van-button__text {
  color: rgba(0, 0, 0, 0.6);
}
.couponsinfo .y-coupons-list-c p {
  width: 100%;
  float: left;
  color: #fa7043;
  font-size: 0.34rem;
  font-weight: bold;
}
.couponsinfo .y-coupons-list-c i {
  font-size: 0.27rem;
  width: 100%;
  float: left;
  color: #fa7043;
  margin-top: 0.17rem;
}
.couponsinfo .y-coupons-list-c {
  width: 110px;
  float: left;
  padding: 22px 0px 0px 20px;
}
.couponsinfo .y-coupons-list-l h2 {
  float: left;
  width: 100%;
  color: #fff;
  margin: 18px 20px;
}
.couponsinfo .y-coupons-list-l h2 p {
  font-size: 41px;
  color: #fff;
  float: left;
}
.couponsinfo .y-coupons-list-l h2 i {
  font-size: 0.34rem;
  float: left;
  line-height: 63px;
}
.couponsinfo .y-coupons-list-l span {
  width: 74px;
  height: 15px;
  float: left;
  color: #fff;
  padding: 1px 5px;
  font-size: 0.31rem;
  margin-top: 5px;
  background-image: linear-gradient(to right, #fdb8a1, #fa7043);
}
.couponsinfo .y-coupons-list-l {
  width: 103px;
  height: 100%;
  background-color: #fa7043;
}
.couponsinfo .y-coupons-list {
  width: 94%;
  margin-left: 3%;
  float: left;
  margin-bottom: 10px;
  background-image: url(../../assets/images/coupons-list-bg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  height: 116px;
}
.couponsinfo .y-coupons-top,
.couponsinfo .y-coupons-top img {
  width: 100%;
  float: left;
}
.couponsinfo .y-coupons-top {
  margin-bottom: 15px;
}
.couponsinfo .back {
  background: #fff !important;
}
.couponsinfo .back .van-icon {
  color: #000 !important;
}

.couponsinfo .van-nav-bar {
  background-color: transparent;
}
.couponsinfo .van-icon {
  color: #ffffff !important;
}
.couponsinfo .userule {
  width: 7.87rem;
}
.couponsinfo .userule h3 {
  text-align: center;
  background: url(../../assets/images/rule.png) no-repeat 1.75rem;
  background-size: 4.33rem;
  font-size: 0.4rem;
  margin-top: 0.6rem;
  margin-bottom: 0.77rem;
}
.couponsinfo .userule .rulecon {
  display: block;
  font-size: 0.35rem;
  color: #000;
  opacity: 0.6;
  margin: 0 0.65rem;
  line-height: 0.55rem;
}
.couponsinfo .userule .van-button {
  width: 2.94rem;
  height: 0.93rem;
  color: #ff7351 !important;
  border-color: #ff7351;
  background: transparent;
  padding: 0;
  margin: 1.53rem 0 0.55rem 2.59rem;
} */
</style>