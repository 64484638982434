<template>
  <div class="EditUserINfo communityadd">
    <van-nav-bar
      :z-index="99"
      title="新增"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="toHome"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="community_add-1">
      <!-- <van-uploader>
        <img src="../../assets/images/s-bg1.png" class="y-s-bg" />
      </van-uploader>-->
       <!-- <div >
          <img v-if="user.thumb" :src="user.thumb" />
        </div> -->
      <van-uploader :after-read="onRead"  v-model="fileListAdd" accept multiple :before-delete="onDelet">
        <img src="../../assets/images/s-bg1.png" class="y-s-bg" />
        <!-- <div v-for="">
          <img :src="user.thumb" />
        </div> -->
      </van-uploader>
    </div>
    <div class="community_add-2">
      <van-field
        v-model="message"
        rows="2"
        autosize
        type="textarea"
        maxlength="20"
        placeholder="填写标题会有更多赞哦~"
        show-word-limit
      />
    </div>
    <div class="community_add-3">
      <van-field
        v-model="text"
        rows="1"
        autosize
        type="textarea"
        placeholder="添加正文~"
        show-word-limit
      />
    </div>
    <div class="community_add-4">
      <van-button round color="linear-gradient(to right, #35B6D8, #52D3C5)" @click="release">发布</van-button>
    </div>
     <!-- 加载框 -->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showloading:false,
      message: "",
      text: "",
      user:{},
      fileListAdd:[],
      uploadTuList:[]
    };
  },
  methods: {
    // 上传图片
    onRead: function(file) {
      this.showloading = true;
      // var con=this.fileListAdd[0].content
      // console.log("fileListAdd",window.btoa(con));
      var that = this;
      var token = this.$store.getters.token;
      this.$api.member.uploadTu({ imgFile: file.content }).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.uploadTuList.push(res.result.url)
        }else{
          this.showloading=false;
        }
      });
    },
    // 删除
    onDelet(e,detail){
      var index = detail.index
      this.fileListAdd.splice(index,1)
      this.uploadTuList.splice(index,1)
    },
    //点击发布
    release(){
      // console.log("this.uploadTuList",this.uploadTuList);
      var params={
          token: this.$store.getters.token,
          name:this.message,
          desc:this.text,
          alum:this.uploadTuList
      }
       this.$api.enjoylife.communityCreate(params).then(res => {
         if(res.code == 200){
           this.$router.push({
             path:"/community"
           })
         }
       })
    },
    toHome(){
      this.$router.push({
        path:"/home"
      })
    }
  }
};
</script>
<style  scoped>
/* .communityadd .community_add-4 button {
  width: 100%;
}
.communityadd ::v-deep.community_add-3 .van-cell {
  min-height: 200px !important;
}
.communityadd ::v-deep .van-field__word-limit {
  line-height: 30px !important;
  margin-top: 0px !important;
}
.communityadd ::v-deep.community_add-2 .van-field__body {
  float: left;
  width: 90%;
}
.communityadd ::v-deep.community_add-2 .van-cell .van-field__control {
  height: 30px !important;
  line-height: 30px;
}
.communityadd ::v-deep.community_add-2 .van-cell {
  padding: 0px 15px;
}
.communityadd .community_add-4 {
  width: 94%;
  margin-left: 3%;
  float: left;
  padding: 20px 0px;
  margin-top: 150px;
}
.communityadd .community_add-3 {
  width: 100%;
  float: left;
  border-bottom: 1px #eee solid;
  padding: 10px 0px;
}
.communityadd .community_add-2 {
  width: 100%;
  float: left;
  border-bottom: 1px #eee solid;
  padding: 15px 0px;
}
.communityadd .community_add-1 {
  width: 100%;
  float: left;
  margin-top: 10px;
  border-bottom: 1px #eee solid;
  padding: 15px 0px;
}
.communityadd .community_add-1 img {
  width: 80px;
  height: 80px;
  margin-left: 10px;
}
.communityadd .van-image__img{
  border-radius: 0.2rem;
} */
</style>