<template>
  <div class="shopping_mall">
    <!-- <h1>捷捷商城</h1> -->
    <van-nav-bar
      :fixed="false"
      title="捷捷商城"
      :border="false"
      class="titlecon"
      left-arrow
      @click-left="toHome"
    >
      <template #left>
        <van-icon name="arrow-left" color="#fff" />
      </template>
    </van-nav-bar>

    <div class="mall_header">
      <van-search
        :clearable="false"
        :left-icon="img_search"
        v-model="value"
        shape="round"
        placeholder="请输入搜索关键词"
        show-action
        @search="onSearch"
        @focus="onfocus"
      >
        <template #action>
          <van-cell @click="showPopup">
            <img :src="img_sort" alt="pic" />
            <div class="fen" @click="onSort">分类</div>
          </van-cell>

          <!-- 商品分类 -->
          <van-popup
            v-model="showsort"
            round
            position="right"
            :style="{width:'80%', height:'100%'}"
          >
            <!-- 商品分类页 -->
            <!-- <h1>分类</h1> -->
            <div class="sortpage">
              <van-collapse v-model="activeNames">
                <!-- 全部分类 -->
                <van-collapse-item
                  title="分类"
                  name="allsort"
                  :value="cateName"
                  :border="false"
                  class="all"
                >
                  <span
                    v-for="(item,index) in cateList"
                    :key="'cateList1'+index"
                    :class="item.classname"
                    @click="allsort(index,item.id)"
                  >{{item.name}}</span>
                </van-collapse-item>
                <!-- 二级分类 -->
                <div v-if="catearr.length != 0">
                  <div v-for="(a,i) in catearr" :key="'catrarr2'+i">
                    <!-- v-if="goodsCateChildren.length != 0" -->
                    <van-collapse-item
                      :title="a.child.title"
                      :name="'erji'+i"
                      :value="a.erjiName"
                      :border="false"
                      v-if="a.child.list.length != 0"
                    >
                      <span
                        v-for="(item,index) in a.child.list"
                        :key="'child3'+index"
                        :class="item.classCateChildren"
                        @click="erjisort(index,item.id,i)"
                      >{{item.name}}</span>
                    </van-collapse-item>
                  </div>
                </div>
                <!-- 价格区间 -->
                <van-collapse-item
                  title="价格区间"
                  name="pricesec"
                  :is-link="false"
                  :border="false"
                  :disabled="true"
                  class="price"
                >
                  <input type="text" placeholder="最低价" class="minprice" />
                  <span class="line">-</span>
                  <input type="text" placeholder="最高价" class="maxprice" />
                </van-collapse-item>

                <span v-for="(item,index) in goodsSearchInfo.attrList" :key="'attrList5'+index">
                  <van-collapse-item
                    v-if="item.item.length != 0"
                    :title="item.name"
                    :name="item.name+item.id"
                    :value="item.activevalue"
                    :border="false"
                  >
                    <span
                      v-for="(itemin,i) in item.item"
                      :key="'item6'+i"
                      :class="itemin.classattr"
                      @click="attractive(item,itemin,index,itemin.id)"
                    >{{itemin.item}}</span>
                  </van-collapse-item>
                </span>
              </van-collapse>
              <!-- footer  -->
              <div class="footer">
                <div class="select">
                  <span v-if="cateName !='' ">{{cateName}}</span>
                  <span v-for="(item,index) in catearr" :key="'catearr7'+index">
                    <span v-if="item.erjiName != ''">{{item.erjiName}}</span>
                  </span>

                  <span v-if="brandName !='' ">{{brandName}}</span>
                  <span v-for="(item,index) in goodsSearchInfo.attrList" :key="'attrList8'+index">
                    <span v-if="item.activevalue != ''">{{item.activevalue}}</span>
                  </span>
                </div>
                <van-button round type="info" class="reset" @click="reset">重置</van-button>
                <van-button round type="info" class="determine" @click="determine">
                  确定
                  <!-- <span class="num">(50+件商品)</span> -->
                </van-button>
              </div>
            </div>
          </van-popup>
        </template>
      </van-search>
    </div>
    <!-- banner -->
    <div class="mall_banner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in bannerList" :key="index">
          <van-image fit="contain" :src="item.bannerthumb" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 优惠券 -->
    <div class="discount" @click="ongetCoupons">
      <!-- <van-image fit="contain" v-for="(item,index) in discount" :key="index" :src="item" /> -->
      <div class="quan" v-for="(item,index) in quanList" :key="index">
        <span v-if="item.type == 0" class="xian">￥<span>{{item.money *1}}</span>
        </span>
        <span v-if="item.type == 1" class="bai">
          <span>{{item.money *10}}</span>%
        </span>
        <!-- <span class="desc">满{{Number(item.threshold_money)}}元使用 | {{item.type == 0?'现金券':'折扣券'}}</span> -->
        <van-button round type="info" class="btn">点击领取</van-button>
      </div>
    </div>
    <!-- 商品 -->
    <div class="recommend">
      <!-- <van-tabs type="card"> -->
      <!-- 热门推荐 -->
      <!-- <van-tab title="热门推荐" name="a" class="clearfix"> -->
      <div class="rec_title">
        <span class="hot">热门推荐</span>
        <span class="gang">|</span>
        <span>新品速递，超值热卖</span>
      </div>
      <div class="goods clearfix">
        <div
          class="box f-l"
          v-for="(item,index) in goodsList"
          :key="index"
          @click="toDetalis(item.id)"
        >
          <van-image fit="contain" :src="item.goodsthumb" />
          <p class="word slh">{{item.title}}</p>
          <div class="xilie">
            <span>{{item.showSpecName}}</span>
            <!-- <span>{{item.xilie2}}</span> -->
          </div>
          <div style="white-space: nowrap;">
          <span class="nowmon">￥<span>{{item.showSpecPrice}}<span class="qi">起</span></span>
          </span>
          <span class="beforemon">￥<span>{{item.showSpecOriginPrice}}</span>
          </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 购物车 -->
    <div class="shopcar" @click="toShopcar">
      <van-icon :name="img_shopcar" size="0.6rem" :badge="cartsNum" />
    </div>

    <!-- 底部标签 -->
    <van-tabbar v-model="active" class="y-foot">
      <van-tabbar-item to="pre">
        <span>预约配镜</span>
        <template #icon="props">
          <img :src="props.active ? icons.book.active : icons.book.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="home">
        <span>会员中心</span>
        <template #icon="props">
          <img :src="props.active ? icons.my.active : icons.my.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      shareConfig: {},
      allsortId: "",
      shuxingId: [],
      showloading: false,
      showsort: false,
      cateName: "",
      brandName: "",
      cateList: [],
      catearr: [],
      goodsSearchInfo: {},
      minprice: "",
      maxprice: "",
      shopCenter: {},
      cartsNum: "",
      bannerList: [],
      quanList: [],
      goodsList: [],
      sortgoodsList: [],
      // 分类展开项
      // activeNames: ["1", "2", "3", "4", "5", "6"],
      activeNames: ["allsort", "pricesec", "pinpai"],
      value: "防蓝光眼镜",
      show: false,
      active: "",
      img_sort: require("../../assets/images/index/sort.png"),
      img_search: require("../../assets/images/index/search.png"),
      img_shopcar: require("../../assets/images/index/shopcarb.png"),
      icons: {
        book: {
          active: require("../../assets/images/foot1-1.png"),
          inactive: require("../../assets/images/foot1.png")
        },
        my: {
          active: require("../../assets/images/foot2-1.png"),
          inactive: require("../../assets/images/foot2.png")
        }
      }
    };
  },
  created: function() {
    this.getShopCenter();
    // this.getGoodsList();
  },
  methods: {
    shareFun() {
        var that = this;
        var tempurl = location.href;
        if(this.$store.getters.user_id != undefined && this.$store.getters.user_id!=null){
            if(location.href.indexOf("?") == -1){// 不存在？ 则没有参数
                tempurl = location.href + '?type=member&sharememberid='+this.$store.getters.user_id;
            }else{
                if(location.href.indexOf("sharememberid") == -1){// 不存在则直接加
                    tempurl = location.href + '&type=member&sharememberid='+this.$store.getters.user_id;
                }else{ // 参数替换
                    var heheurl = location.href;
                    var heheArr = heheurl.split('&');
                    tempurl = "";
                    for(let i in heheArr){
                        if(i == 0 ){
                           tempurl = heheArr[0]; 
                           continue;
                        }

                        if(heheArr[i].indexOf('sharememberid=') != -1){
                            tempurl +=  "&sharememberid=" + this.$store.getters.user_id;
                        }else{
                            tempurl +=  "&" + heheArr[i];
                        }
                    }
                }
                // tempurl = location.href + '&type=member&sharememberid='+this.$store.getters.user_id;
            }
        }
        const url = tempurl;
        console.log("Iframe ---share----url=" + url);
        const shareUrl = location.href.split("#")[0];
        that.$api.common.commonShare({url: shareUrl}).then(res => {
            if (res.code == 200) {
                that.shareConfig = res.result;
                wx.config({
                    debug: that.shareConfig.debug,
                    beta: that.shareConfig.beta,
                    jsApiList: that.shareConfig.jsApiList,
                    appId: that.shareConfig.appId,
                    nonceStr: that.shareConfig.nonceStr, // 随机串
                    timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
                    signature: that.shareConfig.signature // 签名
                });
                // console.log('Iframe ---微信分享设置---开始');
                // 分享
                wx.ready(function () {
                    var shareDataA = {
                        title: that.$publicData.shareTitle, // 分享标题
                        desc: that.$publicData.shareDesc, // 分享描述
                        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.$publicData.imgUrl + that.$publicData.img, // 分享图标
                        success: function () {
                        },
                        cancel: function (e) {
                        }
                    };

                    var shareDataB = {
                        title: that.$publicData.shareTitle, // 分享标题
                        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.$publicData.imgUrl + that.$publicData.img, // 分享图标
                        success: function () {
                        },
                        cancel: function (e) {
                        }
                    };
                    wx.updateAppMessageShareData(shareDataA); // 分享给朋友
                    wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
                    // console.log('Iframe ---微信分享设置---结束');
                });
            }
        });
    },
    //商城首页接口
    getShopCenter() {
      this.shareFun();
      this.showloading = true;
      var param = {
        token: this.$store.getters.token
      };
      this.$api.mall.shopCenter(param).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.shopCenter = res.result;
          this.bannerList = this.shopCenter.bannerList;
          this.cartsNum = this.shopCenter.cartsNum;
          this.goodsList = this.shopCenter.goodsList;
          this.quanList = this.shopCenter.quanList;
          //  bannerList图片格式判断
          for (var i in this.bannerList) {
            var bannerpic = this.bannerList[i].thumb;
            if (bannerpic.indexOf("http") == -1) {
              this.bannerList[i].bannerthumb =
                this.$publicData.imgUrl + bannerpic;
            } else {
              this.bannerList[i].bannerthumb = bannerpic;
            }
          }
          //  goodsList图片格式判断
          for (var j in this.goodsList) {
            var goodspic = this.goodsList[j].thumb;
            if (goodspic.indexOf("http") == -1) {
              this.goodsList[j].goodsthumb = this.$publicData.imgUrl + goodspic;
            } else {
              this.goodsList[j].goodsthumb = goodspic;
            }
          }
          //  quanList图片格式判断
          for (var k in this.quanList) {
            var quanpic = this.quanList[k].thumb;
            if (quanpic && quanpic.indexOf("http") == -1) {
              this.quanList[k].quanthumb = this.$publicData.imgUrl + quanpic;
            } else {
              this.quanList[k].quanthumb = quanpic;
            }
          }
        }
      });
    },
    reset() {
      this.cateName = "";
      this.brandName = "";
      // this.erjiName = "";
      // this.minprice = "";
      // this.maxprice = "";
      document.getElementsByClassName("minprice")[0].value = "";
      document.getElementsByClassName("maxprice")[0].value = "";
      for (var e in this.catearr) {
        this.catearr[e].erjiName = "";
      }
      // 二级
      if (this.catearr.length > 0) {
        this.catearr = [];
      }
      for (var i in this.cateList) {
        this.cateList[i].classname = "btn";
      }
      for (var j in this.brandList) {
        this.brandList[j].classbrand = "btn";
      }
      for (var k in this.goodsSearchInfo.attrList) {
        this.goodsSearchInfo.attrList[k].activevalue = "";
        for (var m in this.goodsSearchInfo.attrList[k].item) {
          this.goodsSearchInfo.attrList[k].item[m].classattr = "btn";
        }
      }
    },
    //商品分类接口
    getGoodsSearchInfo() {
      var param = {
        token: this.$store.getters.token,
        is_member:1
      };
      this.$api.mall.goodsSearchInfo(param).then(res => {
        if (res.code == 200) {
          this.goodsSearchInfo = res.result;
          // 全部分类
          var cate = this.goodsSearchInfo.cateList;
          for (var i in cate) {
            var classname = "btn";
            cate[i].classname = classname;
          }
          this.cateList = cate;
          // 品牌
          var brand = this.goodsSearchInfo.brandList;
          for (var j in brand) {
            var classbrand = "btn";
            brand[j].classbrand = classbrand;
          }
          this.brandList = brand;
          //选中项activevalue
          var attr = this.goodsSearchInfo.attrList;
          for (var k in attr) {
            attr[k].activevalue = "";
            for (var m in attr[k].item) {
              var classattr = "btn";
              attr[k].item[m].classattr = classattr;
            }
          }
          // console.log(this.goodsSearchInfo.attrList);
        }
      });
    },
    //点击按钮
    attractive(ie, e, index, id) {
       this.shuxingId[index] = id;
      var attritem = this.goodsSearchInfo.attrList[index];
      for (var i in attritem.item) {
        attritem.item[i].classattr = "btn";
      }
      e.classattr = e.classattr == "btn" ? "active" : "btn";
      ie.activevalue = e.item;
      this.$forceUpdate(); //强制刷新数据
    },
    //点击全部分类按钮
    allsort(index, id) {
      this.allsortId = id;
      for (var i in this.cateList) {
        this.cateList[i].classname = "btn";
      }
      this.cateList[index].classname =
        this.cateList[index].classname == "btn" ? "active" : "btn";
      this.cateName = this.cateList[index].name;
      this.$forceUpdate(); //强制刷新数据
      var param = {
        token: this.$store.getters.token,
        id: id,
        is_member:1
      };
      this.$api.mall.goodsCateChildren(param).then(res => {
        this.goodsCateChildren = res.result;
        // 二级分类
        for (var j in this.goodsCateChildren.list) {
          var CateChildren = "btn";
          this.goodsCateChildren.list[j].classCateChildren = CateChildren;
        }

        this.catearr = [{ id: id, child: this.goodsCateChildren }];
        // console.log("this.catearr", this.catearr);
        this.activeNames.push("erji0");
        for (var m = 0; m < this.activeNames.length; m++) {
          for (var n = m + 1; n < this.activeNames.length; n++) {
            if (this.activeNames[m] == this.activeNames[n]) {
              this.activeNames.splice(n, 1);
            }
          }
        }
      });
    },
    //点击确定
    determine() {
      this.showsort = false;
      this.cateName = "";
      this.brandName = "";
      // this.getGoodsList();
      this.minprice = document.getElementsByClassName("minprice")[0].value;
      this.maxprice = document.getElementsByClassName("maxprice")[0].value;

      // console.log(this.allsortId);
      // console.log(this.minprice);
      // console.log(this.maxprice);
      // console.log(this.shuxingId);
      window.localStorage.setItem("allsortId",this.allsortId);
      window.localStorage.setItem("minprice",this.minprice);
      window.localStorage.setItem("maxprice",this.maxprice);
      var aa = JSON.stringify(this.shuxingId)
      window.localStorage.setItem("shuxingId",aa);


      this.$router.push({
        path: "/goodsList"
      });
    },
    //点击二级分类
    erjisort(index, id, i) {
      this.allsortId = this.catearr[this.catearr.length - 1].id;
      for (var c in this.catearr) {
        if (c > i) {
          this.catearr.splice(c);
          // console.log("qqq",this.catearr.length, this.catearr);
        }
      }
      // this.activeNames.splice(i + 4);
      var param = {
        token: this.$store.getters.token,
        id: id,
        is_member:1
      };
      this.$api.mall.goodsCateChildren(param).then(res => {
        this.goodsCateChildren = res.result;
        for (var j in this.goodsCateChildren.list) {
          var CateChildren = "btn";
          this.goodsCateChildren.list[j].classCateChildren = CateChildren;
        }
        for (var a in this.catearr[i].child.list) {
          this.catearr[i].child.list[a].classCateChildren = "btn";
        }
        this.catearr[i].child.list[index].classCateChildren =
          this.catearr[i].child.list[index].classCateChildren == "btn"
            ? "active"
            : "btn";
        this.catearr[i].erjiName = this.catearr[i].child.list[index].name;
        var obj = {
          id: id,
          child: this.goodsCateChildren
        };
        this.catearr.push(obj);
        this.activeNames.push("erji" + (i + 1));
        for (var m = 0; m < this.activeNames.length; m++) {
          for (var n = m + 1; n < this.activeNames.length; n++) {
            if (this.activeNames[m] == this.activeNames[n]) {
              this.activeNames.splice(n, 1);
            }
          }
        }
        // console.log("hhh", i, this.catearr);
        console.log(this.activeNames);
      });
    },
    // 商城商品列表
    getGoodsList() {
      this.showloading = true;
      var param = {
        token: this.$store.getters.token
      };
      this.$api.mall.goodsList(param).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.sortgoodsList = res.result;
        }
      });
    },
    //跳转到首页
    toHome() {
      this.$router.push({
        path: "/home"
      });
    },
    //点击跳转到购物车
    toShopcar() {
      var that = this;
      that.$router.push({
        path: "/shopcar"
      });
    },
    //   搜索
    onSearch() {},
    // 表单获取焦点时
    onfocus() {
      var that = this;
      that.$router.push({
        path: "/goodsList"
      });
    },
    //   分类
    onSort() {},
    // 弹出层
    showPopup() {
      // this.show = true;
      this.showsort = true;
      this.getGoodsSearchInfo();
      this.cateName = "";
      this.brandName = "";
      // this.erjiName = "";
      // this.minprice = "";
      // this.maxprice = "";
      // document.getElementsByClassName("minprice")[0].value = "";
      // document.getElementsByClassName("maxprice")[0].value = "";
      for (var e in this.catearr) {
        this.catearr[e].erjiName = "";
      }
      // 二级
      if (this.catearr.length > 0) {
        this.catearr = [];
      }
      for (var i in this.cateList) {
        this.cateList[i].classname = "btn";
      }
      for (var j in this.brandList) {
        this.brandList[j].classbrand = "btn";
      }
      for (var k in this.goodsSearchInfo.attrList) {
        this.goodsSearchInfo.attrList[k].activevalue = "";
        for (var m in this.goodsSearchInfo.attrList[k].item) {
          this.goodsSearchInfo.attrList[k].item[m].classattr = "btn";
        }
      }
    },
    // 跳转优惠券页
    ongetCoupons() {
      var that = this;
      that.$router.push({
        path: "/coupons"
      });
    },
    // 点击商品跳转商品详情页
    toDetalis(id) {
      var that = this;
      that.$router.push({
        path: "/goods_details",
        query: {
          id: id
        }
      });
    },
  },
   mounted:function(){
    //进入页面强制刷新一次
    // if(location.href.indexOf("#reloaded") == -1){
    //   location.href = location.href + "#reloaded";
    //   location. reload();
    // }
  }
};
</script>

<style>

</style>
