<template>
  <div class="new_address">
    <van-nav-bar
      :z-index="99"
      :border="false"
      :title="titleadd"
      :fixed="false"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <van-form>
      <!-- :rules="[{ required: true, message: '请填写用户名' }]" -->
      <van-field v-model="shouname" name="收件人" label="收件人" placeholder="请填写收件人姓名" />
      <van-field v-model="shoutel" name="手机号码" label="手机号码" placeholder="请填写收件人手机号" />
      <van-field
        v-model="fieldValue"
        is-link
        readonly
        label="所在地区"
        placeholder="请选择地区"
        @click="showaddress = true"
      />
      <van-popup v-model="showaddress" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          active-color="#35b6d8"
          :options="options"
          @close="showaddress = false"
          @finish="onFinish"
        />
      </van-popup>
      <van-field v-model="detailedadd" name="详细地址" label="详细地址" placeholder="市县、乡镇、街道、楼牌号等" />

      <van-field class="defaultadd" name="switch" label="设为默认收货地址">
        <template #input>
          <van-switch v-model="switchChecked" size="20" />
        </template>
      </van-field>
      <div class="saveanduse">
        <van-button round block type="info" native-type="submit" @click="save_use">保存并使用</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shouname: "",
      shoutel: "",
      showaddress: false,
      fieldValue: "北京/北京市/东城区",
      cascaderValue: 3743,
      // cascaderValue: [26,972,3743],
      detailedadd: "",
      switchChecked: false,
      options: [],
      queryid: "",
      addressDetail: {},
      titleadd: ""
    };
  },
  created: function() {
    this.getddressDetail();
    this.getAreasDetail();
  },
  methods: {
    //查询详情接口
    getddressDetail() {
      this.queryid = this.$route.query.id;
      if (this.queryid != -1) {
        this.titleadd = "修改收件地址";
        var params = {
          token: this.$store.getters.token,
          id: this.$route.query.id
        };
        this.$api.address.memberAddressDetail(params).then(res => {
          if (res.code == 200) {
            this.addressDetail = res.result;
            if (this.options != -1) {
              this.shouname = this.addressDetail.name;
              this.shoutel = this.addressDetail.phone;
              this.detailedadd = this.addressDetail.address;
              this.switchChecked =
                this.addressDetail.is_default == 1 ? true : false;
              this.cascaderValue = this.addressDetail.area_id;
              this.fieldValue =
                res.result.province.areaname +
                "/" +
                res.result.city.areaname +
                "/" +
                res.result.area.areaname;
            }
          }
        });
      } else {
        this.titleadd = "新建收件地址";
      }
    },
    //地址接口
    getAreasDetail() {
      var params = {};
      this.$api.address.areasDetail(params).then(res => {
        if (res.code == 200) {
          this.options = res.result;
        }
      });
    },
    onFinish({ selectedOptions }) {
      // console.log(selectedOptions);
      this.showaddress = false;
      this.fieldValue = selectedOptions.map(option => option.text).join("/");
      // console.log("cascaderValue", this.cascaderValue);
      // console.log("options",this.options);
    },
    //点击保存并使用
    save_use() {
      if (this.queryid != -1) {
        //修改接口
        var paramedit = {
          token: this.$store.getters.token,
          id: this.$route.query.id,
          area_id: this.cascaderValue,
          name: this.shouname,
          phone: this.shoutel,
          address: this.detailedadd,
          is_default: this.switchChecked == true ? 1 : 0
        };
        this.$api.address.memberAddressEdit(paramedit).then(res => {
          // this.$router.push({
          //   path: "/address"
          // });
          this.$router.go(-1)
        });
      } else {
        // 新增接口
        var checked = this.switchChecked == true ? 1 : 0;
        var paramcreate = {
          token: this.$store.getters.token,
          area_id: this.cascaderValue,
          name: this.shouname,
          phone: this.shoutel,
          address: this.detailedadd,
          is_default: checked
        };
        this.$api.address.memberAddressCreate(paramcreate).then(res => {
          // this.$router.push({
          //   path: "/address"
          // });
           this.$router.go(-1)
        });
      }
    }
  }
};
</script>

<style>
</style>