<template>
  <div class="appointment_record">
    <van-nav-bar :fixed="false" :border="false" title="预约记录" left-arrow @click-left="gohome">
      <template #left>
        <van-icon name="arrow-left" color="#000"/>
      </template>
    </van-nav-bar>
    <div class="y_add_storage">
      <van-tabs background="#F3F4F9" v-model="curTab" @click="changeTab">
        <van-tab title="全部" name="-1"></van-tab>
        <van-tab title="待处理" name="1"></van-tab>
        <van-tab title="已完成" name="2"></van-tab>
        <van-tab title="已取消" name="0"></van-tab>
      </van-tabs>
      <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
        <div class="y_storage_list">
          <div class="y_storage_list_item" v-for="(item,index) in list" :key="index">
            <div class="y_storage_no" @click="gotoDetail(item.id)">
              <span>预约时间：{{item.pre_time}}</span>
              <span
                class="y_storage_no_state"
                :class="{y_storage_no_state_wait:item.status==1,y_storage_no_state_finish:item.status==2}"
              >{{item.showStatus}}</span>
            </div>
            <div class="y-book-listrxia" @click="gotoDetail(item.id)">
              <div class="y-book-listrxia1">
                <p>预约地址</p>
                <span>{{item.pre_address}}</span>
              </div>
              <div class="y-book-listrxia1">
                <p>预约目的</p>
                <span>{{item.showRemark}}</span>
              </div>
            </div>
            <div class="y-con-admin" v-if="item.store!=null">
              <van-row>
                <van-col span="24" v-for="juese in item.store.staff" :key="juese.id">
                  <div @click="toYan(juese.id)">
                    <!-- <div v-if="juese.role_id ==1">
                      <van-image round class="y-con-admin_img" :src="juese.staffthumb" />
                      <p>
                        <span>{{juese.name}}</span>
                        <i>市场主管</i>
                      </p>
                    </div> -->
                    <div v-if="juese.role_id ==2">
                      <van-image round class="y-con-admin_img" :src="juese.staffthumb" />
                      <p>
                        <span>{{juese.name}}</span>
                        <i>验光师</i>
                      </p>
                    </div>
                  </div>
                </van-col>
                <!-- <van-col span="12">
                  <div>
                    <van-image
                      round
                      class="y-con-admin_img"
                      src="https://img01.yzcdn.cn/vant/cat.jpeg"
                    />
                    <p>
                      <span>欧阳淼淼</span>
                      <i>验光师</i>
                    </p>
                  </div>
                </van-col>-->
              </van-row>
            </div>
            <div class="y-appo-qx" v-if="item.status==1">
              <van-button
                round
                size="small"
                color="#999"
                plain
                style="float: right; padding:5px 14px;"
                @click="cancelPreOrder(item.id)"
              >取消预约</van-button>
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </div>
    <!-- 加载框 -->
    <!-- <van-popup class="jiazai" v-model="showloading">
      <van-loading type="spinner" />
    </van-popup>-->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Dialog } from "vant";
export default {
  data() {
    return {
      showloading: false,
      curTab: "-1",
      list: [],
      loading_goods: true, //是否处于加载状态
      finished_goods: false, //是否已加载完所有数据
      isLoading: false, //下拉刷新
      pages: {
        page: 1,
        size: 10
      },
      staffthumb: ""
    };
  },
  created: function() {
    this.getTableList();
  },
  methods: {
    //下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.pages.page = 1;
        this.isLoading = false;
        this.getLaTableList();
      }, 1000);
    },
    // 取消预约
    cancelPreOrder(orderid) {
      Dialog.confirm({
        message: "是否确定取消本次预约？"
      })
        .then(() => {
          this.$api.member
            .cancelPreOrder({ token: this.$store.getters.token, id: orderid })
            .then(res => {
              if (res.code == 200) {
                this.getTableList();
                this.$toast("已取消");
              }
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    //跳转到验光师详情
    toYan(id) {
      this.$router.push({
        path: "/yan_details",
        query: {
          id: id
        }
      });
    },
    // 跳转至首页
    gohome() {
      this.$router.push("/home");
    },
    // 查询预约详情
    gotoDetail(id) {
      this.$router.push({
        path: "/appointment_details",
        query: {
          id: id
        }
      });
    },
    // 切换tab
    changeTab() {
      var that = this;
      that.pages.page = 1;
      that.list = [];
      that.finished_goods = false;
      that.getTableList();
    },
    // 获取列表
    getTableList() {
      this.showloading = true;
      var that = this;
      var params = {
        token: that.$store.getters.token,
        page: that.pages.page,
        size: that.pages.size
      };
      if (that.curTab != -1) {
        params.status = that.curTab;
      }
      that.$api.member.preOrderList(params).then(res => {
        if (res.code == 200) {
          that.showloading = false;
          var goodsList = res.result.list;
          if (goodsList && goodsList.length != 0) {
            // that.list = that.list.concat(goodsList);
            that.list = goodsList;
            that.pages.page++;
            that.loading_goods = false;
            for (var i in that.list) {
              if (that.list[i].store != null) {
                var liststaff = that.list[i].store.staff;
                for (var j in liststaff) {
                  var liststaffpic = liststaff[j].thumb;
                  if (liststaffpic.indexOf("http") == -1) {
                    that.list[i].store.staff[j].staffthumb =
                      that.$publicData.imgUrl + liststaffpic;
                  } else {
                    that.list[i].store.staff[j].staffthumb = liststaffpic;
                  }
                }
              }
            }
          } else {
            that.finished_goods = true;
          }
          // console.log("list", that.list);
        } else {
          that.finished_goods = true;
        }
      });
    },
    getLaTableList() {
      var that = this;
      var params = {
        token: that.$store.getters.token,
        page: that.pages.page,
        size: that.pages.size
      };
      if (that.curTab != -1) {
        params.status = that.curTab;
      }
      that.$api.member.preOrderList(params).then(res => {
        if (res.code == 200) {
          var goodsList = res.result.list;
          if (goodsList && goodsList.length != 0) {
            // that.list = that.list.concat(goodsList);
            that.list = goodsList;
            that.pages.page++;
            that.loading_goods = false;
            for (var i in that.list) {
              if (that.list[i].store != null) {
                var liststaff = that.list[i].store.staff;
                for (var j in liststaff) {
                  var liststaffpic = liststaff[j].thumb;
                  if (liststaffpic.indexOf("http") == -1) {
                    that.list[i].store.staff[j].staffthumb =
                      that.$publicData.imgUrl + liststaffpic;
                  } else {
                    that.list[i].store.staff[j].staffthumb = liststaffpic;
                  }
                }
              }
            }
          } else {
            that.finished_goods = true;
          }
          // console.log("list", that.list);
        } else {
          that.finished_goods = true;
        }
      });
    }
  }
};
</script>

<style scoped>
/* .appointment_record{
  padding-top: 1.3rem;
  background: #f3f4f9;
}
.appointment_record .van-nav-bar{
  position: absolute;
  width: 100%;
  top: 0;
  background: transparent;
}
.appointment_record .van-button {
  height: auto;
}
.appointment_record .y-con-admin {
  width: 86%;
  float: left;
  margin-left: 4%;
  margin-bottom: 20px;
  background-color: #f3f4f9;
  padding: 10px 3%;
  border-radius: 8px;
}
.appointment_record .y-con-admin_img {
  width: 35px;
  height: 35px;
  float: left;
  margin-right: 8px;
}
.appointment_record .y-con-admin p {
  float: left;
}
.appointment_record .y-con-admin p span {
  width: 100%;
  float: left;
  font-size: 0.35rem;
  color: #000;
}
.appointment_record .y-con-admin p i {
  width: 100%;
  float: left;
  font-size: 0.35rem;
  color: #999;
}
.appointment_record .y-appo-qx {
  width: 90%;
  float: left;
  padding: 12px 5%;
  border-top: 1px #eeeeee solid;
}
.appointment_record .y-book-listrxia {
  width: 92%;
  float: left;
  padding: 14px 15px;
}
.appointment_record .y-book-listrxia1 {
  padding: 0px 0px 10px 0px;
}
.appointment_record .y-book-listrxia span {
  font-size: 0.35rem;
  color: #000;
  padding: 5px 0px;
}
.appointment_record .y-book-listrxia p {
  font-size: 0.35rem;
  color: #999;
  padding: 5px 0px;
}

.appointment_record ::v-deep .van-tabs__line {
  background-color: transparent;
}
.appointment_record ::v-deep .van-tab--active {
  font-size: 0.5rem;
  font-weight: 600;
}
.appointment_record .van-tabs__line {
  background-color: transparent !important;
}
.appointment_record .y_add_storage {
  background-color: #f3f4f9;
  min-height: 95vh;
  float: left;
  width: 100%;
  padding-bottom: 0.5rem;
}
.appointment_record .y_storage_list {
  padding: 0 15px;
}
.appointment_record .y_storage_list .y_storage_list_item {
  width: 100%;
  float: left;
  margin: 8px 0;
  border-radius: 7.5px;
  background-color: #fff;
  box-shadow: 0px 2px 5px #eeeeee;
}
.appointment_record .y_storage_no {
  height: 45px;
  line-height: 45px;
  padding: 0 12px;
  font-size: 0.35rem;
  color: #000;
  padding-right: 0;
  border-bottom: 1px #eeeeee solid;
  position: relative;
}
.appointment_record .y_storage_no div {
  float: left;
}

.appointment_record .y_storage_no_tag_zb {
  background-color: #5cc6da;
}
.appointment_record .y_storage_no_tag_db {
  background-color: #7fc89e;
}
.appointment_record .y_storage_no_num {
  margin-left: 8px;
  font-size: 0.35rem;
  line-height: 21px;
}
.appointment_record .y_storage_no_state {
  padding: 5px 14px;
  font-size: 0.35rem;
  margin-top: 10px;
  float: right;
  line-height: 13px;
  border: 1px #eee solid;
  border-radius: 25px 0 0 25px;
}
.appointment_record .y_storage_no_state_wait {
  border: 1px #f54749 solid;
  color: #f54749;
  border-right: none;
}
.appointment_record .y_storage_no_state_finish {
  border: 1px #35b6d8 solid;
  color: #35b6d8;
  border-right: none;
}
.appointment_record .y_stirage_info {
  padding-left: 12px;
  padding: 15px 12px;
}
.appointment_record .y_stirage_info_name {
  font-size: 0.35rem;
  line-height: 18px;
}
.appointment_record .y_stirage_info_time {
  font-size: 0.35rem;
  color: #999;
  padding: 3px 0px;
}
.appointment_record .y_new_add_storage {
  width: 100%;
  background: linear-gradient(to right, #35b6d8, #52d3c5);
  border: none;
  margin-top: 15px;
} */
</style>