import Vue from "vue";
import Router from "vue-router";

import Iframe from "./page/Iframe.vue";

//组件模块
import login from "./page/login/login.vue";
import login_wx from "./page/login/login_wx.vue";
import login_phone from "./page/login/login_phone.vue";
import setup from "./page/home/setup.vue";
import setup_edit from "./page/home/setup-edit.vue";
import edit_address from "./page/home/edit_address.vue"; //收件地址
import news_address from "./page/home/news_address.vue"; //新增收件地址
import new_address from "./page/home/new_address.vue"; //新增收件地址2
import home from "./page/home/home.vue";

import shopping_mall from "./page/home/shopping_mall.vue"; //捷捷商城
import goodsList from "./page/mall/goodsList.vue"; //商品列表
import goods_details from "./page/mall/goods_details.vue"; //商品详情
import shopcar from "./page/mall/shopcar.vue"; //购物车
import goods_evaluate from "./page/mall/goods_evaluate.vue"; //商品评价
import goods_order from "./page/mall/goods_order.vue"; //填写订单
import paySuccess from "./page/mall/paySuccess.vue"; //订单支付成功

import door from "./page/home/door.vue"; //上门配镜
import service_process from "./page/home/service_process.vue"; //服务流程
import news_list from "./page/home/news_list.vue"; //爱眼护眼列表
import news_info from "./page/home/news_info.vue"; //爱眼护眼详情
import vip from "./page/home/vip.vue"; //会员
import community from "./page/home/community.vue"; //种草社区
import community_info from "./page/home/community_info.vue"; //种草社区详情
import community_add from "./page/home/community_add.vue"; //种草社区详情

import pre from "./page/pre/pre.vue"; //预约首页
import appointment_record from "./page/pre/appointment_record.vue"; //预约记录
import appointment_details from "./page/pre/appointment_details.vue"; //预约记录详情
import optometrist from "./page/pre/optometrist.vue"; //预约记录验光师详情(还没写 没找到入口)
import yan_details from "./page/pre/yan_details.vue"; //视力档案
import optometry from "./page/optometry/optometry.vue"; //视力档案
import optometry_infor from "./page/optometry/optometry_infor.vue"; //视力档案详情
import optometry_revise from "./page/optometry/optometry_revise.vue"; //修改视力档案
import optometry_add from "./page/optometry/optometry_add.vue"; //视力档案新增
import optometry_evaluate from "./page/optometry/optometry_evaluate.vue"; //视力档案评价
import optometry_wateval from "./page/optometry/optometry_wateval.vue"; //视力档案查看评价
import order_list from "./page/order/order_list.vue"; //我的订单列表
import order_details from "./page/order/order_details.vue"; //配镜订单详情
import myorder_details from "./page/order/myorder_details.vue"; //我的订单详情
import order_comment from "./page/order/order_comment.vue"; //我的订单评论
import after_sales from "./page/order/after_sales.vue"; //我的订单售后
import order_evaluate from "./page/order/order_evaluate.vue"; //我的订单查看评价
import service_list from "./page/service/service_list.vue"; //售后列表
import service_details from "./page/service/service_details.vue"; //售后详情
import comment_list from "./page/comment/comment_list.vue"; //评价列表
import coupons from "./page/coupons/coupons.vue"; //领券中心
import myCoupon from "./page/coupons/myCoupon.vue"; //领券中心
import sendCoupons from "./page/coupons/sendCoupons.vue"; //领取优惠券
import integral from "./page/integral/integral.vue"; //积分首页
import integral_task from "./page/integral/integral_task.vue"; //积分任务integral_exchange
import integral_exchange from "./page/integral/integral_exchange.vue"; //积分兑换
import integral_info from "./page/integral/integral_info.vue"; //积分兑换详情
import integral_add from "./page/integral/integral_add.vue"; //积分兑换详情订单填写
import integral_paysuccess from "./page/integral/integral_paysuccess.vue"; //积分兑换详成功
import invitation from "./page/home/invitation.vue"; //邀请

import sales_details from "./page/other/sales_details.vue"; //评价列表
import receiving_information from "./page/admin/receiving_information.vue"; //收件信息
import tel from "./page/home/tel.vue"; //修改手机号

Vue.use(Router);

export default new Router({
  routes: [
  // 内容的模板
    {
        path: "/",
        component: Iframe,
        meta: {
            requiresAuth: false,
        },
        children: [
          {
            path: "/",
            name: "HelloWorld",
            component: home,
            meta: {
              requiresAuth: false,
              share_config:true,
            },
          },
          {
            path: "/login",
            name: "login",
            component: login,
            meta: {
              requiresAuth: false,
              share_config:true,
            },
          },
          {
            path: "/login_wx",
            name: "login_wx",
            component: login_wx,
            meta: {
              requiresAuth: false,
              share_config:true,
            },
          },
          {
            path: "/login_phone",
            name: "login_phone",
            component: login_phone,
            meta: {
              requiresAuth: false,
              share_config:true,
            },
          },
          {
            path: "/setup",
            name: "setup",
            component: setup,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/address",
            name: "edit_address",
            component: edit_address,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/news_address",
            name: "news_address",
            component: news_address,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/new_address",
            name: "new_address",
            component: new_address,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/setup_edit",
            name: "setup_edit",
            component: setup_edit,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/home",
            name: "home",
            component: home,
            meta:{
              requiresAuth: true,
              keepAlive: true,
              share_config:true,
            }
          },
          {
            path: "/shopping_mall",
            name: "shopping_mall",
            component: shopping_mall,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/goodsList",
            name: "goodsList",
            component: goodsList,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/goods_details",
            name: "goods_details",
            component: goods_details,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/shopcar",
            name: "shopcar",
            component: shopcar,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/goods_evaluate",
            name: "goods_evaluate",
            component: goods_evaluate,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/goods_order",
            name: "goods_order",
            component: goods_order,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/paySuccess",
            name: "paySuccess",
            component: paySuccess,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/door",
            name: "door",
            component: door,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/service_process",
            name: "service_process",
            component: service_process,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/news_list",
            name: "news_list",
            component: news_list,
            meta: {
              requiresAuth: false,
              keepAlive: true, //此组件需要被缓存
              share_config:false,
            },
          },
          {
            path: "/news_info",
            name: "news_info",
            component: news_info,
            meta: {
              requiresAuth: false,
              share_config:false,
            },
          },
          {
            path: "/vip",
            name: "vip",
            component: vip,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/community",
            name: "community",
            component: community,
            meta: {
              requiresAuth: true,
              keepAlive: true, //此组件需要被缓存
              share_config:true,
            },
          },
          {
            path: "/community_info",
            name: "community_info",
            component: community_info,
            meta: {
              requiresAuth: true,

            },
          },
          {
            path: "/community_add",
            name: "community_add",
            component: community_add,
            meta: {
              requiresAuth: true,

            },
          },

          {
            path: "/appointment_record",
            name: "appointment_record",
            component: appointment_record,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/pre",
            name: "pre",
            component: pre,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/optometry",
            name: "optometry",
            component: optometry,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/optometry_infor",
            name: "optometry_infor",
            component: optometry_infor,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/optometry_add",
            name: "optometry_add",
            component: optometry_add,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/optometry_revise",
            name: "optometry_revise",
            component: optometry_revise,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/optometry_evaluate",
            name: "optometry_evaluate",
            component: optometry_evaluate,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/optometry_wateval",
            name: "optometry_wateval",
            component: optometry_wateval,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/appointment_details",
            name: "appointment_details",
            component: appointment_details,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/optometrist",
            name: "optometrist",
            component: optometrist,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/yan_details",
            name: "yan_details",
            component: yan_details,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/order_list",
            name: "order_list",
            component: order_list,
            meta: {
              requiresAuth: true,
              keepAlive: true, //此组件需要被缓存
              share_config:true,
            },
          },
          {
            path: "/order_details",
            name: "order_details",
            component: order_details,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/myorder_details",
            name: "myorder_details",
            component: myorder_details,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/after_sales",
            name: "after_sales",
            component: after_sales,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/order_evaluate",
            name: "order_evaluate",
            component: order_evaluate,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/service_list",
            name: "service_list",
            component: service_list,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/service_details",
            name: "service_details",
            component: service_details,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/order_comment",
            name: "order_comment",
            component: order_comment,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/comment_list",
            name: "comment_list",
            component: comment_list,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },

          {
            path: "/sales_details",
            name: "sales_details",
            component: sales_details,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/receiving_information",
            name: "receiving_information",
            component: receiving_information,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/tel",
            name: "tel",
            component: tel,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/coupons",
            name: "coupons",
            component: coupons,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/myCoupon",
            name: "myCoupon",
            component: myCoupon,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/sendCoupons",
            name: "sendCoupons",
            component: sendCoupons,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/integral",
            name: "integral",
            component: integral,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/integral_task",
            name: "integral_task",
            component: integral_task,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/integral_exchange",
            name: "integral_exchange",
            component: integral_exchange,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/integral_info",
            name: "integral_info",
            component: integral_info,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/integral_add",
            name: "integral_add",
            component: integral_add,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/integral_paysuccess",
            name: "integral_paysuccess",
            component: integral_paysuccess,
            meta: {
              requiresAuth: true,
              share_config:true,
            },
          },
          {
            path: "/invitation",
            name: "invitation",
            component: invitation,
            meta: {
              requiresAuth: true,
              // share_config:true,
            },
          },
      ]
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) { //如果savedPosition存在，滚动条会自动跳到记录的值的地方
      return savedPosition;
    } else {
      return { x: 0, y: 0 };//savedPosition也是一个记录x轴和y轴位置的对象
    }
  },
  // scrollBehavior(to, from, savedPosition) {
  //   return { x: 0, y: 0 };
  // },
});
