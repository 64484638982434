<template>
  <div class="integraltask">
    <!-- :class="{back:back}" -->
    <van-nav-bar
      :z-index="99"
      :fixed="false"
      :border="false"
      title="积分任务"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y-integral_task-all">
      <div class="y-integral_task">
        <p>累计获得积分</p>
        <span>{{scoreList.total_score}}</span>
        <!-- <i>还差{{2000-scoreList.total_score}}积分升级V6</i> -->
      </div>
      <div class="y-integral-2">
        <div class="y-integral-2-list">
          <div class="y-integral-2-info" v-for="(item,index) in scoreList.list" :key="index">
            <img :src="item.score_task.scorethumb" />
            <div class="y-integral-2-info-c">
              <p>
                {{item.score_task.name}}
                <i>+{{item.score_task.score_num}}积分</i>
              </p>
              <van-slider
                v-if="item.score_task_id ==2 ||item.score_task_id == 3||item.score_task_id == 4"
                v-model="item.curr_value"
                active-color="#EE904F"
                disabled
                :max="item.total_value"
                class="y-in-f-jt"
              >
                <template></template>
              </van-slider>
              <span>{{item.score_task.desc}}</span>
            </div>
            <div class="y-integral-2-info-r">
              <!-- :to="item.score_task.btn_path" -->
              <van-button
                v-if="item.score_task_id !=1"
                round
                type="info"
                size="small"
                color="#EE904F"
                @click="toPj(item.score_task.btn_path,item.score_task_id,item.id)"
              >{{item.score_task.btn_name}}</van-button>
              <van-button
                v-if="item.score_task_id ==1 &&item.is_done == 0"
                round
                type="info"
                size="small"
                color="#EE904F"
                @click="toPj(item.score_task.btn_path,item.score_task_id,item.id)"
              >{{item.score_task.btn_name}}</van-button>
              <van-button
                v-if="item.score_task_id ==1 && item.is_done == 1 && item.is_get_score == 0"
                round
                type="info"
                size="small"
                color="red"
                @click="toLing(item.id,index)"
              >待领取</van-button>
              <!-- @click="show_rwPopup(index)" -->
              <van-button
                v-if="item.score_task_id ==1 &&item.is_done == 1 && item.is_get_score == 1"
                round
                type="info"
                size="small"
                color="#999"
              >已完成</van-button>
            </div>
          </div>

          <!-- <div class="y-integral-2-info">
            <img src="../../assets/images/integral2.png" />
            <div class="y-integral-2-info-c">
              <p>
                完善资料
                <i>+10积分</i>
              </p>
              <span>完善个人资料可获得10积分</span>
            </div>
            <div class="y-integral-2-info-r">
              <van-button round type="info" size="small" color="#EE904F" @click="show_rwPopup">完善信息</van-button>
            </div>
          </div>

          <div class="y-integral-2-friends y-integral-2-info">
            <img src="../../assets/images/integral3.png" />
            <div class="y-integral-2-info-c">
              <p>
                邀请5个好友
                <i>+100积分</i>
              </p>
              <van-slider
                v-model="value"
                active-color="#EE904F"
                disabled
                :max="5"
                class="y-in-f-jt"
              >
                <template></template>
              </van-slider>
              <span>已邀请2人，还差3人</span>
            </div>
            <div class="y-integral-2-info-r">
              <van-button round type="info" size="small" color="#EE904F">邀请好友</van-button>
            </div>
          </div>
          <div class="y-integral-2-info">
            <img src="../../assets/images/integral4.png" />
            <div class="y-integral-2-info-c">
              <p>
                评价商品
                <i>+5积分</i>
              </p>
              <span>评价商品可获得5积分</span>
            </div>
            <div class="y-integral-2-info-r">
              <van-button round type="info" size="small" color="#EE904F">去评价</van-button>
            </div>
          </div>-->
        </div>
        <van-popup :close-on-click-overlay="false" v-model="show_rw" class="y-task-all">
          <h2>任务完成</h2>
          <div class="y-task-top">
            <p>奖励</p>
          </div>
          <div class="y-task-zj">
            <p>+{{scorenum}}</p>
            <img src="../../assets/images/jb.png" />
          </div>
          <div class="y-task-buttom">
            <van-button
              round
              closed
              color="linear-gradient(to right, #F28E26, #FD644F)"
              type="primary"
              @click="show_rw = false"
            >继续做任务赚积分</van-button>
          </div>
        </van-popup>
      </div>
    </div>
  </div>
</template>
 <script>
import { Toast } from "vant";
export default {
  data() {
    return {
      back: false,
      show_rw: false,
      value: 2,
      // slidervalue:0,
      scoreList: {},
      // indexbtn:""
      scorenum: ""
    };
  },
  created: function() {
    this.getScore();
  },
  methods: {
    // 积分列表接口
    getScore() {
      var param = {
        token: this.$store.getters.token,
        status: this.curTab == -1 ? "" : this.curTab
      };
      this.$api.score.memberScoreTaskList(param).then(res => {
        this.scoreList = res.result;
        for (var i in this.scoreList.list) {
          var showscore = this.scoreList.list[i].score_task.thumb;
          if (showscore.indexOf("http") == -1) {
            this.scoreList.list[i].score_task.scorethumb =
              this.$publicData.imgUrl + showscore;
          } else {
            this.scoreList.list[i].score_task.scorethumb = showscore;
          }
          if (this.scoreList.list[i].total_value) {
            // this.slidervalue = this.scoreList.list[i].curr_value;
            // console.log("slidervalue",this.slidervalue);
          }
        }
      });
    },
    //点击待领取
    toLing(id, index) {
      this.scorenum = this.scoreList.list[index].score_task.score_num;
      var params = {
        token: this.$store.getters.token,
        id: id
      };
      this.$api.score.takeTaskScore(params).then(res => {
        if (res.code == 200) {
          this.show_rw = true;
          this.getScore();
        }
      });
    },
    //点击去评价
    toPj(url, score_task_id, id) {
      // if (score_task_id == 0) {
      //   this.$toast("无可评价信息");
      // } else {
      if (score_task_id == 2) {
        if (this.scoreList.notCommentYgOrderId == 0) {
          this.$toast("无可评价信息");
        } else {
          this.$router.push({
            path: "/optometry_evaluate",
            query: {
              id: id,
              creatid: this.scoreList.notCommentYgOrderId
            }
          });
        }
      }
      if (score_task_id == 4) {
        if (this.scoreList.notCommentAfterSaleId == 0) {
          this.$toast("无可评价信息");
        } else {
          this.$router.push({
            path: "/service_details",
            query: {
              id: this.scoreList.notCommentAfterSaleId
            }
          });
        }
      }
      if (score_task_id == 3) {
        if (this.scoreList.notCommentZjOrderId == 0) {
          this.$toast("无可评价信息");
        } else {
          this.$router.push({
            path: "/order_comment",
            query: {
              id: this.scoreList.notCommentZjOrderId,
              status: 0,
              type: 2
            }
          });
        }
      }
      // }
    },
    show_rwPopup(index) {
      // this.indexbtn = id;
      // console.log(index);
      this.scorenum = this.scoreList.list[index].score_task.score_num;
      this.show_rw = true;
    }
    //   onChange(value) {
    //   Toast("当前值：" + value);
    // },
    // TabBar() {
    //   var userOld = document.getElementsByClassName("y-integral-2-list")[0];
    //   var that = this;
    //   if (userOld.scrollHeight - scrollY <= 500) {
    //     that.back = true;
    //   } else {
    //     that.back = false;
    //   }
    // }
  },
  mounted() {
    // window.addEventListener("scroll", this.TabBar);
  }
};
</script>
<style scoped>
/* .integraltask .van-nav-bar {
  position: absolute;
  width: 100%;
}
.integraltask .y-task-buttom .van-button {
  width: 240px;
}
.integraltask .y-task-buttom {
  width: 240px;
  margin: 0 auto;
}
.integraltask .y-task-zj {
  width: 150px;
  float: left;
  margin: 0px 70px 25px 70px;
}
.integraltask .y-task-zj p {
  font-size: 42px;
  color: #c36200;
  font-weight: bold;
  float: left;
  margin-right: 5px;
}
.integraltask ::v-deep .y-task-zj img {
  width: 33px !important;
  height: auto !important;
  float: left;
  margin-top: 8px;
}
.integraltask .y-task-all h2 {
  width: 100%;
  float: left;
  font-size: 0.35rem;
  color: #c36200;
  font-weight: bold;
  margin-top: 35px;
}
.integraltask .y-task-top p {
  color: #e19c39;
}
.integraltask .y-task-top {
  background-image: url(../../assets/images/coupons-bg4.png);
  background-size: 100%;
  background-repeat: no-repeat;
  width: 162px;
  height: 40px;
  float: left;
  margin: 40px 40px 5px 40px;
  text-align: center;
  font-size: 0.35rem;
}
.integraltask .y-integral-2-info .van-popup--center {
  background-color: transparent !important;
}
.integraltask .y-task-all {
  padding: 30px 23px 20px 23px;
  width: 249px;
  height: 305px;
  border-radius: 8px;
  text-align: center;
  background-image: url(../../assets/images/coupons-bg3.png);

  background-size: 100%;
  background-repeat: no-repeat;
}

.integraltask ::v-deep .y-in-f-jt .van-slider__button {
  display: none;
}
.integraltask .y-integral-2-friends .y-integral-2-info-c span {
  font-size: 0.35rem !important;
}
.integraltask .van-slider--disabled {
  opacity: 1;
}
.integraltask .y-in-f-jt {
  width: 120px;
  float: left;
  height: 3px;
  margin-top: 6px;
}

.integraltask .y-integral_task {
  width: 100%;
  float: left;
  text-align: center;
  margin: 130px 0px 60px 0px;
}
.integraltask .y-integral_task p,
.integraltask .y-integral_task span,
.integraltask .y-integral_task i {
  width: 100%;
  float: left;
  color: #fff;
}
.integraltask .y-integral_task p {
  font-size: 0.35rem;
  color: #fff;
}
.integraltask .y-integral_task span {
  font-size: 40px;
  padding: 13px 0px 26px 0px;
}
.integraltask .y-integral_task i {
  font-size: 0.35rem;
  color: #fcada4;
}

.integraltask .y-integral-2-info-r .van-button {
  height: 28px;
  padding: 4px 12px;
  margin-top: 13px;
}
.integraltask .y-integral-2-info-c span {
  font-size: 0.35rem;
  color: #999;
  margin-top: 6px;
}
.integraltask .y-integral-2-info-c p i {
  font-size: 0.35rem;
  font-weight: normal;
  color: #ee904f;
}
.integraltask .y-integral-2-info-c p {
  font-weight: bold;
  font-size: 0.35rem;
  color: #000;
  margin-top: 8px;
}
.integraltask .y-integral-2-info-c p,
.integraltask .y-integral-2-info-c span {
  width: 100%;
  float: left;
}
.integraltask .y-integral-2-info-c {
  width: 55%;
  float: left;
}
.integraltask .y-integral-2-info-r {
  float: right;
}
.integraltask .y-integral-2-info img {
  width: 55px;
  height: 55px;
  float: left;
  margin-right: 10px;
}
.integraltask .y-integral-2-info {
  width: 93%;
  padding: 3%;
  float: left;
  border: 1px #eeeeee solid;
  margin: 5px 0px;
  border-radius: 5px;
}
.integraltask .y-integral-2-list {
  width: 94%;
  float: left;
  margin: 0px 3% 10px 3%;
}
.integraltask .y-integral-2 {
  width: 100%;
  float: left;
  margin: 5px 0px;
}

.integraltask .y-integral_task-all {
  background-image: url(../../assets/images/integral-bg2.png);
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  float: left;
}
.integraltask ::v-deep .back {
  background: #fff !important;
}
.integraltask .back .van-icon {
  color: #000 !important;
}
.integraltask ::v-deep .back .van-nav-bar__title {
  color: #000 !important;
}
.integraltask .van-nav-bar {
  background-color: transparent;
}
.integraltask .van-icon {
  color: #ffffff !important;
}
.integraltask ::v-deep .van-nav-bar__title {
  color: #ffffff !important;
} */
</style>