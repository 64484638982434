/**
 * 个人中心接口
 */
import axios from "../http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块，把请求字段直接映射过来

const member = {
  //获取个人信息
  userInfo(params) {
    return axios.get("/api/member/userInfo", { params: params });
  },
  //更新头像昵称
  editUserInfo(params) {
    return axios.post("/api/member/editUserInfo", qs.stringify(params));
  },
  //更新手机号
  editUserPhone(params) {
    return axios.post("/api/member/editUserPhone", qs.stringify(params));
  },
   // 上传图片
  uploadTu (params) {
    return axios.post('/api/common/uploadBase64', qs.stringify(params))
  },
  // 获取预约目的
  getPreOrderPosition(params) {
    return axios.get('/api/member/getPreOrderPosition',  { params: params });
  },
  // 预约-获取员工信息
  staffInfo(params) {
    return axios.get('/api/staffInfo',  { params: params });
  },
  // 预约订单列表
  preOrderList(params) {
    return axios.post('/api/member/preOrderList', qs.stringify(params))
  },
  // 提交预约
  submitPreOrderNew(params) {
    return axios.post('/api/member/submitPreOrderNew', qs.stringify(params))
  },
  // 取消预约
  cancelPreOrder(params){
    return axios.post('/api/member/cancelPreOrder', qs.stringify(params))
  },
  // 查询预约详情
  preOrderDetail(params){
    return axios.post('/api/member/preOrderDetail', qs.stringify(params))
  },
  // 验光记录
  createYgRecord(params){
    return axios.post('/api/member/createYgRecord', qs.stringify(params))
  },
  // 验光记录详情
  ygRecordDetail(params){
    return axios.post('/api/member/ygRecordDetail', qs.stringify(params))
  },
  // 邀请有礼
  memberExtendList(params){
    return axios.post('/api/member/memberExtendList', qs.stringify(params))
  },
  // 预约时间
  preDateTime(params){
    return axios.post('/api/member/preDateTime', qs.stringify(params))
  },
  // 奖励券/积分
  doneEventThing(params){
    return axios.post('/api/member/doneEventThing', qs.stringify(params))
  },
};

// 导出接口
export default member;
