<template>
  <div class="door">
    <van-nav-bar
      :fixed="false"
      :placeholder="true"
      :border="false"
      title="模式介绍"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y-my-door-banner f-l">
      <!-- <h2>
        <span>你的视界</span>
        <p>我们来帮忙</p>
        <van-button type="primary" size="mini">立即预约</van-button>
      </h2>-->
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in bannerList" :key="index">
            <img :src="item.bannerthumb">
        </van-swipe-item>
        <!-- <van-swipe-item>2</van-swipe-item>
        <van-swipe-item>3</van-swipe-item>
        <van-swipe-item>4</van-swipe-item> -->
      </van-swipe>
    </div>
    <div class="y-my-door-xia f-l">
      <div class="y-my-door-list" v-for="(item,index) in doorList" :key="index">
        <div class="y-my-door-list-top">
          <p>
            <i>{{index + 1}}</i>
          </p>
          <span>
            {{item.question}}
            <!-- <van-divider /> -->
          </span>
        </div>
        <div class="y-my-door-list-xia">
          <p>{{item.answer}}</p>
        </div>
      </div>

      <!-- <div class="y-my-door-list">
            <div class="y-my-door-list-top">
                <p><i>1</i></p>
                <span>此处为捷捷模式问答标题
                    <van-divider />
                </span>
            </div>
            <div class="y-my-door-list-xia">
                <p>
                    此处为捷捷配镜运营模式说明，可自定义问答内容，加深顾客对捷捷配镜上门服务的认知。
                </p>
            </div>
        </div>
        <div class="y-my-door-list">
            <div class="y-my-door-list-top">
                <p><i>2</i></p>
                <span>仓库怎么分配？
                    <van-divider />
                </span>
            </div>
            <div class="y-my-door-list-xia">
                <p>
                    仓库以总库和分库（车辆）的方式设置，按地区设置总库，每一个车（或门店）为
                    一个分库，网上商场店可以直接挂钩总库。总库与分库之间调拨方式流转，与厂家
                    采购退换货不经过分库，分库只起支撑销售和记录的作用，销售开单时如果总库有
                    货也可直接从总库出货加工，最好也能自动同时给分库调拨此货。
                </p>
            </div>
        </div>
        <div class="y-my-door-list">
            <div class="y-my-door-list-top">
                <p><i>3</i></p>
                <span>加工店是到地区总部还是在车上或者店里进行？
                    <van-divider />
                </span>
            </div>
            <div class="y-my-door-list-xia">
                <p>
                    仓库以总库和分库（车辆）的方式设置，按地区设置总库，每一个车（或门店）为
                    一个分库，网上商场店可以直接挂钩总库。总库与分库之间调拨方式流转，与厂家
                    采购退换货不经过分库，分库只起支撑销售和记录的作用，销售开单时如果总库有
                    货也可直接从总库出货加工，最好也能自动同时给分库调拨此货。
                </p>
            </div>
        </div>
        <div class="y-my-door-list">
            <div class="y-my-door-list-top">
                <p><i>4</i></p>
                <span>库存在车上的话 门店或者地区总部是否有仓储？
                    <van-divider />
                </span>
            </div>
            <div class="y-my-door-list-xia">
                <p>
                    总库需要有仓储，由总库进货后调拨给分库，分库销售后再总库补货（调拨方式）
                </p>
            </div>
        </div>
        <div class="y-my-door-list">
            <div class="y-my-door-list-top">
                <p><i>5</i></p>
                <span>自由配镜（是否提供线上自主选镜服务）？
                    <van-divider />
                </span>
            </div>
            <div class="y-my-door-list-xia">
                <p>
                    总库需要有仓储，由总库进货后调拨给分库，分库销售后再总库补货（调拨方式）
                </p>
            </div>
      </div>-->
    </div>
     <!-- 加载框 -->
      <!-- <van-popup class="jiazai" v-model="showloading">
        <van-loading type="spinner" />
      </van-popup> -->
       <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showloading:false,
      doorList: [],
      bannerList:[],
      bannerthumb:""
    };
  },
  created: function() {
    this.getModeServiceList();
    this.getBannerList();
  },
  methods: {
    //服务-列表接口
    getModeServiceList() {
      this.showloading =true;
      var params = {
        token: this.$store.getters.token
      };
      this.$api.enjoylife.modeServiceList(params).then(res => {
        if (res.code == 200) {
          this.showloading=false;
          this.doorList = res.result.list;
        }
      });
    },
    //轮播图-查询接口
    getBannerList() {
      this.showloading=true;
      var params = {
        token: this.$store.getters.token,
        type:2
      };
      this.$api.enjoylife.bannerList(params).then(res => {
        if (res.code == 200) {
          this.showloading=false;
          this.bannerList = res.result.list;
          for(var i in this.bannerList){
            var bannerPic = this.bannerList[i].thumb;
            if(bannerPic.indexOf("http")==-1){
              this.bannerList[i].bannerthumb = this.$publicData.imgUrl + bannerPic
            }else{
              this.bannerList[i].bannerthumb =bannerPic;
            }
          }
        }
      });
    },
  }
};
</script>
<style>
/* .door .y-my-door-list-top span .van-divider {
  border-bottom: 4px #e5e5e5 solid;
  margin: -5px 0px 0px 0px;
}
.door .y-my-door-list-top {
  width: 100%;
  float: left;
}
.door .y-my-door-list-xia {
  margin-left: 30px;
  font-size: 0.34rem;
  color: #999;
  line-height: 24px;
  margin-top: 10px;
  float: left;
}
.door .y-my-door-list-top span {
  font-size: 0.34rem;
  margin-left: 10px;
  font-weight: 600;
  float: left;
}
.door .y-my-door-list-top p {
  width: 20px;
  float: left;
  height: 20px;
  background-image: linear-gradient(to bottom, #7edaf2, #35b6d8);
  color: #ffffff;
  font-size: 0.34rem;
  border-radius: 0px 7px 0px 7px;
  text-align: center;
}
.door .y-my-door-list-top p i {
  margin-top: 5px;
  line-height: 29px;
}
.door .y-my-door-list {
  width: 100%;
  float: left;
  margin-bottom: 16px;
}
.door .y-my-door-xia {
  width: 94%;
  margin-left: 3%;
  margin-top: 15px;
}
.door .y-my-door-banner {
  width: 94%;
  margin-left: 3%;
  margin-top: 15px;
  height: 150px;
  background-repeat: no-repeat;
  margin: 0 0.4rem;
}
.door .y-my-door-banner .van-swipe-item{
    width: 100%;
    height: 3.88rem;
}
.door .y-my-door-banner .van-swipe-item img{
    margin-left: 10%;
    width: 80%;
    height: 100%;
    border-radius: 0.35rem;
}
.door .y-my-door-banner h2 {
  padding: 30px 20px 0px 20px;
  color: #ffffff;
}
.door .y-my-door-banner h2 span {
  font-size: 0.34rem;
  letter-spacing: 3px;
}
.door .y-my-door-banner h2 p {
  font-size: 0.34rem;
  font-weight: bold;
  letter-spacing: 3px;
  color: #ffffff;
}
.door .y-my-door-banner .van-button {
  background-color: #ffffff;
  border: none;
  color: #4abddc;
  margin-top: 12px;
  padding: 3px 8px;
  border-radius: 10px 1px 10px 1px;
}
.door .y-my-door-banner .van-button span {
  font-size: 0.34rem;
  letter-spacing: 0px;
} */
</style>