import { LOGIN, LOGOUT } from '.././mutation_types'
import { getToken, setToken, removeToken } from '../../utils/auth'

// localStorage 保存数据时间更久，不会因为关闭标签而丢失

const state = {
  user_id: localStorage.getItem('user_id') || 0,
  user_name: localStorage.getItem('user_name') || '未登录',
  phone: localStorage.getItem('phone') || '',
  token: getToken() || '',
  thumb: localStorage.getItem('thumb') || '',
  number: localStorage.getItem('number') || '',
  role_id: localStorage.getItem('role_id') || 0,
  result: localStorage.getItem('result') || {}
}

const getters = {
  user_id: state => {
    return state.user_id
  },
  user_name: state => {
    return state.user_name
  },
  phone: state => {
    return state.phone
  },
  token: state => {
    return state.token
  },
  thumb: state => {
    return state.thumb
  },
  number: state => {
    return state.number
  },
  role_id: state => {
    return state.role_id
  },
  result: state => {
    return state.result
  }
}

const mutations = {
  
  [LOGIN](state, data) {
    localStorage.setItem('user_id', data.id); // 设置localStorage
    localStorage.setItem('user_name', data.name);
    localStorage.setItem('phone', data.phone);
    localStorage.setItem('token', data.token);
    localStorage.setItem('thumb', data.thumb);
    localStorage.setItem('number', data.number);
    localStorage.setItem('role_id', data.role_id);
    localStorage.setItem('result', data.result);
    state.user_id = data.id;
    state.user_name = data.name;
    state.phone = data.phone;
    state.token = data.token;
    state.thumb = data.thumb;
    state.number = data.number;
    state.role_id = data.role_id;
    state.result = data.result;
    setToken(state.token);
  },
  [LOGOUT](state) {
    localStorage.removeItem('user_id'); // 移除localStorage
    localStorage.removeItem('user_name');
    localStorage.removeItem('phone');
    localStorage.removeItem('token');
    localStorage.removeItem('thumb');
    localStorage.removeItem('number');
    localStorage.removeItem('role_id');
    localStorage.removeItem('result');
    state.user_id = 0
    state.user_name = '';
    state.phone = '';
    state.token = '';
    state.thumb = '';
    state.number = '';
    state.result = {};
    removeToken(state.token);
  }
}

export default {
  state,
  mutations,
  getters
}
