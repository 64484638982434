<template>
  <!-- 购物车 -->

  <div class="goods_shopcar">
    <van-nav-bar :fixed="false" title="购物车" :border="false" left-arrow @click-left="$router.back()">
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <!-- 购物车 -->
    <van-checkbox-group v-model="resultbox" ref="checkboxGroup" @change="changebox">
      <!-- 商品 -->
      <van-swipe-cell class="clearfix" v-for="(item,index) in goodsCarDetail" :key="index">
        <van-checkbox checked-color="#35B6D8" class="f-l" :name="item.id" label-disabled></van-checkbox>
        <van-card
          :price="item.goods_spec.member_discount"
          :origin-price="item.goods_spec.member_price"
          :desc="item.goods_spec.goods_spec_name"
          :title="item.goods.title"
          class="goods-card f-l"
          :thumb="item.goods.goodsthumb"
        />
        <van-stepper v-model="item.num" integer @change="changenum(item.id,item.num)" />
        <template #right>
          <van-button
            square
            text="删除"
            type="danger"
            class="delete-button"
            @click="todelete(index,item.id)"
          />
        </template>
      </van-swipe-cell>
    </van-checkbox-group>
    <!-- 全选 -->
    <div class="footer">
      <van-submit-bar :price="totalmon" button-text="去结算" @submit="onSubmit">
        <van-checkbox checked-color="#35B6D8" v-model="checkedall" @click="checkAll">全选</van-checkbox>
        <span class="discount">
          共优惠:￥
          <span>{{youmon.toFixed(2)}}</span>
        </span>
      </van-submit-bar>
    </div>
    <!-- 加载框 -->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showloading: false,
      goodsCarDetail: [],
      goodsthumb: "",
      totalmon: 0,
      youmon: 0,
      cartids: [],
      resultbox: [],
      checkedall: false,
      discount: 90.83,
      shopcar: [
        {
          name: "a",
          img: require("../../assets/images/banner_1.png"),
          title: "米家防蓝光眼镜钛轻盈 防辐射眼镜平面无度数眼镜蓝光阻隔率>80%",
          price: "223.2",
          del: "270",
          desc: "防蓝光1.61非球面镜片0-800 | 度数留言",
          value: 1
        },
        {
          name: "b",
          img: require("../../assets/images/banner_1.png"),
          title: "米家防蓝光眼镜钛轻盈 防辐射眼镜平面无度数眼镜蓝光阻隔率>80%",
          price: "223.2",
          del: "270",
          desc: "防蓝光1.61非球面镜片0-800 | 度数留言",
          value: 2
        },
        {
          name: "c",
          img: require("../../assets/images/banner_1.png"),
          title: "米家防蓝光眼镜钛轻盈 防辐射眼镜平面无度数眼镜蓝光阻隔率>80%",
          price: "223.2",
          del: "270",
          desc: "防蓝光1.61非球面镜片0-800 | 度数留言",
          value: 3
        }
      ]
    };
  },
  created: function() {
    this.getGoodsCarDetail();
  },
  methods: {
    //查看购物车接口
    getGoodsCarDetail() {
      this.showloading = true;
      var param = {
        token: this.$store.getters.token
      };
      this.$api.mall.goodsCarDetail(param).then(res => {
        this.showloading = false;
        if (res.code == 200) {
          this.goodsCarDetail = res.result;
          //图片格式
          for (var i in this.goodsCarDetail) {
            var pic = this.goodsCarDetail[i].goods.thumb;
            this.goodsCarDetail[i].goods.goodsthumb = "";
            if (pic.indexOf("http") == -1) {
              this.goodsCarDetail[i].goods.goodsthumb =
                this.$publicData.imgUrl + pic;
            } else {
              this.goodsCarDetail[i].goods.goodsthumb = pic;
            }
          }
          if (this.cartids.length == this.goodsCarDetail.length) {
            this.checkedall = true;
            this.allmoney();
          }
          if(this.cartids.length == 0){
             this.checkedall = false;
          }
          // this.totalmon = 0;
          // this.youmon = 0;
          // console.log("aa", this.resultbox);
          var allmon = 0;
          var youMon = 0;
          var youMonArr = [];
          for (var j in this.resultbox) {
            for (var g in this.goodsCarDetail) {
              if (this.resultbox[j] == this.goodsCarDetail[g].id) {
                var nowMon =
                  Number(this.goodsCarDetail[g].goods_spec.member_discount) *
                  Number(this.goodsCarDetail[g].num); //实际金额
                var oriMon =
                  Number(this.goodsCarDetail[g].goods_spec.member_price) *
                  Number(this.goodsCarDetail[g].num); //原来金额
                allmon += nowMon;
                youMon = oriMon - nowMon;
                youMonArr.push(youMon);
              }
            }
          }

          // for (var j in this.resultbox) {
          //   var ind = this.resultbox[j];
          //   var nowMon =
          //     Number(this.goodsCarDetail[ind].goods_spec.member_discount) *
          //     Number(this.goodsCarDetail[ind].num); //实际金额
          //   var oriMon =
          //     Number(this.goodsCarDetail[ind].goods_spec.member_price) *
          //     Number(this.goodsCarDetail[ind].num); //原来金额
          //   allmon += nowMon;
          //   youMon = oriMon - nowMon;
          //   youMonArr.push(youMon);
          // }

          this.totalmon = allmon * 100;
          this.youmon = 0;
          for (var k in youMonArr) {
            this.youmon += youMonArr[k];
          }
        }
      });
    },
    //   全选
    checkAll() {
      var that = this;
      if (that.checkedall) {
        that.$refs.checkboxGroup.toggleAll(true);
        that.allmoney();
      } else {
        that.$refs.checkboxGroup.toggleAll(false);
        that.totalmon = 0;
        that.youmon = 0;
      }
    },
    //计算总金额
    allmoney() {
      var that = this;
      var money = 0;
      var you = 0;
      var youArr = [];
      for (var i in that.goodsCarDetail) {
        //计算合计金额
        var mon =
          Number(that.goodsCarDetail[i].goods_spec.member_discount) *
          Number(that.goodsCarDetail[i].num); //实际金额
        var monori =
          Number(that.goodsCarDetail[i].goods_spec.member_price) *
          Number(that.goodsCarDetail[i].num); //原价
        money += mon;
        //计算优惠金额
        you = monori - mon;
        youArr.push(you);
      }
      that.totalmon = money * 100;
      // console.log(youArr);
      that.youmon = 0;
      for (var j in youArr) {
        that.youmon += youArr[j];
      }
    },
    // 点击去结算
    onSubmit() {
      var that = this;
      // console.log("去结算",this.cartids.length);
      if (that.cartids.length == 0) {
        that.$toast("请选择商品");
      } else {
        // console.log(this.cartids);
        var param = {
          token: that.$store.getters.token,
          cartids: that.cartids
        };
        that.$api.goodsOrder.cartsCreate(param).then(res => {
          if (res.code == 200) {
            that.$router.push({
              path: "/goods_order",
              query: {
                id: res.result.id
              }
            });
          }
        });
      }
    },
    //步进器改变
    changenum(id, num) {
      // console.log(id);
      // console.log(num);
      var param = {
        token: this.$store.getters.token,
        id: id,
        num: num
      };
      this.$api.mall.goodsCarChangeNum(param).then(res => {
        this.getGoodsCarDetail();
      });
    },
    //点击删除
    todelete(index, id) {
      // 删除勾选项时
      // console.log(this.cartids, id);
      if (this.cartids.indexOf(id) != -1) {
        var cartindex = this.cartids.indexOf(id);
        this.cartids.splice(cartindex, 1);
      }
      // console.log(this.cartids);
      this.showloading = true;
      var param = {
        token: this.$store.getters.token,
        id: id,
        num: 0
      };
      this.$api.mall.goodsCarChangeNum(param).then(res => {
        this.showloading = false;
        if (res.code == 200) {
          this.getGoodsCarDetail();
        }
      });
    },
    // 复选框改变时
    changebox(checked) {
      // console.log(checked.length, this.goodsCarDetail.length);
      // console.log("checked", checked);
      this.cartids = [];
      // for (var c in checked) {
      //   var check = checked[c];
      //   var carId = this.goodsCarDetail[check].id;
      //   // console.log("carId",carId);
      //   this.cartids.push(carId);
      // }
      this.cartids = checked;
      // console.log(this.cartids);
      // console.log(this.cartids instanceof Array);
      //购物车id数组
      // var caridArr = [];
      if (checked.length == this.goodsCarDetail.length) {
        this.checkedall = true;
        this.allmoney();
      } else {
        this.checkedall = false;
        var allmon = 0;
        var youMon = 0;
        var youMonArr = [];
        for (var i in checked) {
          for (var g in this.goodsCarDetail) {
            if (checked[i] == this.goodsCarDetail[g].id) {
              var nowMon =
                Number(this.goodsCarDetail[g].goods_spec.member_discount) *
                Number(this.goodsCarDetail[g].num); //实际金额
              var oriMon =
                Number(this.goodsCarDetail[g].goods_spec.member_price) *
                Number(this.goodsCarDetail[g].num); //原来金额
              allmon += nowMon;
              youMon = oriMon - nowMon;
              youMonArr.push(youMon);
            }
          }
        }

        // for (var i in checked) {
        //   var ind = checked[i];
        //   var nowMon =
        //     Number(this.goodsCarDetail[ind].goods_spec.member_discount) *
        //     Number(this.goodsCarDetail[ind].num); //实际金额
        //   var oriMon =
        //     Number(this.goodsCarDetail[ind].goods_spec.member_price) *
        //     Number(this.goodsCarDetail[ind].num); //原来金额
        //   allmon += nowMon;
        //   youMon = oriMon - nowMon;
        //   youMonArr.push(youMon);
        // }

        this.totalmon = allmon * 100;
        this.youmon = 0;
        for (var j in youMonArr) {
          this.youmon += youMonArr[j];
        }
      }
    }
  }
};
</script>

<style scoped>
</style>