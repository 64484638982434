/**
 * 售后接口
 */
import axios from "../http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块，把请求字段直接映射过来

const aftersale = {
  //评价标签
  afterSaleTag(params) {
    return axios.post("/api/member/afterSaleTag", qs.stringify(params));
  },
  //配镜-提交售后信息
  afterSaleCreate(params) {
    return axios.post("/api/member/afterSaleCreate", qs.stringify(params));
  },
  //售后查询列表
  afterSaleList(params) {
    return axios.post("/api/member/afterSaleList", qs.stringify(params));
  },
  //售后详情
  afterSaleDetail(params) {
    return axios.post("/api/member/afterSaleDetail", qs.stringify(params));
  },
  //完成售后
  afterSaleDone(params) {
    return axios.post("/api/member/afterSaleDone", qs.stringify(params));
  },
};
// 导出接口
export default aftersale;
