const formatTime = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

//   return `${[year, month, day].map(formatNumber).join("/")} ${[
//     hour,
//     minute,
//     second,
//   ]
//     .map(formatNumber)
//     .join(":")}`;
};

// 返回年月日
const dateYMD = (date) => {
  let dateArr = date.split(" ");
  return dateArr[0];
};
// 计算两个时间的差
const calcDuration = (startTime, endTime) => {
  let diff = turnTimeStamp(endTime) - turnTimeStamp(startTime);
  let str = "";
  // 毫秒化天
  let d = Math.floor(diff / (24 * 3600 * 1000));
  if (d > 0) {
    str += d + "天";
  }
  // 毫秒化小时
  let rh = diff % (24 * 3600 * 1000);
  let h = Math.floor(rh / (3600 * 1000));
  h = h < 10 ? "0" + h : h;
  if (h > 0 || d > 0) {
    str += h + "小时";
  }
  // 毫秒化分钟
  let rm = rh % (3600 * 1000);
  let m = Math.floor(rm / (60 * 1000));
  m = m < 10 ? "0" + m : m;
  if (m > 0 || d > 0 || h > 0) {
    str += m + "分";
  }

  // 毫秒化秒
  let rs = rm % (60 * 1000);
  let s = Math.round(rs / 1000);
  s = s < 10 ? "0" + s : s;
  if (s > 0 || d > 0 || h > 0 || m > 0) {
    str += s + "秒";
  }
  return str;
};
// 时间字符串转时间戳
const turnTimeStamp = (date) => {
  date = date.substring(0, 19).replace(/-/g, "/"); //必须把日期'-'转为'/'
  return new Date(date).getTime();
};
// 时间戳转字符串
const timestampToTime = (timestamp) => {
  var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

  let Y = date.getFullYear() + "-";

  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";

  let D = date.getDate() + "";

  let h = date.getHours() + ":";

  let m = date.getMinutes() + ":";
  let s = date.getSeconds();
  return Y + M + D; //时分秒可以根据自己的需求加上
};
// 时间戳转字符串
const timestampToYearMouth = (timestamps) => {
  var date = new Date(timestamps); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

  let Y = date.getFullYear() + "-";

  let M =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  return Y + M; //时分秒可以根据自己的需求加上
};
// 时间戳转字符串
const timestampToTimeAll = (timestamp) => {
  var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

  let Y = date.getFullYear() + "-";

  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";

  let D = date.getDate() + " ";

  let h = date.getHours() + ":";

  let m = date.getMinutes() + ":";
  let s = date.getSeconds();
  s = s == "0" ? "00" : s ;
  return Y + M + D + h + m + s; //时分秒可以根据自己的需求加上
};
// 时间戳转字符串
const timestampToTimeHour = (timestamp) => {
  var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

  let Y = date.getFullYear() + "-";

  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";

  let D = date.getDate() + " ";

  let h = date.getHours();
  return Y + M + D + h; //时分秒可以根据自己的需求加上
};
// 萤石云使用
const DateFormat = function(date, fmt) {
  //author: meizz
  var o = {
    "M+": new Date(date).getMonth() + 1, //月份
    "d+": new Date(date).getDate(), //日
    "h+": new Date(date).getHours(), //小时
    "m+": new Date(date).getMinutes(), //分
    "s+": new Date(date).getSeconds(), //秒
    "q+": Math.floor((new Date(date).getMonth() + 3) / 3), //季度
    S: new Date(date).getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (new Date(date).getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};
module.exports = {
  dateYMD,
  calcDuration,
  DateFormat,
  formatTime,
  timestampToTime,
  timestampToYearMouth,
  timestampToTimeAll,
  timestampToTimeHour,
};
