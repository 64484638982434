<template>
  <div class="EditUserINfo aftersales">
    <van-nav-bar
      :z-index="99"
      title="售后详情"
      :placeholder="true"
      :fixed="false"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y_add_storage">
      <div class="y-user-old1 f-l">
        <div class="y-order-top">
          <p>售后商品</p>
        </div>
        <div
          class="y-user-oldxia f-l y-op_orderxia y-xzcptop-img y-order-list"
          v-for="(item,index) in pjOrderArr"
          :key="index"
        >
          <van-card
            :num="item.num"
            :price="item.price"
            :title="item.goods.title"
            :thumb="item.goods.goodsthumb"
          >
            <template #tags>
              <!-- <van-tag color="#F3F4F9" text-color="#999">FRANZLISZT</van-tag>
              <van-tag color="#F3F4F9" text-color="#999">超轻系列</van-tag>-->
              <van-tag color="#F3F4F9" text-color="#999">{{item.goods_spec.goods_spec_name}}</van-tag>
            </template>
          </van-card>
        </div>
        <!-- <div class="y-user-oldxia f-l y-op_orderxia y-xzcptop-img y-order-list">
          <van-card
            num="1"
            price="1599.99"
            title="男女复古超轻圆框眼镜框 树脂材质耐磨抗压"
            thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
          >
            <template #tags>
              <van-tag color="#F3F4F9" text-color="#999">FRANZLISZT</van-tag>
              <van-tag color="#F3F4F9" text-color="#999">超轻系列</van-tag>
            </template>
          </van-card>
        </div>-->
      </div>
      <div class="y-order-xx">
        <div class="y-order-top">
          <p>填写售后理由</p>
        </div>
        <div class="y-hf-1">
          <van-field
            v-model="message"
            rows="2"
            autosize
            type="textarea"
            maxlength="200"
            placeholder="补充描述，有助于商家更好的处理售后问题"
            show-word-limit
          />
        </div>
        <div class="y-hf-2">
          <van-uploader
            v-model="fileList"
            multiple
            :max-count="3"
            :after-read="onRead"
            :before-delete="onDelet"
          >
            <template>
              <img src="../../assets/images/s-bg.png" class="y-s-bg" />
            </template>
          </van-uploader>
        </div>
      </div>
      <div class="y-edit-button">
        <van-button
          @click="send_sales"
          round
          type="primary"
          block
          color="linear-gradient(to right, #35B6D8, #52D3C5)"
        >提交</van-button>
      </div>
    </div>
    <!-- 加载框 -->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showloading: false,
      fileList: [],
      message: "",
      pjOrderDetail: {},
      pjOrderArr: [],
      goodsthumb: "",
      uploadTuList: []
    };
  },
  created: function() {
    this.getPjOrderDetail();
  },
  methods: {
    //配镜订单详情接口
    getPjOrderDetail() {
      var params = {
        token: this.$store.getters.token,
        id: this.$route.query.id
      };
      this.$api.pjorder.pjOrderDetail(params).then(res => {
        if (res.code == 200) {
          this.pjOrderDetail = res.result;
          this.pjOrderArr = res.result.pj_order_goods;
          for (var i in this.pjOrderArr) {
            var pjpic = this.pjOrderArr[i].goods.thumb;
            if (pjpic.indexOf("http") == -1) {
              this.pjOrderArr[i].goods.goodsthumb =
                this.$publicData.imgUrl + pjpic;
            } else {
              this.pjOrderArr[i].goods.goodsthumb = pjpic;
            }
          }
        }
      });
    },
    //上传图片
    onRead: function(file) {
      this.showloading = true;
      this.$api.member.uploadTu({ imgFile: file.content }).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.uploadTuList.push(res.result.url);
          // console.log("this.uploadTuList",this.uploadTuList);
        } else {
          this.showloading = false;
        }
      });
    },
    // 删除
    onDelet(e, detail) {
      var index = detail.index;
      this.fileList.splice(index, 1);
      this.uploadTuList.splice(index, 1);
    },
    //点击提交
    send_sales() {
      var params = {
        token: this.$store.getters.token,
        type: 2,
        order_sn: this.pjOrderDetail.order_sn,
        content: this.message,
        alum: this.uploadTuList
      };
      this.$api.aftersale.afterSaleCreate(params).then(res => {
        if (res.code == 200) {
          this.$toast("提交成功！");
          this.$router.push({
            path: "/order_list"
          });
        }
      });
    }
  }
};
</script>
<style scoped>
/* .aftersales ::v-deep .y-hf-2 .van-uploader__preview-image {
  width: 1.73rem !important;
  height: 1.73rem !important;
}
.aftersales ::v-deep.y-hf-2 .van-image__img {
  width: 65px;
  height: 65px;
}
.aftersales .y-s-bg {
  width: 65px;
  height: 65px;
}

.aftersales .y-service {
  margin-top: 70px !important;
}
.aftersales .y-service img {
  margin-left: 131px !important;
}
.aftersales .y-hf-1 .van-cell {
  border: none;
  padding: 0px !important;
  margin: 10px 0px;
}
.aftersales .y-hf-1,
.aftersales .y-hf-2,
.aftersales .y-service-silder {
  width: 92%;
  float: left;
  margin-left: 4%;
}
.aftersales .y-order-xx .y-order-top p {
  margin-left: 0px;
  padding: 8px 15px;
  font-weight: bold;
}

.aftersales .van-popup--bottom {
  width: 90%;
  padding: 10px 5% 0px 5%;
}
.aftersales .van-cell {
  border: 1px #eeeeee solid;
  padding: 8px 15px !important;
  margin: 40px 0px 15px 0px;
}
.aftersales ::v-deep.van-popup__close-icon {
  color: #999 !important;
  margin-top: -10px;
}
.aftersales .y-edit-button {
  width: 94%;
  float: left;
  margin: 20px 3%;
}
.aftersales .y-bottom20 {
  margin-bottom: 20px;
}

.aftersales .y-hf-img {
  width: 65px;
  height: 65px;
}

.aftersales .y-order-list {
  margin-top: 10px;
  padding-bottom: 15px;
}
.aftersales .y-order-top {
  width: 100%;
  float: left;
}
.aftersales .y-order-top p {
  font-size: 0.35rem;
  padding: 8px 20px;
  color: #000;
}
.aftersales .y-user-old1,
.aftersales .y-order-xx {
  width: 94%;
  margin-left: 3%;
  background: #ffffff;
  border-radius: 8px;
  float: left;
  padding: 15px 0px;
  color: #999;
  margin-top: 10px;
  box-shadow: 0px 2px 5px #eeeeee;
}
.aftersales .y-user-old1 {
  margin-top: 15px;
}
.aftersales .van-card {
  background-color: #ffffff;
}
.aftersales .y-xzcptop-img .van-image {
  width: 90px !important;
  height: 90px !important;
  border: 1px #eeeeee solid;
  border-radius: 8px;
}
.aftersales .van-tag--default {
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 0.15rem;
  border-radius: 0.13rem;
}
.aftersales .van-card__thumb {
  margin-right: 18px;
}
.aftersales .y-order-det {
  width: 94%;
  margin-left: 3%;
  margin-top: 100px;
  color: #ffffff;
  font-size: 0.35rem;
  line-height: 20px;
}
.aftersales .y-order-det img {
  width: 18px;
  float: left;
  margin: 0px 8px;
}
.aftersales .van-nav-bar {
  background-color: transparent;
}

.aftersales .y-por-rectop {
  color: #ffffff;
  margin-top: 30px;
  width: 94%;
  margin-left: 3%;
}
.aftersales .y-setuptop {
  float: left;
  padding: 12px 0px;
}

.aftersales .y_add_storage {
  background-color: #f3f4f9;
  min-height: 100vh;
  float: left;
  width: 100%;
} */
</style>