/**
 * 优惠券接口
 */
import axios from "../http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块，把请求字段直接映射过来

const coupon = {
  //领券中心
  couponCenter(params) {
    return axios.post("/api/member/couponCenter", qs.stringify(params));
  },
  //领券中心领券
  couponLingQuan(params) {
    return axios.post("/api/member/couponLingQuan", qs.stringify(params));
  },
  //优惠券列表
  couponList(params) {
    return axios.post("/api/member/couponList", qs.stringify(params));
  },
  //转赠
  couponZhuan(params) {
    return axios.post("/api/member/couponZhuan", qs.stringify(params));
  },
  zhuanZengDetail(params) {
    return axios.post("/api/zhuanZengDetail", qs.stringify(params));
  },
  // 领券
  couponZhuanLing(params) {
    return axios.post("/api/member/couponZhuanLing", qs.stringify(params));
  },
};
// 导出接口
export default coupon;
