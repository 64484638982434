<template>
  <!-- 收件地址 -->

  <div class="address">
    <van-nav-bar
      :z-index="99"
      :border="false"
      title="收件地址"
      :fixed="false"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <!-- 地址 -->
    <div class="addr">
      <van-swipe-cell v-for="(item,index) in addressList" :key="index">
        <div class="row">
          <div class="left" @click="toOrder(item.id)">
            <span class="area">{{item.adderssInfo}}</span>
            <span class="detailed">{{item.address}}</span>
            <span class="name">{{item.name}}</span>
            <span>{{item.phone}}</span>
          </div>
          <van-image :src="img_icon" fit="contain" @click="toNewAddress(item.id)" />
        </div>
        <template #right>
          <van-button square type="danger" text="删除" @click="deladdress(item.id)" />
        </template>
      </van-swipe-cell>
    </div>
    <!-- to="newAddress" -->
    <van-button class="newadd" round type="info" @click="toNewadd">+新增收货地址</van-button>
    <!-- 加载框 -->
    <!-- <van-popup class="jiazai" v-model="showloading">
        <van-loading type="spinner" />
    </van-popup>-->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showloading: false,
      img_icon: require("../../assets/images/index/icon.png"),
      addressList: [],
      addressStatus: ""
    };
  },
  created: function() {
    this.getAddressList();
  },
  methods: {
    //收货地址列表接口
    getAddressList() {
      this.showloading = true;
      var param = {
        token: this.$store.getters.token
      };
      this.$api.address.memberAddressList(param).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.addressList = res.result;
          this.addressStatus = this.$route.query.status;
        }
      });
    },
    //删除地址
    deladdress(id) {
      var that = this;
      var param = {
        token: that.$store.getters.token,
        id: id
      };
      this.$api.address.memberAddressRemove(param).then(res => {
        if (res.code == 200) {
          this.getAddressList();
        }
      });
    },
    // 路由跳转
    toNewadd() {
      var that = this;
      that.$router.push({
        path: "/new_address",
        query: {
          id: "-1"
        }
      });
    },
    toNewAddress(id) {
      var that = this;
      that.$router.push({
        path: "/new_address",
        query: {
          id: id
        }
      });
    },
    //跳转到订单详情
    toOrder(id) {
      // console.log("ooo");
      if (this.addressStatus == 1) {
        // console.log("goods_order");
        this.$router.push({
          path: "/goods_order",
          query: {
            id: this.$route.query.id,
            address_id: id
          }
        });
      }
      if (this.addressStatus == 2) {
        // console.log("integral_add");
        this.$router.push({
          path: "/integral_add",
          query: {
            id: this.$route.query.id,
            address_id: id
          }
        });
      }
    }
  }
};
</script>

<style>
</style>