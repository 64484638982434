<template>
  <div class="optomtry">
    <van-nav-bar
      :fixed="false"
      :border="false"
      :placeholder="true"
      title="视力档案"
      left-arrow
      @click-left="toHome"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y_add_storage">
      <div class="y-optom-banner">
        <!-- <img src="../../assets/images/optometry-banner.png" /> -->
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(item,index) in bannerList" :key="index">
            <img :src="item.bannerthumb" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="clearfix">
        <!-- 搜索框 -->
        <van-search :clearable="false" v-model="value" left-icon placeholder="请输入搜索关键词" class="f-l">
          <template #right-icon>
            <div @click="getYgRecordList">
              <img src="../../assets/images/searchlan.png" alt="pic" />
            </div>
          </template>
        </van-search>
        <!-- 添加 -->
        <div class="right_jia f-l" @click="toAdd">
          <van-icon name="plus" />
        </div>
      </div>
      <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
        <div class="y_storage_list clearfix">
          <div
            class="y_storage_list_item"
            v-for="(item,index) in ygRecordList"
            :key="index"
            @click="toOptometryinfo(item.id)"
          >
            <div class="y_storage_no">
              <span
                class="y_storage_no_tag"
                :class="{y_storage_no_tag_zb:item.type== 1 , y_storage_no_tag_db:item.type != 1}"
              >{{ item.type == 1 ?'我':'验光师'}}</span>
              <!-- <span
                class="y_storage_no_num"
              >{{new Date(item.updated_at).toLocaleString('chinese',{hour12:false})}}</span> -->
              <span
                class="y_storage_no_num"
              >{{item.created_at}}</span>
            </div>
            <div class="y_stirage_info f-l">
              <div class="y_stirage_info_img">
                <van-image round :src="item.member.memberthumb" />
              </div>
              <div class="y_stirage_info_name">
                <span>{{item.belong_name==null?item.member.name:item.belong_name}}</span>
              </div>
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </div>
    <!-- 加载框 -->
    <!-- <van-popup class="jiazai" v-model="showloading">
      <van-loading type="spinner" />
    </van-popup>-->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      showloading: false,
      value: "",
      ygRecordList: [],
      memberthumb: "",
      curTab: "-1",
      bannerList: [],
      bannerthumb: ""
    };
  },
  created: function() {
    this.getYgRecordList();
    this.getBannerList();
  },
  methods: {
    //下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        this.getLaYgRecordList();
      }, 1000);
    },
    //轮播图-查询接口
    getBannerList() {
      this.showloading = true;
      var params = {
        token: this.$store.getters.token,
        type: 3
      };
      this.$api.enjoylife.bannerList(params).then(res => {
        this.showloading = false;
        this.bannerList = res.result.list;
        for (var i in this.bannerList) {
          var bannerPic = this.bannerList[i].thumb;
          if (bannerPic.indexOf("http") == -1) {
            this.bannerList[i].bannerthumb =
              this.$publicData.imgUrl + bannerPic;
          } else {
            this.bannerList[i].bannerthumb = bannerPic;
          }
        }
      });
    },
    toAdd() {
      this.$router.push({
        path: "/optometry_add"
      });
    },
    toHome() {
      this.$router.push({
        path: "/home"
      });
    },
    toOptometryinfo(id) {
      this.$router.push({
        path: "/optometry_infor",
        query: {
          id: id
        }
      });
    },
    getYgRecordList() {
      this.showloading = true;
      var params = {
        token: this.$store.getters.token,
        key: this.value
      };
      this.$api.ygrecord.ygRecordList(params).then(res => {
        this.showloading = false;
        this.ygRecordList = res.result;
        for (var i in this.ygRecordList) {
          var ygRecordListpic = this.ygRecordList[i].member.thumb;
          if (ygRecordListpic.indexOf("http") == -1) {
            this.ygRecordList[i].member.memberthumb =
              this.$publicData.imgUrl + ygRecordListpic;
          } else {
            this.ygRecordList[i].member.memberthumb = ygRecordListpic;
          }
        }
      });
    },
    getLaYgRecordList() {
      var params = {
        token: this.$store.getters.token,
        key: this.value
      };
      this.$api.ygrecord.ygRecordList(params).then(res => {
        this.ygRecordList = res.result;
        for (var i in this.ygRecordList) {
          var ygRecordListpic = this.ygRecordList[i].member.thumb;
          if (ygRecordListpic.indexOf("http") == -1) {
            this.ygRecordList[i].member.memberthumb =
              this.$publicData.imgUrl + ygRecordListpic;
          } else {
            this.ygRecordList[i].member.memberthumb = ygRecordListpic;
          }
        }
      });
    }
  }
  // mounted: function() {
  //   this.$http
  //     .post(
  //       "http://jiejie.shanmuzhi.com/api/staff/shiftDepotInList",
  //       {
  //         token: "46310ebce28ea196452b63fa9ec8c8fe",
  //         status: "1"
  //       },
  //       { emulateJSON: true }
  //     )
  //     .then(function(res) {
  //       console.log(res);
  //     });
  // }
};
</script>

<style scoped>
/* .optomtry .y-optom-banner .my-swipe img {
  width: 10rem;
  height: 4rem;
  border-radius: 0;
}
.optomtry {
  background: #f3f4f9;
}
.optomtry .van-nav-bar {
  background: transparent;
}
.optomtry .right_jia {
  width: 0.93rem;
  height: 0.93rem;
  background: #fff;
  text-align: center;
  border-radius: 0.2rem;
  font-size: 0.48rem;
  color: #35b6d8;
  padding-top: 0.2rem;
  box-sizing: border-box;
  margin-top: 0.25rem;
}
.optomtry .van-field__right-icon img {
  width: 0.45rem;
  display: block;
}
.optomtry .van-search {
  margin-left: 0.1rem;
  width: 8.5rem;
}
.optomtry .van-search__content {
  background: #fff !important;
  height: 0.9rem;
  border-radius: 0.13rem;
}
.optomtry .y_stirage_info_img .van-image {
  width: 32px;
  height: 32px;
}
.optomtry .y-optom-banner,
.optomtry .y-optom-banner img {
  width: 100%;
}
.optomtry .y_stirage_info_img {
  float: left;
}

.optomtry ::v-deep .van-tabs__line {
  background-color: transparent;
}
.optomtry ::v-deep .van-tab--active {
  font-size: 0.35rem;
}
.optomtry .van-tabs__line {
  background-color: transparent !important;
}
.optomtry .van-tab--active {
  font-size: 0.35rem;
}
.optomtry .y_add_storage {
  background-color: #f3f4f9;
  width: 100%;
  float: left;
  min-height: 100vh;
}
.optomtry .y_storage_list {
  padding: 0 15px;
}
.optomtry .y_storage_list .y_storage_list_item {
  width: 100%;
  float: left;
  margin: 8px 0;
  border-radius: 7.5px;
  background-color: #fff;
  box-shadow: 0px 2px 5px #eeeeee;
}
.optomtry .y_storage_no {
  height: 45px;
  line-height: 45px;
  padding: 0 12px;
  padding-right: 0;
  border-bottom: 1px #eeeeee solid;
  position: relative;
}
.optomtry .y_storage_no div {
  float: left;
}
.optomtry .y_storage_no_tag {
  display: inline-block;
  border-radius: 10px 0px 0px 0px;
  color: white;
  line-height: 21px;
  font-size: 0.35rem;
  width: 50px;
  text-align: center;
  padding: 0 2px;
}
.optomtry .y_storage_no_tag_zb {
  background-color: #5cc6da;
}
.optomtry .y_storage_no_tag_db {
  background-color: #7fc89e;
}
.optomtry .y_storage_no_num {
  margin-left: 8px;
  font-size: 0.35rem;
  line-height: 21px;
}
.optomtry .y_storage_no_state {
  padding: 5px 14px;
  font-size: 0.35rem;
  margin-top: 10px;
  float: right;
  line-height: 13px;
  border: 1px #eee solid;
  border-radius: 25px 0 0 25px;
}
.optomtry .y_storage_no_state_wait {
  border: 1px #f54749 solid;
  color: #f54749;
  border-right: none;
}
.optomtry .y_storage_no_state_finish {
  border: 1px #35b6d8 solid;
  color: #35b6d8;
  border-right: none;
}
.optomtry .y_stirage_info {
  padding-left: 12px;
  padding: 12px 12px;
}
.optomtry .y_stirage_info_name {
  font-size: 0.35rem;
  line-height: 32px;
  float: left;
  margin-left: 9px;
}
.optomtry .y_stirage_info_time {
  font-size: 0.35rem;
  color: #999;
  padding: 3px 0px;
}
.optomtry .y_new_storage {
  padding: 20px 12px 40px;
}
.optomtry .y_new_add_storage {
  width: 100%;
  background: linear-gradient(to right, #35b6d8, #52d3c5);
  border: none;
  margin-top: 15px;
} */
</style>