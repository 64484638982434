<template>
  <div class="EditUserINfo servicedetails">
    <!-- :class="{back:back}" -->
    <van-nav-bar
      :z-index="99"
      :fixed="false"
      :border="false"
      title="售后详情"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y-usertop y_add_storage">
      <div class="y-setuptop f-l y-order-det y-service">
        <img v-if="afterSaleDetail.status == 0" src="../../assets/images/order_wait.png" />
        <img v-if="afterSaleDetail.status == 1" src="../../assets/images/order_reply.png" />
        <img v-if="afterSaleDetail.status == 2" src="../../assets/images/order-finish.png" />
        <p v-if="afterSaleDetail.status == 0">待回复</p>
        <p v-if="afterSaleDetail.status == 1">已回复</p>
        <p v-if="afterSaleDetail.status == 2">已完成</p>
      </div>
      <!-- 待回复 -->
      <div class="y-service-silder y-service-wait" v-if="afterSaleDetail.status == 0">
        <div class="y-service-silder1">
          <P style="background-color:#fff;"></P>
          <van-divider style="background-color:#fff;" />
          <P class="y-c-replyp" style="background-color:#fff;"></P>
          <van-divider />
          <P></P>
          <van-divider />
          <P></P>
        </div>
        <div class="y-service-silder2">
          <van-row>
            <van-col class="y-c-wait" span="6" style="color:#fff;">提交申请</van-col>
            <van-col class="y-c-reply" span="6" style="color:#fff;">待回复</van-col>
            <van-col class="y-c-complete" span="6">已回复</van-col>
            <van-col class="y-c-complete" span="6">已完成</van-col>
          </van-row>
        </div>
      </div>
      <!-- 已回复 -->
      <div class="y-service-silder y-service-wait" v-if="afterSaleDetail.status == 1">
        <div class="y-service-silder1">
          <P style="background-color:#fff;"></P>
          <van-divider style="background-color:#fff;" />
          <P class="y-c-replyp" style="background-color:#fff;"></P>
          <van-divider style="background-color:#fff;" />
          <P style="background-color:#fff;"></P>
          <van-divider />
          <P></P>
        </div>
        <div class="y-service-silder2">
          <van-row>
            <van-col class="y-c-wait" span="6" style="color:#fff;">提交申请</van-col>
            <van-col class="y-c-reply" span="6" style="color:#fff;">待回复</van-col>
            <van-col class="y-c-complete" span="6" style="color:#fff;">已回复</van-col>
            <van-col class="y-c-complete" span="6">已完成</van-col>
          </van-row>
        </div>
      </div>
      <!-- 已完成 -->
      <div class="y-service-silder y-service-wait" v-if="afterSaleDetail.status == 2">
        <div class="y-service-silder1">
          <P style="background-color:#fff;"></P>
          <van-divider style="background-color:#fff;" />
          <P class="y-c-replyp" style="background-color:#fff;"></P>
          <van-divider style="background-color:#fff;" />
          <P style="background-color:#fff;"></P>
          <van-divider style="background-color:#fff;" />
          <P style="background-color:#fff;"></P>
        </div>
        <div class="y-service-silder2">
          <van-row>
            <van-col class="y-c-wait" span="6" style="color:#fff;">提交申请</van-col>
            <van-col class="y-c-reply" span="6" style="color:#fff;">待回复</van-col>
            <van-col class="y-c-complete" span="6" style="color:#fff;">已回复</van-col>
            <van-col class="y-c-complete" span="6" style="color:#fff;">已完成</van-col>
          </van-row>
        </div>
      </div>

      <div class="y-user-old1 f-l">
        <div class="y-order-top">
          <p>售后商品</p>
        </div>
        <div
          class="y-user-oldxia f-l y-op_orderxia y-xzcptop-img y-order-list"
          v-for="(item,index) in afterSaleDetail.showInfo.goodsInfo"
          :key="index"
        >
          <van-card
            :num="item.num"
            :price="item.price"
            :title="item.title"
            :thumb="item.goodsthumb"
          >
            <template #tags>
              <!-- <van-tag color="#F3F4F9" text-color="#999">FRANZLISZT</van-tag>
              <van-tag color="#F3F4F9" text-color="#999">超轻系列</van-tag>-->
              <van-tag color="#F3F4F9" text-color="#999">{{item.spec}}</van-tag>
            </template>
          </van-card>
        </div>
      </div>
      <div class="y-order-xx">
        <div class="y-order-top">
          <p>售后信息</p>
        </div>
        <p>
          <i>售后凭证：</i>
          <span>
            <i>{{afterSaleDetail.content}}</i>
            <div class="clearfix">
              <van-image
                v-for="(item,index) in afterSaleDetail.alumList"
                :key="index"
                class="y-hf-img"
                radius="8"
                :src="item"
                @click="tobigpic(index)"
              />
            </div>
          </span>
        </p>
        <p>
          <i>申请时间：</i>
          <span>{{afterSaleDetail.updated_at}}</span>
        </p>
        <p>
          <i>订单编号：</i>
          <span>{{afterSaleDetail.order_sn}}</span>
        </p>
      </div>
      <van-popup class="tanchu" v-model="showpic">
        <div>
          <img
            v-if="afterSaleDetail.alumList"
            :src="afterSaleDetail.alumList[showindex]"
            @click="showpic = false"
          />
        </div>
      </van-popup>
      <!-- 验光师回复 -->
      <div class="y-order-xx" v-if="afterSaleDetail.status != 0">
        <div class="y-order-top">
          <p>验光师回复</p>
        </div>
        <div class="y-hf-1">
          <van-field
            v-model="message"
            rows="2"
            autosize
            type="textarea"
            maxlength="200"
            placeholder="回复内容"
            readonly
          />
        </div>
        <div class="y-hf-2">
          <!-- <van-uploader v-model="fileList" multiple :max-count="3">
          <template>-->
          <img
            v-for="(item,index) in afterSaleDetail.staffAlumList"
            :key="index"
            :src="item"
            class="y-s-bg y-s-bgimg"
            @click="yanpic(index)"
          />
          <!-- </template>
          </van-uploader>-->
        </div>
      </div>
      <!-- 评论/查看评论 -->
      <div class="y-order-xx order_comment" v-if="afterSaleDetail.status == 2">
        <div class="y-order-top">
          <p>评价</p>
        </div>
        <div class="evaluate">
          <div class="goods_text">
            <!-- 文字 -->
            <van-field
              v-if="afterSaleDetail.is_comment == 0"
              v-model="plmessage"
              rows="5"
              autosize
              type="textarea"
              maxlength="500"
              placeholder="亲，分享您的使用感受、服务等，还可获得积分哦~"
              show-word-limit
            />
            <div class="pingl" v-if="afterSaleDetail.is_comment == 1">
              <span>{{plmessage}}</span>
            </div>
          </div>
          <!-- 上传照片 -->
          <van-uploader
            v-if="afterSaleDetail.is_comment == 0"
            v-model="fileListpl"
            multiple
            :max-count="3"
            upload-text="上传照片"
            upload-icon="photo-o"
            :after-read="onReadpl"
            :before-delete="onDeletpl"
          />
          <div class="shangc" v-if="afterSaleDetail.is_comment == 1">
            <img
              v-for="(pic,index) in afterSaleDetail.comment.showAlum"
              :key="'pic'+index"
              :src="pic"
              @click="pingpic(index)"
            />
          </div>
        </div>
        <div class="order-score jiejie-score">
          <!-- 星星 -->
          <van-rate
            v-if="afterSaleDetail.is_comment == 0"
            v-model="score"
            :size="25"
            color="#EE904F"
            void-icon="star"
            void-color="#ddd"
            @change="onChange"
          />
          <van-rate
            v-if="afterSaleDetail.is_comment == 1"
            v-model="score"
            :size="25"
            color="#EE904F"
            void-icon="star"
            void-color="#ddd"
            @change="onChange"
            :readonly="true"
          />
          <span class="order-satisfied">{{scoretext}}</span>
          <!-- 标签 -->
          <div class="order-btn" v-if="afterSaleDetail.is_comment == 0">
            <van-button
              v-for="(item,index) in pjOrderCommentTag"
              :key="index"
              round
              :color="item.color"
              type="info"
              plain
              @click="toChange(item)"
            >{{item.name}}</van-button>
          </div>
          <div class="order-btn" v-if="afterSaleDetail.is_comment == 1">
            <van-button
              v-for="(item,index) in afterSaleDetail.comment.tagArr"
              :key="index"
              round
              color="#ee904f"
              type="info"
              plain
            >{{item}}</van-button>
          </div>
        </div>
        <van-button
          round
          type="info"
          class="order-sub"
          @click="subpingjia"
          v-if="afterSaleDetail.is_comment == 0"
        >提交评价</van-button>
      </div>
      <van-popup class="tanchu" v-model="showping" v-if="afterSaleDetail.is_comment == 1">
        <div>
          <img
            v-if="afterSaleDetail.comment.showAlum"
            :src="afterSaleDetail.comment.showAlum[showpingindex]"
            @click="showping = false"
          />
        </div>
      </van-popup>
      <van-popup class="tanchu" v-model="showyan">
        <div>
          <img
            v-if="afterSaleDetail.staffAlumList"
            :src="afterSaleDetail.staffAlumList[showyanindex]"
            @click="showyan = false"
          />
        </div>
      </van-popup>

      <!-- 积分弹框 -->
      <van-popup :close-on-click-overlay="false" v-model="show_rw" class="lqwork">
        <h2>任务完成</h2>
        <div class="y-task-top">
          <p>奖励</p>
        </div>
        <div class="y-task-zj">
          <p>+{{scorenum}}</p>
          <img src="../../assets/images/jb.png" />
        </div>
        <div class="y-task-buttom">
          <van-button
            round
            closed
            color="linear-gradient(to right, #F28E26, #FD644F)"
            type="primary"
            @click="toback"
          >完成</van-button>
        </div>
      </van-popup>
      <!-- 加载框 -->
      <van-overlay class="jiazai" :show="showloading">
        <div class="wrapper">
          <van-loading type="spinner" />
        </div>
      </van-overlay>

      <!-- 验光师处理结果 -->
      <!-- <div class="y-order-xx y-service1">
        <div class="y-order-top">
          <p>验光师处理结果</p>
        </div>
        <div class="y-service1-admin">
          <van-image class="y-hf-img2" radius="5" src="https://img01.yzcdn.cn/vant/cat.jpeg" />
          <h2>
            <p>欧阳淼淼</p>
            <span>13811002512</span>
          </h2>
        </div>
        <div class="y-service1-xia">
          <p>双方协商同意退款。</p>
        </div>
      </div>-->

      <div class="y-edit-button">
        <van-button
          v-if="afterSaleDetail.status == 1"
          to="home"
          round
          type="primary"
          block
          color="linear-gradient(to right, #35B6D8, #52D3C5)"
          @click="afterSaleDone"
        >完成</van-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showloading: false,
      show_rw: false,
      scorenum: 0,
      tagarr: [],
      pjOrderCommentTag: [],
      score: 5,
      scoretext: "满意",
      plmessage: "",
      showyan: false,
      showping: false,
      showyanindex: 0,
      showpingindex: 0,
      showpic: false,
      showindex: 0,
      fileList: [],
      fileListpl: [],
      uploadTuList: [],
      message: "",
      afterSaleDetail: {
        showInfo: {
          goodsInfo: []
        }
      },
      goodsthumb: "",
      alumList: [],
      staffAlumList: []
    };
  },
  created: function() {
    this.getAfterSaleDetail();
    this.getPjOrderCommentTag();
  },
  methods: {
    toback() {
      this.$router.back();
    },
    //点击提交评价
    subpingjia() {
      this.showloading = true;
      var params = {
        token: this.$store.getters.token,
        // type: this.$route.query.bigStatus,
        type: 5,
        id: this.afterSaleDetail.id,
        star: this.score,
        // content: document.getElementsByTagName("textarea")[0].value,
        content: this.plmessage,
        tag: this.tagarr,
        alum: this.uploadTuList
      };
      this.$api.pjorder.commentCreate(params).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          if (res.result.isPopGetScore && res.result.isPopGetScore == 1) {
            this.scorenum = res.result.popScoreNum;
            this.show_rw = true;
          } else {
            this.$toast("提交成功");
            this.$router.back();
          }

          // this.$router.push({
          //   path: "/service_list"
          // });
        }
      });
    },
    // 评价按钮接口
    getPjOrderCommentTag() {
      var param = {
        token: this.$store.getters.token
      };
      this.$api.aftersale.afterSaleTag(param).then(res => {
        var pjOrder = res.result;
        for (var i in pjOrder) {
          this.pjOrderCommentTag.push({
            name: pjOrder[i],
            active: 0,
            color: "#B8B8B8"
          });
        }
      });
    },
    //点击评价按钮
    toChange(item) {
      if (item.active == 0) {
        item.active = 1;
        item.color = "#ee904f";
      } else {
        item.active = 0;
        item.color = "#B8B8B8";
      }
      this.tagarr = [];
      for (var i in this.pjOrderCommentTag) {
        var pjOrderactive = this.pjOrderCommentTag[i].active;
        var pjOrdername = this.pjOrderCommentTag[i].name;
        if (pjOrderactive == 1) {
          this.tagarr.push(this.pjOrderCommentTag[i].name);
        }
      }
    },
    //评分改变
    onChange(score) {
      this.score = score;
      if (score == 5) {
        this.scoretext = "满意";
      } else if (score == 4) {
        this.scoretext = "较满意";
      } else if (score == 3) {
        this.scoretext = "一般";
      } else if (score == 2) {
        this.scoretext = "差";
      } else {
        this.scoretext = "非常差";
      }
    },
    //上传图片
    onReadpl: function(file) {
      this.showloading = true;
      this.$api.member.uploadTu({ imgFile: file.content }).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.uploadTuList.push(res.result.url);
          // console.log("this.uploadTuList",this.uploadTuList);
        } else {
          this.showloading = false;
        }
      });
    },
    // 删除
    onDeletpl(e, detail) {
      var index = detail.index;
      this.fileListpl.splice(index, 1);
      this.uploadTuList.splice(index, 1);
    },
    getAfterSaleDetail() {
      this.showloading = true;
      var param = {
        token: this.$store.getters.token,
        id: this.$route.query.id
      };
      this.$api.aftersale.afterSaleDetail(param).then(res => {
        this.showloading = false;
        if (res.code == 200) {
          this.afterSaleDetail = res.result;
          this.message = this.afterSaleDetail.staff_content;
          var goodsinfo = this.afterSaleDetail.showInfo.goodsInfo;
          for (var i in goodsinfo) {
            var goodspic = goodsinfo[i].thumb;
            if (goodspic.indexOf("http") == -1) {
              this.afterSaleDetail.showInfo.goodsInfo[i].goodsthumb =
                this.$publicData.imgUrl + goodspic;
            } else {
              this.afterSaleDetail.showInfo.goodsInfo[i].goodsthumb = goodspic;
            }
          }
          var showalum = this.afterSaleDetail.alumArr;
          this.afterSaleDetail.alumList = [];
          for (var j in showalum) {
            if (showalum[j].indexOf("http") == -1) {
              var alumpic1 = this.$publicData.imgUrl + showalum[j];
              this.afterSaleDetail.alumList.push(alumpic1);
            } else {
              var alumpic2 = showalum[j];
              this.afterSaleDetail.alumList.push(alumpic2);
            }
          }
          var showstaffalum = this.afterSaleDetail.staffAlum;
          this.afterSaleDetail.staffAlumList = [];
          for (var k in showstaffalum) {
            if (showstaffalum[k].indexOf("http") == -1) {
              var staffpic1 = this.$publicData.imgUrl + showstaffalum[k];
              this.afterSaleDetail.staffAlumList.push(staffpic1);
            } else {
              var staffpic2 = showstaffalum[k];
              this.afterSaleDetail.staffAlumList.push(staffpic2);
            }
          }
          // console.log("QQ",this.afterSaleDetail.staffAlumList);

          // console.log("alumArr", this.afterSaleDetail.alumArr);
          // console.log("alumList", this.afterSaleDetail.alumList);
          if (
            this.afterSaleDetail.is_comment &&
            this.afterSaleDetail.is_comment == 1
          ) {
            this.plmessage = this.afterSaleDetail.comment.content;
            this.score = this.afterSaleDetail.comment.star;
            if (this.score == 5) {
              this.scoretext = "满意";
            } else if (this.score == 4) {
              this.scoretext = "较满意";
            } else if (this.score == 3) {
              this.scoretext = "一般";
            } else if (this.score == 2) {
              this.scoretext = "差";
            } else {
              this.scoretext = "非常差";
            }
            if (
              this.afterSaleDetail.comment.alum &&
              this.afterSaleDetail.comment.alum.length != 0
            ) {
              var commentAlum = this.afterSaleDetail.comment.alum;
              var showcommentAlum = JSON.parse(commentAlum);
              // console.log("showcommentAlum",showcommentAlum);
              this.afterSaleDetail.comment.showAlum = [];
              for (var s in showcommentAlum) {
                if (showcommentAlum[s].indexOf("http") == -1) {
                  var pic = this.$publicData.imgUrl + showcommentAlum[s];
                  this.afterSaleDetail.comment.showAlum.push(pic);
                } else {
                  this.afterSaleDetail.comment.showAlum.push(
                    showcommentAlum[s]
                  );
                }
              }
            }
          }
        }
      });
    },
    //点击图片
    tobigpic(index) {
      this.showpic = true;
      this.showindex = index;
    },
    yanpic(index) {
      this.showyan = true;
      this.showyanindex = index;
    },
    pingpic(index) {
      this.showping = true;
      this.showpingindex = index;
    },
    //点击完成
    afterSaleDone() {
      var param = {
        token: this.$store.getters.token,
        id: this.$route.query.id
      };
      this.$api.aftersale.afterSaleDone(param).then(res => {
        this.$router.push({
          path: "/service_list"
        });
      });
    }
    // TabBar() {
    //   var userOld = document.getElementsByClassName("y-user-old1")[0];
    //   var that = this
    //   if (userOld.scrollHeight - scrollY <= 300) {
    //     that.back = true;
    //   } else {
    //     that.back = false;
    //     }
    // }
  },
  mounted() {
    // window.addEventListener("scroll", this.TabBar);
  }
};
</script>
<style scoped>
/* .servicedetails .tanchu img {
  width: 9rem;
}
.servicedetails .tanchu div {
  padding: 0.5rem;
  background: #fff;
}
.servicedetails .van-nav-bar {
  position: absolute;
  width: 100%;
}
.servicedetails .back {
  background: #fff !important;
}
.servicedetails .back .van-icon {
  color: #000 !important;
}
.servicedetails ::v-deep .back .van-nav-bar__title {
  color: #000 !important;
}
.servicedetails ::v-deep .van-uploader__preview-image {
  width: auto !important;
  height: 1.73rem !important;
}
.servicedetails ::v-deep .y-hf-2 .van-image__img {
  width: 1.73rem !important;
  height: 1.73rem !important;
}
.servicedetails .y-s-bg {
  width: 1.73rem !important;
  height: 1.73rem !important;
}
.servicedetails .y-s-bgimg {
  border-radius: 0.2rem;
}
.servicedetails .y-service1-xia {
  width: 94%;
  margin-left: 3%;
  float: left;
  border-top: 1px #eee solid;
  margin-top: 10px;
}
.servicedetails .y-service1-xia p {
  margin-left: 0px !important;
  margin-top: 10px;
  font-size: 0.35rem;
  padding: 0px !important;
}
.servicedetails .y-service1-admin h2 {
  float: left;
  font-weight: normal;
  margin-left: 8px;
}
.servicedetails .y-service1-admin h2 p {
  font-size: 0.35rem;
  color: #000;
  margin-left: 0px;
  padding: 7px 0px 3px 0px;
}
.servicedetails .y-hf-img2 {
  width: 35px;
  height: 35px;
  float: left;
  margin-left: 4%;
  margin-top: 10px;
}
.servicedetails .y-service-complete .y-service-silder1 p {
  border: 1px #ffffff solid;
  background-color: #ffffff;
}
.servicedetails .y-service-complete .y-service-silder2 {
  color: #ffffff;
}
.servicedetails .y-service-complete .y-c-complete {
  font-size: 0.35rem;
}

.servicedetails .y-service-reply .y-service-silder1 .y-c-replyp,
.servicedetails .y-service-reply .y-service-silder1 p:first-child {
  border: 1px #ffffff solid;
  background-color: #ffffff;
}
.servicedetails .y-service-reply .y-c-reply,
.servicedetails .y-service-reply .y-c-wait {
  color: #ffffff;
}
.servicedetails .y-service-reply .y-c-reply {
  font-size: 0.35rem;
}

.servicedetails .y-service-wait .y-service-silder1 p:first-child {
  border: 1px #ffffff solid;
  background-color: #ffffff;
}
.servicedetails .y-service-wait .y-c-wait {
  color: #ffffff;
  font-size: 0.35rem;
}

.servicedetails .y-service-silder1 .van-divider {
  width: 65px;
  float: left;
  margin: 2.5px 8px;
}
.servicedetails .y-service-silder1 p {
  width: 4px;
  height: 4px;
  float: left;
  border: 1px #a0e1e8 solid;
  border-radius: 5px;
  background-color: #a0e1e8;
}
.servicedetails .y-service-silder2 {
  text-align: center;
  width: 100%;
  float: left;
  font-size: 0.35rem;
  color: #a0e1e8;
  margin: 10px 0px 10px 0px;
}
.servicedetails .y-service-silder1 {
  width: 80%;
  float: left;
  margin-left: 10%;
  margin-top: 12px;
}
.servicedetails .y-service {
  margin-top: 70px !important;
}
.servicedetails .y-service img {
  margin-left: 3rem !important;
}
.servicedetails .y-hf-1 .van-cell {
  border: none;
  padding: 0px !important;
  margin: 10px 0px;
}
.servicedetails .y-hf-1,
.servicedetails .y-hf-2,
.servicedetails .y-service-silder {
  width: 92%;
  float: left;
  margin-left: 4%;
}
.servicedetails .y-order-xx .y-order-top p {
  margin-left: 0px;
  padding: 8px 15px;
  font-weight: bold;
}

.servicedetails .van-popup--bottom {
  width: 90%;
  padding: 10px 5% 0px 5%;
}
.servicedetails .van-cell {
  border: 1px #eeeeee solid;
  padding: 8px 15px !important;
  margin: 40px 0px 15px 0px;
}
.servicedetails ::v-deep .van-popup__close-icon {
  color: #999 !important;
  margin-top: -10px;
}
.servicedetails .y-edit-button {
  width: 94%;
  float: left;
  margin: 20px 3%;
}
.servicedetails .y-bottom20 {
  margin-bottom: 20px;
}
.servicedetails .y-order-xx p i {
  width: 80px;
  float: left;
  font-size: 0.35rem;
  color: #999;
}
.servicedetails .y-order-xx p span {
  font-size: 0.35rem;
  color: #666;
  float: left;
  width: 240px;
}
.servicedetails .y-order-xx p span i {
  float: left;
  color: #666;
  width: 100%;
  margin-bottom: 5px;
}
.servicedetails .y-hf-img {
  width: 65px;
  height: 65px;
}
.servicedetails .y-order-xx p {
  width: 92%;
  float: left;
  margin-left: 4%;
  padding: 5px 0px;
}
.servicedetails .y-order-list {
  margin-top: 10px;
  padding-bottom: 15px;
}
.servicedetails .y-order-top {
  width: 100%;
  float: left;
}
.servicedetails .y-order-top p {
  font-size: 0.35rem;
  padding: 8px 20px;
  color: #000;
}
.servicedetails .y-user-old1,
.servicedetails .y-order-xx {
  width: 94%;
  margin-left: 3%;
  background: #ffffff;
  border-radius: 8px;
  float: left;
  padding: 15px 0px;
  color: #999;
  margin-top: 10px;
  box-shadow: 0px 2px 5px #eeeeee;
}
.servicedetails .van-card {
  background-color: #ffffff;
}
.servicedetails .y-xzcptop-img .van-image {
  width: 90px !important;
  height: 90px !important;
  border: 1px #eeeeee solid;
  border-radius: 8px;
}
.servicedetails .van-tag--default {
  margin-top: 0.2rem;
  margin-right: 10px;
  margin-bottom: 10px;
}
.servicedetails .van-card__thumb {
  margin-right: 18px;
}
.servicedetails .y-order-det {
  width: 94%;
  margin-left: 3%;
  margin-top: 100px;
  color: #ffffff;
  font-size: 0.43rem;
  line-height: 20px;
}
.servicedetails .y-order-det img {
  width: 18px;
  float: left;
  margin: 0px 8px;
}
.servicedetails .y-order-det p {
  color: #fff;
}
.servicedetails .van-nav-bar {
  background-color: transparent;
}
.servicedetails .van-icon {
  color: #ffffff !important;
}
.servicedetails .y-usertop {
  float: left;
  background-image: url(../../assets/images/promotion.png);
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.servicedetails .y-por-rectop {
  color: #ffffff;
  margin-top: 30px;
  width: 94%;
  margin-left: 3%;
}
.servicedetails .y-setuptop {
  float: left;
  padding: 12px 0px;
}

.servicedetails .y_add_storage {
  background-color: #f3f4f9;
  min-height: 100vh;
  float: left;
  width: 100%;
}
.servicedetails .van-nav-bar .van-icon {
  color: #ffffff;
}
.servicedetails .van-nav-bar {
  background-color: transparent;
}
.servicedetails ::v-deep .van-nav-bar__title {
  color: #ffffff;
} */
</style>