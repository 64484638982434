<template>
  <!-- 个人中心 top -->
  <div class="optomtrist">
    <van-nav-bar :fixed="true" title="详细信息" left-arrow @click-left="$router.back()">
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y-pre">
      <div class="y-predet-title">
        <div class="y-predet-title1 f-l">
          <img src="../../assets/images/order_wait.png" />
          <p>待分配</p>
        </div>
        <!-- 已分配状态开始 -->
        <!-- <div class="y-predet-title1">
        <img src="../../assets/images/order-finish.png" />
        <p>已分配</p>
        </div>-->
        <!-- 已分配状态结束 -->
        <div class="y-pre-deta-car">
          <div class="y-pre-deta-car-top f-l">
            <div class="y-pre-deta-car-car">
              <img src="../../assets/images/pre-det1.png" />
            </div>
            <div class="y-pre-deta-car-carinfo">
              <p>车牌号</p>
              <span>京MBJ075</span>
            </div>
          </div>
          <div class="y-pre-deta-car-xia f-l">
            <div class="y-pre-deta-car-car">
              <img src="../../assets/images/pre-det2.png" />
            </div>
            <div class="y-con-admin">
              <van-row>
                <van-col span="12">
                  <van-image
                    round
                    class="y-con-admin_img"
                    src="https://img01.yzcdn.cn/vant/cat.jpeg"
                  />
                  <p>
                    <span>张翔宇</span>
                    <i>市场主管</i>
                  </p>
                </van-col>
                <van-col span="12">
                  <van-image
                    round
                    class="y-con-admin_img"
                    src="https://img01.yzcdn.cn/vant/cat.jpeg"
                  />
                  <p>
                    <span>欧阳淼淼</span>
                    <i>验光师</i>
                  </p>
                </van-col>
              </van-row>
            </div>
          </div>
        </div>
        <div class="y-pre-infor">
          <van-field v-model="name" label="联系人" placeholder="请输入联系人姓名" />
          <van-field v-model="tel" type="tel" label="手机号码" placeholder="请输入手机号码" />
          <van-field v-model="tame" label="预约时间" placeholder="请输入预约时间" />
          <van-field v-model="add" label="预约地址" placeholder="请输入预约地址" />
          <!-- <van-field v-model="remarks" label="备注" placeholder /> -->
        </div>
      </div>

      <div class="y-pre-objective">
        <h2>预约目的</h2>
        <div class="y-pre-objective1">
          <van-button color="#35B6D8" round plain>验光</van-button>
          <van-button color="#35B6D8" round plain>近视镜</van-button>
        </div>
      </div>
      <div class="y-pre-button" @click="showPopup">
        <van-button
          type="primary"
          block
          round
          color="linear-gradient(to right, #35B6D8, #52D3C5)"
        >取消预约</van-button>
      </div>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
export default {
  data() {
    return {
      tel: "13811006973",
      name: "马楠",
      tame: "2021.04.03 14:30",
      add: "北京市石景山区万达广场A区西侧停车场",
      remarks: "验光，配眼镜"
    };
  }
};
</script>
<style  scoped>
/* .optomtrist .y-pre-deta-car-carinfo p {
    font-size: 0.35rem;
    color: #999;
}
.optomtrist .y-pre-deta-car-carinfo span {
    font-size: 0.35rem;
    color: #000;
    line-height: 26px;
}
.optomtrist .y-pre-deta-car-car {
  background-color: #5cc6da;
  width: 30px;
  height: 30px;
  float: left;
  border-radius: 30px;
  text-align: center;
  margin-right: 13px;
  margin-top: 5px;
}
.optomtrist .y-pre-deta-car-car img {
  width: 15px;
  margin-top: 8px;
}
.optomtrist .y-pre-deta-car-top {
  padding: 20px 14px 6px 14px;
}
.optomtrist .y-pre-deta-car-xia {
  padding: 6px 14px 20px 14px;
}
.optomtrist .y-pre-deta-car-top,
.optomtrist .y-pre-deta-car-xia {
  width: 92%;
}
.optomtrist .y-pre-deta-car {
  margin: 0px 3%;
  width: 94%;
  float: left;
  border-radius: 10px;
  background-color: #ffffff;
}
.optomtrist .y-con-admin {
  width: 86%;
  float: right;
  background-color: transparent;
  padding: 4px 0px 0px 0px;
  border-radius: 0px;
  margin: 0px;
}
.optomtrist .y-con-admin_img {
  width: 35px;
  height: 35px;
  float: left;
  margin-right: 8px;
}
.optomtrist .y-con-admin p {
  float: left;
      padding-left: 0px;
}
.optomtrist .y-con-admin p span {
  width: 100%;
  float: left;
  font-size: 0.35rem;
  color: #000;
}
.optomtrist .y-con-admin p i {
  width: 100%;
  float: left;
  font-size: 0.35rem;
  color: #999;
}

.optomtrist .y-predet-title1 p {
  font-size: 0.35rem;
  color: #ffffff;
  padding: 0px 9px;
  float: left;
}
.optomtrist .y-predet-title1 img {
  width: 18px;
  float: left;
  margin-top: 2px;
}
.optomtrist .y-predet-title1 {
  padding: 80px 0px 25px 27px;
}
.optomtrist .van-cell {
  background-color: transparent;
}
.optomtrist .van-nav-bar .van-icon {
  color: #ffffff !important;
}
.optomtrist .van-nav-bar {
  background-color: transparent;
}
.optomtrist ::v-deep .van-nav-bar__title {
  color: #ffffff !important;
}
.optomtrist ::v-deep .van-field__label {
  width: 60px !important;
}
.optomtrist .y-pre-objective h2 {
  font-size: 0.35rem;
  padding: 5px 0px 2px 15px;
}
.optomtrist .y-pre-button {
  margin: 180px 0px 10px 0px;
}
.optomtrist .y-foot .van-tabbar-item--active {
  color: #35b6d8;
}
.optomtrist .y-foot .van-tabbar-item__icon img {
  width: 20px;
  margin-bottom: 2px;
}
.optomtrist .y-pre-objective1 .van-button {
  padding: 5px 14px;
  height: auto;
  line-height: none;
  font-size: 0.35rem;
  margin: 5px;
}
.optomtrist .y-pre-infor .van-cell {
  border-bottom: 1px #e8e8e9 solid;
}
.optomtrist .y-pre-objective,
.optomtrist .y-pre-button {
  margin-left: 3%;
  width: 94%;
  float: left;
}
.optomtrist .y-pre-objective1 {
  margin: 10px 0px;
  padding: 10px;
  width: 91%;
  float: left;
  border-radius: 10px;
  background-color: #ffffff;
}
.optomtrist .y-pre-infor {
  margin: 15px 3%;
  width: 94%;
  float: left;
  border-radius: 10px;
  background-color: #ffffff;
}
.optomtrist .y-predet-title {
  float: left;
  background-image: url("../../assets/images/pre-bg2.png");
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.optomtrist .y-pre-title h2 {
  padding: 80px 30px;
  color: #ffffff;
}
.optomtrist .y-pre-title h2 p {
  font-size: 0.35rem;
}
.optomtrist .y-pre-title h2 span {
  color: #d8f1f3;
  font-size: 0.35rem;
  line-height: 22px;
}
.optomtrist .y-pre {
  background-color: #f3f4f9;
  width: 100%;
  min-height: 100vh;
  float: left;
} */
</style>
