<template>
  <div class="optometry_evaluate">
    <van-nav-bar :border="false" title="发表评价" left-arrow @click-left="$router.back()">
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="orderEvaluate">
      <!-- 订单评分 -->
      <div class="order-score jiejie-score">
        <van-rate
          v-model="score"
          :size="25"
          color="#EE904F"
          void-icon="star"
          void-color="#ddd"
          @change="onChange"
        />
        <span class="order-satisfied">{{scoretext}}</span>
        <div class="order-btn">
          <van-button
            v-for="(item,index) in pjOrderCommentTag"
            :key="index"
            round
            :color="item.color"
            type="info"
            plain
            @click="toChange(item)"
          >{{item.name}}</van-button>
        </div>
      </div>
      <!-- 商品评价 -->
      <div class="evaluate">
        <div class="goods_text">
          <van-field
            v-model="message"
            rows="5"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="亲，分享您的使用感受、服务等，还可获得积分哦~"
            show-word-limit
          />
          <!-- <textarea placeholder="亲，分享您的使用感受、服务等，还可获得积分哦~"></textarea>
          <span class="goods-num">0/500</span>-->
        </div>
      </div>

      <!-- 提交按钮 -->
      <van-button round type="info" class="order-sub" @click="subpingjia">提交评价</van-button>
    </div>
    <!-- 加载框 -->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
    <!-- 积分弹框 -->
    <van-popup :close-on-click-overlay="false" v-model="show_rw" class="lqwork">
      <h2>任务完成</h2>
      <div class="y-task-top">
        <p>奖励</p>
      </div>
      <div class="y-task-zj">
        <p>+{{scorenum}}</p>
        <img src="../../assets/images/jb.png" />
      </div>
      <div class="y-task-buttom">
        <van-button
          round
          closed
          color="linear-gradient(to right, #F28E26, #FD644F)"
          type="primary"
          @click="toback"
        >完成</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show_rw: false,
      scorenum: 0,
      message: "",
      showloading: false,
      img_yanjing: require("../../assets/images/1.jpg"),
      fileList: [],
      score: 5,
      scoretext: "满意",
      pjOrderCommentTag: [],
      pjOrderDetail: {},
      goodsthumb: "",
      tagarr: [],
      uploadTuList: [],
      tagList: ["配送超快", "服务到位", "眼睛合适", "完美的一次体验"],
      tagArr: []
    };
  },
  created: function() {
    this.getPjOrderCommentTag();
  },
  methods: {
    // 按钮接口
    getPjOrderCommentTag() {
      this.showloading = true;
      var param = {
        token: this.$store.getters.token
      };
      this.$api.pjorder.ygRecordCommentTag(param).then(res => {
        this.showloading = false;
        if (res.code == 200) {
          var pjOrder = res.result;
          for (var i in pjOrder) {
            this.pjOrderCommentTag.push({
              name: pjOrder[i],
              active: 0,
              color: "#B8B8B8"
            });
          }
        }
      });
    },
    toChange(item) {
      // console.log("item",this.pjOrderCommentTag);
      if (item.active == 0) {
        item.active = 1;
        item.color = "#ee904f";
      } else {
        item.active = 0;
        item.color = "#B8B8B8";
      }
      this.tagarr = [];
      for (var i in this.pjOrderCommentTag) {
        var pjOrderactive = this.pjOrderCommentTag[i].active;
        var pjOrdername = this.pjOrderCommentTag[i].name;
        if (pjOrderactive == 1) {
          this.tagarr.push(this.pjOrderCommentTag[i].name);
        }
      }
      // console.log(this.tagarr);
    },
    //点击评分按钮
    onChange(score) {
      this.score = score;
      if (score == 5) {
        this.scoretext = "满意";
      } else if (score == 4) {
        this.scoretext = "较满意";
      } else if (score == 3) {
        this.scoretext = "一般";
      } else if (score == 2) {
        this.scoretext = "差";
      } else {
        this.scoretext = "非常差";
      }
    },
    // 点击提交评价
    subpingjia() {
      this.showloading = true;
      var params = {
        token: this.$store.getters.token,
        type: 4,
        id: this.$route.query.creatid,
        star: this.score,
        content: document.getElementsByTagName("textarea")[0].value,
        tag: this.tagarr
      };
      this.$api.pjorder.commentCreate(params).then(res => {
        if (res.code == 200) {
          if (res.result.isPopGetScore && res.result.isPopGetScore == 1) {
            this.scorenum = res.result.popScoreNum;
            this.show_rw = true;
            this.showloading = false;
          } else {
            this.showloading = false;
            this.$toast("提交成功");
            this.$router.back();
          }
          // this.$toast("提交成功");
          // this.$router.push({
          //   path: "/optometry_infor",
          //   query: {
          //     id: this.$route.query.id
          //   }
          // });
        }
      });
    },
    toback() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
/* .optometry_evaluate {
  background: #f3f4f9;
  min-height: 100vh;
}
.optometry_evaluate .van-nav-bar {
  background: transparent;
}
.optometry_evaluate .evaluate textarea {
  width: 100%;
  height: 5rem;
  border: 0;
  resize: none;
  box-sizing: border-box;
  padding: 0.3rem 0.4rem;
  font-size: 0.35rem;
}

.optometry_evaluate .evaluate textarea::-webkit-input-placeholder {
  font-size: 0.35rem;
  color: #000;
  opacity: 0.6;
}
.optometry_evaluate .evaluate textarea::-moz-placeholder {
  font-size: 0.35rem;
  color: #000;
  opacity: 0.6;
}
.optometry_evaluate .evaluate textarea:-moz-placeholder {
  font-size: 0.35rem;
  color: #000;
  opacity: 0.6;
}
.optometry_evaluate .evaluate textarea:-ms-input-placeholder {
  font-size: 0.35rem;
  color: #000;
  opacity: 0.6;
}
.optometry_evaluate .evaluate .van-uploader {
  margin-left: 0.4rem;
  margin-bottom: 0.4rem;
}
.optometry_evaluate .evaluate .van-uploader .van-image,
.optometry_evaluate .evaluate .van-uploader img {
  width: 1.73rem;
  height: 1.73rem;
  border-radius: 0.13rem;
}
.optometry_evaluate .evaluate .van-uploader .van-uploader__preview,
.optometry_evaluate .evaluate .van-uploader .van-uploader__upload {
  width: 1.73rem;
  height: 1.73rem;
  margin-right: 0.2rem;
}
.optometry_evaluate .evaluate .van-uploader .van-uploader__upload {
  border-radius: 0.13rem;
  border: 0.03rem dashed #cacaca;
}
.optometry_evaluate .evaluate .van-uploader .van-uploader__preview-delete {
  border-radius: 50%;
  top: -0.1rem;
  right: -0.1rem;
  opacity: 0.7;
}
.optometry_evaluate .evaluate .van-uploader .van-uploader__preview-delete-icon {
  top: -0.03rem;
  right: -0.03rem;
}
.optometry_evaluate .evaluate .van-uploader .van-uploader__upload .van-uploader__upload-text {
  font-size: 0.35rem;
  color: #000;
  opacity: 0.6;
}
.optometry_evaluate .evaluate .goods-num {
  position: absolute;
  top: 3.8rem;
  right: 0.35rem;
  font-size: 0.35rem;
  opacity: 0.3;
}
.optometry_evaluate .jiejie-score {
  background: #fff;
  margin-bottom: 0.4rem;
  border-radius: 0.27rem;
  box-shadow: 0rem 0.07rem 0.21rem 0rem rgb(0 0 0 / 7%);
  padding: 0.52rem 0.4rem 0.2rem;
}
.optometry_evaluate .orderEvaluate .evaluate {
  position: relative;
  background: #fff;
  box-shadow: 0rem 0.07rem 0.21rem 0rem rgb(0 0 0 / 7%);
  border-radius: 0.27rem;
}
.optometry_evaluate .orderEvaluate {
  background: #f3f4f9;
}
.optometry_evaluate .jiejie-order-goods .van-image {
  border: 0.03rem solid #e5e5e5;
  margin-right: 0.26rem;
  border-radius: 0.13rem;
  width: 1.6rem;
  height: 1.6rem;
}
.optometry_evaluate .jiejie-order-goods .van-image img {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.13rem;
}
.optometry_evaluate .jiejie-order-goods {
  display: flex;
  padding: 0.4rem;
}
.optometry_evaluate .jiejie-order-goods .van-image {
  border: 0.03rem solid #e5e5e5;
  margin-right: 0.26rem;
  border-radius: 0.13rem;
}
.optometry_evaluate .goods_text {
  position: relative;
}
.optometry_evaluate .orderEvaluate {
  padding: 0.3rem 0.4rem 0.2rem;
}
.optometry_evaluate .van-rate {
  margin-left: 1.5rem;
  margin-right: 0.5rem;
}
.optometry_evaluate .order-satisfied {
  vertical-align: super;
  font-size: 0.4rem;
  color: #ee904f;
}
.optometry_evaluate .order-btn {
  margin-top: 0.5rem;
}
.optometry_evaluate .order-btn .van-button {
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
  border-color: #666;
  height: 0.67rem;
  margin-right: 0.3rem;
  border-radius: 0.33rem;
  margin-bottom: 0.3rem;
}
.optometry_evaluate .order-btn .van-button span {
  font-size: 0.35rem;
}
.optometry_evaluate .order-btn .btn-active {
  background: #fdf1ed;
  border-color: #ee904f;
}
.optometry_evaluate .order-btn .btn-active span {
  color: #ee904f;
}
.optometry_evaluate .order-sub {
  position: absolute;
  bottom: 0;
  margin-bottom: 0.8rem;
  margin-top: 0.7rem;
  width: 9.2rem;
  height: 1rem;
  background: linear-gradient(97deg, #35b6d8, #52d3c5);
  box-shadow: 0rem 0.13rem 0.28rem 0rem rgba(205, 205, 205, 0.2);
  border-color: #35b6d8;
  font-size: 0.4rem;
  font-weight: 500;
} */
</style>
