<template>
  <!-- 商品详情 -->

  <div class="details">
    <!-- <van-icon name="arrow-left" color="#000000" class="fanhui" @click="fanhui" /> -->
    <van-nav-bar
      :fixed="false"
      title="商品详情"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <!-- banner  -->
    <div class="banner">
      <van-swipe indicator-color="#36B7D7" :autoplay="3000">
        <van-swipe-item v-for="(image,index) in goodsDetail.AlbumArr" :key="'pic'+index">
          <van-image fit="fill" :src="image" />
        </van-swipe-item>
      </van-swipe>
      <!-- <p>米家防蓝光眼镜钛轻盈 防辐射眼镜平面无度数眼镜蓝光阻隔率>80%</p> -->
      <p>{{goodsDetail.title}} {{goodsDetail.showSpecInfo.goods_spec_name}}</p>
      <div class="money">
        <span class="now">
          ￥
          <!-- <span>135</span> -->
          <span>{{goodsDetail.showSpecInfo.member_discount}}</span>
        </span>
        <span class="before">
          ￥
          <!-- <span>299</span> -->
          <span>{{goodsDetail.showSpecInfo.member_price}}</span>
        </span>
      </div>
    </div>
    <!-- 规格 -->
    <div class="specs">
      <p class="top" @click="guige(goodsDetail.showSpecInfo.goods_spec_name)">
        <span class="left">规格</span>
        <!-- <span class="con">已选 防蓝光1.56非球面镜片0-400 | 留言度数</span> -->
        <span class="con">{{goodsDetail.showSpecInfo.goods_spec_name}}</span>
        <van-icon name="arrow" />
      </p>
      <p class="down">
        <span class="left">服务</span>
        <span class="con">送货上门·售后无忧</span>
      </p>
    </div>
    <!-- 商品评价 -->
    <div class="goods_evaluate">
      <van-cell :border="false" is-link @click="toEvaluate" value="查看全部">
        <template #title>
          <span class="custom-title">评价({{commentsArr.length}})</span>
        </template>
      </van-cell>
      <!-- @click="toEvaluate" -->
      <div class="evaluate" v-for="item in commentsArr" :key="'commentsArr'+item">
        <div class="evaluate_top clearfix">
          <div class="top_img f-l">
            <!-- <van-image round src="https://img01.yzcdn.cn/vant/cat.jpeg" /> -->
            <van-image round :src="item.member.memberthumb" />
          </div>
          <div class="top_user f-l">
            <span class="user_name">{{item.member.name}}</span>
            <span class="time">{{item.chatime}}前</span>
            <span>{{item.specName}}</span>
          </div>
          <!-- <div class="top_time">
            <span>{{item.chatime}}前</span>
          </div>-->
        </div>
        <div class="evaluate_tag">
          <van-button v-for="(tag,i) in item.tagArr" :key="'tagArr'+i" round type="info">{{tag}}</van-button>
        </div>
        <div class="evaluate_pic">
          <img @click="clickImage(p)" v-for="(pic,p) in item.alumList" :key="'pic'+p" :src="pic" />
        </div>
        <p class="evaluate_desc">{{item.content}}</p>
        <!-- 查看大图 -->
        <van-image-preview
          v-model="showBigImg"
          :images="item.alumList"
          :startPosition="bigImgindex"
        ></van-image-preview>
      </div>
    </div>
    <!-- 商品详情 -->
    <div class="goods_info" v-if="goodsDetail.content">
      <van-cell :border="false" value>
        <template #title>
          <span class="custom-title">商品详情</span>
        </template>
      </van-cell>
      <div class="content" v-html="goodsDetail.content"></div>
    </div>
    <!-- footer  -->
    <div class="footercar clearfix">
      <div class="footer_shopcar f-l" @click="toShopcar">
        <!-- <img class="goodsicon" :src="img_shopcar" alt="pic"> -->
        <van-icon
          v-if="goodsDetail.cartsNum != 0"
          :name="img_shopcar"
          :badge="goodsDetail.cartsNum"
        />
        <van-icon v-if="goodsDetail.cartsNum == 0" :name="img_shopcar" />
        <span>购物车</span>
      </div>
      <div class="footer_btn">
        <van-button class="btn incar" round type="info" @click="showshopcar = true">加入购物车</van-button>
        <van-button class="btn buynow" type="info" round @click="showshopcar = true">立即购买</van-button>
      </div>
    </div>
    <van-popup
      v-model="showshopcar"
      :close-on-click-overlay="false"
      round
      closeable
      position="bottom"
      :style="{ height: '70%' }"
    >
      <div class="pophp_top">
        <img :src="goodsDetail.detailthumb" alt />
        <div class="right">
          <span class="mon">
            ￥
            <span>{{specsMon}}</span>
          </span>
          <span class="beginmon">
            原价:
            <span>￥{{specsyuanMon}}</span>
          </span>
          <span class="ku">库存:{{specsNum}}件</span>
          <span v-if="specsName !=''" class="alreadyspe slh">已选规格:{{specsName}}</span>
        </div>
      </div>
      <div class="pophp_down">
        <div class="spe">
          <h3>规格</h3>
          <div class="spe_btn">
            <van-button
              round
              type="info"
              v-for="(item,index) in goodsDetail.goods_spec"
              :key="'goodsDetail'+index"
              :class="item.classname"
              @click="changeSpecs(index)"
            >{{item.goods_spec_name}}</van-button>
            <!-- <van-button round type="info">防蓝光1.56非球面镜片0-400 | 度数留言防蓝光1.56非球面镜片0-400 | 度数留言</van-button>
            <van-button round type="info">防蓝光1.56非球面镜片0-400 | 度数留言</van-button>
            <van-button round type="info">防蓝光1.56非球面镜片0-400 | 度数留言</van-button>-->
          </div>
          <div class="num clearfix">
            <span class="f-l">数量</span>
            <van-stepper v-model="valuecar" class="f-r" />
          </div>
        </div>
      </div>
      <div class="okbtn">
        <van-button round type="info" class="determine" @click="determine">加入购物车</van-button>
        <van-button round type="info" class="determinetrue" @click="determinetrue">立即购买</van-button>
      </div>
    </van-popup>

    <!-- <van-popup
      v-model="showtrue"
      :close-on-click-overlay="false"
      round
      closeable
      position="bottom"
      :style="{ height: '70%' }"
    >
      <div class="pophp_top">
        <img :src="goodsDetail.detailthumb" alt />
        <div class="right">
          <span class="mon">
            ￥
            <span>{{specsMon}}</span>
          </span>
          <span class="ku">库存:{{specsNum}}件</span>
          <span v-if="specsName !=''" class="alreadyspe slh">已选规格:{{specsName}}</span>
        </div>
      </div>
      <div class="pophp_down">
        <div class="spe">
          <h3>规格</h3>
          <div class="spe_btn">
            <van-button
              round
              type="info"
              v-for="(item,index) in goodsDetail.goods_spec"
              :key="index"
              :class="item.classname"
              @click="changeSpecs(index)"
            >{{item.goods_spec_name}}</van-button>
          </div>
          <div class="num clearfix">
            <span class="f-l">数量</span>
            <van-stepper v-model="valuetrue" class="f-r" />
          </div>
        </div>
      </div>
      <div class="okbtn">
        <van-button round type="info" class="determine" @click="determinetrue">确定</van-button>
      </div>
    </van-popup>-->
    <!-- 加载框 -->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBigImg: false,
      bigImgindex: 0,
      chatime: "",
      commentsArr: [],
      showloading: false,
      showshopcar: false,
      // showtrue: false,
      specsName: "",
      specsNum: "",
      specsMon: "",
      specsyuanMon: "",
      specsid: "",
      valuecar: 1,
      valuetrue: 1,
      classname: "",
      goodsDetail: {
        showSpecInfo: {
          member_price: ""
        }
      },
      AlbumArr: [],
      img_icon: require("../../assets/images/icon.png"),
      img_shopcar: require("../../assets/images/shopcar.png")
    };
  },
  created: function() {
    this.getGoodsDetail();
  },
  methods: {
    // 点击图片
    clickImage(index) {
      this.showBigImg = true;
      this.bigImgindex = index;
    },
    // 点击规格
    guige(name) {
      this.showshopcar = true;
      for (var j in this.goodsDetail.goods_spec) {
        this.goodsDetail.goods_spec[j].classname = "btn";
        if (this.goodsDetail.goods_spec[j].goods_spec_name == name) {
          this.goodsDetail.goods_spec[j].classname = "btnactive";
          this.specsName = this.goodsDetail.goods_spec[j].goods_spec_name; //规格
          this.specsNum = this.goodsDetail.goods_spec[j].show_num; //库存
          this.specsMon = this.goodsDetail.goods_spec[j].member_discount; //价钱
          this.specsyuanMon = this.goodsDetail.goods_spec[j].member_price; //原价
          this.specsid = this.goodsDetail.goods_spec[j].id; //商品id
        }
      }
    },
    //商品详情接口
    getGoodsDetail() {
      this.showloading = true;
      var param = {
        token: this.$store.getters.token,
        goods_id: this.$route.query.id
      };
      this.$api.mall.goodsDetail(param).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.goodsDetail = res.result;
          this.commentsArr = res.result.commentsArr;
          for (var c in this.commentsArr) {
            // this.commentsArr[c].chatime = this.intervalTime(
            //   new Date(this.commentsArr[c].updated_at).valueOf(),
            //   new Date().valueOf()
            // );
            this.commentsArr[c].chatime = this.intervalTime(
              new Date(
                this.commentsArr[c].updated_at.replace(/-/g, "/")
              ).valueOf(),
              new Date().valueOf()
            );
            var memberpic = this.commentsArr[c].member.thumb;
            if (memberpic.indexOf("http") == -1) {
              this.commentsArr[c].member.memberthumb =
                this.$publicData.imgUrl + memberpic;
            } else {
              this.commentsArr[c].member.memberthumb = memberpic;
            }
            for (var a in this.commentsArr[c].alumArr) {
              var alumitem = this.commentsArr[c].alumArr[a];
              if (alumitem.indexOf("http") == -1) {
                this.commentsArr[c].alumArr[a] =
                  this.$publicData.imgUrl + alumitem;
              }
              this.commentsArr[c].alumList = this.commentsArr[c].alumArr;
              // console.log("alumList",this.commentsArr[c].alumList);
            }
          }
          // 图片格式
          var pic = this.goodsDetail.thumb;
          if (pic.indexOf("http") == -1) {
            this.goodsDetail.detailthumb = this.$publicData.imgUrl + pic;
          } else {
            this.goodsDetail.detailthumb = pic;
          }
          this.showAlbumArr = this.goodsDetail.showAlbumArr;
          this.goodsDetail.AlbumArr = [];
          for (var i in this.showAlbumArr) {
            if (this.showAlbumArr[i].indexOf("http") == -1) {
              var album1 = this.$publicData.imgUrl + this.showAlbumArr[i];
              this.goodsDetail.AlbumArr.push(album1);
            } else {
              var album2 = this.showAlbumArr[i];
              this.goodsDetail.AlbumArr.push(album2);
            }
          }
          // console.log("AlbumArr", this.goodsDetail.AlbumArr);
          //规格按钮效果
          for (var j in this.goodsDetail.goods_spec) {
            this.goodsDetail.goods_spec[j].classname = "btn";
            if (
              this.goodsDetail.goods_spec[j].goods_spec_name ==
              this.goodsDetail.showSpecInfo.goods_spec_name
            ) {
              this.goodsDetail.goods_spec[j].classname = "btnactive";
              this.specsName = this.goodsDetail.goods_spec[j].goods_spec_name; //规格
              this.specsNum = this.goodsDetail.goods_spec[j].show_num; //库存
              this.specsMon = this.goodsDetail.goods_spec[j].member_discount; //价钱
              this.specsyuanMon = this.goodsDetail.goods_spec[j].member_price; //原价
              this.specsid = this.goodsDetail.goods_spec[j].id; //商品id
            }
          }
          // this.goodsDetail.goods_spec[0].classname = "btnactive";
          // this.specsName = this.goodsDetail.goods_spec[0].goods_spec_name; //规格
          // this.specsNum = this.goodsDetail.goods_spec[0].show_num; //库存
          // this.specsMon = this.goodsDetail.goods_spec[0].staff_money; //价钱
          // this.specsid = this.goodsDetail.goods_spec[0].id; //商品id
        }
      });
    },
    //点击规格按钮
    changeSpecs(index) {
      for (var i in this.goodsDetail.goods_spec) {
        this.goodsDetail.goods_spec[i].classname = "btn";
      }
      this.goodsDetail.showSpecInfo.goods_spec_name = this.goodsDetail.goods_spec[
        index
      ].goods_spec_name;
      this.goodsDetail.showSpecInfo.member_discount = this.goodsDetail.goods_spec[
        index
      ].member_discount;
      this.goodsDetail.showSpecInfo.member_price = this.goodsDetail.goods_spec[
        index
      ].member_price;
      this.specsName = this.goodsDetail.goods_spec[index].goods_spec_name; //规格
      this.specsNum = this.goodsDetail.goods_spec[index].show_num; //库存
      this.specsMon = this.goodsDetail.goods_spec[index].member_discount; //价钱
      this.specsyuanMon = this.goodsDetail.goods_spec[index].member_price; //原价
      this.specsid = this.goodsDetail.goods_spec[index].id; //商品id
      this.goodsDetail.goods_spec[index].classname =
        this.goodsDetail.goods_spec[index].classname == "btn"
          ? "btnactive"
          : "btn";
      // console.log(this.goodsDetail.goods_spec);
      this.$forceUpdate();
    },
    //点击立即购买确定
    determinetrue() {
      if (this.specsNum != 0 && this.specsNum - this.valuecar >= 0) {
        var param = {
          token: this.$store.getters.token,
          goods_id: this.$route.query.id,
          goods_spec_id: this.specsid,
          num: this.valuecar
        };
        this.$api.goodsOrder.directCreate(param).then(res => {
          if (res.code == 200) {
            this.showshopcar = false;
            this.$router.push({
              path: "/goods_order",
              query: {
                id: res.result.id
              }
            });
          }
        });
      } else {
        this.$toast("库存不足");
      }
    },
    //点击加入购物车确定
    determine() {
      if (this.specsNum != 0 && this.specsNum - this.valuecar >= 0) {
        //商品添加到购物车接口
        var param = {
          token: this.$store.getters.token,
          goods_id: this.$route.query.id,
          goods_spec_id: this.specsid,
          num: this.valuecar
        };
        this.$api.mall.goodsCarAdd(param).then(res => {
          this.showshopcar = false;
          this.$toast("已加入购物车");
          for (var i in this.goodsDetail.goods_spec) {
            this.goodsDetail.goods_spec[i].classname = "btn";
            this.goodsDetail.goods_spec[0].classname = "btnactive";
            this.specsName = this.goodsDetail.goods_spec[0].goods_spec_name; //规格
            this.specsNum = this.goodsDetail.goods_spec[0].show_num; //库存
            this.specsMon = this.goodsDetail.goods_spec[0].member_discount; //价钱
            this.specsyuanMon = this.goodsDetail.goods_spec[0].member_price; //原价
            this.specsid = this.goodsDetail.goods_spec[0].id; //商品id
          }
          this.getGoodsDetail();
        });
      } else {
        this.$toast("库存不足");
      }
    },
    toShopcar() {
      var that = this;
      that.$router.push({
        path: "/shopcar"
      });
    },
    //跳转到评价页面
    toEvaluate() {
      this.$router.push({
        path: "/goods_evaluate",
        query: {
          id: this.$route.query.id
        }
      });
    },
    intervalTime(currentTimsmap, endTime) {
      // console.log(currentTimsmap, endTime);
      var subTime = endTime - currentTimsmap;
      // console.log(subTime);
      if (subTime > 0) {
        //  相差的天数
        var titmeTotal = subTime; //时间差的毫秒数
        var days = Math.floor(titmeTotal / (24 * 3600 * 1000));
        // 相差的小时数
        var leave1 = titmeTotal % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        var hours = Math.floor(leave1 / (3600 * 1000));
        //  计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000));
        //  计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟后剩余的毫秒数
        var seconds = Math.round(leave3 / 1000);

        // days + "天" + hours + "小时" + minutes + "分"
        return days + "天" + hours + "小时" + minutes + "分";
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
/* .details ::v-deep .van-popup__close-icon--top-right {
  top: 0.3rem !important;
  right: 0.3rem !important;
}
.details .van-swipe-item .van-image {
  width: 10rem;
  height: 7.4rem;
} */
</style>