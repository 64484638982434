<template>
  <div class="EditUserINfo orderdetails">
    <!-- :class="{back:back}" -->
    <van-nav-bar
      :z-index="99"
      :border="false"
      title="订单详情"
      :fixed="false"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y-usertop">
      <div class="y-setuptop f-l y-order-det">
        <img v-show="bigStatus == 0" src="../../assets/images/order_wait.png" />
        <p v-show="bigStatus == 0">待付款</p>
        <img v-show="bigStatus == 1" src="../../assets/images/order_wait.png" />
        <p v-show="bigStatus == 1">制作中</p>
        <img v-show="bigStatus == 2" src="../../assets/images/order_car.png" />
        <p v-show="bigStatus == 2">待配送</p>
        <img v-show="bigStatus == 3" src="../../assets/images/order-finish.png" />
        <p v-show="bigStatus == 3">已完成</p>
        <!-- <img src="../../assets/images/order_car.png" />
        <p>待收货</p>
          <img src="../../assets/images/order_wait.png" />
        <p>待付款</p>
          <img src="../../assets/images/order-finish.png" />
        <p>待发货</p>-->
      </div>
      <div class="y-order-1">
        <div class="y-order1-left f-l">
          <van-icon name="location-o" color="#000" size="18" />
        </div>
        <div class="y-order1-con f-l" v-if="pjOrderDetail.is_old_order==0">
          <p>{{pjOrderDetail.yg_order.member.default_address.name}} {{pjOrderDetail.yg_order.member.default_address.phone}}</p>
          <span>{{pjOrderDetail.yg_order.member.default_address.address}}</span>
          <!-- <span>很长一段内容很长一段内容很长一段内容很长一段内容很长一段内容很长一段内容很内容很内容很内容很内容很</span> -->
        </div>
        <div class="y-order1-con f-l" v-if="pjOrderDetail.is_old_order==1">
          <p>{{old_orderInfo.name}} {{old_orderInfo.phone}}</p>
          <span>{{old_orderInfo.address}}</span>
          <!-- <span>很长一段内容很长一段内容很长一段内容很长一段内容很长一段内容很长一段内容很内容很内容很内容很内容很</span> -->
        </div>
      </div>
      <!-- 产品信息 -->
      <div
        class="y-user-old1 f-l"
        v-if="pjOrderDetail.pj_order_goods&&pjOrderDetail.pj_order_goods.length!=0"
      >
        <div class="y-order-top">
          <p>产品信息</p>
        </div>
        <div v-for="(item,index) in pjOrderDetail.pj_order_goods" :key="index">
          <div class="y-user-oldxia f-l y-op_orderxia y-xzcptop-img y-order-list">
            <van-card
              :num="item.num"
              :price="item.goods_spec.member_discount"
              :origin-price="item.goods_spec.member_price"
              :title="item.goods.title"
              :thumb="item.goods.goodsthumb"
            >
              <template #tags>
                <van-tag color="#F3F4F9" text-color="#999">
                  <span class="slh">{{item.goods_spec.goods_spec_name}}</span>
                </van-tag>
                <!-- <van-tag color="#F3F4F9" text-color="#999">超轻系列</van-tag> -->
              </template>
            </van-card>
          </div>
        </div>
      </div>
      <!-- 订单信息 -->
      <div class="y-order-xx clearfix" v-if="pjOrderDetail.is_old_order==0">
        <p class="clearfix">
          <i>订单编号</i>
          <span>{{pjOrderDetail.zj_order.order_sn}}</span>
        </p>
        <p class="clearfix">
          <i>下单时间</i>
          <span>{{pjOrderDetail.created_at}}</span>
        </p>
        <p class="clearfix">
          <i>支付方式</i>
          <span>{{pjOrderDetail.pay_way}}</span>
        </p>
        <p class="clearfix">
          <i>交易时间</i>
          <span>{{pjOrderDetail.pay_at}}</span>
        </p>
        <p class="clearfix">
          <!-- <i>备注</i> -->
          <i>特殊加工</i>
          <span>{{pjOrderDetail.remark}}</span>
        </p>
        <p class="clearfix">
          <!-- <i>备注</i> -->
          <i>备注</i>
          <span>{{pjOrderDetail.member_remark}}</span>
        </p>
      </div>
      <!-- 商品信息 -->
      <div class="y-user-old1 f-l" v-if="pjOrderDetail.is_old_order==0">
        <div class="y-user-olddate f-l y-op_orderpay">
          <p>
            商品总额
            <!-- <span>¥{{pjOrderDetail.origin_total_price}}</span> -->
            <span>¥{{priceall.toFixed(2)}}</span>
          </p>
          <!-- v-if="pjOrderDetail.peisong_price!=0" -->
          <p>
            运费
            <span>¥{{pjOrderDetail.peisong_price}}</span>
          </p>
          <!-- v-if="zkyoumoney!=0" -->
          <!-- <p>
            会员等级折扣 ({{pjOrderDetail.member_level?pjOrderDetail.member_level.name:""}})
            <span>(-￥{{zkyoumoney.toFixed(2)}}) {{parseFloat(pjOrderDetail.member_level?pjOrderDetail.member_level.discount:10)}}折</span>
          </p> -->
          <p>
            会员折扣
            <span>-¥{{zhekou.toFixed(2)}}</span>
          </p>
          <!-- v-if="pjOrderDetail.preferential!=0" -->
          <p>
            代金券
            <span>-¥{{pjOrderDetail.preferential}}</span>
          </p>
          <!-- v-if="pjOrderDetail.use_score_number!=0" -->
          <p>
            积分抵用
            <span>-￥{{(pjOrderDetail.use_score_number/100).toFixed(2)}}</span>
          </p>
          <!-- <p>
            会员折扣
            <span>-¥{{((pjOrderDetail.origin_total_price*1-pjOrderDetail.goods_total_price*1)+ pjOrderDetail.staff_discount_price*1).toFixed(2)}}</span>
          </p>-->
          <!-- v-if="pjOrderDetail.staff_discount_price!=0" -->
          <!-- <p>
            员工抹零
            <span>-¥{{pjOrderDetail.staff_discount_price}}</span>
          </p> -->
          <p>
            实付金额
            <!-- <span>¥{{pjOrderDetail.final_pay_price}}</span> -->
            <span>¥{{sfprice.toFixed(2)}}</span>
          </p>
          <!-- <p class="y-paymoney">
            实付金额：
            <span>¥{{pjOrderDetail.final_pay_price}}</span>
          </p>-->
        </div>
      </div>
      <!-- 退款 -->
      <div
        class="y-order-xx y-order-tt clearfix"
        v-if="pjOrderDetail.zj_order.is_refund == 1 && pjOrderDetail.is_old_order==0"
      >
        <p class="clearfix">
          <i>是否退款</i>
          <span>{{pjOrderDetail.zj_order.is_refund == 1?'已退款':''}}</span>
        </p>
        <p class="clearfix">
          <i>退款时间</i>
          <span>{{pjOrderDetail.zj_order.refund_time}}</span>
        </p>
        <p class="clearfix">
          <i>退款备注</i>
          <span>{{pjOrderDetail.zj_order.refund_remark}}</span>
        </p>
      </div>

      <!-- 光度信息 -->
      <h3 class="oldh" v-if="pjOrderDetail.is_old_order==1">光度信息</h3>
      <div class="y-order-xx clearfix" v-if="pjOrderDetail.is_old_order==1 && guangdu">
        <p class="clearfix">
          <i>眼镜用途</i>
          <span>{{guangdu.type ==0?'近用':guangdu.type ==1?'远用':'渐进'}}</span>
        </p>
        <p class="clearfix">
          <i>主导眼</i>
          <span>{{guangdu.dominance==0?'左眼主导L':'右眼主导R'}}</span>
        </p>
        <p class="clearfix">
          <i>球镜</i>

          <span class="left">左</span>
          <span>{{guangdu.left_q_mirror}}</span>

          <span class="right">右</span>
          <span>{{guangdu.rigth_q_mirror}}</span>
        </p>
        <p class="clearfix">
          <i>柱镜</i>

          <span class="left">左</span>
          <span>{{guangdu.left_z_mirror}}</span>

          <span class="right">右</span>
          <span>{{guangdu.right_z_mirror}}</span>
        </p>
        <p class="clearfix">
          <i>轴位</i>

          <span class="left">左</span>
          <span>{{guangdu.left_axis}}</span>

          <span class="right">右</span>
          <span>{{guangdu.right_axis}}</span>
        </p>
        <p class="clearfix">
          <i>矫正视力</i>

          <span class="left">左</span>
          <span>{{guangdu.left_vision}}</span>

          <span class="right">右</span>
          <span>{{guangdu.right_vision}}</span>
        </p>
        <p class="clearfix">
          <i>ADD</i>
          <span>{{guangdu.ADD}}</span>
        </p>
        <p class="clearfix">
          <i>瞳高</i>

          <span class="left">左</span>
          <span>{{guangdu.left_Pupil_height}}</span>

          <span class="right">右</span>
          <span>{{guangdu.right_Pupil_height}}</span>
        </p>
        <p class="clearfix">
          <i>耳距</i>
          <span>{{guangdu.er_distance}}</span>
        </p>
        <p class="clearfix">
          <i>脸宽</i>
          <span>{{guangdu.face_width}}</span>
        </p>
        <p class="clearfix">
          <i>瞳距</i>
          <span class="left">左</span>
          <span>{{guangdu.pdL}}</span>
          <span class="right">右</span>
          <span>{{guangdu.pdR}}</span>
        </p>
      </div>

      <!-- 产品+消费信息 -->
      <h3 class="oldh" v-if="pjOrderDetail.is_old_order==1">产品+消费信息</h3>
      <div class="y-order-xx clearfix" v-if="pjOrderDetail.is_old_order==1">
        <p class="clearfix">
          <i>商品</i>
          <span
            class="name"
            v-for="(olditem,index) in old_orderInfo.goodsInfo"
            :key="index"
          >{{olditem.t_product.series}} {{olditem.t_product.title}}</span>
          <span class="mon f-r">{{pjOrderDetail.goods_total_price}}元</span>
        </p>
        <!-- <p class="clearfix">
          <i>验光</i>
          <span class="name">配镜验光服务</span>
          <span class="mon f-r">0.00元</span>
        </p>-->
        <p class="clearfix">
          <i>优惠折扣</i>
          <span
            class="mon f-r"
          >{{(pjOrderDetail.final_pay_price-pjOrderDetail.goods_total_price).toFixed(2)}}元</span>
        </p>
        <p class="clearfix">
          <i>总计</i>
          <span class="mon f-r">{{pjOrderDetail.final_pay_price}}元</span>
        </p>
      </div>
      <!-- 评价 -->
      <div class="y-foot-bottom" v-if="bigStatus == 3 && pjOrderDetail.zj_order.is_refund == 0">
        <van-tabbar v-model="active" class="y-order-foot">
          <!-- <router-link> -->
          <van-button
            @click="toOrder_evaluate"
            v-if="pjOrderDetail.is_comment == 1"
            round
            size="small"
            color="#35B6D8"
            plain
            style="float: right; padding:5px 0;"
          >查看评价</van-button>
          <van-button
            v-if="pjOrderDetail.is_comment == 0"
            round
            size="small"
            color="#35B6D8"
            plain
            style="float: right; padding:5px 0;"
            @click="toOrder_comment"
          >评价</van-button>
        </van-tabbar>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      guangdu: {},
      zkyoumoney: 0,
      priceall: 0,
      zhekou: 0, //会员折扣
      paypriceall: 0,
      sfprice: 0,
      old_orderInfo: {},
      active: "",
      show: false,
      back: false,
      pjOrderDetail: {
        yg_order: {
          member: {
            default_address: {
              name: ""
            }
          }
        },
        zj_order: { order_sn: "" }
      },
      bigStatus: "",
      goodsthumb: ""
    };
  },
  created: function() {
    this.getPjOrderDetail();
  },
  methods: {
    //跳转到评价
    toOrder_comment() {
      var id = this.$route.query.id;
      var bigStatus = this.$route.query.bigStatus;
      // console.log("id", id);
      this.$router.push({
        path: "/order_comment",
        query: {
          id: id,
          status: 0,
          type: 2
        }
      });
    },
    //跳转到查看评价
    toOrder_evaluate() {
      this.$router.push({
        path: "/order_evaluate",
        query: {
          order_sn: this.pjOrderDetail.order_sn
        }
      });
    },
    //配镜订单详情接口
    getPjOrderDetail() {
      var params = {
        token: this.$store.getters.token,
        id: this.$route.query.id
      };
      this.$api.pjorder.pjOrderDetail(params).then(res => {
        this.pjOrderDetail = res.result;
        for (var p in this.pjOrderDetail.pj_order_goods) {
          var price =
            this.pjOrderDetail.pj_order_goods[p].goods_spec.member_price *
            this.pjOrderDetail.pj_order_goods[p].num;
          this.priceall += price;
          var payprice =
            this.pjOrderDetail.pj_order_goods[p].goods_spec.member_discount *
            this.pjOrderDetail.pj_order_goods[p].num;
          this.paypriceall += payprice; //商品总额
        }
        this.zkyoumoney = this.pjOrderDetail.member_level
          ? (this.paypriceall +
              this.pjOrderDetail.peisong_price * 1 -
              this.pjOrderDetail.use_score_number / 100 -
              this.pjOrderDetail.preferential) *
            (1 - this.pjOrderDetail.member_level.discount / 10)
          : 0; //会员等级折扣优惠金额
        this.sfprice =
          this.paypriceall +
          this.pjOrderDetail.peisong_price * 1 -
          this.pjOrderDetail.use_score_number / 100 -
          this.pjOrderDetail.preferential * 1 -
          this.zkyoumoney * 1 -
          this.pjOrderDetail.staff_discount_price * 1;
        this.bigStatus = this.$route.query.bigStatus;
        // 会员折扣
        this.zhekou =
          this.priceall -
          this.pjOrderDetail.peisong_price * 1 -
          this.pjOrderDetail.preferential * 1 -
          this.pjOrderDetail.use_score_number / 100 -
          this.sfprice;
        // 老信息
        // console.log("this.pjOrderDetail",this.pjOrderDetail);
        if (this.pjOrderDetail.is_old_order == 1) {
          var oldinfo = this.pjOrderDetail.old_order_info;
          this.old_orderInfo = JSON.parse(oldinfo);
          console.log("老信息", this.old_orderInfo);
          var guang = this.old_orderInfo.guangdu;
          this.guangdu = JSON.parse(guang);
          // console.log("光度", this.guangdu);
        } else {
          // 新信息
          for (var i in this.pjOrderDetail.pj_order_goods) {
            var pjpic = this.pjOrderDetail.pj_order_goods[i].goods.thumb;
            if (pjpic.indexOf("http") == -1) {
              this.pjOrderDetail.pj_order_goods[i].goods.goodsthumb =
                this.$publicData.imgUrl + pjpic;
            } else {
              this.pjOrderDetail.pj_order_goods[i].goods.goodsthumb = pjpic;
            }
          }
          if (this.pjOrderDetail.yg_order.member.default_address == null) {
            this.pjOrderDetail.yg_order.member.default_address = {
              name: this.pjOrderDetail.yg_order.member.name,
              phone: this.pjOrderDetail.yg_order.member.phone,
              address: ""
            };
          }
        }
      });
    },
    showPopup() {
      this.show = true;
    }
    // TabBar() {
    //   var userOld = document.getElementsByClassName("y-order-1")[0];
    //   var that = this;
    //   // console.log("sss",userOld.scrollHeight);
    //   if (userOld.scrollHeight - scrollY <= 100) {
    //     that.back = true;
    //   } else {
    //     that.back = false;
    //   }
    // }
  },
  mounted() {
    // window.addEventListener("scroll", this.TabBar);
  }
};
</script>
<style scoped>
/* .orderdetails .van-nav-bar{
  position: absolute;
  width: 100%;
}
.orderdetails .van-card__title {
  margin-bottom: 0.15rem;
}
.orderdetails .back {
  background: #fff !important;
}
.orderdetails .back .van-icon {
  color: #000 !important;
}
.orderdetails ::v-deep.back .van-nav-bar__title {
  color: #000 !important;
}
.orderdetails .y-foot-bottom {
  width: 100%;
  float: left;
  height: 60px;
}
.orderdetails .y-order-foot {
  width: 94%;
  padding-left: 3%;
  float: left;
  border-top: 1px #eeeeee solid;
}
.orderdetails .y-order-foot .van-button {
  width: 82px;
  float: right;
  height: 25px;
  margin: 11px 5px;
}

.orderdetails .y-paymoney {
  text-align: right;
}
.orderdetails .y-paymoney span {
  width: auto !important;
  font-size: 0.35rem !important;
  font-weight: bold;
  color: #ee1f0f !important;
}
.orderdetails .y-user-olddate p {
  width: 92%;
  margin-left: 4%;
  font-size: 0.35rem;
  padding: 7px 0px;
}
.orderdetails .y-user-olddate p span {
  width: 110px;
  font-size: 0.35rem;
  color: #000;
  float: right;
  text-align: center;
}
.orderdetails .y-user-olddate {
  padding: 6px 0px;
  font-size: 0.35rem;
  width: 100%;
}
.orderdetails .y-user-old1 {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 15px 0px;
  color: #999;
  margin-top: 10px;
  box-shadow: 0px 2px 5px #eeeeee;
}
.orderdetails ::v-deep .van-nav-bar__title {
  color: #ffffff !important;
}
.orderdetails .van-popup--bottom {
  width: 90%;
  padding: 10px 5% 0px 5%;
}
.orderdetails .van-cell {
  border: 1px #eeeeee solid;
  padding: 8px 15px !important;
  margin: 40px 0px 15px 0px;
}
.orderdetails ::v-deep .van-popup__close-icon {
  color: #999 !important;
  margin-top: -10px;
}
.orderdetails .y-order-s,
.orderdetails .y-order-m {
  padding: 8px 18px;
  border: 1px #35b6d8 solid;
  float: left;
}
.orderdetails .y-order-m {
  border-radius: 0px 20px 20px 0px;
}
.orderdetails .y-order-m img {
  width: 19px;
  float: left;
}
.orderdetails .y-order-s {
  border-radius: 20px 0px 0px 20px;
  color: #35b6d8;
  font-size: 0.35rem;
  margin-right: 10px;
}

.orderdetails .y-bottom20 {
  margin-bottom: 20px;
}

.orderdetails .y-order-xx p i {
  width: 70px;
  float: left;
  font-size: 0.35rem;
  color: #999;
}
.orderdetails .y-order-xx p span {
  font-size: 0.35rem;
  color: #000;
}
.orderdetails .y-order-xx p {
  width: 92%;
  margin-left: 4%;
  padding: 7px 0px;
}
.orderdetails .y-order-yg {
  width: 100%;
  padding-left: 4%;
  padding-right: 4%;
  float: left;
  font-size: 0.35rem;
  padding-bottom: 0.2rem;
  border-bottom: 1px #eeeeee solid;
  box-sizing: border-box;
}
.orderdetails .y-order-yg p {
  float: left;
  color: #000;
}
.orderdetails .y-order-yg span {
  margin-left: 40px;
  color: #999;
  display: block;
  margin-bottom: 0.2rem;
}
.orderdetails .y-order-list {
  margin-top: 10px;
  padding-bottom: 15px;
}
.orderdetails .y-order-top p {
  font-size: 0.35rem;
  padding: 8px 20px;
  color: #000;
}
.orderdetails .y-user-old1,
.orderdetails .y-order-xx {
  width: 94%;
  margin-left: 3%;
  background: #ffffff;
  border-radius: 8px;
  float: left;
  padding: 15px 0px;
  color: #999;
  margin-top: 10px;
  box-shadow: 0px 2px 5px #eeeeee;
}
.orderdetails .van-card {
  background-color: #ffffff;
}
.orderdetails .y-xzcptop-img .van-image {
  width: 90px !important;
  height: 90px !important;
  border: 1px #eeeeee solid;
  border-radius: 8px;
}
.orderdetails .van-tag--default {
  margin-right: 10px;
  margin-bottom: 10px;
}
.orderdetails .van-card__thumb {
  margin-right: 18px;
}
.orderdetails .y-order1-con span {
  color: #999;
  padding-top: 10px;
  float: left;
}
.orderdetails .y-order1-con p {
  color: #000;
}
.orderdetails .y-order1-con {
  padding: 0px 10px;
  width: 83%;
  text-align: left;
  font-size: 0.35rem;
}
.orderdetails .y-order1-right .van-icon {
  color: #999 !important;
}
.orderdetails .y-order1-left .van-icon {
  color: #000 !important;
}
.orderdetails .y-order-1 {
  width: 90%;
  min-height: 1.6rem !important;
  float: left;
  margin-left: 3%;
  padding: 15px 2%;
  background: #ffffff;
  border-radius: 8px;
  height: auto;
  box-shadow: 0px 2px 5px #eeeeee;
  text-align: center;
  margin-top: 10px;
}
.orderdetails .y-order-det {
  width: 94%;
  margin-left: 3%;
  margin-top: 70px;
  color: #ffffff;
  font-size: 0.35rem;
  line-height: 20px;
}
.orderdetails .y-order-det img {
  width: 0.5rem;
  float: left;
  margin: 0px 8px;
}
.orderdetails .y-order-det p {
  color: #fff;
}
.orderdetails .van-nav-bar {
  background-color: transparent;
}
.orderdetails .van-icon {
  color: #ffffff !important;
}
.orderdetails .y-usertop {
  float: left;
  background-image: url(../../assets/images/pre-admin-bg.png);
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #f3f4f9;
  padding-bottom: 0.6rem;
}
.orderdetails .y-por-rectop {
  color: #ffffff;
  margin-top: 30px;
  width: 94%;
  margin-left: 3%;
}
.orderdetails .y-setuptop {
  float: left;
  margin-bottom: 0.2rem;
  padding: 12px 0px;
  font-size: 0.45rem;
} */
</style>