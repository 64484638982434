<template>
  <!-- 我的订单-查看评价 -->

  <div class="viewComments">
    <!-- 返回键 -->
    <van-nav-bar
      :z-index="99"
      :border="false"
      title="评价详情"
      :fixed="false"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <!-- 商品 -->
    <div class="commentsGoods jiejie-order-goods">
      <div class="ordergoods clearfix" v-for="(item,index) in goodsList" :key="index">
        <van-image :src="item.goods.goodsthumb" fit="cover" />
        <div class="goods-right">
          <span class="goods-title">{{item.goods.title}}</span>
          <span>{{item.goods_spec.goods_spec_name}}</span>
        </div>
      </div>
    </div>
    <!-- 评星 -->
    <div class="comments-score jiejie-score">
      <van-rate
        v-model="score"
        :size="25"
        color="#EE904F"
        void-icon="star"
        void-color="#ddd"
        :readonly="true"
      />
      <span class="order-satisfied">{{scoretext}}</span>
      <div class="order-btn">
        <van-button
          round
          type="info"
          class="btn-active"
          v-for="(item,index) in tagArr"
          :key="index"
        >{{item}}</van-button>
      </div>
    </div>
    <!-- 用户评价 -->
    <div class="comments-user">
      <span class="user-time">{{chatime}}前</span>
      <div class="user-name">
        <van-image :src="memberuser.memberthumb" round fit="cover" />
        <div class="name-right">
          <span class="name">{{memberuser.name}}</span>
          <div class="memberdesc">
            <span v-for="(item,index) in goodsList" :key="index">{{item.goods_spec.goods_spec_name}}</span>
          </div>
        </div>
      </div>
      <p>{{commentDetail.content}}</p>
      <!-- <van-image :src="img_recommend" fit="cover" class="user-pic" /> -->
      <van-image
        v-for="(item,index) in commentDetail.alumList"
        @click="clickImage(index)"
        :key="index"
        :src="item"
        fit="cover"
        class="user-pic"
      />
    </div>
    <!-- 查看大图 -->
    <van-image-preview
      v-model="showBigImg"
      :images="commentDetail.alumList"
      :startPosition="bigImgindex"
    ></van-image-preview>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scoretext: "满意",
      showBigImg: false,
      bigImgindex: 0,
      img_yanjing: require("../../assets/images/1.jpg"),
      score: 5,
      img_portrait: require("../../assets/images/1.jpg"),
      img_recommend: require("../../assets/images/1.jpg"),
      commentDetail: {},
      goodsList: [],
      tagArr: [],
      memberuser: {},
      memberthumb: "",
      goodsthumb: "", //商品图片
      alumList: [],
      chatime: ""
    };
  },
  created: function() {
    this.getCommentDetail();
  },
  methods: {
    // 点击图片
    clickImage(index) {
      this.showBigImg = true;
      this.bigImgindex = index;
    },
    //   查看评价接口
    getCommentDetail() {
      var params = {
        token: this.$store.getters.token,
        order_sn: this.$route.query.order_sn
      };
      this.$api.pjorder.commentDetail(params).then(res => {
        this.commentDetail = res.result;
        if (res.result.pjOrderInfo == null) {
          this.goodsList = res.result.memberOrderInfo.member_order_goods;
          this.memberuser = res.result.memberOrderInfo.member;
        } else {
          this.goodsList = res.result.pjOrderInfo.pj_order_goods;
          this.memberuser = res.result.pjOrderInfo.member;
        }
        this.score = this.commentDetail.star;
        if (this.score == 5) {
          this.scoretext = "满意";
        } else if (this.score == 4) {
          this.scoretext = "较满意";
        } else if (this.score == 3) {
          this.scoretext = "一般";
        } else if (this.score == 2) {
          this.scoretext = "差";
        } else {
          this.scoretext = "非常差";
        }
        this.tagArr = res.result.tagArr;

        this.chatime = this.intervalTime(
          new Date(res.result.updated_at.replace(/-/g, "/")).valueOf(),
          new Date().valueOf()
        );
        // console.log("chatime",this.chatime);
        //  商品图片
        for (var i in this.goodsList) {
          var goodspic = this.goodsList[i].goods.thumb;
          if (goodspic.indexOf("http") == -1) {
            this.goodsList[i].goods.goodsthumb =
              this.$publicData.imgUrl + goodspic;
          } else {
            this.goodsList[i].goods.goodsthumb = goodspic;
          }
        }
        // 会员头像
        var memberpic = this.memberuser.thumb;
        if (memberpic.indexOf("http") == -1) {
          this.memberuser.memberthumb = this.$publicData.imgUrl + memberpic;
        } else {
          this.memberuser.memberthumb = memberpic;
        }
        //评论图片
        if (res.result.alum != null) {
          this.commentDetail.alumList = [];
          for (var j in this.commentDetail.alumArr) {
            var arritem = this.commentDetail.alumArr[j];
            if (arritem.indexOf("http") == -1) {
              var alum = this.$publicData.imgUrl + arritem;
              this.commentDetail.alumList.push(alum);
            } else {
              this.commentDetail.alumList.push(arritem);
            }
          }
          // console.log("评价图",this.commentDetail.alumList);
        }
      });
    },
    intervalTime(currentTimsmap, endTime) {
      // console.log(currentTimsmap, endTime);
      var subTime = endTime - currentTimsmap;
      // console.log(subTime);
      if (subTime > 0) {
        //  相差的天数
        var titmeTotal = subTime; //时间差的毫秒数
        var days = Math.floor(titmeTotal / (24 * 3600 * 1000));
        // 相差的小时数
        var leave1 = titmeTotal % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        var hours = Math.floor(leave1 / (3600 * 1000));
        //  计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000));
        //  计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟后剩余的毫秒数
        var seconds = Math.round(leave3 / 1000);

        // days + "天" + hours + "小时" + minutes + "分"
        return days + "天" + hours + "小时" + minutes + "分";
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
/* .viewComments .ordergoods {
  margin-bottom: 0.3rem;
}
.viewComments .van-nav-bar {
  background: transparent;
}
.viewComments .jiejie-order-goods .goods-right span {
  font-size: 0.35rem;
  color: #000000;
  opacity: 0.6;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.1rem;
}
.viewComments .jiejie-order-goods .goods-right .goods-title {
  opacity: 1;
  font-size: 0.35rem;
}
.viewComments .jiejie-score {
  background: #fff;
  margin-top: 0.4rem;
  border-radius: 0.27rem;
  box-shadow: 0rem 0.07rem 0.21rem 0rem rgb(0 0 0 / 7%);
  padding: 0.52rem 0rem 0.2rem 0.4rem;
}
.viewComments .jiejie-score .van-rate {
  margin-left: 1.5rem;
  margin-right: 0.5rem;
}
.viewComments .jiejie-score .order-satisfied {
  vertical-align: super;
  font-size: 0.4rem;
  color: #ee904f;
}
.viewComments .jiejie-score .order-btn {
  margin-top: 0.5rem;
}
.viewComments .jiejie-score .order-btn .van-button {
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
  border-color: #666;
  height: 0.67rem;
  margin-right: 0.3rem;
  border-radius: 0.33rem;
  margin-bottom: 0.3rem;
}
.viewComments .jiejie-score .order-btn .btn-active {
  background: #fdf1ed;
  border-color: #ee904f;
}
.viewComments .jiejie-score .order-btn .btn-active span {
  color: #ee904f;
}
.viewComments .jiejie-order-goods .goods-right {
  float: left;
  width: 6.45rem;
  padding-top: 0.3rem;
}
.viewComments .jiejie-order-goods {
  padding: 0.4rem;
}
.viewComments .jiejie-order-goods .van-image {
  border: 0.03rem solid #e5e5e5;
  margin-right: 0.26rem;
  border-radius: 0.13rem;
  width: 1.5rem;
  height: 1.5rem;
  float: left;
}
.viewComments .jiejie-order-goods .van-image img {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.13rem;
}
.viewComments {
  background: #f3f4f9;
  min-height: 100vh;
  padding: 0 0.4rem 0.4rem;
}
.viewComments .commentsGoods {
  background: #fff;
  border-radius: 0.27rem;
  box-shadow: 0rem 0.07rem 0.21rem 0rem rgba(0, 0, 0, 0.07);
  margin-top: 0.6rem;
  margin-bottom: 0.4rem;
}
.viewComments .comments-user {
  position: relative;
  background: #fff;
  margin-top: 0.4rem;
  border-radius: 0.27rem;
  box-shadow: 0rem 0.07rem 0.21rem 0rem rgba(0, 0, 0, 0.07);
  padding: 0.3rem 0.4rem 0.3rem;
}
.viewComments .comments-user .user-time {
  position: absolute;
  top: 0.45rem;
  right: 0.4rem;
  font-size: 0.35rem;
  opacity: 0.3;
}
.viewComments .comments-user .user-name {
  display: flex;
}
.viewComments .comments-user .user-name .van-image {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.27rem;
}
.viewComments .comments-user .user-name .name-right {
  padding-top: 0.2rem;
}
.viewComments .comments-user .user-name .name-right span {
  font-size: 0.35rem;
  opacity: 0.5;
}
.viewComments .comments-user .user-name .name-right .name {
  font-size: 0.37rem;
  opacity: 1;
}
.viewComments .comments-user p {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  font-size: 0.35rem;
  opacity: 0.9;
}
.viewComments .comments-user .user-pic {
  width: 2.5rem;
  height: 2.5rem;
}
.viewComments .comments-user .user-pic img {
  border-radius: 0.13rem;
}
.viewComments .memberdesc {
  display: block;
  white-space: nowrap;
  width: 5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.15rem;
}
.viewComments .memberdesc span {
  padding: 0 0.2rem;
  border-right: 0.01rem solid #333;
}
.viewComments .memberdesc span:nth-of-type(1) {
  padding-left: 0;
}
.viewComments .memberdesc :nth-last-child(1) {
  border-right: 0;
} */
</style>