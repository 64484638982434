<template>
  <div class="EditUserINfo integraladd">
    <van-nav-bar
      :z-index="99"
      title="填写订单"
      :border="false"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <!-- 地址 -->
    <div class="y-integral_add-1" @click="toAddress">
      <!-- <router-link to="receiving_information"> -->
      <div class="y-integral_add-1-icon f-l">
        <van-icon name="location-o" color="#36B7D7" size="25px" />
      </div>
      <div
        class="y-integral_add-1-z f-l"
        v-if="goodsDetail.member_address==null || goodsDetail.member_address.address == null"
      >
        <p>请填写收货地址</p>
      </div>
      <div
        class="y-integral_add-1-z f-l"
        v-if="goodsDetail.member_address && goodsDetail.member_address.address"
      >
        <p>{{goodsDetail.member_address.address}}</p>
        <span>{{goodsDetail.member_address.name}} {{goodsDetail.member_address.phone}}</span>
      </div>
      <div class="y-integral_add-1-icon2 f-l">
        <van-icon name="arrow" />
      </div>
      <!-- </router-link> -->
    </div>
    <div class="y-xx"></div>
    <!-- 商品 -->
    <div class="y-integral_add-card f-l">
      <van-card
        v-for="(item, index) in goodsDetail.member_order_goods"
        :key="index"
        :num="item.num"
        :title="item.goods.title"
        :thumb="ToPic(item.goods.thumb)"
      >
        <template #tags>
          <van-tag plain type="danger">{{item.goods_spec.goods_spec_name}}</van-tag>
        </template>
        <template #price>
          <span>{{item.score_price}}积分</span>
        </template>
      </van-card>
    </div>
    <div class="y-fg f-l"></div>
    <!-- 运费 -->
    <div>
      <van-cell title="运费">
        <template #default>
          <span>{{goodsDetail.yunfeiScore}}积分</span>
        </template>
      </van-cell>
    </div>

    <van-tabbar v-model="active">
      <p class="y-integral_add-bu-p">
        {{goodsDetail.use_score_number}}
        <i>积分</i>
      </p>
      <van-button
        type="primary"
        block
        round
        color="linear-gradient(to right, #F28E26, #FD644F)"
        class="y-integral_info-buttom"
        @click="toDui"
      >立即兑换</van-button>
    </van-tabbar>
    <!-- 加载框 -->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showloading:false,
      active: "",
      goodsDetail: {
        member_address: {},
        member_order_goods: {}
      }
    };
  },
  created: function() {
    this.getDetail();
    this.getAddress();
  },
  methods: {
    ToPic(pic) {
      if (pic && pic.indexOf("http") == -1) {
        return this.$publicData.imgUrl + pic;
      } else {
        return pic;
      }
    },
    // 点击立即兑换
    toDui() {
      var params = {
        token: this.$store.getters.token,
        order_id: this.$route.query.id
      };
      this.$api.score.payScoreMemberOrder(params).then(res => {
        if (res.code == 200) {
          this.$router.push({
            path: "/integral_paysuccess"
          });
        }
      });
    },
    //修改地址接口
    getAddress() {
      if (this.$route.query.address_id) {
        this.showloading = true;
        var param = {
          token: this.$store.getters.token,
          order_id: this.$route.query.id,
          address_id: this.$route.query.address_id
        };
        this.$api.goodsOrder.setAddress(param).then(res => {
          this.showloading = false;
          if (res.code == 200) {
            this.getDetail();
          }
        });
      }
    },
    //跳转到收货地址列表
    toAddress() {
      var that = this;
      that.$router.push({
        path: "/address",
        query: {
          id: that.$route.query.id,
          status: 2
        }
      });
    },
    // 商品详情
    getDetail() {
      this.showloading = true;
      var params = {
        token: this.$store.getters.token,
        order_id: this.$route.query.id
      };
      this.$api.goodsOrder.detail(params).then(res => {
        this.showloading = false;
        if (res.code == 200) {
          this.goodsDetail = res.result;
        }
      });
    }
  }
};
</script>
<style  scoped>
/* .integraladd ::v-deep .van-nav-bar {
  background-color: #fff !important;
}
.integraladd .van-tabbar{
  border-top: 1px #eeeeee solid;
}
.integraladd .y-integral_add-bu-p i{
  font-size: 0.35rem;
 
}
.integraladd .y-integral_add-bu-p{
  float: left;
  color:#EE904F ;
  font-size: 0.35rem;
   padding: 13px 10px;
   width: 70%;
}
.integraladd .y-integral_info-buttom{
  width: 110px;
  margin: 7px 3%;
  height: 35px;
  float: right;
}
.integraladd .y-integral_add-card .van-card__num{
padding-top: 10px;
color: #000;
}
.integraladd .y-integral_add-card .van-card__origin-price{
font-size: 0.35rem;
color: #000;
padding-top: 10px;
text-decoration:none;
}
.integraladd .y-integral_add-card  .van-tag{
background-color:#F3F4F9 ;
border: 1px #F3F4F9 solid;
color: #cccccc;
padding: 2px 18px;
border-radius: 12px;
margin-top: 3px;
}
.integraladd .y-integral_add-card .van-card__title{
font-size: 0.35rem;
line-height: 20px;
max-height: 52px;
width: 85%;
 display: -webkit-box; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 2; 
    overflow: hidden;
}
.integraladd .y-integral_add-card .van-image{
  border: 1px #eeeeee solid;
}
.integraladd .y-integral_add-card .van-card{
  background-color: transparent;
}
.integraladd .y-integral_add-card{
   width: 100%;
  float: left;
  margin: 14px 0px;
}
.integraladd .y-integral_add-1-icon2 {
  float: right;
  margin-top: 5px;
}
.integraladd .y-integral_add-1-z span {
  font-size: 0.35rem;
  color: #999;
  width: 100%;
  float: left;
  margin-top: 10px;
}
.integraladd .y-integral_add-1-z {
  font-size: 0.35rem;
  width: 80%;
}
.integraladd .y-xx {
  width: 100%;
  height: 2px;
  background: -webkit-repeating-linear-gradient(
    135deg,
    #c72b1d 0,
    #c72b1d 20%,
    transparent 0,
    transparent 25%,
    #36b7d7 0,
    #36b7d7 45%,
    transparent 0,
    transparent 50%
  );
  background: repeating-linear-gradient(
    -45deg,
    #c72b1d 0,
    #c72b1d 20%,
    transparent 0,
    transparent 25%,
    #36b7d7 0,
    #36b7d7 45%,
    transparent 0,
    transparent 50%
  );
  background-size: 80px;
  content: "";
  float: left;
}
.integraladd .y-integral_add-1 {
  width: 94%;
  float: left;
  padding: 20px 3%;
}
.integraladd .y-integral_add-1-icon {
  margin-right: 8px;
} */
</style>