<template>
  <div class="EditUserINfo communityInfo">
    <van-nav-bar
      :z-index="99"
      title="种草社区"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y-community_info">
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(item, index) in alumpic" :key="index">
          <img v-lazy="item" />
        </van-swipe-item>
      </van-swipe>
      <div class="y-community_info1">
        <h2>
          <span>{{communityDetail.name}}</span>
          <i>发表于 {{communityDetail.updated_at}}</i>
        </h2>
        <div class="y-community_info2">
          <p>{{communityDetail.desc}}</p>
          <!-- <p>
            在日常生活中，如果你知道一些用眼卫生知识，那对你的眼睛来说可以是一生受用不
            尽，以下就是常用且相当简单的日常眼睛保健常识。
          </p>
          <p>
            长时间与电脑接触的朋友们，是不是经常感觉眼睛很累，如果你按照以下的步骤检查
            一下，那会改观很多的
          </p>
          <p>
            1、调整荧幕的对比和明亮度可以帮助减少电脑所引起的眼睛问题，因为荧幕闪烁太快
            或太慢都将导致眼睛的不适。荧幕扫描频率应大于每秒60次，如果扫描频率太慢将会
            造成荧幕闪烁，进一步造成眼睛不适。
          </p>
          <p>
            2、一般人正常的阅读角度约为俯角40度。一般说来直视电脑荧幕是一种不自然的角度，
            所以容易引起疲倦、眼睛酸痛、甚至头疼。
          </p>
          <p>
            3、在光线部份，以看得清楚及舒适为原则。并需注意灯光、日光灯、阳光、反光表面及
            亮体等造成反光的光源，同时应注意荧幕位置，以看不到任何光线反射为准。
          </p>
          <p>
            4、在调整电脑的同时，你个人也要注意自我保护的常识，只要你遵照以下“3B”护眼法，
            准保你眼睛亮晶晶。长时间凝视电脑，会减少眼睛眨动，引起眼睛的干燥，因此平时应
            多眨动眼睛，保持眼睛湿润。
          </p>-->
        </div>
      </div>

      <van-tabbar v-model="active">
        <div class="y-community-admin">
          <!-- <img src="https://img01.yzcdn.cn/vant/cat.jpeg" /> -->
          <img :src="communityDetail.member.memberthumb" />
          <span>{{communityDetail.member.name}}</span>
          <h2 @click="community_zan(communityDetail.id)">
            <van-icon v-show="communityDetail.is_zan == '0'" name="like-o" size="24px" color="#999" />
            <i v-show="communityDetail.is_zan == 0">{{communityDetail.zan}}</i>
            <van-icon v-show="communityDetail.is_zan == '1'" name="like" size="24px" color="red" />
            <i style="color:red;" v-show="communityDetail.is_zan == 1">{{communityDetail.zan}}</i>
          </h2>
        </div>
      </van-tabbar>
    </div>
     <!-- 加载框 -->
      <!-- <van-popup class="jiazai" v-model="showloading">
        <van-loading type="spinner" />
      </van-popup> -->
       <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showloading:false,
      active: 0,
      images: [
        "https://img01.yzcdn.cn/vant/cat.jpeg",
        "https://img01.yzcdn.cn/vant/apple-2.jpg"
      ],
      communityDetail: {
        member:{
          memberthumb:""
        }
      },
      alumpic: [],
      // memberthumb:""
    };
  },
  created: function() {
    this.getCommunityDetail();
  },
  methods: {
    getCommunityDetail() {
      this.showloading=true;
      var params = {
        token: this.$store.getters.token,
        id: this.$route.query.id
      };
      this.$api.enjoylife.communityDetail(params).then(res => {
        if (res.code == 200) {
          this.showloading=false;
          this.communityDetail = res.result;
          var tempArr = res.result.alumArr;
          this.alumpic=[];
          for (var i in tempArr) {
            var showalum = tempArr[i];
            if (showalum.indexOf("http") == -1) {
              this.alumpic.push(this.$publicData.imgUrl + showalum) 
            }
             else{
              this.alumpic.push(showalum);
             }
          }
          var showmember = this.communityDetail.member.thumb;
         if(showmember.indexOf("http")==-1){
            this.communityDetail.member.memberthumb = this.$publicData.imgUrl + showmember
         }else{
            this.communityDetail.member.memberthumb = showmember
         }
        }
      });
    },
    //点赞
    community_zan(id) {
      var params = {
        token: this.$store.getters.token,
        id: id
      };
      this.$api.enjoylife.communityZan(params).then(res => {
        if (res.code == 200) {
          this.getCommunityDetail();
        }
      });
    }
  }
};
</script>
<style scoped>
/* .communityInfo .y-community-admin {
  width: 94%;
  margin: 10px 3%;
  float: left;
}
.communityInfo .van-tabbar {
  border-top: 1px #eeeeee solid;
}
.communityInfo .y-community-admin h2 {
  float: right;
}
.communityInfo .y-community-admin h2 .van-icon:hover {
  color: red;
}
.communityInfo .y-community-admin h2 .van-icon {
  margin: 4px 2px 0px 0px;
}
.communityInfo .y-community-admin h2 i {
  font-size: 0.34rem;
  color: #000;
  float: left;
  margin-top: 7px;
}
.communityInfo ::v-deep .van-nav-bar{
  background-color: #fff !important;
}
.communityInfo .y-community-admin span {
  font-size: 0.34rem;
  color: #000;
  float: left;
  line-height: 30px;
  margin-left: 5px;
}
.communityInfo .y-community-admin img {
  width: 30px !important;
  height: 30px;
  float: left;
  border-radius: 30px !important;
}

.communityInfo .y-community_info2 {
  margin-top: 10px;
  font-size: 0.34rem;
  line-height: 21px;
  width: 100%;
  float: left;
  margin-bottom: 70px;
}
.communityInfo .y-community_info2 p {
  color: #666;
  text-indent: 2em;
}
.communityInfo .y-community_info1 h2 span {
  font-size: 0.34rem;
  margin-bottom: 10px;
  width: 100%;
  float: left;
}

.communityInfo .y-community_info1 h2 i {
  font-size: 0.34rem;
  color: #999;
  float: left;
  margin-bottom: 14px;
}
.communityInfo .y-community_info1 h2 {
  text-align: left;
}
.communityInfo .y-community_info1 {
  width: 100%;
  float: left;
  margin-top: 20px;
}
.communityInfo .van-swipe img {
  width: 100%;
  border-radius: 10px;
  height: 12.27rem;
}
.communityInfo .y-community_info {
  width: 94%;
  margin-left: 3%;
  float: left;
  margin-top: 10px;
} */
</style>