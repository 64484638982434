/**
 * 捷捷商城订单接口
 */
import axios from "../http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块，把请求字段直接映射过来
const goodsOrder = {
  //商品详情直接下单
  directCreate(params) {
    return axios.post(
      "/api/member/goodsOrder/directCreate",
      qs.stringify(params)
    );
  },
  //购物车下单
  cartsCreate(params) {
    return axios.post(
      "/api/member/goodsOrder/cartsCreate",
      qs.stringify(params)
    );
  },
  //商品订单列表
  list(params) {
    return axios.post("/api/member/goodsOrder/list", qs.stringify(params));
  },
  //取消订单
  cancel(params) {
    return axios.post("/api/member/goodsOrder/cancel", qs.stringify(params));
  },
  //订单设置收货地址
  setAddress(params) {
    return axios.post(
      "/api/member/goodsOrder/setAddress",
      qs.stringify(params)
    );
  },
  //支付订单
  pay(params) {
    return axios.post("/api/member/goodsOrder/pay", qs.stringify(params));
  },
  //确认收货
  recive(params) {
    return axios.post("/api/member/goodsOrder/recive", qs.stringify(params));
  },
  //订单详情
  detail(params) {
    return axios.post("/api/member/goodsOrder/detail", qs.stringify(params));
  },
  //优惠券
  memberCoupon(params) {
    return axios.post("/api/member/goodsOrder/memberCoupon", qs.stringify(params));
  },
};
// 导出接口
export default goodsOrder;
