<template>
  <!-- 支付成功 -->
  <div class="paySuccess integralPay">
     <van-nav-bar
      :z-index="99"
      title="兑换结果"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="con">
      <van-icon name="success" />
      <span class="success">恭喜您，兑换成功！</span>
      <van-button round type="info" to="/">返回首页</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // 返回键
    // fanhui() {
    //   var that = this;
    //   that.$router.go(-1);
    // }
  }
};
</script>

<style scoped>
/* .paySuccess .con .van-icon {
  font-size: 2rem;
  line-height: 2.66rem;
  text-align: center;
  width: 2.66rem;
  height: 2.66rem;
  border-radius: 50%;
  overflow: hidden;
  color: #fff;
  background: linear-gradient(97deg, #F28E26, #FD644F);
  margin-top: 150px;
  margin-left: 3.68rem;
}
.paySuccess .con .success {
  display: block;
  text-align: center;
  margin-top: 1.39rem;
  font-size: 0.43rem;
}
.paySuccess .con .money {
  display: block;
  text-align: center;
  font-size: 0.5rem;
  font-weight: 600;
  margin-top: 1.23rem;
}
.paySuccess .con .van-button {
  position: fixed;
  bottom: 30px;
  width: 300px;
  height: 40px;
  background: linear-gradient(97deg, #F28E26, #FD644F);
  border: 0;
  margin-left: 0.99rem;
  box-shadow: 0rem 0.13rem 0.28rem 0rem rgba(53, 182, 216, 0.2);
} */
</style>