<template>
  <div class="EditUserINfo optometryinfo">
    <van-nav-bar
      :z-index="99"
      :fixed="false"
      :border="false"
      :placeholder="true"
      title="视力档案"
      left-arrow
      @click-left="toOptometry"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y_add_storage clearfix">
      <div class="user_info">
        <div class="left">
          <van-image round class="y_user_img" :src="ygRecordDetail.member.memberthumb" />
        </div>
        <div class="right">
          <div class="y_nickname_integral">
            <span class="y_nickname">{{ygRecordDetail.username}}</span>
          </div>
        </div>
        <div class="edit_icon" @click="torevise">
          <img src="../../assets/images/edit.png" alt="pic" />
        </div>
      </div>
      <div class="y-user-old f-l">
        <h2>档案信息</h2>
        <div class="y-user-old1 f-l">
          <div class="y-user-oldtop f-l">
            <h2>
              <img src="../../assets/images/my-ico7.png" />
              <p>{{ygJson.type == 0 ? '近用': '远用'}}，{{ygJson.dominance == 0 ? '左眼主导L': '右眼主导R'}}</p>
            </h2>
          </div>
          <div class="y-user-oldxia f-l y-op_orderxia">
            <p>
              左球镜L
              <span>{{ygJson.left_q_mirror}}</span>
            </p>
            <p>
              右球镜R
              <span>{{ygJson.right_q_mirror}}</span>
            </p>
            <p>
              左柱镜L
              <span>{{ygJson.left_z_mirror}}</span>
            </p>
            <p>
              右柱镜R
              <span>{{ygJson.right_z_mirror}}</span>
            </p>
            <p>
              左轴位L
              <span>{{ygJson.left_axis}}</span>
            </p>
            <p>
              右轴位R
              <span>{{ygJson.right_axis}}</span>
            </p>
            <p>
              左矫正视力L
              <span>{{ygJson.left_vision}}</span>
            </p>
            <p>
              右矫正视力R
              <span>{{ygJson.right_vision}}</span>
            </p>
            <!-- <p>
              瞳距
              <span>{{ygJson.pd}}</span>
            </p>-->
            <p>
              左瞳距L
              <span>{{ygJson.pdL}}</span>
            </p>
            <p>
              右瞳距R
              <span>{{ygJson.pdR}}</span>
            </p>
            <p>
              左瞳高L
              <span>{{ygJson.left_Pupil_height}}</span>
            </p>
            <p>
              右瞳高R
              <span>{{ygJson.right_Pupil_height}}</span>
            </p>
             <p>
              脸宽
              <span>{{ygJson.face_width}}</span>
            </p>
            <p>
              耳距
              <span>{{ygJson.er_distance}}</span>
            </p>
            <p>
              ADD
              <span>{{ygJson.ADD}}</span>
            </p>
          </div>
          <div class="y-con-admin" v-if="ygRecordDetail.yg_order">
            <van-image
              round
              class="y-con-admin_img"
              :src="ygRecordDetail.yg_order.staff.staffthumb"
            />
            <p>
              <span>{{ygRecordDetail.yg_order.staff.name}}</span>
              <i>验光师 {{ygRecordDetail.yg_order.created_at}}</i>
            </p>
            <van-button
              v-if="ygRecordDetail.yg_order.is_comment == 0"
              round
              type="info"
              class="optevaluate"
              @click="toevaluate"
            >
              <img src="../../assets/images/star.png" alt="pic" />
              评价
            </van-button>
            <van-button
              v-if="ygRecordDetail.yg_order.is_comment == 1"
              round
              type="info"
              class="optevaluate"
              @click="opWateval"
            >查看评价</van-button>
          </div>
        </div>
        <!-- <van-button
          v-if="ygRecordDetail.yg_order.is_comment == 1"
          round
          type="info"
          class="watevaluate"
          @click="opWateval"
        >查看评价</van-button>-->
      </div>
    </div>
    <!-- 加载框 -->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showloading: false,
      ygRecordDetail: {
        member: {
          memberthumb: ""
        },
        yg_order: {
          is_comment: "",
          staff:{
            staffthumb:""
          }
        }
      },
      ygJson: {}
    };
  },
  created: function() {
    this.getYgRecordDetail();
  },
  methods: {
    //跳转到视力档案
    toOptometry() {
      var params = {
        token: this.$store.getters.token,
        id: this.$route.query.id,
        belong_name: this.ygRecordDetail.username
        // yg_json: JSON.stringify(this.ygJson)
      };
      this.$api.ygrecord.editYgRecord(params).then(res => {
        this.$router.push({
          path: "/optometry"
        });
      });

      // this.$router.push({
      //   path:"/optometry"
      // })
    },
    //跳转到修改姓名
    torevise() {
      this.$router.push({
        path: "/optometry_revise",
        query: {
          type: 2,
          id: this.$route.query.id,
          name: this.ygRecordDetail.username
        }
      });
    },
    // 验光记录详情接口
    getYgRecordDetail() {
      this.showloading = true;
      var params = {
        token: this.$store.getters.token,
        id: this.$route.query.id
      };
      this.$api.ygrecord.ygRecordDetail(params).then(res => {
        this.showloading = false;
        if (res.code == 200) {
          this.ygRecordDetail = res.result;
          this.ygJson = JSON.parse(JSON.parse(this.ygRecordDetail.yg_json));
          var userinfoname = "";
          if (this.ygRecordDetail.belong_name) {
            userinfoname = this.ygRecordDetail.belong_name;
          } else {
            userinfoname = this.ygRecordDetail.member.name;
          }
          if (this.$route.query.name) {
            var name = this.$route.query.name;
            this.ygRecordDetail.username = name;
          } else {
            this.ygRecordDetail.username = userinfoname;
          }

          if (this.ygRecordDetail.member.thumb.indexOf("http") == -1) {
            this.ygRecordDetail.member.memberthumb =
              this.$publicData.imgUrl + this.ygRecordDetail.member.thumb;
          } else {
            this.ygRecordDetail.member.memberthumb = this.ygRecordDetail.member.thumb;
          }
          if (this.ygRecordDetail.yg_order) {
            if (
              this.ygRecordDetail.yg_order.staff.thumb.indexOf("http") == -1
            ) {
              this.ygRecordDetail.yg_order.staff.staffthumb =
                this.$publicData.imgUrl +
                this.ygRecordDetail.yg_order.staff.thumb;
            } else {
              this.ygRecordDetail.yg_order.staff.staffthumb = this.ygRecordDetail.yg_order.staff.thumb;
            }
          }
        }
      });
    },
    //点击评价
    toevaluate() {
      this.$router.push({
        path: "/optometry_evaluate",
        query: {
          id: this.$route.query.id,
          creatid: this.ygRecordDetail.yg_order.id
        }
      });
    },
    //点击查看评价
    opWateval() {
      this.$router.push({
        path: "/optometry_wateval",
        query: {
          order_sn: this.ygRecordDetail.yg_order.order_sn
        }
      });
    }
  }
};
</script>
<style>
/* .optometryinfo {
  background: #f3f4f9;
}
.optometryinfo .van-nav-bar {
  background: transparent !important;
}
.optometryinfo .edit_icon {
  position: absolute;
  top: 0.27rem;
  right: 0.27rem;
}
.optometryinfo .edit_icon img {
  width: 0.44rem;
}
.optometryinfo .y-op_orderxia p span {
  width: 30px !important;
}
.optometryinfo .y-op_orderxia p {
  width: 37%;
  float: left;
}
.optometryinfo .y-con-admin {
  position: relative;
  width: 86%;
  float: left;
  margin-left: 4%;
  background-color: #f3f4f9;
  padding: 10px 3%;
  border-radius: 8px;
}
.optometryinfo .y-con-admin_img {
  width: 35px;
  height: 35px;
  float: left;
}
.optometryinfo .y-con-admin p {
  float: left;
  padding-left: 10px;
}
.optometryinfo .y-con-admin p i {
  font-size: 0.35rem;
}
.optometryinfo .y-con-admin p span {
  width: 100%;
  float: left;
  font-size: 0.35rem;
  color: #000;
  margin-bottom: 3px;
}
.optometryinfo .y-user-oldxia p {
  height: 30px;
  font-size: 0.35rem;
  color: #999;
  line-height: 30px;
  padding: 6px 20px;
}
.optometryinfo .y-user-oldxia p span {
  width: 110px;
  height: 30px;
  font-size: 0.35rem;
  color: #000;
  float: right;
  text-align: center;
}
.optometryinfo .y-user-oldxia {
  padding: 6px 0px;
  font-size: 0.35rem;
  width: 100%;
}
.optometryinfo .y-user-oldtop h2 {
  float: left;
  padding-left: 65px;
}
.optometryinfo .y-user-oldtop h2 img {
  width: 22px;
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}
.optometryinfo .y-user-oldtop h2 p {
  float: left;
  color: #000;
}
.optometryinfo .y-user-oldtop {
  width: 94%;
}
.optometryinfo .y-user-oldtop {
  border-bottom: 1px #eeeeee solid;
  font-size: 0.35rem;
  padding-left: 20px;
  padding-bottom: 13px;
}
.optometryinfo .y-user-oldtop h2 img {
  margin-top: 3px !important;
}
.optometryinfo .y-user-old1 {
  width: 94%;
  background: #ffffff;
  border-radius: 8px;
  padding: 15px 0px;
  color: #999;
  margin-left: 3%;
  margin-top: 10px;
  box-shadow: 0px 2px 5px #eeeeee;
}
.optometryinfo .y-user-old {
  width: 100%;
  margin-bottom: 12px;
}
.optometryinfo .y-user-old h2 {
  font-size: 0.35rem;
  line-height: 30px;
  margin-left: 15px;
}
.optometryinfo .y_nickname_integral {
  padding-top: 5px;
}
.optometryinfo .y_nickname {
  padding-right: 10px;
  font-size: 0.35rem;
  color: #fff;
  line-height: 1rem;
}
.optometryinfo .y_user_img {
  width: 51px;
  height: 51px;
  padding: 0px 15px 0 16px;
}
.optometryinfo .user_info {
  position: relative;
  background: linear-gradient(to right, #35b6d8, #52d3c5);
  border-radius: 8px;
  padding: 18px 0px;
  width: 94%;
  margin: 15px 3%;
  float: left;
}
.optometryinfo .user_info .left {
  float: left;
  width: 78px;
}
.optometryinfo .user_info .right {
  float: left;
}
.optometryinfo .van-button {
  background: #29bae0;
  border-color: #29bae0;
  padding: 0;
  color: #fff !important;
}
.optometryinfo .optevaluate {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  width: 1.65rem;
  height: 0.67rem;
}
.optometryinfo .van-button img {
  width: 0.35rem;
}
.optometryinfo .watevaluate {
  width: 1.82rem;
  height: 0.67rem;
  float: right;
  margin: 0.57rem 0.88rem 0.3rem 0;
} */
</style>