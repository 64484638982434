<template>
  <div class="y-all login">
    <div class="y-dd">
      <h2>Hi,早上好！</h2>
      <p>欢迎使用捷捷配镜，轻松预约上门定制，制作完成送货上门，不再担心路程远没时间的问题！</p>
    </div>
    <div class="y-login">
      <p class="y-log-p">快捷登录/注册</p>
      <van-cell-group class="filegroup">
        <p class="labelname">手机号码</p>
        <van-field v-model="tel" type="tel" placeholder="请输入手机号码">
          <template #button>
            <!-- 发送验证码 -->
            <van-button class="yanzheng" size="small" type="primary" @click="sendyzma">获取验证码</van-button>
            <!-- 倒计时  -->
            <van-count-down
              class="daoshu"
              ref="countDown"
              millisecond
              :time="60000"
              :auto-start="false"
              format="ss秒后重新发送"
              @finish="finish"
            />
          </template>
        </van-field>
        <p class="labelname">手机验证码</p>
        <van-field v-model="sms" placeholder="请输入手机验证码" />
      </van-cell-group>
    </div>
    <van-button
      @click="toHome"
      round
      type="primary"
      block
      color="linear-gradient(to right, #35B6D8, #52D3C5)"
    >登录</van-button>
    <div class="y-login-wx">
      <van-divider>or</van-divider>
      <router-link to="login_wx">
        <van-image round width="35px" height="35px" :src="require('/src/assets/images/wx.png')" />
      </router-link>
    </div>
  </div>
</template>

<script>
import { LOGOUT, LOGIN } from "../../vuex/mutation_types";
export default {
  name: "login",
  data() {
    return {
      tel: "",
      sms: ""
    };
  },
  methods: {
    //点击发送验证码
    sendyzma() {
      // console.log(this.$api);
      if (this.tel) {
        var t = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        if (t.test(this.tel)) {
          //手机号格式正确
          var param = {
            phone: this.tel
          };
          this.$api.login.phoneCode(param).then(res => {
            if (res.code == 200) {
              // console.log(res);
              document.getElementsByClassName("yanzheng")[0].style.display =
                "none";
              document.getElementsByClassName("daoshu")[0].style.display =
                "block";
              this.$refs.countDown.start(); //开始
            }
          });
        } else {
          this.$toast("手机号输入错误");
        }
      } else {
        this.$toast("请输入手机号");
      }
    },
    //点击手机验证密码登录
    toHome() {
      var type = window.localStorage.getItem("extendType");
      var id = window.localStorage.getItem("extendId");
      // console.log("phone" + type);
      // console.log("phone" + id);
      var t = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (this.tel && t.test(this.tel)) {
        var param = {
          phone: this.tel,
          phonecode: this.sms
        };
        if (type != undefined && type != "" && type != null) {
          param.type = type;
        }
        if (id != undefined && id != "" && id != null) {
          param.id = id;
        }

        this.$api.login.phoneCodeLogin(param).then(res => {
          if (res.code == 200) {
            console.log(res.result);
            this.$store.commit(LOGIN, {
              token: res.result.token,
              result: JSON.stringify(res.result),
              id:res.result.id,
              name:res.result.name,
            });
            console.log(this.$store.getter);
            var inPath = window.localStorage.getItem("inPath");
            if (inPath != undefined && inPath != null && inPath != "") {
              window.localStorage.removeItem("inPath");
              this.$router.push({
                path: inPath
              });
              return;
            }
            this.$router.push({
              path: "/home"
            });
          }
        });
      } else {
        this.$toast("信息不完整");
      }
    },
    // 倒计时结束
    finish() {
      // this.$toast("倒计时结束");
      document.getElementsByClassName("daoshu")[0].style.display = "none";
      document.getElementsByClassName("yanzheng")[0].style.display = "block";
      this.$refs.countDown.reset(); //重置
    }
  }
};
</script>
<style scoped>
</style>
