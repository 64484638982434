<template>
    <!-- 个人中心 top -->
    <div class="y-usertop home">
        <div class="home_top">
            <div class="title clearfix">
                <div class="y-map">
                    <img src="../../assets/images/my-logo.png" />
                </div>
                <div class="y-code">
                    <img src="../../assets/images/ewm.png" @click="showPopup" />
                    <van-popup v-model="show" :close-on-click-overlay="false" closeable>
                        <div class="y-mymodel">
                            <p class="y-mymodel1">
                                <!-- <van-image round :src="memberInfo.thumb" /> -->
                                <van-image round :src="touxiang" />
                            </p>
                            <div class="y-mymodel2">
                                <p class="y-mymodel3 f-l">
                                    <img :src="memberInfo.extendpic" />
                                </p>
                                <span>扫一扫二维码，享受会员优惠</span>
                            </div>
                        </div>
                    </van-popup>
                </div>
            </div>
            <div class="y-my-user_info clearfix">
                <router-link to="setup">
                    <div class="thumb f-l">
                        <van-image round :src="touxiang" />
                    </div>
                </router-link>
                <div class="info f-l">
                    <div class="nickname">{{memberInfo.name}}</div>
                    <router-link to="setup">
                        <div class="id">查看并修改个人资料</div>
                    </router-link>
                </div>
                <div class="y-my-yop2">
                    <van-row>
                        <van-col span="8" @click="toIntegral">
                            <span>{{memberInfo.score}}</span>
                            <p>积分</p>
                        </van-col>
                        <van-col span="8" @click="toMycoupon">
                            <span>{{memberInfo.quanCount}}</span>
                            <p>优惠券</p>
                        </van-col>
                        <van-col span="8" @click="toVip">
              <span class="member_icon">
                <img class="levelicon" :src="membericon" />
              </span>
                            <p>{{memberInfo.member_level.name}}</p>
                        </van-col>
                    </van-row>
                </div>
            </div>
        </div>
        <div class="y-my-nav">
            <van-row>
                <van-col span="6">
                    <router-link to="appointment_record">
                        <img src="../../assets/images/my-nav1.png" />
                        <p>预约记录</p>
                    </router-link>
                </van-col>
                <van-col span="6">
                    <router-link to="optometry">
                        <img src="../../assets/images/my-nav2.png" />
                        <p>视力档案</p>
                    </router-link>
                </van-col>
                <van-col span="6">
                    <router-link to="order_list">
                        <img src="../../assets/images/my-nav3.png" />
                        <p>我的订单</p>
                    </router-link>
                </van-col>
                <van-col span="6">
                    <router-link to="coupons">
                        <img src="../../assets/images/my-nav4.png" />
                        <p>领券中心</p>
                    </router-link>
                </van-col>
            </van-row>
            <van-row style="margin-top:10px;">
                <van-col span="6">
                    <router-link to="integral">
                        <img src="../../assets/images/my-nav5.png" />
                        <p>积分中心</p>
                    </router-link>
                </van-col>
                <van-col span="6">
                    <router-link to="invitation">
                        <img src="../../assets/images/my-nav6.png" />
                        <p>邀请有礼</p>
                    </router-link>
                </van-col>
                <van-col span="6">
                    <router-link to="shopping_mall">
                        <img src="../../assets/images/my-nav7.png" />
                        <p>捷捷商城</p>
                    </router-link>
                </van-col>
                <van-col span="6">
                    <router-link to="service_list">
                        <img src="../../assets/images/my-nav8.png" />
                        <p>售后服务</p>
                    </router-link>
                </van-col>
            </van-row>
        </div>
        <div class="y-fg"></div>
        <div class="y-my-integral">
            <div class="y-my-integral-top clearfix">
                <p>积分商城</p>
                <span @click="toExchange">
          <img src="../../assets/images/my-integral.png" />
          <van-icon size="16" name="arrow" />
        </span>
            </div>
            <div class="y-my-integral-xia">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div
                                v-for="(el,index) in scoreGoodsBanner"
                                :key="index"
                                class="swiper-slide"
                                @click="toInfo(el.id)"
                        >
                            <img class="img" :src="el.jifen_thumb" />
                            <p>{{el.title}}</p>
                            <span>
                {{el.scoreNum}}
                <i>积分</i>
              </span>
                        </div>
                    </div>
                    <!-- 如果需要分页器 -->
                    <div class="swiper-pagination"></div>
                </div>
            </div>
        </div>
        <div class="y-my-gg" @click="toInvitation">
            <img src="../../assets/images/my-gg.png" />
        </div>
        <div class="y-my-life clearfix">
            <div class="y-my-integral-top clearfix">
                <p>乐享生活</p>
            </div>
            <div class="y-my-life-x">
                <router-link to="door">
                    <div class="y-my-life-1">
                        <h2>
                            <p>上门配镜</p>
                            <span>在线预约验光车上门验光</span>
                            <i>配镜更方便</i>
                        </h2>
                    </div>
                </router-link>
                <div class="y-my-life-r">
                    <div class="y-my-life-2" @click="show_exclusivePopup">
                        <h2>
                            <p>专属验光师</p>
                            <span>专属您的验光师</span>
                            <i>一对一交流更贴心</i>
                        </h2>
                    </div>
                    <van-popup
                            :close-on-click-overlay="false"
                            v-model="show_exclusive"
                            closeable
                            class="y-home-myexclusive"
                    >
                        <div class="y-mymodel">
                            <div class="y-mymodel2">
                                <div class="y-home-myexclusive-img">
                                    <div class="y-home-myexclusive-zi">您的专属验光师</div>
                                    <!-- <van-image round src="https://img01.yzcdn.cn/vant/cat.jpeg" /> -->
                                    <van-image round :src="touxiang" />
                                </div>
                                <p class="y-mymodel3 f-l">
                                    <!-- <img src="../../assets/images/code.jpg" /> -->
                                    <img :src="memberInfo.yanguangma" />
                                </p>
                                <van-row>
                                    <van-col span="12">
                                        <van-icon name="contact" size="20px" />
                                        <p>{{memberInfo.serve_staff_name}}</p>
                                    </van-col>
                                    <van-col span="12">
                                        <van-icon name="phone-o" size="20px" />
                                        <!-- <p>{{memberInfo.serve_staff_phone}}</p> -->
                                        <a :href="'tel:'+ memberInfo.serve_staff_phone">{{memberInfo.serve_staff_phone}}</a>
                                    </van-col>
                                </van-row>
                            </div>
                        </div>
                    </van-popup>
                    <router-link to="vip">
                        <div class="y-my-life-3">
                            <h2>
                                <p>会员权益</p>
                                <span>累计积分升级抵现</span>
                                <i>换礼更实惠</i>
                            </h2>
                        </div>
                    </router-link>
                </div>
                <div class="y-my-life-4">
                    <router-link to="community">
                        <div class="y-my-life-1">
                            <h2>
                                <p>种草社区</p>
                                <span>达人潮流搭配经验分享</span>
                                <i>让您更时尚</i>
                            </h2>
                        </div>
                    </router-link>

                    <div class="y-my-life-r">
                        <router-link to="news_list">
                            <div class="y-my-life-2">
                                <h2>
                                    <p>爱眼护眼</p>
                                    <span>精选视光领域文章</span>
                                    <i>保护视力更专业</i>
                                </h2>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="y-fg"></div>
        <div class="y-my-flow clearfix">
            <router-link to="service_process">
                <div class="y-my-integral-top clearfix">
                    <p>服务流程</p>
                    <span>
            <van-icon size="15" name="arrow" />
          </span>
                </div>
                <div class="y-my-flowx">
                    <van-row type="flex" justify="space-around">
                        <van-col span="6">
                            <img src="../../assets/images/my-flow1.png" />
                            <p>预约上门服务</p>
                        </van-col>
                        <van-col span="6">
                            <img src="../../assets/images/my-flow2.png" />
                            <p>上车开始服务</p>
                        </van-col>
                        <van-col span="6">
                            <img src="../../assets/images/my-flow3.png" />
                            <p>专业医学验光</p>
                        </van-col>
                    </van-row>
                </div>
            </router-link>
        </div>
        <div class="y-fg"></div>
        <div class="y-my-flow clearfix y-my-news">
            <div class="y-my-integral-top clearfix">
                <router-link to="news_list">
                    <p>专家解答</p>
                    <span>
            <van-icon size="15" name="arrow" />
          </span>
                </router-link>
            </div>
            <div class="y-my-expert">
                <div
                        class="y-my-expert-list"
                        v-for="(item,index) in articles"
                        :key="index"
                        @click="toNewsinfo(item.id)"
                >
                    <!-- <keep-alive> -->
                    <van-row gutter="20">
                        <van-col span="12">
                            <h2>{{item.title}}</h2>
                            <p class="articles_desc">{{item.desc}}</p>
                            <span>{{item.publish_at}}</span>
                        </van-col>
                        <van-col span="12">
                            <img :src="item.zhuanjia_thumb" style="width:100%" />
                        </van-col>
                    </van-row>
                    <!-- </keep-alive> -->
                </div>
            </div>
        </div>
        <!-- 优惠券奖励 -->
        <van-popup v-model="show_quan" class="jiangli" :close-on-click-overlay="false">
            <img src="/statics/images/index/quan.png" alt />
            <div class="con">
                <h3>恭喜你获得了</h3>
                <div class="name" v-if="quanobj.config.type == 0">
                    <span>{{quanobj.config.money*1}}元</span>
                    现金券
                </div>
                <div class="name" v-if="quanobj.config.type == 1">
                    <span>{{quanobj.config.money*10}}%</span>
                    折扣券
                </div>
                <div class="btn" @click="quanque(quanobj.id)">确定</div>
                <div class="des" @click="toquan(quanobj.id)">我的优惠券</div>
            </div>
        </van-popup>
        <!-- 积分奖励 -->
        <van-popup v-model="show_jf" class="jiangli jfjl" :close-on-click-overlay="false">
            <img src="/statics/images/index/jfquan.png" alt />
            <div class="con">
                <h3>恭喜你获得了</h3>
                <div class="yq">
                    <span>-成功邀请-</span>
                    <img :src="jfquanobj.config.member_thumb" alt="pic" />
                    <span class="user">{{jfquanobj.config.name}}</span>
                </div>
                <div class="name">
                    <span>{{jfquanobj.config.score}}积分</span>
                </div>
                <div class="btn" @click="jfque(jfquanobj.id)">确定</div>
                <div class="des" @click="toji(jfquanobj.id)">我的积分</div>
            </div>
        </van-popup>
        <div class="footnav">
            <!-- <foot-nav></foot-nav> -->
            <van-tabbar v-model="active" class="y-foot">
                <van-tabbar-item to="pre">
                    <span>预约配镜</span>
                    <template #icon="props">
                        <img :src="props.active ? icons.book.active : icons.book.inactive" />
                    </template>
                </van-tabbar-item>
                <van-tabbar-item to="home">
                    <span>会员中心</span>
                    <template #icon="props">
                        <img :src="props.active ? icons.my.active : icons.my.inactive" />
                    </template>
                </van-tabbar-item>
            </van-tabbar>
        </div>
        <!-- 加载框 -->
        <!-- <van-popup v-model="showloading">
              <van-loading type="spinner" />
        </van-popup>-->
        <van-overlay class="jiazai" :show="showloading">
            <div class="wrapper">
                <van-loading type="spinner" />
            </div>
        </van-overlay>
    </div>
</template>
<script>
    import Swiper from "swiper";
    import "swiper/swiper.min.css";
    import wx from "weixin-js-sdk";
    // import footNav from "./footnav"
    export default {
        data() {
            return {
                show_quan: false,
                show_jf: false,
                quanobj: {
                    config: {}
                },
                jfquanobj: {
                    config: {}
                },
                shareConfig: {},
                showloading: false,
                userInfo: {},
                articles: [],
                touxiang: "",
                yanguangma: "",
                zhuanjia_thumb: "",
                membericon: "",
                memberInfo: {
                    thumb: "",
                    extend_thumb: "",
                    member_level: { icon: "" }
                },
                extendpic: "",
                scoreGoodsBanner: [],
                jifen_thumb: "",
                user: {},
                name: "",
                active: 1,
                id: "0",
                show: false,
                show_exclusive: false,
                icons: {
                    book: {
                        active: require("../../assets/images/foot1-1.png"),
                        inactive: require("../../assets/images/foot1.png")
                    },
                    my: {
                        active: require("../../assets/images/foot2-1.png"),
                        inactive: require("../../assets/images/foot2.png")
                    }
                }
            };
        },
        // components:{
        //   footNav
        // },
        created: function() {
            // 判断登录没有，没有登录跳转到登录页面
            var token = this.$store.getters.token;
            console.log(this.$store.getters.user_id);
            if (token == undefined || token == null || token == "") {
                window.localStorage.setItem("inPath", "/home");
                this.$router.push("/login_wx");
                return;
            }
            this.getUserInfo();
        },
        methods: {
            // 跳转到积分详情
            toInfo(id) {
                this.$router.push({
                    path: "/integral_info",
                    query: {
                        id: id
                    }
                });
            },
            //获取个人信息接口
            getUserInfo() {
                this.shareFun();
                this.showloading = true;
                var param = {
                    token: this.$store.getters.token
                };
                //   console.log(this.$store.getters);
                this.$api.member.userInfo(param).then(res => {
                    if (res.code == 200) {
                        this.showloading = false;
                        this.userInfo = res.result;
                        this.articles = res.result.articles;
                        this.memberInfo = res.result.memberInfo;
                        this.scoreGoodsBanner = res.result.scoreGoodsBanner;
                        //   奖励
                        if (this.userInfo.have_event == 1) {
                            //   有一个奖励
                            if (this.userInfo.event_arr.length == 1) {
                                if (this.userInfo.event_arr[0].type == 1) {
                                    this.quanobj = this.userInfo.event_arr[0];
                                    this.show_quan = true;
                                } else {
                                    this.jfquanobj = this.userInfo.event_arr[0];
                                    this.show_jf = true;
                                }
                            } else {
                                // 两个奖励都有
                                if (this.userInfo.event_arr[0].type == 1) {
                                    this.quanobj = this.userInfo.event_arr[0];
                                    this.jfquanobj = this.userInfo.event_arr[1];
                                    this.show_quan = true;
                                } else {
                                    this.jfquanobj = this.userInfo.event_arr[0];
                                    this.quanobj = this.userInfo.event_arr[1];
                                    this.show_jf = true;
                                }
                            }
                            this.jfquanobj.config.member_thumb = this.jfquanobj.config.thumb.indexOf("http")==-1?this.$publicData.imgUrl+this.jfquanobj.config.thumb:this.jfquanobj.config.thumb;
                        }

                        // 验光师二维码
                        if (this.memberInfo.serve_staff_qrcode.indexOf("http") == -1) {
                            this.memberInfo.yanguangma =
                                this.$publicData.imgUrl + this.memberInfo.serve_staff_qrcode;
                        } else {
                            this.memberInfo.yanguangma = this.memberInfo.serve_staff_qrcode;
                        }

                        if (this.memberInfo.extend_thumb.indexOf("http") == -1) {
                            this.memberInfo.extendpic =
                                this.$publicData.imgUrl + this.memberInfo.extend_thumb;
                        } else {
                            this.memberInfo.extendpic = this.memberInfo.extend_thumb;
                        }

                        var homethumb = res.result.memberInfo.thumb;
                        var memberthumb = res.result.memberInfo.member_level.icon;
                        // 会员头像
                        this.touxiang =
                            homethumb.indexOf("http") == -1
                                ? this.$publicData.imgUrl + homethumb
                                : homethumb;
                        this.membericon =
                            memberthumb.indexOf("http") == -1
                                ? this.$publicData.imgUrl + memberthumb
                                : memberthumb;
                        for (var i in this.scoreGoodsBanner) {
                            var goodsbanner = this.scoreGoodsBanner[i].thumb;
                            if (goodsbanner.indexOf("http") == -1) {
                                this.scoreGoodsBanner[i].jifen_thumb =
                                    this.$publicData.imgUrl + goodsbanner;
                            } else {
                                this.scoreGoodsBanner[i].jifen_thumb = goodsbanner;
                            }
                        }
                        for (var j in this.articles) {
                            var articlesbanner = this.articles[j].thumb;
                            if (articlesbanner.indexOf("http") == -1) {
                                this.articles[j].zhuanjia_thumb =
                                    this.$publicData.imgUrl + articlesbanner;
                            } else {
                                this.articles[j].zhuanjia_thumb = articlesbanner;
                            }
                        }
                    }
                });
            },
            // getQuan(id) {
            //   var param = {
            //     token: this.$store.getters.token,
            //     id: id
            //   };
            //   //   console.log(this.$store.getters);
            //   this.$api.member.doneEventThing(param).then(res => {
            //     if (res.code == 200) {
            //       return true;
            //     }
            //   });
            // },
            // 点击券奖励确定
            quanque(id) {
                this.showloading = true;
                var param = {
                    token: this.$store.getters.token,
                    id: id
                };
                this.$api.member.doneEventThing(param).then(res => {
                    if (res.code == 200) {
                        this.showloading = false;
                        if (this.userInfo.event_arr.length == 1) {
                            this.show_quan = false;
                        } else {
                            if (this.userInfo.event_arr[0].type == 1) {
                                this.show_quan = false;
                                this.show_jf = true;
                            } else {
                                this.show_quan = false;
                            }
                        }
                    } else {
                        this.showloading = false;
                    }
                });
            },
            // 点击积分奖励确定
            jfque(id) {
                this.showloading = true;
                var param = {
                    token: this.$store.getters.token,
                    id: id
                };
                this.$api.member.doneEventThing(param).then(res => {
                    if (res.code == 200) {
                        this.showloading = false;
                        if (this.userInfo.event_arr.length == 1) {
                            this.show_jf = false;
                        } else {
                            if (this.userInfo.event_arr[0].type == 2) {
                                this.show_jf = false;
                                this.show_quan = true;
                            } else {
                                this.show_jf = false;
                            }
                        }
                    } else {
                        this.showloading = false;
                    }
                });
            },
            // 点击我的优惠券
            toquan(id) {
                this.showloading = true;
                var param = {
                    token: this.$store.getters.token,
                    id: id
                };
                this.$api.member.doneEventThing(param).then(res => {
                    if (res.code == 200) {
                        this.showloading = false;
                        this.$router.push({
                            path: "/myCoupon"
                        });
                    }
                });
            },
            // 点击去哦的积分
            toji(id) {
                this.showloading = true;
                var param = {
                    token: this.$store.getters.token,
                    id: id
                };
                this.$api.member.doneEventThing(param).then(res => {
                    if (res.code == 200) {
                        this.showloading = false;
                        this.$router.push({
                            path: "/integral"
                        });
                    }
                });
            },
            //跳转到会员权益
            toVip() {
                this.$router.push({
                    path: "/vip"
                });
            },
            //跳转到邀请有礼
            toInvitation() {
                this.$router.push({
                    path: "/invitation"
                });
            },
            //跳转到积分
            toIntegral() {
                this.$router.push({
                    path: "/integral"
                });
            },
            //跳转到积分兑换
            toExchange() {
                this.$router.push({
                    path: "/integral_exchange"
                });
            },
            //跳转到我的优惠券
            toMycoupon() {
                this.$router.push({
                    path: "/myCoupon"
                });
            },
            //跳转详情
            toNewsinfo(id) {
                this.$router.push({
                    path: "/news_info",
                    query: {
                        id: id
                    }
                });
            },
            showPopup() {
                this.show = true;
            },
            show_exclusivePopup() {
                this.show_exclusive = true;
            },
            onSwiper(swiper) {
                // console.log(swiper);
            },
            onSlideChange() {
                // console.log("slide change");
            },
            shareFun() {
                var that = this;
                var tempurl = location.href;
                if (
                    this.$store.getters.user_id != undefined &&
                    this.$store.getters.user_id != null
                ) {
                    if (location.href.indexOf("?") == -1) {
                        // 不存在？ 则没有参数
                        tempurl =
                            location.href +
                            "?type=member&sharememberid=" +
                            this.$store.getters.user_id;
                    } else {
                        tempurl =
                            location.href +
                            "&type=member&sharememberid=" +
                            this.$store.getters.user_id;
                    }
                }
                const url = tempurl;
                console.log("home-share----url=" + url);
                const shareUrl = location.href.split("#")[0];
                that.$api.common.commonShare({ url: shareUrl }).then(res => {
                    if (res.code == 200) {
                        that.shareConfig = res.result;
                        wx.config({
                            debug: that.shareConfig.debug,
                            beta: that.shareConfig.beta,
                            jsApiList: that.shareConfig.jsApiList,
                            appId: that.shareConfig.appId,
                            nonceStr: that.shareConfig.nonceStr, // 随机串
                            timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
                            signature: that.shareConfig.signature // 签名
                        });
                        // console.log('home-微信分享设置---开始');
                        // 分享
                        wx.ready(function() {
                            var shareDataA = {
                                title: that.$publicData.shareTitle, // 分享标题
                                desc: that.$publicData.shareDesc, // 分享描述
                                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                imgUrl: that.$publicData.imgUrl + that.$publicData.img, // 分享图标
                                success: function() {},
                                cancel: function(e) {}
                            };

                            var shareDataB = {
                                title: that.$publicData.shareTitle, // 分享标题
                                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                imgUrl: that.$publicData.imgUrl + that.$publicData.img, // 分享图标
                                success: function() {},
                                cancel: function(e) {}
                            };
                            wx.updateAppMessageShareData(shareDataA); // 分享给朋友
                            wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
                            // console.log('home-微信分享设置---结束');
                        });
                    }
                });
            }
        },
        mounted: function() {
            new Swiper(".swiper-container", {
                loop: true, // 循环模式选项
                slidesPerView: 3,
                slidesPerGroup: 3,
                // 如果需要分页器
                pagination: {
                    el: ".swiper-pagination"
                },
                observer: true,
                observeParents: true
            });
            // //进入页面强制刷新一次
            // if (location.href.indexOf("#reloaded") == -1) {
            //     location.href = location.href + "#reloaded";
            //     location.reload();
            // }
        },
        activated: function() {
            this.getUserInfo();
            this.show_quan = false;
            this.show_jf = false;
        }
    };
</script>
<style>
</style>
