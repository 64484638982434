<template>
  <!-- 个人中心 top -->
  <div class="y-pre">
    <div class="y-pre-title">
      <h2>
        <p>
          <van-icon name="phone" color="#ffffff" size="16"/>
          <span>400-000-5169</span>
        </p>
        <span>预约上门 清晰到家 不满意 不收费</span>
      </h2>
    </div>
    <div class="y-pre-infor">
      <van-field v-model="name" label="联系人" placeholder="请输入联系人姓名" />
      <van-form>
      <van-field :rules="phoneRules" v-model="phone" type="tel" label="手机号码" placeholder="请输入手机号码"/>
      </van-form>
      <van-field
        v-model="preshow_time"
        label="预约时间"
        placeholder="请输入预约时间"
        readonly
        @click-input="changetime"
      />
      <van-field v-model="add" label="预约地址" placeholder="请输入预约地址" />
    </div>
    <div class="y-pre-objective">
      <h2>预约目的</h2>
      <div class="y-pre-objective1">
        <van-button
          v-for="item in list"
          :key="item.index"
          :value="item"
          round
          plain
          :color="item.color"
          @click="prePosition(item)"
        >{{item.name}}</van-button>
      </div>
    </div>
    <div class="y-pre-button" @click="submitPreOrderNew">
      <van-button
        type="primary"
        block
        round
        color="linear-gradient(to right, #35B6D8, #52D3C5)"
      >确认预约</van-button>
    </div>
    <!-- 预约时间 -->
    <!-- <van-popup
      v-model="showtime"
      :close-on-click-overlay="false"
      round
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="datehour"
        title="选择预约时间"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="canceltime"
        @confirm="confirmtime"
      />
    </van-popup>-->

    <van-popup
    class="chosetime"
      v-model="showyue"
      closeable
      style="width:auto; height:auto;"
      :close-on-click-overlay="false"
    >
      <div class="yuyue">
        <h3>选择时间</h3>
        <table>
          <!-- 日期 -->
          <tr>
            <td></td>
             <td class="popdata" v-for="(item,index) in date" :key="index">{{item.date}}</td>
          </tr>
          <!-- 星期 -->
          <tr>
            <td></td>
            <td class="popweek" v-for="(item,index) in date" :key="index">{{item.week}}</td>
          </tr>
          <tr v-for="(item,index) in time" :key="index">
            <!-- 时间段 -->
            <td class="poptime">{{item.show}}</td>
            <!-- 约 -->
            <td class="popyue" v-for="(itemyue,i) in 5" :key="i" @click="getyuetime(index,i)"><span class="yue">约</span></td>
          </tr>
        </table>
      </div>
    </van-popup>
    <!-- 预约成功 -->
    <van-popup v-model="show" :close-on-click-overlay="false">
      <div class="y-pre-popup">
        <div class="y-pre-popup-infor">
          <p class="y-pre-popup-infor-img f-l">
            <img src="../../assets/images/pre-succ.png" />
          </p>
          <i>预约成功</i>
          <span>我们会尽快与您联系，请保持手机畅通</span>
          <van-button
            round
            color="linear-gradient(to right, #35B6D8, #52D3C5)"
            @click="gotoPreList"
          >知道了</van-button>
        </div>
      </div>
    </van-popup>
    <van-tabbar v-model="active" class="y-foot">
       <!-- <foot-nav></foot-nav> -->
      <van-tabbar-item to="pre">
        <span>预约配镜</span>
        <template #icon="props">
          <img :src="props.active ? icons.book.active : icons.book.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="home">
        <span>会员中心</span>
        <template #icon="props">
          <img :src="props.active ? icons.my.active : icons.my.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
     <!-- 加载框 -->
      <!-- <van-popup class="jiazai" v-model="showloading">
        <van-loading type="spinner" />
      </van-popup> -->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script >
import { timestampToTimeAll } from "../../utils/util";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      shareConfig: {},
      phoneRules:[
         {
          required: true,
          message: "手机号码不能为空",
          trigger: "onBlur"
        },
        {
          // 自定义校验规则
          validator: value => {
            return /^1[3-9][0-9]{9}$/.test(value);
          },
          message: "请输入正确格式的手机号码",
          trigger: "onBlur"
        }
      ],
      showloading:false,
      date:[],
      time:[],
      yueindex:0,
      yuei:0,
      yuedata:["07.15","07.16","07.17","07.18","07.19"],
      yueweek:["周四","周五","周六","周日","周一"],
      yuetime:["09:00","11:30","13:30","17:30","19:30"],
      // showtime: false,
      showyue: false,
      minDate: new Date(),
      maxDate: "",
      currentDate: new Date(),
      phone: "",
      list: [],
      name: "",
      preshow_time: "",
      pre_time: "",
      show: false,
      add: "",
      active: 0,
      icons: {
        book: {
          active: require("../../assets/images/foot1-1.png"),
          inactive: require("../../assets/images/foot1.png")
        },
        my: {
          active: require("../../assets/images/foot2-1.png"),
          inactive: require("../../assets/images/foot2.png")
        }
      }
    };
  },
  //  components:{
  //   footNav
  // },
  created: function() {
    // 判断登录没有，没有登录跳转到登录页面
    var token = this.$store.getters.token;
    if (token == undefined || token == null || token == "") {
      window.localStorage.setItem("inPath", "/pre");
      this.$router.push("/login_wx");
      return;
    }
    this.getPreOrderPosition();
    this.getPreDateTime();
    this.shareFun();
    var date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    date.setDate(date.getDate() - 1);
    this.maxDate = date;
  },
  methods: {
    // 预约时间接口
    getPreDateTime(){
       var params = {
        token: this.$store.getters.token
      };
       this.$api.member.preDateTime(params).then(res => {
         if(res.code == 200){
           this.date = res.result.date;
           this.time = res.result.time
         }
       })
    },
    //点击约
    getyuetime(index,i){
       document.getElementsByTagName("tr")[this.yueindex+2].getElementsByClassName("popyue")[this.yuei].getElementsByTagName("span")[0].style.color="#35B6D8"; 
      document.getElementsByTagName("tr")[this.yueindex+2].getElementsByClassName("popyue")[this.yuei].getElementsByTagName("span")[0].style.backgroundColor="#eee"; 

      var data = this.date[i].bigDate;
      var time = this.time[index].bigTime;
      var showtime = this.time[index].show;
      var preTime = data +" " + time;
      this.preshow_time = data +" " + showtime;
      this.pre_time = preTime;
      // var year  = new Date().getFullYear();
      // var preTime =year + "-" + data +" " + time+":00";
      // this.pre_time = preTime.replace('.', '-')
      
      document.getElementsByTagName("tr")[index+2].getElementsByClassName("popyue")[i].getElementsByTagName("span")[0].style.color="#fff"; 
      document.getElementsByTagName("tr")[index+2].getElementsByClassName("popyue")[i].getElementsByTagName("span")[0].style.backgroundColor="#35B6D8"; 
      this.yueindex = index;
      this.yuei = i;
      // console.log(this.yueindex,this.yuei);
      this.showyue = false;
    },
    //点击预约时间
    changetime() {
      // this.showtime = true;
      this.showyue = true;
      // this.getPreDateTime();
    },
    // //点击时间取消
    // canceltime() {
    //   this.showtime = false;
    // },
    // //点击时间确定
    // confirmtime(value) {
    //   var time = new Date(value).getTime();
    //   this.pre_time = timestampToTimeAll(time);
    //   this.showtime = false;
    // },

    // 跳转至预约列表
    gotoPreList() {
      this.$router.push("/appointment_record");
    },
    // 确认预约
    submitPreOrderNew() {
      // 判断有没有预约目的
      var position = new Array();
      for (var i in this.list) {
        if (this.list[i].active == 1) {
          position.push(this.list[i].name);
        }
      }
      // console.log("position", position);
      if (position.length == 0) {
        this.$toast("请选择预约目的！");
        return;
      }
      var reg = /^1[3-9][0-9]{9}$/;
      if(!reg.test(this.phone)){
        this.$toast("手机号码错误")
        return false;
      }
      var params = {
        token: this.$store.getters.token,
        name: this.name,
        pre_time: this.pre_time,
        pre_phone: this.phone,
        pre_address: this.add,
        position: position
      };
      this.$api.member.submitPreOrderNew(params).then(res => {
        if (res.code == 200) {
          if (res.msg == "预约成功") {
            this.showPopup();
          } else {
            this.$toast(res.msg);
          }
        }
      });
    },
    // 点击预约目的按钮
    prePosition(item) {
      if (item.active == 0) {
        item.active = 1;
        item.color = "#35B6D8";
      } else {
        item.active = 0;
        item.color = "#B8B8B8";
      }
      for (var i in this.list) {
        if (this.list[i].index == item.index) {
          this.list[i] = item;
        }
      }
    },
    // 获取预约目的
    getPreOrderPosition() {
      this.showloading=true;
      this.$api.member
        .getPreOrderPosition({ token: this.$store.getters.token })
        .then(res => {
          this.showloading=false;
          if (res.code == 200) {
            var tempList = res.result;
            for (var i in tempList) {
              this.list.push({
                index: i,
                name: tempList[i],
                active: 0,
                color: "#B8B8B8"
              });
            }
          }
        });
    },
    showPopup() {
      this.show = true;
    },
    shareFun() {
        var that = this;
        var tempurl = location.href;
        if(this.$store.getters.user_id != undefined && this.$store.getters.user_id!=null){
            if(location.href.indexOf("?") == -1){// 不存在？ 则没有参数
                tempurl = location.href + '?type=member&sharememberid='+this.$store.getters.user_id;
            }else{
                if(location.href.indexOf("sharememberid") == -1){// 不存在则直接加
                    tempurl = location.href + '&type=member&sharememberid='+this.$store.getters.user_id;
                }else{ // 参数替换
                    var heheurl = location.href;
                    var heheArr = heheurl.split('&');
                    tempurl = "";
                    for(let i in heheArr){
                        if(i == 0 ){
                           tempurl = heheArr[0]; 
                           continue;
                        }

                        if(heheArr[i].indexOf('sharememberid=') != -1){
                            tempurl +=  "&sharememberid=" + this.$store.getters.user_id;
                        }else{
                            tempurl +=  "&" + heheArr[i];
                        }
                    }
                }
            }
        }
        const url = tempurl;
        console.log("home-share----url=" + url);
        const shareUrl = location.href.split("#")[0];
        that.$api.common.commonShare({url: shareUrl}).then(res => {
            if (res.code == 200) {
                that.shareConfig = res.result;
                wx.config({
                    debug: that.shareConfig.debug,
                    beta: that.shareConfig.beta,
                    jsApiList: that.shareConfig.jsApiList,
                    appId: that.shareConfig.appId,
                    nonceStr: that.shareConfig.nonceStr, // 随机串
                    timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
                    signature: that.shareConfig.signature // 签名
                });
                // 分享
                wx.ready(function () {
                    var shareDataA = {
                        title: that.$publicData.shareTitle, // 分享标题
                        desc: that.$publicData.shareDesc, // 分享描述
                        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.$publicData.imgUrl + that.$publicData.img, // 分享图标
                        success: function () {
                        },
                        cancel: function (e) {
                        }
                    };

                    var shareDataB = {
                        title: that.$publicData.shareTitle, // 分享标题
                        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.$publicData.imgUrl + that.$publicData.img, // 分享图标
                        success: function () {
                        },
                        cancel: function (e) {
                        }
                    };
                    wx.updateAppMessageShareData(shareDataA); // 分享给朋友
                    wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
                });
            }
        });
    },
  },
  mounted:function(){
    //进入页面强制刷新一次
    // if(location.href.indexOf("#reloaded") == -1){
    //   location.href = location.href + "#reloaded";
    //   location. reload();
    // }
  }
};
</script>
<style  scoped>
/* .y-pre .yiyue{
  color: #fff;
}
.y-pre table{
  margin: 0.25rem;
}
.y-pre td{
  text-align: center;
}
.y-pre .popdata{
  font-size: 0.37rem;
  padding: 0 0.15rem;
}
.y-pre .popweek{
  font-size: 0.32rem;
  opacity: 0.6;
  padding: 0.05rem 0 0.15rem;
}
.y-pre .poptime{
  font-size: 0.32rem;
  height: 0.85rem;
  padding-right: 0.15rem;
}
.y-pre .popyue span{
  display: inline-block;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.4rem;
  color: #35b6d8;
  background-color: #eee;
  border-radius: 50%;
  margin: 0.1rem 0;
}
.y-pre ::v-deep .van-popup__close-icon {
  right: 47% !important;
  top: 13.5rem !important;
}
.y-pre .van-cell {
  background-color: transparent;
}
.y-pre .yuyue h3 {
  font-size: 0.45rem;
  line-height: 1.5rem;
  padding-left: 0.4rem;
  color: #35b6d8;
  border-bottom: 0.01rem solid rgba(0, 0, 0, 0.1);
}
.y-pre .y-pre-popup-infor i {
  font-size: 0.35rem;
  color: #000;
  margin-top: 10px;
}
.y-pre ::v-deep .van-field__label {
  width: 60px !important;
}
.y-pre .y-pre-popup-infor span {
  font-size: 0.35rem;
  color: #999;
  margin-bottom: 20px;
}
.y-pre .y-pre-popup-infor i,
.y-pre .y-pre-popup-infor span {
  width: 100%;
  float: left;
  text-align: center;
  padding: 8px 0px;
}
.y-pre .y-pre-popup-infor .van-button span {
  font-size: 0.35rem;
  color: #ffffff;
  margin-bottom: 0px;
}
.y-pre .y-pre-popup-infor .van-button {
  width: 120px;
  height: 32px;
  line-height: auto;
  margin-left: 87px;
}
.y-pre .y-pre-popup-infor-img img {
  width: 218px !important;
  margin-top: 35px;
  height: auto;
  margin-left: 38px;
}
.y-pre .chosetime {
  padding: 0.2rem 0.2rem 1.5rem;
  background-color: transparent;
}
.y-pre .chosetime .yuyue{
  border-radius: 8px;
  background-color: #fff;
  padding: 0.2rem;
}
.y-pre .y-pre-popup {
  width: 295px;
  padding-bottom: 30px;
  border-radius: 8px;
}
.y-pre .y-pre-objective h2 {
  font-size: 0.35rem;
  padding: 5px 0px 2px 15px;
}
.y-pre .y-pre-button {
  margin: 10px 0px 50px 0px;
}
.y-pre .y-foot .van-tabbar-item--active {
  color: #35b6d8;
}
.y-pre .y-foot .van-tabbar-item__icon img {
  width: 20px;
  margin-bottom: 2px;
}
.y-pre .y-pre-objective1 .van-button {
  padding: 5px 14px;
  height: auto;
  line-height: none;
  font-size: 0.35rem;
  margin: 5px;
}
.y-pre .prebuttonActive {
  border-color: #35b6d8;
  color: #35b6d8;
}
.y-pre .y-pre-infor .van-cell {
  border-bottom: 1px #e8e8e9 solid;
}
.y-pre .y-pre-objective,
.y-pre .y-pre-button {
  margin-left: 3%;
  width: 94%;
  float: left;
}
.y-pre .y-pre-objective1 {
  margin: 10px 0px;
  padding: 10px;
  width: 91%;
  float: left;
  border-radius: 10px;
  background-color: #ffffff;
}
.y-pre .y-pre-infor {
  margin: 15px 3%;
  width: 94%;
  float: left;
  border-radius: 10px;
  background-color: #ffffff;
}
.y-pre .y-pre-title {
  float: left;
  background-image: url("../../assets/images/pre-bg.png");
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 254px;
}
.y-pre .y-pre-title h2 {
  padding: 80px 30px;
  color: #ffffff;
}
.y-pre .y-pre-title h2 p {
  font-size: 0.35rem;
}
.y-pre .y-pre-title h2 span {
  color: #d8f1f3;
  font-size: 0.35rem;
  line-height: 22px;
}
.y-pre {
  background-color: #f3f4f9;
  width: 100%;
  min-height: 100vh;
  float: left;
}
.y-pre .y-pre-button .van-button {
  margin-bottom: 0.6rem;
} */
</style>
