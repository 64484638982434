import { render, staticRenderFns } from "./yan_details.vue?vue&type=template&id=2c55d2e6&scoped=true&"
import script from "./yan_details.vue?vue&type=script&lang=js&"
export * from "./yan_details.vue?vue&type=script&lang=js&"
import style0 from "./yan_details.vue?vue&type=style&index=0&id=2c55d2e6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c55d2e6",
  null
  
)

export default component.exports