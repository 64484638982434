<template>
  <div class="optometry_add">
    <van-nav-bar
      :z-index="99"
      :fixed="false"
      :border="false"
      :placeholder="true"
      title="添加视力档案"
      left-arrow
      :v-else-if="false"
      @click-left="toOptometry"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <!-- 卡片 -->
    <div class="user_info clearfix">
      <div class="left f-l">
        <!-- 头像 -->
        <img :src="memberthumb" />
      </div>
      <div class="right f-l">
        <div class="y_nickname_integral">
          <!-- 姓名 -->
          <span class="y_nickname">{{username}}</span>
          <!-- 电话 -->
          <!-- <span class="y_tel"></span> -->
          <!-- 地址 -->
          <!-- <div class="address">
            <span></span>
          </div>-->
        </div>
      </div>
      <div class="edit_icon" @click="torevise">
        <img src="../../assets/images/edit.png" alt="pic" />
      </div>
    </div>

    <div class="y-user-old">
      <h2>验光处方</h2>
      <van-form>
        <div class="y-user-old1">
          <div class="y-user-oldtop">
            <van-radio-group v-model="optometry_info.type" direction="horizontal">
              <van-radio name="0" checked-color="#35B6D8" class="y-radio">近用</van-radio>
              <van-radio name="1" checked-color="#35B6D8" class="y-radio">远用</van-radio>
              <van-radio name="2" checked-color="#35B6D8" class="y-radio">渐进</van-radio>
            </van-radio-group>
            <van-radio-group
              v-model="optometry_info.dominance"
              direction="horizontal"
              style="margin-top:5px"
            >
              <van-radio name="0" checked-color="#35B6D8" class="y-radio">左眼主导L</van-radio>
              <van-radio name="1" checked-color="#35B6D8" class="y-radio">右眼主导R</van-radio>
            </van-radio-group>
          </div>
          <div class="y-user-oldxia">
            <p>
              右球镜R
              <span>
                <van-stepper
                  v-model="optometry_info.right_q_mirror"
                  step="0.25"
                  min="-10000"
                  :decimal-length="2"
                />
              </span>
            </p>
            <p>
              左球镜L
              <span>
                <van-stepper
                  v-model="optometry_info.left_q_mirror"
                  step="0.25"
                  min="-10000"
                  :decimal-length="2"
                />
              </span>
            </p>
            <p>
              右柱镜R
              <span>
                <van-stepper
                  v-model="optometry_info.right_z_mirror"
                  step="0.25"
                  min="-10000"
                  :decimal-length="2"
                />
              </span>
            </p>
            <p>
              左柱镜L
              <span>
                <van-stepper
                  v-model="optometry_info.left_z_mirror"
                  step="0.25"
                  min="-10000"
                  :decimal-length="2"
                />
              </span>
            </p>
            <p>
              右轴位R
              <span>
                <input class="youinput" v-model="optometry_info.right_axis" />
              </span>
            </p>
            <p>
              左轴位L
              <span>
                <input class="zuoinput" v-model="optometry_info.left_axis" />
              </span>
            </p>
            <p>
              右矫正视力R
              <span>
                <input v-model="optometry_info.right_vision" required="required" />
              </span>
            </p>
            <p>
              左矫正视力L
              <span>
                <input v-model="optometry_info.left_vision" required="required" />
              </span>
            </p>
            <!-- <p>
              瞳距
              <span>
                <input v-model="optometry_info.pd" required="required" />
              </span>
            </p>-->
            <p>
              右瞳距R
              <span>
                <input v-model="optometry_info.pdR" required="required" />
              </span>
            </p>
            <p>
              左瞳距L
              <span>
                <input v-model="optometry_info.pdL" required="required" />
              </span>
            </p>
            <p>
              脸宽
              <span>
                <input v-model="optometry_info.face_width" required="required" />
              </span>
            </p>
            <p>
              耳距
              <span>
                <input v-model="optometry_info.er_distance" required="required" />
              </span>
            </p>
            <p>
              右瞳高R
              <span>
                <input v-model="optometry_info.right_Pupil_height" />
              </span>
            </p>
            <p>
              左瞳高L
              <span>
                <input v-model="optometry_info.left_Pupil_height" />
              </span>
            </p>
            <p>
              ADD
              <span>
                <van-stepper v-model="optometry_info.ADD" step="0.2" :decimal-length="1" />
              </span>
            </p>
          </div>
        </div>
      </van-form>
    </div>
    <!-- 保存 -->
    <div class="optometrysave">
      <van-button
        color="linear-gradient(to right,#35B6D8, #52D3C5)"
        round
        type="info"
        @click="tosave"
      >保存</van-button>
    </div>
    <!-- 加载框 -->
    <!-- <van-popup class="jiazai" v-model="showloading">
        <van-loading type="spinner" />
    </van-popup>-->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showloading: false,
      memberthumb: "",
      username: "",
      optometry_info: {
        // 近用0，远用1,渐进2
        type: "0",
        // 主导 左眼0 右眼1
        dominance: "0",
        // 左球镜L
        left_q_mirror: 0,
        // 右球镜R
        right_q_mirror: 0,
        // 左柱镜L
        left_z_mirror: 0,
        // 右柱镜R
        right_z_mirror: 0,
        // 左轴位L
        left_axis: 0,
        // 右轴位R
        right_axis: 0,
        // 左矫正视力L
        left_vision: 1.0,
        // 右矫正视力L
        right_vision: 1.0,
        // 瞳距
        // pd: 1.0,
        // 右瞳距
        pdR: 1.0,
        // 左瞳距
        pdL: 1.0,
        // 脸宽
        face_width: 1.0,
        // 耳距
        er_distance: 1.0,
        //左瞳高L
        left_Pupil_height: 1.5,
        //右瞳高R
        right_Pupil_height: 1.5,

        // ADD
        ADD: 1.0
      }
    };
  },
  created: function() {
    this.getuse();
  },
  methods: {
    //跳转到视力档案
    toOptometry() {
      this.$router.push({
        path: "/optometry"
      });
    },
    //   个人中心接口
    getuse() {
      this.showloading = true;
      var params = {
        token: this.$store.getters.token
      };
      this.$api.member.userInfo(params).then(res => {
        this.showloading = false;
        this.username = this.$route.query.name;
        var memberInfo = res.result.memberInfo.thumb;
        if (memberInfo.indexOf("http") == -1) {
          this.memberthumb = this.$publicData.imgUrl + memberInfo;
        } else {
          this.memberthumb = memberInfo;
        }
      });
    },
    torevise() {
      this.$router.push({
        path: "/optometry_revise",
        query: {
          type: 1
        }
      });
    },
    // 点击保存
    tosave() {
      var params = {
        token: this.$store.getters.token,
        belong_name: this.username,
        yg_json: JSON.stringify(this.optometry_info)
      };
      this.$api.ygrecord.createYgRecord(params).then(res => {
        this.$router.push({
          path: "/optometry"
        });
      });
    }
  }
};
</script>

<style scoped>
/* .optometry_add {
  min-height: 100vh;
  background: #f3f4f9;
  padding-bottom: 0.8rem;
}
.optometry_add .van-nav-bar{
  background: transparent;
}
.optometry_add .user_info {
  margin-top: 0.6rem;
}
.optometry_add .user_info .right {
  width: 6rem;
}
.optometry_add .user_info .left img {
  border-radius: 50% !important;
  width: 1.36rem;
  height: 1.36rem;
  margin-left: 0.3rem;
}
.optometry_add .user_info .right .y_nickname {
  font-size: 0.4rem;
  font-weight: bold;
  line-height: 1rem;
}
.optometry_add .user_info .right .address {
  margin-top: 0.2rem;
}
.optometry_add .user_info .right .address span {
  color: #fff;
  font-size: 0.35rem;
  opacity: 0.6;
  line-height: 0.5rem;
}
.optometry_add .user_info .right .y_tel {
  opacity: 0.6;
  color: #fff;
  font-size: 0.35rem;
}
.optometry_add .y-user-old {
  margin: 0;
}
.optometry_add .y-user-oldtop {
  border-bottom: 1px #eeeeee solid;
   font-size: 0.35rem;
  padding-left: 20px;
  padding-bottom: 13px;
}
.optometry_add .y-radio {
  width: 40%;
  padding: 5px 0px;
}
.optometry_add .van-radio-group {
  flex-wrap: inherit;
}
.optometry_add .y-user-oldxia p {
  height: 30px;
  line-height: 30px;
  padding: 6px 20px;
  position: relative;
}
.optometry_add .y-user-oldxia p span {
  width: 110px;
  height: 30px;
  float: right;
  text-align: center;
}
.optometry_add .y-user-oldxia {
  padding: 6px 0px;
   font-size: 0.35rem;
}
.optometry_add .y-user-oldxia p span input {
  width: 100px;
  height: 30px;
  border: 1px #eeeeee solid;
  padding: 0px 5px;
}
.optometry_add .optometrysave .van-button {
  width: 9.2rem;
  margin-top: 0.69rem;
  margin-left: 0.4rem;
} */
</style>