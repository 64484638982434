<template>
  <div class="news_info">
    <van-nav-bar
      :fixed="false"
      :border="false"
      :placeholder="true"
      title="爱眼护眼文章专区"
      left-arrow
      @click-left="tonewlist"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y_add_storage">
      <div class="y-home-news-info f-l">
        <div class="y-home-news-info-top f-l">
          <!-- <h2>预防近视三要四不要</h2> -->
          <h2>{{articlesDetail.info.title}}</h2>
          <!-- <i>2021.04.21 13:40:25</i> -->
          <i>{{articlesDetail.info.updated_at}}</i>
        </div>
        <div class="y-home-news-info-xia f-l">
          <p class="content" v-html="articlesDetail.info.content"></p>
        </div>
      </div>
      <div class="y-home-news-list-buttom">
        <van-row type="flex" justify="space-between">
          <van-col span="12">
            <van-button round type="info" @click="shang">
              <p>
                上一篇
                <!-- <span>如何正确保护眼...</span> -->
                <span>{{articlesDetail.shang.title}}</span>
              </p>
            </van-button>
          </van-col>
          <van-col span="12">
            <van-button round type="info" @click="xia">
              <p>
                下一篇
                <!-- <span>保护眼睛的十种方法...</span> -->
                <span>{{articlesDetail.xia.title}}</span>
              </p>
            </van-button>
          </van-col>
        </van-row>
      </div>
    </div>
    <!-- 加载框 -->
    <!-- <van-popup class="jiazai" v-model="showloading">
        <van-loading type="spinner" />
    </van-popup>-->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      showloading: false,
      shareConfig:{},
      articlesDetail: {
        info: {
          title: "",
          describe: "",
          updated_at: ""
        },
        shang: {
          title: ""
        },
        xia: {
          title: ""
        }
      }
    };
  },
  created: function() {
    this.getArticlesDetail();
    
  },
  methods: {
    //跳转到文章专区
    tonewlist() {
      this.$router.push({
        path:"/news_list"
      })
      // this.$router.go(-1);
    },
    //文章列表
    getArticlesDetail() {
      this.showloading = true;
      var params = {
        token: this.$store.getters.token,
        id: this.$route.query.id
      };
      this.$api.enjoylife.articlesDetail(params).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.articlesDetail = res.result;
          var articlesPic = this.articlesDetail.info.thumb;
          if(articlesPic == null || articlesPic ==''){
            articlesPic = this.$publicData.imgUrl + this.$publicData.img;
          }
            if (articlesPic.indexOf("http") == -1) {
              this.articlesDetail.info.articlesthumb =
                this.$publicData.imgUrl + articlesPic;
            } else {
              this.articlesDetail.info.articlesthumb = articlesPic;
            }  

          var isOnline = location.href.indexOf('member.jiejieglass.com')>0
          if(isOnline){
            this.shareFun();
          }
        }
      });
    },
    //点击上一篇
    shang() {
      if (!(this.articlesDetail.shang instanceof Array)) {
        this.$router.push({
          path: "/news_info",
          query: {
            id: this.articlesDetail.shang.id
          }
        });
        window.location.reload();
      } else {
        this.$toast("没有上一篇");
      }
    },
    //点击下一篇
    xia() {
      if (!(this.articlesDetail.xia instanceof Array)) {
        this.$router.push({
          path: "/news_info",
          query: {
            id: this.articlesDetail.xia.id
          }
        });
        window.location.reload();
      } else {
        this.$toast("没有下一篇");
      }
    },
    shareFun() {
      var that = this;
      const url = location.href;
      // console.log("url=" + url);
      const shareUrl = location.href.split("#")[0];
      that.$api.common.commonShare({ url: shareUrl }).then(res => {
        if (res.code == 200) {
          that.shareConfig = res.result;
          wx.config({
            debug: that.shareConfig.debug,
            beta: that.shareConfig.beta,
            jsApiList: that.shareConfig.jsApiList,
            appId: that.shareConfig.appId,
            nonceStr: that.shareConfig.nonceStr, // 随机串
            timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
            signature: that.shareConfig.signature // 签名
          });

          // 分享
          wx.ready(function() {
            var shareDataA = {
              title: that.articlesDetail.info.title, // 分享标题
              desc: "分享自捷捷公众号", // 分享描述
              link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.articlesDetail.info.articlesthumb, // 分享图标
              success: function() {
              },
              cancel: function(e) {
              }
            };
            var shareDataB = {
              title: that.articlesDetail.info.title, // 分享标题
              link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.articlesDetail.info.articlesthumb, // 分享图标
              success: function() {
              },
              cancel: function(e) {
              }
            };
            wx.updateAppMessageShareData(shareDataA); // 分享给朋友
            wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
          });
        }
      });
    },
  }
};
</script>
<style  scoped>
/* .news_info{
  background: #f3f4f9;
} 
.news_info .van-nav-bar{
  background: transparent;
  position: absolute;
  width: 100%;
}
.news_info .y-home-news-list-buttom .van-button .van-button__text {
  width: 170px;
  text-align: left;
}
.news_info .y_add_storage{
  padding-top: 1.2rem;
}
.news_info .y-home-news-list-buttom .van-button p {
  float: left;
}
.news_info .y-home-news-list-buttom .van-button span {
  font-size: 12px;
  color: #999;
  width: 90px;
  float: right;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 5px;
  text-overflow: ellipsis;
}
.news_info .y-home-news-list-buttom .van-button {
  height: 35px;
  line-height: 35px;
  border: 1px #eeeeee solid;
  background-color: #ffffff;
  color: #000;
  padding: 0px 15px;
  width: 170px;
  text-align: left;
}
.news_info .y-home-news-info-xia {
  margin-top: 20px;
  font-size: 0.35rem;
  line-height: 21px;
}
.news_info .y-home-news-info-xia p {
  color: #666;
  text-indent: 2em;
}
.news_info .y-home-news-info-top i {
  font-size: 0.35rem;
  color: #999;
  float: left;
  padding-top: 10px;
}
.news_info .y-home-news-info-top h2 {
  font-size: 0.35rem;
}
.news_info .y-home-news-info-top,
.news_info .y-home-news-info-xia {
  width: 100%;
}
.news_info .y-home-news-list-buttom {
  float: left;
  width: 94%;
  margin: 15px 3% 20px 3%;
}
.news_info .y-home-news-info {
  width: 86.5%;
  margin-left: 3%;
  background-color: #ffffff;
  margin-top: 10px;
  border-radius: 10px;
  padding: 13px;
}*/
</style>