<template>
  <div class="integralexchange">
    <!-- :class="{back:back}" -->
    <van-nav-bar
      :z-index="99"
      :fixed="false"
      :border="false"
      title="积分兑换专区"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y-integral-all">
      <div class="y-integral-1 y-integral_exchange">
        <img src="../../assets/images/integral1.png" />
        <router-link to="integral">
          <h2>
            <p>
              <span>{{shopCenter.total_score}}</span>
              <i>积分</i>
              <van-icon name="arrow" color="#EE904F" size="16" />
            </p>
            <span>购物赚积分 积分当钱花</span>
          </h2>
        </router-link>
      </div>
      <div class="y-integral_exchange-shop">
        <div class="y-integral_exchange-shop-top">积分兑换商品</div>
        <div class="y-integral_exchange-shop-list clearfix">

          <div class="y-integral_exchange-shop-list1" v-for="(item,index) in goodsList" :key="index" @click="toInfo(item.id)">
            <img :src="ToPic(item.thumb)" />
            <div class="y-integral_exchange-shop-list1-title">{{item.title}}</div>
            <div class="y-integral_exchange-shop-list1-xia clearfix">
              <p>{{item.showSpecOriginPrice}} 积分</p>
              <span>
                {{item.showSpecPrice}}
                <i>积分</i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 加载框 -->
     <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Dialog } from "vant";
export default {
  data() {
    return {
      showloading:false,
      shopCenter: {},
      goodsList: [],
      // extendList:{},
      back: false,
      list: [
        {
          title: "美国0度新款眼镜盒男女简约近视眼镜收纳盒",
          price: 1999,
          integral: 500,
          images: "https://img01.yzcdn.cn/vant/cat.jpeg"
        },
        {
          title: "美国0度新款眼镜盒男女简约近视眼镜收纳盒",
          price: 1999,
          integral: 500,
          images: "https://img01.yzcdn.cn/vant/cat.jpeg"
        }
      ]
    };
  },
  created: function() {
    //  Dialog.confirm({ message: "积分商城开发中,敬请期待"
    //         }) .then(() => {
    //                 window.localStorage.removeItem("buyGoodsSpecInfo");
    //                 this.$router.push({
    //                   path: "/integral"
    //                 });
    //           })
    //   .catch(() => {
    //     this.$router.push({
    //       path: "/integral"
    //     });
    //   });

    // this.getExtendList();
    this.getList();
  },
  methods: {
    ToPic(pic) {
      if (pic.indexOf("http") == -1) {
        return this.$publicData.imgUrl + pic;
      } else {
        return pic;
      }
    },
    // 跳转到商品详情
    toInfo(id){
      this.$router.push({
        path:"/integral_info",
        query:{
          id:id
        }
      })
    },
    // 积分商城
    getList() {
      this.showloading = true;
      var param = {
        token: this.$store.getters.token
      };
      this.$api.score.scoreShopCenter(param).then(res => {
        this.showloading = false;
        if (res.code == 200) {
          this.shopCenter = res.result;
          this.goodsList = this.shopCenter.goodsList;
        }
      });
    },
    //积分推广列表
    // getExtendList(){
    //   var param = {
    //     token: this.$store.getters.token
    //   };
    //    this.$api.score.memberExtendList(param).then(res => {
    //      if(res.code == 200){
    //        this.extendList = res.result;
    //      }
    //    })
    // },
    showPopup() {
      this.show = true;
    }
    // TabBar() {
    //   var userOld = document.getElementsByClassName("y-integral_exchange-shop")[0];
    //   var that = this;
    //   if (userOld.scrollHeight - scrollY <= 500) {
    //     that.back = true;
    //   } else {
    //     that.back = false;
    //   }
    // }
  },
  mounted() {
    // window.addEventListener("scroll", this.TabBar);
  }
};
</script>
<style scoped>
/* .integralexchange .van-nav-bar {
  position: absolute;
  width: 100%;
} 
.integralexchange .y-integral_exchange-shop-list1-xia span i {
  font-size: 0.35rem;
}
.integralexchange .y-integral_exchange-shop-list1-xia span {
  background-image: url(../../assets/images/coupons-bg2shop.png);
  float: right;
  background-size: 100%;
  width: 90px;
  height: 25px;
  line-height: 25px;
  color: #fff;
  font-size: 0.35rem;
  text-align: center;
}
.integralexchange .y-integral_exchange-shop-list1-xia p {
  font-size: 0.35rem;
  color: #999;
  text-decoration: line-through;
  line-height: 25px;
  padding: 0px 10px;
  float: left;
}
.integralexchange .y-integral_exchange-shop-list1-xia {
  width: 90%;
  float: left;
  margin: 0px 5% 10px 5%;
  background-color: #f3f4f9;
  height: 25px;
}
.integralexchange .y-integral_exchange-shop-list1-title {
  font-size: 0.35rem;
  line-height: 20px;
  width: 90%;
  margin: 6px 5%;
  float: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.integralexchange .y-integral_exchange-shop-list1 img {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
}
.integralexchange .y-integral_exchange-shop-list1 {
  width: 47%;
  margin-right: 3%;
  background-color: #fff;
  border-radius: 8px;
  float: left;
  box-shadow: 2px 2px 5px #ebecf1;
}
.integralexchange .y-integral_exchange-shop-top {
  width: 100%;
  text-align: center;
  font-size: 0.35rem;
}
.integralexchange .y-integral_exchange-shop {
  width: 100%;
  float: left;
  margin-top: 10px;
}
.integralexchange .y-integral_exchange-shop-list {
  width: 97%;
  float: left;
  margin-left: 3%;
  margin-top: 10px;
}
.integralexchange .y-integral_exchange {
  width: 87% !important;
  float: left;
  margin: 240px 3% 15px 3% !important;
  background-color: #fff;
  padding: 16px 12px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px #ebecf1;
}
.integralexchange .y-integral-1 span {
  font-size: 0.35rem;
  color: #999;
  float: left;
}
.integralexchange .y-integral-1 {
  width: 94%;
  margin: 10px 3% 15px 3%;
  float: left;
}
.integralexchange .y-integral-1 h2 {
  float: left;
  width: 60%;
}
.integralexchange .y-integral-1 h2 p {
  width: 100%;
  float: left;
  color: #ee904f;
  margin-bottom: 3px;
}
.integralexchange .y-integral-1 h2 p span {
  margin-top: 0.25rem;
  font-size: 0.35rem;
  float: left;
  margin-right: 5px;
  color: #ee904f;
}
.integralexchange .y-integral-1 h2 p i {
  font-size: 0.35rem;
  float: left;
  margin: 7px 0px 0px 0px;
}
.integralexchange .y-integral-1 h2 p .van-icon {
  color: #ee904f !important;
  float: left;
  margin-top: 11px;
}
.integralexchange .y-integral-1 img {
  width: 50px;
  height: 50px;
  float: left;
  margin: 0px 10px;
}
.integralexchange .y-integral-all {
  width: 100%;
  background-image: url(../../assets/images/coupons-bg2.png);
  background-size: 100%;
  background-repeat: no-repeat;
  float: left;
  background-color: #f3f4f9;
  min-height: 100vh;
}

.integralexchange ::v-deep .back {
  background: #fff !important;
}
.integralexchange .back .van-icon {
  color: #000 !important;
}
.integralexchange ::v-deep .back .van-nav-bar__title {
  color: #000 !important;
}
.integralexchange .van-nav-bar {
  background-color: transparent;
}
.integralexchange .van-icon {
  color: #ffffff !important;
}
.integralexchange ::v-deep .van-nav-bar__title {
  color: #ffffff !important;
}*/
</style>