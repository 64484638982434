<template>
  <div class="news_list">
    <van-nav-bar
      :fixed="false"
      :placeholder="true"
      :border="false"
      title="爱眼护眼文章专区"
      left-arrow
      @click-left="tohome"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y_add_storage">
      <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
        <div
          class="y-home-news-list clearfix"
          v-for="(item,index) in articlesList"
          :key="index"
          @click="toNewsinfo(item.id)"
        >
          <div class="y-home-news-list-l">
            <img :src="item.articlesthumb?item.articlesthumb:$publicData.imgUrl+$publicData.img" />
          </div>
          <div class="y-home-news-list-r">
            <p>{{item.title}}</p>
            <span>{{item.describe}}</span>
            <i>{{item.updated_at}}</i>
          </div>
        </div>
      </van-pull-refresh>
    </div>
    <!-- 加载框 -->
    <!-- <van-popup class="jiazai" v-model="showloading">
      <van-loading type="spinner" />
    </van-popup>-->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      isLoading: false,
      showloading: false,
      articlesList: [],
      articlesthumb: "",
      shareConfig:{},
    };
  },
  created: function() {
    this.getArticlesList();
    var isOnline = location.href.indexOf('member.jiejieglass.com')>0
    if(isOnline){
      this.shareFun();
    }
  },
  methods: {
    //下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        this.getLaArticlesList();
      }, 1000);
    },
    //文章列表
    getArticlesList() {
      this.showloading = true;
      var params = {
        token: this.$store.getters.token
      };
      this.$api.enjoylife.articlesList(params).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.articlesList = res.result.list;
          for (var i in this.articlesList) {
            var articlesPic = this.articlesList[i].thumb;
            if (articlesPic && articlesPic.indexOf("http") == -1) {
              this.articlesList[i].articlesthumb =
                this.$publicData.imgUrl + articlesPic;
            } else {
              this.articlesList[i].articlesthumb = articlesPic;
            }
          }
            // console.log("articlesList",this.articlesList);
        }
      });
    },
    getLaArticlesList() {
      var params = {
        token: this.$store.getters.token
      };
      this.$api.enjoylife.articlesList(params).then(res => {
        if (res.code == 200) {
          this.articlesList = res.result.list;
          for (var i in this.articlesList) {
            var articlesPic = this.articlesList[i].thumb;
            if (articlesPic.indexOf("http") == -1) {
              this.articlesList[i].articlesthumb =
                this.$publicData.imgUrl + articlesPic;
            } else {
              this.articlesList[i].articlesthumb = articlesPic;
            }
          }
        }
      });
    },
    //跳转详情
    toNewsinfo(id) {
      this.$router.push({
        path: "/news_info",
        query: {
          id: id
        }
      });
    },
    //跳转到首页
    tohome() {
      this.$router.push({
        path: "/home"
      });
    },
    shareFun() {
      var that = this;
      const url = location.href;
      // console.log("url=" + url);
      const shareUrl = location.href.split("#")[0];
      that.$api.common.commonShare({ url: shareUrl }).then(res => {
        if (res.code == 200) {
          that.shareConfig = res.result;
          wx.config({
            debug: that.shareConfig.debug,
            beta: that.shareConfig.beta,
            jsApiList: that.shareConfig.jsApiList,
            appId: that.shareConfig.appId,
            nonceStr: that.shareConfig.nonceStr, // 随机串
            timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
            signature: that.shareConfig.signature // 签名
          });
          // 分享
          wx.ready(function() {
            var shareDataA = {
              title: "爱眼护眼——分享你的护眼经验", // 分享标题
              desc: "分享自捷捷公众号", // 分享描述
              link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.$publicData.imgUrl + that.$publicData.img, // 分享图标
              success: function() {
              },
              cancel: function(e) {
              }
            };
            var shareDataB = {
              title: "爱眼护眼——分享你的护眼经验", // 分享标题
              link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.$publicData.imgUrl + that.$publicData.img, // 分享图标
              success: function() {
              },
              cancel: function(e) {
              }
            };
            wx.updateAppMessageShareData(shareDataA); // 分享给朋友
            wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
          });
        }
      });
    },
  }
};
</script>
<style  scoped>
</style>