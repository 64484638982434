/**
 * 收货地址接口
 */
import axios from "../http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块，把请求字段直接映射过来

const address = {
  //地区信息
  areasDetail(params) {
    return axios.get("/api/areasDetail", { params: params });
  },
  //收货地址列表
  memberAddressList(params) {
    return axios.post("/api/member/memberAddressList", qs.stringify(params));
  },
  //新增
  memberAddressCreate(params) {
    return axios.post("/api/member/memberAddressCreate", qs.stringify(params));
  },
  //修改
  memberAddressEdit(params) {
    return axios.post("/api/member/memberAddressEdit", qs.stringify(params));
  },
  //查询详情
  memberAddressDetail(params) {
    return axios.post("/api/member/memberAddressDetail", qs.stringify(params));
  },
  //删除
  memberAddressRemove(params) {
    return axios.post("/api/member/memberAddressRemove", qs.stringify(params));
  },
};
// 导出接口
export default address;
