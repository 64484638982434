<template>
  <div class="order_comment">
    <van-nav-bar :border="false" title="发表评价" left-arrow @click-left="$router.back()">
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
      <!-- <template #left>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" to="order_list">
            <van-icon name="arrow-left" color="#000" />
          </router-view>
        </keep-alive>
      </template>-->
    </van-nav-bar>
    <div class="orderEvaluate">
      <!-- 商品评价 -->
      <div class="evaluate">
        <div v-if="routype == 2">
          <div
            class="orderGoods jiejie-order-goods"
            v-for="(item,index) in pjOrderDetail.pj_order_goods"
            :key="index"
          >
            <van-image :src="item.goods.goodsthumb" fit="cover" />
            <div class="goods-right">
              <span class="goods-title">{{item.goods.title}}</span>
              <span>{{item.goods_spec.goods_spec_name}}</span>
            </div>
          </div>
        </div>
        <div v-if="routype == 1">
          <div
            class="orderGoods jiejie-order-goods"
            v-for="(item,index) in detailGoods.member_order_goods"
            :key="index"
          >
            <van-image :src="item.goods.detailpic" fit="cover" />
            <div class="goods-right">
              <span class="goods-title">{{item.goods.title}}</span>
              <span>{{item.goods_spec.goods_spec_name}}</span>
            </div>
          </div>
        </div>
        <div class="goods_text">
          <van-field
            v-model="message"
            rows="5"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="亲，分享您的使用感受、服务等，还可获得积分哦~"
            show-word-limit
          />
          <!-- <textarea placeholder="亲，分享您的使用感受、服务等，还可获得积分哦~" @change="changetext"></textarea>
          <span class="goods-num">0/500</span>-->
        </div>
        <van-uploader
          v-model="fileList"
          multiple
          :max-count="3"
          upload-text="上传照片"
          upload-icon="photo-o"
          :after-read="onRead"
          :before-delete="onDelet"
        />
      </div>
      <!-- 订单评分 -->
      <div class="order-score jiejie-score">
        <van-rate
          v-model="score"
          :size="25"
          color="#EE904F"
          void-icon="star"
          void-color="#ddd"
          @change="onChange"
        />
        <span class="order-satisfied">{{scoretext}}</span>
        <div class="order-btn">
          <van-button
            v-for="(item,index) in pjOrderCommentTag"
            :key="index"
            round
            :color="item.color"
            type="info"
            plain
            @click="toChange(item)"
          >{{item.name}}</van-button>
          <!-- <van-button round type="info" class="btn-active">验光师专业</van-button>
          <van-button round type="info">配送超快</van-button>
          <van-button round type="info">服务到位</van-button>
          <van-button round type="info">眼睛合适</van-button>
          <van-button round type="info" class="btn-active">完美的一次体验</van-button>-->
        </div>
      </div>
      <!-- 提交按钮 -->
      <van-button round type="info" class="order-sub" @click="subpingjia">提交评价</van-button>
    </div>
    <!-- 积分弹框 -->
    <van-popup :close-on-click-overlay="false" v-model="show_rw" class="lqwork">
      <h2>任务完成</h2>
      <div class="y-task-top">
        <p>奖励</p>
      </div>
      <div class="y-task-zj">
        <p>+{{scorenum}}</p>
        <img src="../../assets/images/jb.png" />
      </div>
      <div class="y-task-buttom">
        <van-button
          round
          closed
          color="linear-gradient(to right, #F28E26, #FD644F)"
          type="primary"
          @click="toback"
        >完成</van-button>
      </div>
    </van-popup>
    <!-- 加载框 -->
    <van-overlay class="jiazai" :show="showloading">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showloading:false,
      show_rw: false,
      scorenum: 0,
      detailGoods: {},
      routype: "",
      message: "",
      img_yanjing: require("../../assets/images/1.jpg"),
      fileList: [],
      score: 5,
      scoretext: "满意",
      pjOrderCommentTag: [],
      pjOrderDetail: {},
      goodsthumb: "",
      tagarr: [],
      uploadTuList: []
    };
  },
  created: function() {
    this.routype = this.$route.query.type;
    this.getPjOrderCommentTag();
    this.showloading = true;
    if (this.routype == 2) {
      this.getPjOrderDetail();
    } else {
      this.getMyOrderDetail();
    }
  },
  methods: {
    //上传图片
    onRead: function(file) {
      this.showloading = true;
      this.$api.member.uploadTu({ imgFile: file.content }).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.uploadTuList.push(res.result.url);
          // console.log("this.uploadTuList",this.uploadTuList);
        }else{
          this.showloading = false
        }
      });
    },
    // 删除
    onDelet(e, detail) {
      var index = detail.index;
      this.fileList.splice(index, 1);
      this.uploadTuList.splice(index, 1);
    },
    //输入评级
    changetext(a) {
      // console.log("a", a);
    },
    //评分改变
    onChange(score) {
      this.score = score;
      if (score == 5) {
        this.scoretext = "满意";
      } else if (score == 4) {
        this.scoretext = "较满意";
      } else if (score == 3) {
        this.scoretext = "一般";
      } else if (score == 2) {
        this.scoretext = "差";
      } else {
        this.scoretext = "非常差";
      }
    },
    // 评价按钮接口
    getPjOrderCommentTag() {
      var param = {
        token: this.$store.getters.token
      };
      this.$api.pjorder.pjOrderCommentTag(param).then(res => {
        var pjOrder = res.result;
        for (var i in pjOrder) {
          this.pjOrderCommentTag.push({
            name: pjOrder[i],
            active: 0,
            color: "#B8B8B8"
          });
        }
      });
    },
    //我的订单详情接口
    getMyOrderDetail() {
      var param = {
        token: this.$store.getters.token,
        order_id: this.$route.query.id
      };
      this.$api.goodsOrder.detail(param).then(res => {
        this.showloading =false;
        if (res.code == 200) {
          this.detailGoods = res.result;
          for (var i in this.detailGoods.member_order_goods) {
            var pic = this.detailGoods.member_order_goods[i].goods.thumb;
            if (pic.indexOf("http") == -1) {
              this.detailGoods.member_order_goods[i].goods.detailpic =
                this.$publicData.imgUrl + pic;
            } else {
              this.detailGoods.member_order_goods[i].goods.detailpic = pic;
            }
          }
        }
      });
    },
    //配镜订单详情接口
    getPjOrderDetail() {
      var params = {
        token: this.$store.getters.token,
        id: this.$route.query.id
      };
      this.$api.pjorder.pjOrderDetail(params).then(res => {
        this.showloading = false;
        this.pjOrderDetail = res.result;
        for (var i in this.pjOrderDetail.pj_order_goods) {
          var pjpic = this.pjOrderDetail.pj_order_goods[i].goods.thumb;
          if (pjpic.indexOf("http") == -1) {
            this.pjOrderDetail.pj_order_goods[i].goods.goodsthumb =
              this.$publicData.imgUrl + pjpic;
          } else {
            this.pjOrderDetail.pj_order_goods[i].goods.goodsthumb = pjpic;
          }
        }
      });
    },
    //点击评价按钮
    toChange(item) {
      // console.log("item",this.pjOrderCommentTag);
      if (item.active == 0) {
        item.active = 1;
        item.color = "#ee904f";
      } else {
        item.active = 0;
        item.color = "#B8B8B8";
      }
      this.tagarr = [];
      for (var i in this.pjOrderCommentTag) {
        var pjOrderactive = this.pjOrderCommentTag[i].active;
        var pjOrdername = this.pjOrderCommentTag[i].name;
        if (pjOrderactive == 1) {
          this.tagarr.push(this.pjOrderCommentTag[i].name);
        }
      }
      // console.log(this.tagarr);
    },
    // 点击提交评价
    subpingjia() {
      this.showloading = true;
      // 配镜-订单提交评价接口
      // console.log("type", this.$route.query.bigStatus);
      // console.log("id", this.$route.query.id);
      // console.log("star", this.score);
      // console.log(
      //   "content",
      //   document.getElementsByTagName("textarea")[0].value
      // );
      // console.log("tag",this.tagarr);
      // console.log("alum",this.fileList);
      var params = {
        token: this.$store.getters.token,
        // type: this.$route.query.bigStatus,
        type: this.$route.query.type,
        id: this.$route.query.id,
        star: this.score,
        // content: document.getElementsByTagName("textarea")[0].value,
        content: this.message,
        tag: this.tagarr,
        alum: this.uploadTuList
      };
      this.$api.pjorder.commentCreate(params).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          if (res.result.isPopGetScore && res.result.isPopGetScore == 1) {
            this.scorenum = res.result.popScoreNum;
            this.show_rw = true;
          } else {
            this.$toast("提交成功");
            this.$router.back();
          }
          // this.$router.push({
          //   path: "/order_list"
          // });
        }
      });
    },
    toback() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
/* .order_comment {
  background: #f3f4f9;
  min-height: 100vh;
}
.order_comment .van-nav-bar {
  background: transparent;
}
.order_comment .evaluate textarea {
  width: 100%;
  height: 5rem;
  border: 0;
  resize: none;
  box-sizing: border-box;
  padding: 0.3rem 0.4rem;
  font-size: 0.35rem;
}

.order_comment .evaluate textarea::-webkit-input-placeholder {
  font-size: 0.35rem;
  color: #000;
  opacity: 0.6;
}
.order_comment .evaluate textarea::-moz-placeholder {
  font-size: 0.35rem;
  color: #000;
  opacity: 0.6;
}
.order_comment .evaluate textarea:-moz-placeholder {
  font-size: 0.35rem;
  color: #000;
  opacity: 0.6;
}
.order_comment .evaluate textarea:-ms-input-placeholder {
  font-size: 0.35rem;
  color: #000;
  opacity: 0.6;
}
.order_comment .evaluate .van-uploader {
  margin-left: 0.4rem;
  margin-bottom: 0.4rem;
}
.order_comment .evaluate .van-uploader .van-image,
.order_comment .evaluate .van-uploader img {
  width: 1.73rem;
  height: 1.73rem;
  border-radius: 0.13rem;
}
.order_comment .evaluate .van-uploader .van-uploader__preview,
.order_comment .evaluate .van-uploader .van-uploader__upload {
  width: 1.73rem;
  height: 1.73rem;
  margin-right: 0.2rem;
}
.order_comment .evaluate .van-uploader .van-uploader__upload {
  border-radius: 0.13rem;
  border: 0.03rem dashed #cacaca;
}
.order_comment .evaluate .van-uploader .van-uploader__preview-delete {
  border-radius: 50%;
  top: -0.1rem;
  right: -0.1rem;
  opacity: 0.7;
}
.order_comment .evaluate .van-uploader .van-uploader__preview-delete-icon {
  top: -0.03rem;
  right: -0.03rem;
}
.order_comment .evaluate .van-uploader .van-uploader__upload .van-uploader__upload-text {
  font-size: 0.35rem;
  color: #000;
  opacity: 0.6;
}
.order_comment .evaluate .goods-num {
  position: absolute;
  top: 3.8rem;
  right: 0.35rem;
  font-size: 0.35rem;
  opacity: 0.3;
}
.order_comment .orderEvaluate .evaluate .orderGoods {
  border-bottom: 0.01rem solid #e5e5e5;
}
.order_comment .jiejie-score {
  background: #fff;
  margin-top: 0.4rem;
  border-radius: 0.27rem;
  box-shadow: 0rem 0.07rem 0.21rem 0rem rgb(0 0 0 / 7%);
  padding: 0.52rem 0.4rem 0.2rem;
}
.order_comment .orderEvaluate .evaluate {
  position: relative;
  background: #fff;
  box-shadow: 0rem 0.07rem 0.21rem 0rem rgb(0 0 0 / 7%);
  border-radius: 0.27rem;
}
.order_comment .orderEvaluate {
  background: #f3f4f9;
}
.order_comment .jiejie-order-goods .van-image {
  border: 0.03rem solid #e5e5e5;
  margin-right: 0.26rem;
  border-radius: 0.13rem;
  width: 1.6rem;
  height: 1.6rem;
}
.order_comment .jiejie-order-goods .van-image img {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.13rem;
}
.order_comment .jiejie-order-goods {
  display: flex;
  padding: 0.4rem;
}
.order_comment .jiejie-order-goods .van-image {
  border: 0.03rem solid #e5e5e5;
  margin-right: 0.26rem;
  border-radius: 0.13rem;
}
.order_comment .goods-right {
  width: 6rem;
}
.order_comment .goods-right span {
  font-size: 0.35rem;
  color: #000000;
  opacity: 0.6;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.1rem;
}
.order_comment .goods-right .goods-title {
  opacity: 1;
  font-size: 0.35rem;
  margin-bottom: 0.2rem;
}
.order_comment .goods_text {
  position: relative;
}
.order_comment .orderEvaluate {
  padding: 0.3rem 0.4rem 0.2rem;
}
.order_comment .van-rate {
  margin-left: 1.5rem;
  margin-right: 0.5rem;
}
.order_comment .order-satisfied {
  vertical-align: super;
  font-size: 0.4rem;
  color: #ee904f;
}
.order_comment .order-btn {
  margin-top: 0.5rem;
}
.order_comment .order-btn .van-button {
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
  border-color: #666;
  height: 0.67rem;
  margin-right: 0.3rem;
  border-radius: 0.33rem;
  margin-bottom: 0.3rem;
}
.order_comment .order-btn .van-button span {
  font-size: 0.35rem;
}
.order_comment .order-btn .btn-active {
  background: #fdf1ed;
  border-color: #ee904f;
}
.order_comment .order-btn .btn-active span {
  color: #ee904f;
}
.order_comment .order-sub {
  margin-bottom: 0.5rem;
  margin-top: 0.7rem;
  width: 9.2rem;
  height: 1rem;
  background: linear-gradient(97deg, #35b6d8, #52d3c5);
  box-shadow: 0rem 0.13rem 0.28rem 0rem rgba(205, 205, 205, 0.2);
  border-color: #35b6d8;
  font-size: 0.4rem;
  font-weight: 500;
} */
</style>
