<template>
  <div class="setupedit">
    <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <div class="y-edit">
      <van-cell-group>
        <van-field v-model="nickname" clearable placeholder="修改信息" />
      </van-cell-group>
    </div>
    <div class="y-edit-button">
      <van-button
        @click="editUserInfo"
        round
        type="primary"
        block
        color="linear-gradient(to right, #35B6D8, #52D3C5)"
      >保存</van-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "修改基本信息",
      nickname: this.$route.params.id,
      type: 1
    };
  },
  created: function() {
    this.type = this.$route.query.type;
    this.nickname = this.$route.query.name;
  },
  methods: {
    editUserInfo() {
      var that = this;
      var params = {};
      if (that.type == 1) {
        // 名称
        // params = { token: this.$store.getters.token, name: this.nickname };
        that.$router.push({
          path: "/setup",
          query: {
            type: 1,
            name: that.nickname
          }
        });
      } else if (that.type == 6) {
        // 邮箱
        // params = { token: this.$store.getters.token, email: this.nickname };
        that.$router.push({
          path: "/setup",
          query: {
            type: 6,
            name: that.nickname
          }
        });
      }
      // this.$api.member.editUserInfo(params).then(res => {
      //   if (res.code == 200) {
      //     that.$router.push({
      //       path: "/setup"
      //     });
      //   }
      // });
    }
  }
};
</script>
<style scoped>
/* .setupedit .van-nav-bar .van-icon {
  color: #000;
}
.setupedit .y-edit-button {
  width: 94%;
  float: left;
  margin-left: 3%;
  bottom: 0;
  z-index: 1000;
  position: fixed;
  left: 0;
  bottom: 0;
  margin-bottom: 25px;
} */
</style>