/**
  * 公告调用 接口的统一出口
  */
import axios from '../http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块，把请求字段直接映射过来

const common = {
  // 获取字典
  list(params) {
    return axios.get('/api/dict-info/list', { params: params })
  },
  // 获取验证码
  sendCode(params) {
    return axios.post('/api/common/send-code', qs.stringify(params));
  },
  commonShare (params) {
  return axios.get('/api/common/share', { params: params })
  },
  //分享
  shareInfo(){
    
  }
}

// 导出接口
export default common
