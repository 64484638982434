<template>
  <div class="wateval">
    <van-nav-bar
      :z-index="99"
      :border="false"
      title="评价详情"
      :fixed="false"
      left-arrow
      @click-left="$router.back()"
    >
      <template #left>
        <van-icon name="arrow-left" color="#000" />
      </template>
    </van-nav-bar>
    <!-- 商品 -->
    <!-- <div class="commentsGoods jiejie-order-goods">
      <van-image :src="img_yanjing" fit="cover" />
      <div class="goods-right">
        <span class="goods-title">男女复古超轻圆框眼镜框 树脂材质耐磨抗压</span>
        <span>防蓝光1.61非球面镜片0-800</span>
      </div>
    </div>-->
    <!-- 评星 -->
    <div class="comments-score jiejie-score">
      <van-rate
        v-model="score"
        :size="25"
        color="#EE904F"
        void-icon="star"
        void-color="#ddd"
        :readonly="true"
      />
      <span class="order-satisfied">{{scoretext}}</span>
      <div class="order-btn">
        <van-button
          v-for="(item,index) in commentDetail.tagArr"
          :key="index"
          round
          type="info"
          class="btn-active"
        >{{item}}</van-button>
        <!-- <van-button round type="info" class="btn-active">验光</van-button>
        <van-button round type="info" class="btn-active">完美的一次体验</van-button>-->
      </div>
    </div>
    <!-- 用户评价 -->
    <div class="comments-user">
      <!-- <span class="user-time">1小时前</span>
      <div class="user-name">
        <van-image :src="img_portrait" round fit="cover" />
        <div class="name-right">
          <span class="name">放肆的猫</span>
          <span>防蓝光1.61非球面镜片0-800 | 度数留言</span>
        </div>
      </div>-->
      <p>{{commentDetail.content == null?"无详细描述":commentDetail.content}}</p>
      <!-- <van-image :src="img_recommend" fit="cover" class="user-pic" /> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scoretext: "",
      commentDetail: {},
      img_yanjing: require("../../assets/images/1.jpg"),
      score: 0,
      img_portrait: require("../../assets/images/1.jpg"),
      img_recommend: require("../../assets/images/1.jpg")
    };
  },
  created: function() {
    this.getCommentDetail();
  },
  methods: {
    //   查看评价接口
    getCommentDetail() {
      var params = {
        token: this.$store.getters.token,
        order_sn: this.$route.query.order_sn
      };
      this.$api.pjorder.commentDetail(params).then(res => {
        if (res.code == 200) {
          this.commentDetail = res.result;
          this.score = this.commentDetail.star;
          if (this.score == 5) {
            this.scoretext = "满意";
          } else if (this.score == 4) {
            this.scoretext = "较满意";
          } else if (this.score == 3) {
            this.scoretext = "一般";
          } else if (this.score == 2) {
            this.scoretext = "差";
          } else {
            this.scoretext = "非常差";
          }
        }
      });
    }
  }
};
</script>

<style scoped>
</style>