/**
 * 捷捷商城接口
 */
import axios from "../http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块，把请求字段直接映射过来
const mall = {
  //商城首页
  shopCenter(params) {
    return axios.post("/api/member/shopCenter", qs.stringify(params));
  },
  //商城商品详情
  goodsDetail(params) {
    return axios.post("/api/member/goodsDetail", qs.stringify(params));
  },
  // //商城商品评价
  // goodsComment(params) {
  //   return axios.post("/api/member/goodsComment", qs.stringify(params));
  // },
  //商城商品评价
  goodsCommentList(params) {
    return axios.post("/api/member/goodsCommentList", qs.stringify(params));
  },
  //商城商品添加到购物车
  goodsCarAdd(params) {
    return axios.post("/api/member/goodsCar/add", qs.stringify(params));
  },
  //查看购物车
  goodsCarDetail(params) {
    return axios.post("/api/member/goodsCar/detail", qs.stringify(params));
  },
  //更改购物车商品数量
  goodsCarChangeNum(params) {
    return axios.post("/api/member/goodsCar/changeNum", qs.stringify(params));
  },
  //商城商品列表
  goodsList(params) {
    return axios.post("/api/goodsList", qs.stringify(params));
  },
  //商品—分类查询
  goodsSearchInfo(params) {
    return axios.post("/api/goodsSearchInfo", qs.stringify(params));
  },
  //商品—次级分类查询
  goodsCateChildren(params) {
    return axios.post("/api/goodsCateChildren", qs.stringify(params));
  },
};
// 导出接口
export default mall;
