<template>
  <!-- 商品列表 -->

  <div class="goodsList">
    <div class="header clearfix">
      <div class="icon f-l">
        <van-icon name="arrow-left" color="#fff" @click="toMall" />
      </div>
      <van-search
        :clearable="false"
        v-model="keyvalue"
        placeholder="搜索关键字"
        left-icon
        left="arrow-left"
        show-action
      >
        <!-- :right-icon="img_search" -->
        <template #right-icon>
          <van-icon :name="img_search" @click="searchicon" />
        </template>
        <template #action>
          <van-cell @click="showPopup">
            <img :src="img_sort" alt="pic" />
            <div class="fen" @click="onSort">分类</div>
          </van-cell>
          <!-- <van-popup v-model="show" round position="right" :style="{width:'80%', height:'100%'}">
            商品分类页
            <v-sort></v-sort>
          </van-popup>-->
          <van-popup
            v-model="showsort"
            round
            position="right"
            :style="{width:'80%', height:'100%'}"
          >
            <!-- 商品分类页 -->
            <!-- <h1>分类</h1> -->
            <div class="sortpage">
              <van-collapse v-model="activeNames">
                <!-- 全部分类 -->
                <van-collapse-item
                  title="分类"
                  name="allsort"
                  :value="cateName"
                  :border="false"
                  class="all"
                >
                  <span
                    v-for="(item,index) in cateList"
                    :key="'cateList1'+index"
                    :class="item.classname"
                    @click="allsort(index,item.id)"
                  >{{item.name}}</span>
                </van-collapse-item>
                <!-- 二级分类 -->
                <div v-if="catearr.length != 0">
                  <div v-for="(a,i) in catearr" :key="'catrarr2'+i">
                    <!-- v-if="goodsCateChildren.length != 0" -->
                    <van-collapse-item
                      :title="a.child.title"
                      :name="'erji'+i"
                      :value="a.erjiName"
                      :border="false"
                      v-if="a.child.list.length != 0"
                    >
                      <span
                        v-for="(item,index) in a.child.list"
                        :key="'child3'+index"
                        :class="item.classCateChildren"
                        @click="erjisort(index,item.id,i)"
                      >{{item.name}}</span>
                    </van-collapse-item>
                  </div>
                </div>
                <!-- 价格区间 -->
                <van-collapse-item
                  title="价格区间"
                  name="pricesec"
                  :is-link="false"
                  :border="false"
                  :disabled="true"
                  class="price"
                >
                  <input class="minprice" type="text" placeholder="最低价" />
                  <span class="line">-</span>
                  <input class="maxprice" type="text" placeholder="最高价" />
                </van-collapse-item>
                <!-- 品牌 -->
                <!-- <van-collapse-item title="品牌" name="pinpai" :value="brandName" :border="false">
                  <span
                    v-for="(item,index) in brandList"
                    :key="'brandList4'+index"
                    :class="item.classbrand"
                    @click="pinpaisort(index,item.id)"
                  >{{item.name}}</span>
                </van-collapse-item>-->

                <span v-for="(item,index) in goodsSearchInfo.attrList" :key="'attrList5'+index">
                  <van-collapse-item
                    v-if="item.item.length != 0"
                    :title="item.name"
                    :name="item.name+item.id"
                    :value="item.activevalue"
                    :border="false"
                  >
                    <span
                      v-for="(itemin,i) in item.item"
                      :key="'item6'+i"
                      :class="itemin.classattr"
                      @click="attractive(item,itemin,index,itemin.id)"
                    >{{itemin.item}}</span>
                  </van-collapse-item>
                </span>
              </van-collapse>
              <!-- footer  -->
              <div class="footer">
                <div class="select">
                  <span v-if="cateName !='' ">{{cateName}}</span>
                  <span v-for="(item,index) in catearr" :key="'catearr7'+index">
                    <span v-if="item.erjiName != ''">{{item.erjiName}}</span>
                  </span>

                  <span v-if="brandName !='' ">{{brandName}}</span>
                  <span v-for="(item,index) in goodsSearchInfo.attrList" :key="'attrList8'+index">
                    <span v-if="item.activevalue != ''">{{item.activevalue}}</span>
                  </span>
                </div>
                <van-button round type="info" class="reset" @click="reset">重置</van-button>
                <van-button round type="info" class="determine" @click="determine">
                  确定
                  <!-- <span class="num">(50+件商品)</span> -->
                </van-button>
              </div>
            </div>
          </van-popup>
        </template>
      </van-search>
    </div>
    <!-- 热销商品 -->
    <div class="recommend clearfix">
      <div class="goods clearfix" v-if="sortgoodsList.length !=0">
        <div
          class="box f-l"
          v-for="(item,index) in sortgoodsList"
          :key="'sortgoodsList9'+index"
          @click="toDetalis(item.id)"
        >
          <van-image fit="contain" :src="item.goodsthumb" />
          <p class="word slh">{{item.title}}</p>
          <div class="xilie" v-if="item.showInfo">
            <span>{{item.showInfo.showSpecName}}</span>
          </div>
          <span class="nowmon" v-if="item.showInfo">
            ￥
            <span>
              {{item.showInfo.showSpecPrice}}
              <span class="qi">起</span>
            </span>
          </span>
          <span class="beforemon" v-if="item.showInfo">
            ￥
            <span>{{item.showInfo.showSpecOriginPrice}}</span>
          </span>
        </div>
      </div>
      <div class="nogoods" v-else>
        <span>暂无商品</span>
      </div>
    </div>
    <van-overlay :show="showloading">
      <van-loading type="spinner" />
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showloading: false,
      sortgoodsList: [],
      catearr: [],
      goodsSearchInfo: {},
      goodsCateChildren: {},
      cateList: [],
      cateName: "",
      erjiName: "",
      minprice: "",
      maxprice: "",
      brandList: [],
      brandName: "",
      activeNames: ["allsort", "pricesec", "pinpai"],
      allsortId: "",
      pinpaiId: "",
      shuxingId: [],
      keyvalue: null,
      showsort: false,
      img_sort: require("../../assets/images/index/sort.png"),
      img_search: require("../../assets/images/searchlan.png"),
      recommend: [
        {
          img: require("../../assets/images/index/recommend_1.png"),
          p:
            "FRANZLISZT防蓝光近视眼镜框男女复古超轻圆框面无度数眼镜蓝光阻隔率>80%",
          xilie1: "FRANZLISZT",
          xilie2: "超轻系列",
          nowmon: 1599,
          beforemon: 1999
        },
        {
          img: require("../../assets/images/index/recommend_1.png"),
          p: "米家防蓝光眼镜钛轻盈 防辐射眼镜平面无度数眼镜蓝光阻隔率>80%",
          xilie1: "小米",
          xilie2: "超轻系列",
          nowmon: 319.2,
          beforemon: 399
        },
        {
          img: require("../../assets/images/index/recommend_2.png"),
          p: "恋上LianSan防蓝光防辐射眼镜男女款电竞游戏护目镜",
          xilie1: "LianSan",
          xilie2: "捷捷系列",
          nowmon: 233,
          beforemon: 279
        },
        {
          img: require("../../assets/images/index/recommend_2.png"),
          p: "恋上LianSan防蓝光防辐射眼镜男女款电竞游戏护目镜",
          xilie1: "LianSan",
          xilie2: "捷捷系列",
          nowmon: 233,
          beforemon: 279
        },
        {
          img: require("../../assets/images/index/recommend_1.png"),
          p:
            "FRANZLISZT防蓝光近视眼镜框男女复古超轻圆框面无度数眼镜蓝光阻隔率>80%",
          xilie1: "FRANZLISZT",
          xilie2: "超轻系列",
          nowmon: 1599,
          beforemon: 1999
        },
        {
          img: require("../../assets/images/index/recommend_1.png"),
          p: "米家防蓝光眼镜钛轻盈 防辐射眼镜平面无度数眼镜蓝光阻隔率>80%",
          xilie1: "小米",
          xilie2: "超轻系列",
          nowmon: 319.2,
          beforemon: 399
        }
      ],
      bef_allsortId: "",
      bef_minprice: "",
      bef_maxprice: "",
      bef_shuxingId: ""
    };
  },
  created: function() {
    this.getGoodsList();
  },
  methods: {
    //点击搜索图标
    searchicon() {
      this.getGoodsList();
    },
    // 商城商品列表
    getGoodsList() {
      this.bef_allsortId = window.localStorage.getItem("allsortId");
      this.bef_minprice = window.localStorage.getItem("minprice");
      this.bef_maxprice = window.localStorage.getItem("maxprice");
      var shuxingIdhou = window.localStorage.getItem("shuxingId");
      this.bef_shuxingId = JSON.parse(shuxingIdhou);

      var allsortId =
        this.allsortId == "" ? this.bef_allsortId : this.allsortId;
      var minprice = this.minprice == "" ? this.bef_minprice : this.minprice;
      var maxprice = this.maxprice == "" ? this.bef_maxprice : this.maxprice;
      // console.log(this.shuxingId,this.bef_shuxingId );
      var shuxingid =
        this.shuxingId.length == 0 ? this.bef_shuxingId : this.shuxingId;
      var shuxingIds = shuxingid == null ? [] : shuxingid;
      this.showloading = true;

      var param = {
        token: this.$store.getters.token,
        is_member: 1,
        key: this.keyvalue,
        cate_id: allsortId,
        begin_price: minprice,
        end_price: maxprice,
        attr_id: shuxingIds
      };
      this.$api.mall.goodsList(param).then(res => {
        if (res.code == 200) {
          this.showloading = false;
          this.sortgoodsList = res.result;
          //  图片格式判断
          for (var i in this.sortgoodsList) {
            var goodspic = this.sortgoodsList[i].thumb;
            if (goodspic.indexOf("http") == -1) {
              this.sortgoodsList[i].goodsthumb =
                this.$publicData.imgUrl + goodspic;
            } else {
              this.sortgoodsList[i].goodsthumb = goodspic;
            }
          }
        }
      });
      this.allsortId = "";
      this.minprice = "";
      this.maxprice = "";
      this.shuxingId = [];
    },
    //商品分类接口
    getGoodsSearchInfo() {
      var param = {
        token: this.$store.getters.token,
        is_member: 1
      };
      this.$api.mall.goodsSearchInfo(param).then(res => {
        if (res.code == 200) {
          this.goodsSearchInfo = res.result;
          // 全部分类
          var cate = this.goodsSearchInfo.cateList;
          for (var i in cate) {
            var classname = "btn";
            cate[i].classname = classname;
          }
          this.cateList = cate;
          // 品牌
          var brand = this.goodsSearchInfo.brandList;
          for (var j in brand) {
            var classbrand = "btn";
            brand[j].classbrand = classbrand;
          }
          this.brandList = brand;
          //选中项activevalue
          var attr = this.goodsSearchInfo.attrList;
          for (var k in attr) {
            attr[k].activevalue = "";
            for (var m in attr[k].item) {
              var classattr = "btn";
              attr[k].item[m].classattr = classattr;
            }
          }
          // console.log(this.goodsSearchInfo.attrList);
        }
      });
    },
    //点击全部分类按钮
    allsort(index, id) {
      this.allsortId = id;
      // console.log("id",this.allsortId);
      for (var i in this.cateList) {
        this.cateList[i].classname = "btn";
      }
      this.cateList[index].classname =
        this.cateList[index].classname == "btn" ? "active" : "btn";
      this.cateName = this.cateList[index].name;
      this.$forceUpdate(); //强制刷新数据
      var param = {
        token: this.$store.getters.token,
        id: id,
        is_member: 1
      };
      this.$api.mall.goodsCateChildren(param).then(res => {
        this.goodsCateChildren = res.result;
        // 二级分类
        for (var j in this.goodsCateChildren.list) {
          var CateChildren = "btn";
          this.goodsCateChildren.list[j].classCateChildren = CateChildren;
        }

        this.catearr = [{ id: id, child: this.goodsCateChildren }];
        // console.log("this.catearr", this.catearr);
        this.activeNames.push("erji0");
        for (var m = 0; m < this.activeNames.length; m++) {
          for (var n = m + 1; n < this.activeNames.length; n++) {
            if (this.activeNames[m] == this.activeNames[n]) {
              this.activeNames.splice(n, 1);
            }
          }
        }
      });
    },
    // 点击品牌按钮
    pinpaisort(index, id) {
      this.pinpaiId = id;
      for (var i in this.brandList) {
        this.brandList[i].classbrand = "btn";
      }
      this.brandList[index].classbrand =
        this.brandList[index].classbrand == "btn" ? "active" : "btn";
      this.brandName = this.brandList[index].name;
    },
    //点击二级分类
    erjisort(index, id, i) {
      this.allsortId = this.catearr[this.catearr.length - 1].id;
      // console.log("id", this.allsortId);
      for (var c in this.catearr) {
        if (c > i) {
          this.catearr.splice(c);
          // console.log("qqq",this.catearr.length, this.catearr);
        }
      }
      // this.activeNames.splice(i + 4);
      var param = {
        token: this.$store.getters.token,
        id: id,
        is_member: 1
      };
      this.$api.mall.goodsCateChildren(param).then(res => {
        this.goodsCateChildren = res.result;
        for (var j in this.goodsCateChildren.list) {
          var CateChildren = "btn";
          this.goodsCateChildren.list[j].classCateChildren = CateChildren;
        }
        for (var a in this.catearr[i].child.list) {
          this.catearr[i].child.list[a].classCateChildren = "btn";
        }
        this.catearr[i].child.list[index].classCateChildren =
          this.catearr[i].child.list[index].classCateChildren == "btn"
            ? "active"
            : "btn";
        this.catearr[i].erjiName = this.catearr[i].child.list[index].name;
        var obj = {
          id: id,
          child: this.goodsCateChildren
        };
        this.catearr.push(obj);
        this.activeNames.push("erji" + (i + 1));
        for (var m = 0; m < this.activeNames.length; m++) {
          for (var n = m + 1; n < this.activeNames.length; n++) {
            if (this.activeNames[m] == this.activeNames[n]) {
              this.activeNames.splice(n, 1);
            }
          }
        }
        // console.log("hhh", i, this.catearr);
        // console.log(this.activeNames);
      });
    },
    //点击按钮
    attractive(ie, e, index, id) {
      this.shuxingId[index] = id;
      // console.log("qq",index,this.shuxingId);
      var attritem = this.goodsSearchInfo.attrList[index];
      for (var i in attritem.item) {
        attritem.item[i].classattr = "btn";
      }
      e.classattr = e.classattr == "btn" ? "active" : "btn";
      ie.activevalue = e.item;
      this.$forceUpdate(); //强制刷新数据
    },
    //点击确定
    determine() {
      window.localStorage.removeItem("allsortId");
      window.localStorage.removeItem("minprice");
      window.localStorage.removeItem("maxprice");
      window.localStorage.removeItem("shuxingId");
      this.minprice = document.getElementsByClassName("minprice")[0].value;
      this.maxprice = document.getElementsByClassName("maxprice")[0].value;
      this.showsort = false;
      this.cateName = "";
      this.brandName = "";
      this.getGoodsList();
    },
    //点击重置
    reset() {
      this.cateName = "";
      this.brandName = "";
      document.getElementsByClassName("minprice")[0].value = "";
      document.getElementsByClassName("maxprice")[0].value = "";
      // this.erjiName = "";
      // this.minprice = "";
      // this.maxprice = "";
      for (var e in this.catearr) {
        this.catearr[e].erjiName = "";
      }
      // 二级
      if (this.catearr.length > 0) {
        this.catearr = [];
      }
      for (var i in this.cateList) {
        this.cateList[i].classname = "btn";
      }
      for (var j in this.brandList) {
        this.brandList[j].classbrand = "btn";
      }
      for (var k in this.goodsSearchInfo.attrList) {
        this.goodsSearchInfo.attrList[k].activevalue = "";
        for (var m in this.goodsSearchInfo.attrList[k].item) {
          this.goodsSearchInfo.attrList[k].item[m].classattr = "btn";
        }
      }
    },
    //跳转到商城页
    toMall() {
      this.$router.push({
        path: "/shopping_mall"
      });
    },
    //   搜索
    onSearch() {},
    //   分类
    onSort() {},
    // 弹出层
    showPopup() {
      this.showsort = true;
      this.getGoodsSearchInfo();
      if (document.getElementsByClassName("minprice")[0] || document.getElementsByClassName("maxprice")[0]) {
        document.getElementsByClassName("minprice")[0].value = "";
        document.getElementsByClassName("maxprice")[0].value = "";
      }
      this.cateName = "";
      this.brandName = "";
      // this.erjiName = "";
      for (var e in this.catearr) {
        this.catearr[e].erjiName = "";
      }
      // 二级
      if (this.catearr.length > 0) {
        this.catearr = [];
      }
      for (var i in this.cateList) {
        this.cateList[i].classname = "btn";
      }
      for (var j in this.brandList) {
        this.brandList[j].classbrand = "btn";
      }
      for (var k in this.goodsSearchInfo.attrList) {
        this.goodsSearchInfo.attrList[k].activevalue = "";
        for (var m in this.goodsSearchInfo.attrList[k].item) {
          this.goodsSearchInfo.attrList[k].item[m].classattr = "btn";
        }
      }
    },
    // 点击商品跳转商品详情页
    toDetalis(id) {
      var that = this;
      that.$router.push({
        path: "/goods_details",
        query: {
          id: id
        }
      });
    }
  }
};
</script>

<style>
</style>